import React, { useState } from 'react'
import SmallForm from "../../components/forms/small_form";
import { RESTExecutor, withDomains } from "../../lib/domain";

const EditPage = ({
    domains,
    ...props
}) => {
    let edit_user = RESTExecutor.post()
        .config({
            label: 'edit',
        })
        .callbacks(
            (success) => {
                get_user.execute(true)
                props.history.goBack()
            }
        )
        .connect(domains.user)

    let get_user = RESTExecutor.list()
        .config({
            label: 'me'
        })
        .connect(domains.user)

    let resp = get_user.response().result?.data ?? {}

    const [formData, setFormData] = useState({
        'first_name': resp.first_name,
        'last_name': resp.last_name
    })


    return (
        <div>
            <SmallForm
                networkState={edit_user.response()}
                data={
                    formData
                }

                onChange={(val) => {
                    setFormData({
                        ...formData,
                        ...val
                    })
                }}

                title="Edit User Data"
                schema={USER_SCHEMA}
                onSave={() => {
                    edit_user.execute(formData)
                }}
            />
        </div>
    )
}

const USER_SCHEMA = [
    { label: "First name", type: 'text', index: 'first_name', required: true },
    { label: "Last name", type: 'text', index: 'last_name', required: false },

]

export default withDomains(EditPage, 'user')