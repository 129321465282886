import React, { useEffect, useState } from 'react'
import { RESTExecutor, withDomains } from "../lib/domain";
import { Link } from "react-router-dom";
import { Row, Space, Menu, Divider, Dropdown, message, Button, Tooltip, Modal } from "antd";
import { CustomButton } from '../components/forms/buttons';
import CompanyDetailCard from '../components/company_detail_card';
import { PlusOutlined, PlusCircleOutlined, SettingOutlined, LogoutOutlined, MailOutlined, CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { BASE_API_URL } from '../config';

let userName = '';
let imgSource = '';
const { confirm } = Modal;

const MainApplication = ({
    domains,
    authenticator,
    ...props

}) => {


    let get_companies = RESTExecutor.list()
        .config({
            label: 'namespace_register',
            params: { status: "Pending" }
        })
        .callbacks(
            (success) => { },
            (error) => {
                console.log(error);
                error.error_message && message.error(error.error_message);
            }
        )
        .connect(domains.register)

    let list_namespace = RESTExecutor.list()
        .config({
            label: 'list_namespace',
        })
        .connect(domains.user)

    let get_user = RESTExecutor.list()
        .config({
            label: 'me'
        }).callbacks(
            (success) => {
                // setResult(success.result);
                imgSource = success.result.profile_picture_id_url != null ? `${success.result.profile_picture_id_url}` : `https://ui-avatars.com/api/?name=${success.result.name.split(" ")[0]}+${success.result.name.split(" ")[1]}&background=1ED760&color=fff&size=36&rounded=true`
                userName = success.result.name == '' ? success.result.email : success.result.name;
            }
        )
        .connect(domains.user)


    let namespace_invitations = RESTExecutor.list()
        .config({
            label: 'namespace_invitations',
            params: { status: "Pending" }
        }).callbacks(
            (success) => {
            }
        )
        .connect(domains.user)



    let delete_companies = RESTExecutor.delete()
        .config({
            label: 'namespace_requests',
        })
        .callbacks(
            (success) => get_companies.execute(true),
            (error) => {
                console.log(error);
                error.error_message && message.error(error.error_message);
            }
        )
        .connect(domains.register)


    let leave_company = RESTExecutor.post()
        .config({
            label: 'leave_company'
        }).callbacks(
            (success) => {
                // message.success("You have successfully left the company");
                list_namespace.execute(true);
            },
            (error) => message.error(error.error_message)
        ).connect(domains.user)

    let respond_to_invitation = RESTExecutor.post()
        .config({
            label: 'respond_to_invitation'
        }).callbacks(
            (success) => {
                namespace_invitations.execute(true);
                list_namespace.execute(true);
            }
        )
        .connect(domains.user)


    let upgrade_credentials = RESTExecutor.post()
        .config({
            label: 'upgrade_credentials',
        })
        .connect(domains.upgradeCredentials)

    useEffect(() => {
        namespace_invitations.execute(true)
        list_namespace.execute(true)
        get_user.execute(true)
        get_companies.execute(true)
    }, [])


    let namespace_resp = list_namespace.response();
    let invitations_resp = namespace_invitations.response();
    let invite_resp = respond_to_invitation.response();
    let user_resp = get_user.response();
    let companies_resp = get_companies.response();
    // let result = user_resp.result;


    let menu = (
        <Menu className={'dropdown-menu'}>
            <Menu.Item key='1' icon={<img className="user-avatar-img" src={imgSource} />}>
                <span className="menu-item-text">{userName}</span>
            </Menu.Item>
            <Menu.Divider />

            <div className="companies-menu-item-header" >Your Companies</div>

            {
                (namespace_resp.result ?? []).map(
                    (item) =>

                        <Menu.Item key={item.slug}
                            icon={
                                <div className="menu-item-avatar-container">
                                    <img className="menu-item-avatar"
                                        src={item.logo_url != null ? `${item.logo_url}` : `https://ui-avatars.com/api/?name=${item.name.split(" ")[0]}+${item.name.split(" ")[1]}&background=1ED760&color=fff`}
                                    />
                                </div>
                            }
                            title={`${item.name}`}
                            onClick={() => {
                                upgrade_credentials
                                    .onSuccess = ((resp) => {
                                        let str = "";
                                        Object.entries(resp.result).map(([k, v]) => str += `${k}=${v}&`);
                                        let staging = process.env.REACT_APP_nodeenv === 'production' ? '' : '/staging'
                                        window.open(`https://${item.slug}.tigg.app${staging}/#/validate-login?${str.slice(0, -1)}`)
                                    });
                                upgrade_credentials.execute({
                                    "namespace": `${item.slug}`
                                })
                            }}
                        >
                            <span className="menu-item-text">{item.name}</span>
                        </Menu.Item>
                )
            }
            <Menu.Item key='2' icon={<PlusOutlined />} onClick={() => {
                props.history.push('/new-company');
            }}>
                Add New Company
            </Menu.Item>

            <Menu.Divider />
            <Menu.Item key='3' icon={<SettingOutlined />} onClick={() => {
                props.history.push('/account-settings');
            }}>
                Account Settings
            </Menu.Item>

            <Menu.Divider />
            <Menu.Item key='4' icon={<LogoutOutlined />} onClick={() => authenticator.logout()}>
                Logout
            </Menu.Item>

        </Menu>
    );


    const companyMenu = (key, title, id, email) => {
        return (
            <Menu>
                <Menu.Item icon={<MailOutlined />} onClick={() => { window.open(`mailto:${email}?subject=${title}`) }}>
                    Contact Admin
                </Menu.Item>
                <Menu.Item icon={<CloseOutlined />} onClick={() => {
                    function leaveCompany() {
                        leave_company.execute({ "namespace_id": id })
                    }
                    showPromiseConfirm("Do you really want to leave the company?", leaveCompany);


                }}>
                    Leave Company
                </Menu.Item>
            </Menu>
        )
    };


    const invitationsMenu = (key, inviteId, title, email) => {
        return (
            <Menu>
                <Menu.Item icon={<MailOutlined />} onClick={() => {
                    let data = { accept: false, id: inviteId };
                    function rejectInvitation() {
                        respond_to_invitation.execute(data);
                    }
                    showPromiseConfirm("Do you really want to reject this invitation?", rejectInvitation);

                }}>
                    Reject Invitation
                </Menu.Item>
                <Menu.Item icon={<MailOutlined />} onClick={() => { window.open(`mailto:${email}?subject=${title}`) }}>
                    Contact Admin
                </Menu.Item>
            </Menu>);
    }


    const requestsMenu = (id) => {
        return (
            <Menu>
                <Menu.Item icon={<CloseOutlined />} onClick={() => {
                    function deleteCompany() {
                        delete_companies.forId(id).execute(true);
                    }
                    showPromiseConfirm("Do you really want to cancel your request?", deleteCompany);

                }}>
                    Cancel Request
                </Menu.Item>
            </Menu>
        );
    };

    function showPromiseConfirm(title, ok) {
        confirm({
            title: title,
            content: "This action cannot be reversed",
            icon: <ExclamationCircleOutlined />,
            onOk() {
                ok()
            },
            onCancel() { },
        });
    }


    return (
        <div className={'application-container'}>

            <div className="main-header-menu">
                <div className="header-container">
                    <img style={{ cursor: 'pointer' }} src='images/logo.svg' className='main-header-menu-item' onClick={() => { window.open('https://tiggapp.com', "_self") }} />
                    <div className='main-header-menu-item'>
                        <span className='add-org-button'>
                            <CustomButton
                                onClick={() => {
                                    props.history.push({ pathname: `/new-company` });
                                }}
                                label="Add New Organisation"
                                style={{ borderRadius: "4px", backgroundColor: "#E8FBEF" }}
                            />
                        </span>
                        <Tooltip className='add-org-icon'
                            title='Add New Organisation'>
                            <PlusCircleOutlined
                                style={{ display: 'none', color: 'var(--primary)', fontSize: '32px' }}
                                onClick={() => {
                                    props.history.push({ pathname: `/new-company` });
                                }}
                            />
                        </Tooltip>
                        <span style={{ width: "20px" }}></span>



                        <Dropdown overlay={menu} trigger={['click']}>
                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                <img className="user-avatar-img" src={imgSource} />
                            </a>
                        </Dropdown>
                    </div>
                </div>
            </div>

            <div className='main-container'>

                <div className="dashboard-container">

                    <div className="company-card-menu">

                        <div className="unassociated-user" style={{ display: companies_resp.result?.length != 0 || namespace_resp.result || invitations_resp.result?.length != 0 ? "none" : "flex" }}>
                            <div className="unassociated-user-header">
                                You are not associated with any organisation right now
                            </div>
                            <div className="unassociated-user-action">
                                <Button
                                    size="large"
                                    className="company-detail-action-button-accept"
                                    onClick={() => { props.history.push('/new-company') }}
                                >
                                    Start your own
                                </Button>
                            </div>
                        </div>

                        <div className="comapny-card-menu-requests" style={{ display: (companies_resp.result && companies_resp.result?.length != 0) ? "block" : "none" }}>
                            <div className="company-card-menu-header">Requests</div>
                            <div className="company-card-content">
                                {
                                    (companies_resp.result ?? []).map(
                                        (item) =>
                                            <CompanyDetailCard
                                                loading={companies_resp?.fetching}
                                                avatar={item.data.logo_url != null ? `${item.data.logo_url}` : `https://ui-avatars.com/api/?name=${item.data.name.split(" ")[0]}+${item.data.name.split(" ")[1]}&background=1ED760&color=fff`}
                                                menu={requestsMenu(item.data.id)}
                                                key={item.data.id}
                                                slug={item.data.slug}
                                                title={item.data.name}
                                                subtitle={`${item.data.display_name} | ${item.data.slug}.tigg.app`}
                                                actionName="Cancel Request"
                                                submitStyle="company-detail-action-button-cancel"
                                                detailInfo={`Status ${item.data.status}`}
                                                onClick={() => {

                                                    function deleteCompany() {
                                                        delete_companies.forId(item.data.id).execute(true);

                                                    }
                                                    showPromiseConfirm("Do you really want to cancel your request?", deleteCompany);

                                                }}
                                            >
                                            </CompanyDetailCard>
                                    )
                                }
                            </div>
                        </div>

                        <div className="comapny-card-menu-invites" style={{ display: (invitations_resp.result && invitations_resp.result?.length != 0) ? "block" : "none" }}>
                            <div className="company-card-menu-header">Invitations</div>
                            <div className="company-card-content">
                                {
                                    (invitations_resp.result ?? []).map(
                                        (item) =>

                                            <CompanyDetailCard
                                                loading={invite_resp?.fetching}
                                                avatar={item.company.logo_url != null ? `${item.company.logo_url}` : `https://ui-avatars.com/api/?name=${item.company.display_name.split(" ")[0]}+${item.company.display_name.split(" ")[1]}&background=1ED760&color=fff`}
                                                menu={invitationsMenu(item.namespace, item.id, item.namespace, item.company.email)}
                                                key={item.id}
                                                slug={item.company.slug}
                                                title={item.company.name}
                                                // subtitle={`${item.company.members} members | ${item.slug}.tigg.app`}
                                                subtitle={`${item.slug}.tigg.app`}
                                                actionName="Accept Invite"
                                                submitStyle="company-detail-action-button-accept"
                                                detailInfo={`Invited by ${item.referrer.name}`}
                                                onClick={() => {
                                                    let data = { accept: true, id: item.id };

                                                    function acceptInvite() {
                                                        respond_to_invitation.execute(data);
                                                    }

                                                    showPromiseConfirm("Do you really want to accept this invitation?", acceptInvite);

                                                }}
                                            >
                                            </CompanyDetailCard>
                                    )
                                }
                            </div>
                        </div>

                        <div className="comapny-card-menu-list" style={{ display: namespace_resp.result ? "block" : "none" }}>
                            <div className="company-card-menu-header">Your Organisations</div>
                            <div className="company-card-content">
                                {
                                    (namespace_resp.result ?? []).map(
                                        (item) =>

                                            <CompanyDetailCard
                                                loading={namespace_resp?.fetching}
                                                avatar={item.logo_url != null ? `${item.logo_url}` : `https://ui-avatars.com/api/?name=${item.name.split(" ")[0]}+${item.name.split(" ")[1]}&background=1ED760&color=fff`}
                                                menu={companyMenu(item.slug, item.name, item.id, item.email)}
                                                key={item.slug}
                                                slug={item.slug}
                                                title={item.name}
                                                subtitle={`${item.slug}.tigg.app`}
                                                detailInfo={`${item.registered_address}`}
                                                onClick={() => {
                                                    upgrade_credentials
                                                        .onSuccess = ((resp) => {
                                                            let str = "";
                                                            Object.entries(resp.result).map(([k, v]) => str += `${k}=${v}&`);
                                                            let staging = process.env.REACT_APP_nodeenv === 'production' ? '' : '/staging'
                                                            window.open(`https://${item.slug}.tigg.app${staging}/#/validate-login?${str.slice(0, -1)}`)
                                                        });
                                                    upgrade_credentials.execute({
                                                        "namespace": `${item.slug}`
                                                    })
                                                }}
                                                submitStyle="company-detail-action-button-open"
                                            >

                                            </CompanyDetailCard>
                                    )
                                }
                            </div>



                        </div>
                    </div>
                </div>
            </div>


        </div >)
}


export default withDomains(MainApplication, 'user', 'auth', 'upgradeCredentials', 'register')
