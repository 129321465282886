import React, { Component } from 'react';
import { RESTExecutor, withDomains } from '../../lib/domain';
import { Space, Upload } from 'antd';
import PropTypes from 'prop-types'
import { FileDisplayItem } from '../basic_items';
import { showErrorInNotification } from '../../lib/util';
import { AddIcon } from '../../assets/icons';



const { Dragger } = Upload;


/**
 * @constructor
 * @augments {Component<Props, State>}

 */

class FileUploadInput extends Component {
    static propTypes = {
        /** Custom Style properties*/
        style: PropTypes.object,

        /** Label */
        label: PropTypes.string,

        /** Required */
        required: PropTypes.bool,

        /** Extensions to accept acceptedFileExtensions='.png, .xls'*/
        acceptedFileExtensions: PropTypes.string,

        /** triggered when file uploaded to server, gives array of file object with url,size,name */
        onChangeFileList: PropTypes.func,

        /** array of file object with url,size,name */
        value: PropTypes.array,
    }
    constructor(props) {
        super(props)

        this.state = {
            files: props.value || [],
            selected_files: [],
            uploaded_files: [],
            loading_uids: [],
            error_ids: [],
            error_files: []
        }

        this.upload_file = RESTExecutor.post().config({
            // url:'https://call-logs.raralabs.com/gokyo-kb/upload-file',
            label: 'upload',
            headers: { Accepts: 'multipart/form-data' }
        }).callbacks((success) => {
            console.log("file uploaded", success)
        }, (error) => {
            console.log("file upload failed", error)
        }).connect(props.domains.fileUpload);
    }


    uploadFiles = (fileList) => {
        console.log("uploading files");
        this.setState({
            selected_files: fileList
        })
    }
    retryFileToServer = (file) => {
        let formData = new FormData();
        console.log("file is", typeof file, file)
        var { loading_uids, selected_files, error_files, error_ids } = this.state;
        formData.append('file', file);
        error_ids.splice(error_ids.indexOf(file.uid), 1);
        error_files.splice(error_files.indexOf(file), 1);
        loading_uids.push(file.uid);
        selected_files.push(file);
        this.setState({
            error_ids,
            error_files,
            loading_uids,
            selected_files
        }, () => {
            this.upload_file.callbacks((success) => {
                var { loading_uids, selected_files } = this.state;
                loading_uids.splice(loading_uids.indexOf(file.uid), 1);
                this.setState({
                    loading_uids
                })
            }, (error) => {
                var { loading_uids, selected_files, error_files, error_ids } = this.state;
                loading_uids.splice(loading_uids.indexOf(file.uid), 1);
                selected_files.splice(selected_files.indexOf(file), 1);
                error_files.push(file);
                error_ids.push(file.id);
                this.setState({
                    loading_uids,
                    selected_files,
                    error_ids,
                    error_files
                })

                //TODO handle error status of file and retry option
                showErrorInNotification(error)
            }).execute(formData)

        })

    }
    pushFileToServer = (file) => {
        let formData = new FormData();
        console.log("file is", typeof file, file)
        // formData.append('file',file);
        this.upload_file.callbacks((success) => {
            var { loading_uids, selected_files } = this.state;
            loading_uids.splice(loading_uids.indexOf(file.uid), 1);
            this.setState({
                loading_uids
            })
        }, (error) => {
            var { loading_uids, selected_files, error_files, error_ids } = this.state;
            loading_uids.splice(loading_uids.indexOf(file.uid), 1);
            selected_files.splice(selected_files.indexOf(file), 1);
            error_files.push(file);
            error_ids.push(file.id);
            this.setState({
                loading_uids,
                selected_files,
                error_ids,
                error_files
            })

            //TODO handle error status of file and retry option
            showErrorInNotification(error)
        }).execute(formData)
    }
    render() {
        const { style, label, required, acceptedFileExtensions = '.png', value, onChangeFileList, direction = 'horizonal' } = this.props;
        return (
            <div className='rl-form-item' style={{
                width: '100%',
                ...style

            }}>
                {label != null &&
                    <h4><span className='form-label'>{label}</span>{required && <span className='error-color'> *</span>}</h4>}

                <div className='rl-upload-input-wrapper'>
                    {
                        this.state.selected_files.map((fileItem) => {
                            return <FileDisplayItem
                                uploading={this.state.loading_uids.includes(fileItem.uid)}
                                data={{
                                    name: fileItem.name,
                                    size: fileItem.size
                                }} />
                        })
                    }
                    {
                        this.state.error_files.map((fileItem) => {
                            return <FileDisplayItem
                                // uploading={this.state.loading_uids.includes(fileItem.uid)}
                                error={true}
                                onRetry={() => {
                                    this.retryFileToServer(fileItem)
                                }}
                                data={{
                                    name: fileItem.name,
                                    size: fileItem.size
                                }} />
                        })
                    }
                    <Dragger
                        accept={acceptedFileExtensions}
                        showUploadList={false}
                        multiple={true}
                        onChange={(info) => {
                        }}
                        beforeUpload={(file, fileList) => {
                            // this.uploadFiles(fileList)
                            this.pushFileToServer(file)
                        }}
                        customRequest={(options) => {
                            var { loading_uids, selected_files } = this.state;
                            // this.pushFileToServer(options.file)
                            let file = options.file
                            loading_uids.push(file.uid);
                            selected_files.push(file);
                            this.setState({
                                loading_uids,
                                selected_files
                            })
                            console.log("options", options)
                        }}
                        style={{
                        }} className='rl-upload-input' >
                        <div className='content'>
                            <AddIcon className='add-icon' />
                            <div className='detail'>
                                <p className="rl-upload-text">Upload or Drag Files</p>
                                <p className="rl-upload-hint">(JPG, PNG, PDF, XLS, TXT)</p>
                            </div>
                        </div>
                    </Dragger>
                </div>

            </div>
        );
    }
}

export default FileUploadInput = withDomains(FileUploadInput, "fileUpload");
