import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import APP_CONFIG from './config';
import { DomainHolder, DomainProvider } from './lib/domain';
import { basicDomains, networkDomains } from './domains';

let { dev, target, network, auth } = APP_CONFIG;

const domainHolder = new DomainHolder(
    {
        development_mode: dev,
        target: target,
        network_config: network,
        auth_config: auth,
    }
);
domainHolder.build({ basic: basicDomains, network: networkDomains });



ReactDOM.render(
    <DomainProvider holder={domainHolder}>
        <App />
    </DomainProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
