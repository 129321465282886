
const COLLAPSED_LEFT_WIDTH = 58;

class _LayoutManager {
    // THESE VARIABLES ARE FIXED TO ALL CONFIGURATION (EXCEPT AVAILABLE WIDTH);

    globalMargin = 20;
    globalPadding = 20;
    globalSpacing = 20;

    rightSideBarWidth = 0;

    columnWidth = 0;
    availableWidth = 0;

    formSpacing = 30;
    formMargin = 30;
    formPadding = 30;

    contentWidth=0;

    formWidth=0;

    formLeftArea = 0;


    formRightArea=0;


    formColsWidth = 0;



    smallFormWidth = 0;

    leftMargin=50;
    rightMargin=50;


    // INITIALLY LET US ASSUME LEFT SIDE BAR IS EXPANDED
    expanded = true;

    constructor(expanded = true) {
        // console.log("CALCULATION DIMENSIONS FOR LEFT NAV EXPANDED = ", expanded)
        this.expanded = expanded;
        this.availableWidth = (window.innerWidth <= 1280 ? 1280 : window.innerWidth);


        this.columnWidth = Math.floor(((this.availableWidth) - 13 * 20) / 12);

        this.formWidth=this.availableWidth;



        this.formLeftArea = Math.floor(0.6 * (this.formWidth)) - 40;

        this.formEditorArea=Math.floor(0.8*this.formLeftArea) - this.formSpacing;
        this.formExtraArea=this.formLeftArea-this.formEditorArea - this.formSpacing;

       
        this.formRightArea=this.formWidth-this.formLeftArea;


        this.formColsWidth = Math.floor((this.formEditorArea - 2 * this.formSpacing) / 2);


        this.smallFormWidth = (2 * this.formColsWidth + 3 * this.formSpacing);
        // console.log("LAYOUR CALC",{
        //     formLeft:this.formLeftArea,
        //     formEditor:this.formEditorArea,
        //     formExtra:this.formExtraArea,
        //     formRightArea:this.formRightArea,
        //     formCols:this.formColsWidth,
        //     smallForm:this.smallFormWidth
        // })


        let leftSideBarWidth = 0;
       



        // if (expanded) {
        //     // LEFT SIDE BAR IS EXPANDED
        //     leftSideBarWidth =0;// 2 * this.columnWidth + this.globalSpacing;
        //     contentWidth = this.availableWidth - this.rightSideBarWidth - leftSideBarWidth-100;
        //     formRightArea = contentWidth + this.rightSideBarWidth + (0 * this.globalSpacing) - this.formLeftArea;
        //     formWidth = this.formLeftArea + formRightArea;

        // } else {
        //     // LEFT SIDE BAR IS COLLAPSED
        //     leftSideBarWidth = 0; //COLLAPSED_LEFT_WIDTH;
        //     contentWidth = this.availableWidth - this.rightSideBarWidth - leftSideBarWidth-100;
        //     formRightArea = contentWidth + this.rightSideBarWidth + (0 * this.globalSpacing) - this.formLeftArea;
        //     formWidth = this.formLeftArea + formRightArea;
        // }


        document.documentElement.style.setProperty("--main-width", `${this.availableWidth}px`);

        // document.documentElement.style.setProperty("--left-side-bar-width", `${leftSideBarWidth}px`);
        document.documentElement.style.setProperty("--right-side-bar-width", `${this.formRightArea}px`);


        document.documentElement.style.setProperty("--form-width", `${this.formWidth}px`)

        document.documentElement.style.setProperty("--form-left-width", `${this.formLeftArea}px`)
        document.documentElement.style.setProperty("--form-right-width", `${this.formRightArea}px`)
        document.documentElement.style.setProperty("--form-segment-left-width",`${this.formExtraArea}px`);
        document.documentElement.style.setProperty("--form-cols-width", `${this.formColsWidth}px`)

        document.documentElement.style.setProperty("--small-form-width", `${this.smallFormWidth}px`)


        // console.log("NEW CONFIG IS ", this)

    }

    getMeasurements() {
        return this;
    }

    // updateResize = (expanded) => {
    //     if (expanded) {
    //         let formRightArea = this.contentWidthExpanded + this.rightSideBarWidth + (3 * this.globalSpacing) - this.formLeftArea;
    //         // let formColsWidth = parseInt((this.formLeftArea - 4 * this.formSpacing) / 3);
    //         // document.documentElement.style.setProperty("--form-cols-width",`${formColsWidth}px`)
    //         document.documentElement.style.setProperty("--form-right-with-image-width", `${formRightArea}px`)
    //         console.log("expanded", expanded, formRightArea)
    //         // Calculate form right area and form cols width for left side bar expanded for non image only
    //     } else {
    //         // calculate form right area and form cols width  for left side bar collapsed for non image only
    //         let formRightArea = this.contentWidthCollapsed + this.rightSideBarWidth + (3 * this.globalSpacing) - this.formLeftArea;
    //         // let formColsWidth  = parseInt((this.formLeftArea - 4 * this.formSpacing) / 3);
    //         console.log("expanded", expanded, formRightArea)
    //         // document.documentElement.style.setProperty("--form-cols-width",`${formColsWidth}px`)
    //         document.documentElement.style.setProperty("--form-right-with-image-width", `${formRightArea}px`)

    //     }
    // }





}
var LayoutManager = new _LayoutManager();
Object.freeze(LayoutManager);

function updateLayoutManager(expanded) {
    if (expanded != LayoutManager.expanded) {
        Object.seal(LayoutManager);
        LayoutManager = new _LayoutManager(expanded)
        // console.log("new layout manger formed", LayoutManager)
        Object.freeze(LayoutManager)
    } else {
        // console.log("new layout not needed")
    }
}

function toggleLayoutManager() {
    Object.seal(LayoutManager);
    LayoutManager = new _LayoutManager(!LayoutManager.expanded);
    try{
        window.left_bar.toggleExpandState()
    }catch(e){

    }
    // console.log("toggle layout manager");
    Object.freeze(LayoutManager)
}

function refreshLayoutManager() {
    Object.seal(LayoutManager);
    LayoutManager = new _LayoutManager(LayoutManager.expanded);
    // console.log("refresh layout manager");
    Object.freeze(LayoutManager)
}

export { LayoutManager, updateLayoutManager, toggleLayoutManager, refreshLayoutManager };

