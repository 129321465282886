import React from 'react'
import { RESTExecutor, withDomains } from "../../lib/domain";
import { Form, Input, Button, message } from "antd";
import { LockOutlined } from "@ant-design/icons"

const ResetPasswordPage = ({
    domains,
    ...props
}) => {

    const [reset_password_form] = Form.useForm();

    let reset_password = RESTExecutor.post()
        .config({
            label: 'reset_password',
        })
        .callbacks(
            (success) => {
                message.success("Password changed successfully. Please login to your account.");
                props.history.push('/');
            },
            (error) => {
            }
        )
        .forId(props.location.search)
        .connect(domains.password)


    const onFinish = (formData) => {
        reset_password.execute(formData)
    };


    let { validation_errors = {}, error_message } = reset_password.response();
    let validateStatus = Object.keys(validation_errors || {}).length > 0 ? 'error' : undefined
    validateStatus = error_message ? 'error' : undefined


    return (
        <div>
            <div className="container">
                <div className="header-menu">
                    < img src='images/logo.svg' className='header-menu-item' />
                    <span className='header-menu-item'>
                        Remember your Password?
                        <a onClick={() => { props.history.push('/') }}>
                            &nbsp;Log In
                        </a>
                    </span>
                </div>
            </div>
            <div className="main-container">
                <div className="container">
                    <div className="form-container">
                        <img src="images/logo2.svg" className="form-logo" />
                        <p className="form-header" style={{ paddingBottom: "3%" }}>Reset Password</p>

                        < Form
                            requiredMark={false}
                            colon={false}
                            form={reset_password_form}
                            name="forgot-password"
                            className="login-form"
                            onFinish={
                                onFinish
                            }
                        >
                            <Form.Item
                                label="New Password"
                                labelCol={{ span: 24 }}
                                name="new_password"
                                validateStatus={validateStatus}
                                help={(validation_errors?.new_password ?? [null])[0] || error_message}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your new password!",
                                        validateStatus: 'error',
                                    },
                                ]}
                            >
                                <Input.Password
                                    prefix={<LockOutlined />}
                                    placeholder="password"
                                />
                            </Form.Item>


                            <Form.Item
                                label="Confirm Password"
                                labelCol={{ span: 24 }}
                                name="confirm_password"
                                hasFeedback
                                validateStatus={validateStatus}
                                help={(validation_errors?.confirm_password ?? [null])[0] || error_message}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please retype your new password!",
                                        validateStatus: 'error',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('new_password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password
                                    prefix={<LockOutlined />}
                                    placeholder="confirm password"
                                />
                            </Form.Item>

                            <Form.Item>
                                <Button
                                    loading={reset_password.response()?.fetching}
                                    size="large"
                                    htmlType="submit" className="login-form-button">
                                    RESET PASSWORD
                                </Button>
                            </Form.Item>
                        </Form >
                    </div>
                </div>
            </div>
        </div >
    )
}


export default withDomains(ResetPasswordPage, 'password')