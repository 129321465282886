import React, { useState } from 'react'
import { RESTExecutor, withDomains } from "../../lib/domain";
import { Form, Input, Button, message } from "antd";


const ForgotPasswordPage = ({
    domains,
    ...props
}) => {

    const [forgot_password_form] = Form.useForm();

    let send_mail = RESTExecutor.post()
        .config({
            label: 'send_email',
        })
        .callbacks(
            (success) => {
                message.success("Email successfully sent");
            },
            (error) => {
            }
        )
        .connect(domains.password)

    const [emaildata, setData] = useState({ email: "" });

    const onFinish = (formData) => {
        send_mail.execute(formData)
    };


    let { validation_errors = {}, error_message } = send_mail.response();
    let validateStatus = Object.keys(validation_errors || {}).length > 0 ? 'error' : undefined
    validateStatus = error_message ? 'error' : undefined
    return (
        <div>
            <div className="container">
                <div className="header-menu">
                    < img src='images/logo.svg' className='header-menu-item' />
                    <span className='header-menu-item'>
                        Remember your Password?
                        <a onClick={() => { props.history.push('/') }}>
                            &nbsp;Log In
                        </a>
                    </span>
                </div>
            </div>
            <div className="main-container">
                <div className="container">
                    <div className="form-container">
                        <img src="images/logo2.svg" className="form-logo" />
                        <p className="form-header" style={{ paddingBottom: "3%" }}>Forgot Password</p>

                        < Form
                            form={forgot_password_form}
                            name="forgot-password"
                            className="login-form"
                            onChange={(data) => {
                                setData({ "email": forgot_password_form.getFieldValue("email") });
                            }}
                            onFinish={
                                onFinish
                            }
                        >
                            <Form.Item
                                name="email"
                                required
                                validateStatus={validateStatus}
                                help={(validation_errors?.email ?? [null])[0] || error_message}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your Email!",
                                        validateStatus: 'error',
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<img src="images/user.svg" />}
                                    placeholder="name@email.com"
                                />
                            </Form.Item>

                            <Form.Item>
                                <Button
                                    loading={send_mail.response()?.fetching}
                                    size="large"
                                    htmlType="submit" className="login-form-button">
                                    SEND EMAIL
                                </Button>
                            </Form.Item>
                            <Form.Item

                            >
                                Didn't recieve an email? <a onClick={() => { onFinish(emaildata) }}>Send Again</a>
                            </Form.Item>


                        </Form >
                    </div>
                </div>
            </div>
        </div >
    )
}

export default withDomains(ForgotPasswordPage, 'password')