import React, { useState, useEffect } from 'react'
import SmallForm from "../../components/forms/small_form";
import { RESTExecutor, withDomains } from "../../lib/domain";
import { Link } from "react-router-dom";
import { Form, Input, Button, Checkbox, message } from "antd";
import { LockOutlined } from '@ant-design/icons';
import CryptoJS from 'crypto-js';

const key1 = '981239812312312323';
const key2 = '9234809823948394';

const registeredId = loadFromSessionStorage('regId', key1);
let persistedState = loadFromSessionStorage("state", key2);


function loadFromSessionStorage(data, key) {
    try {
        const serializedState = sessionStorage.getItem(data);
        if (serializedState === null) {
            return undefined;
        }
        const decrypted = CryptoJS.AES.decrypt(serializedState, key).toString(CryptoJS.enc.Utf8);
        return JSON.parse(decrypted);
    } catch {
        return undefined;
    }
}

function saveToSessionStorage(state) {
    try {
        const serializedState = JSON.stringify(state);
        const encrypted = CryptoJS.AES.encrypt(serializedState, key2);
        sessionStorage.setItem('state', encrypted)
    } catch (e) {
        console.log(e)
    }
}


const SignupPage = ({
    domains,
    ...props
}) => {


    const onFinish = (formData) => {
        saveToSessionStorage({ ...formData })
        props.history.push({ pathname: `/signup-details` })
    };

    useEffect(() => {
        loadFromSessionStorage('state', key2)
    }, [])


    return (
        <div>
            <div className="container">
                <div className="header-menu">
                    < img src='images/logo.svg' className='header-menu-item' />
                </div>
            </div>
            <div className="main-container">



                <div className="container">
                    <div className="form-container">
                        <img src="images/logo2.svg" className="form-logo" />
                        <p className="form-header" >Your Account is verified</p>
                        <p className="form-sub-header" style={{ paddingBottom: "3%" }}>{props.match.params.email}</p>

                        < Form
                            name="register"
                            className="login-form"
                            initialValues={{
                                remember: true
                            }
                            }
                            onFinish={
                                onFinish
                            }
                        // onFinishFailed={
                        //     onFinishFailed
                        // }
                        >
                            <Form.Item
                                initialValue={loadFromSessionStorage('state', key2)?.name || null}
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your name!"
                                    }
                                ]}
                            >
                                <Input
                                    prefix={<img src="images/user.svg" />}
                                    placeholder="Enter your full name"
                                />
                            </Form.Item>
                            <Form.Item
                                initialValue={loadFromSessionStorage('state', key2)?.password || null}
                                name="password"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your Password!"
                                    },
                                    {
                                        // type: 'regexp',
                                        pattern: /^(?=.*\d)(?=.*[a-zA-Z]).{6,20}$/,
                                        // pattern: new RegExp("^(?=.*\d)(?=.*[a-zA-Z]).{6,20}$"),
                                        message: "Must contain at least one number and at least 6 to 20 characters. Special characters are also allowed."
                                    }
                                ]}
                            >
                                <Input.Password
                                    prefix={<LockOutlined />}
                                    placeholder="Create a Password"
                                    pattern="^(?=.*\d)(?=.*[a-zA-Z]).{6,20}$"
                                    title="Must contain at least one number and at least 6 to 20 characters. Special characters are also allowed."
                                />
                            </Form.Item>


                            <Form.Item
                                initialValue={loadFromSessionStorage('state', key2)?.confirm_password || null}
                                name="confirm_password"
                                hasFeedback
                                labelCol={{ span: 24 }}
                                rules={[
                                    {

                                        required: true,
                                        message: "Please retype your password",
                                        validateStatus: 'error'
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                        },
                                    }),
                                ]}

                            >
                                <Input.Password
                                    prefix={<LockOutlined />}
                                    placeholder="Retype your password"
                                    allowClear={true}
                                />
                            </Form.Item>

                            <Form.Item>
                                <Button
                                    size="large"
                                    htmlType="submit" className="login-form-button">
                                    Create Your Account
                                </Button>
                            </Form.Item>

                        </Form >
                    </div>
                </div>
            </div>
        </div>
    )
}


export default SignupPage;