import React, { Component } from 'react';
import { Checkbox } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { TextInput } from './forms/inputs';
import { TransparentButton, CustomButton } from './forms/buttons';

let list = [{
    key: 1, value: "Kumari Bank Ltd"},
    {key:2,value:"Agriculture Development Bank Ltd"},
    {key:3,value:"Nabil Bank Ltd"},
    {key:4,value:"Nepal Investment Bank Ltd"},
    {key:5,value:"Standard Chartered Bank Nepal Ltd"},
    {key:6,value:"Himalayan Bank Ltd"},
    {key:7,value:"Nepal SBI Bank Ltd"},
    {key:8,value:"Nepal Bangaladesh Bank Ltd"},
    {key:9,value:"Everest Bank Ltd"},
    {key:10,value:"Nepal Bank Ltd"},
    {key:11,value:"Laxmi Bank Ltd"},
    {key:12,value:"Citizens Bank International Ltd"},
    {key:13,value:"Prime Commercial Bank Ltd"},
    {key:14,value:"Sunrise Bank Ltd"},
    {key:15,value:"Century Commercial Bank Ltd"},
    {key:16,value:"Sanima Bank Ltd"},
    {key:17,value:"Machhapuchhre Bank Ltd"},
    {key:18,value:"NIC Asia Bank Ltd"},
    {key:19,value:"Global IME Bank Ltd"},
    {key:20,value:"NMB Bank Ltd"},
    {key:21,value:"Siddhartha Bank Ltd"},
    {key:22,value:"Bank of Kathmandu Ltd"},
    {key:23,value:"Civil Bank Ltd"},
    {key:24,value:"Nepal Credit and Commerce Bank Ltd"},
    {key:25,value:"Rastriya Banijya Bank"},
    {key:26,value:"Prabhu Bank Ltd"
}
]

const _filter_items = list
class PopupFilterContainer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selected_keys:Array.isArray(props.values)?props.values:[],
            options: props.options||[],
            query: ""
        }
       
    }

    componentDidMount(){
        this.onSearch();
    }
    componentWillReceiveProps(nextProps){
        if(this.props.values!=nextProps.values&&Array.isArray(nextProps.values)){
            this.setState({
                selected_keys:nextProps.values
            })
        }
    }

    onClickItem = (k) => {
        let old_keys = this.state.selected_keys;
        this.setState({
            selected_keys: old_keys.includes(k) ? old_keys.filter((a) => a != k) : [...old_keys, k]
        })
    }

    onSelectAll = (available_options) => {
        // let filter_items = _filter_items;
        this.setState({
            selected_keys: available_options.map((it) => `${it.key}`)
        })
    }
    onOKPressed=()=>{
        typeof this.props.onOK==='function'&&this.props.onOK(this.state.selected_keys)
    }
    onCancelPressed=()=>{
        typeof this.props.onOK==='function'&&this.props.onOK([])
        // typeof this.props.onCancel==='function'&&this.props.onCancel();
        // this.setState({
        //     selected_keys:Array.isArray(this.props.values)?this.props.values:[]
        // })
    }
    onSearch=(q)=>{
        let _options=this.props.options||[];
        console.log("searching for",q)
        if(typeof q==='string'&&q.length>0){
            _options = _options.filter(({ value = "", key = "" }) => {
                // console.log("val",value,key)
                value = value.toLowerCase();
                key = `${key}`.toLowerCase();
                return value.includes(q.toLowerCase())
                    || key.includes(q.toLowerCase())
                    ;
            });
        }
        console.log("search results are",_options)
        this.onSelectAll(_options);
    }

    render() {
        const { selected_keys, options, query } = this.state;

        let _options = options;
        if (query && typeof query == 'string' && query.length > 0) {
            _options = _options.filter(({ value = "", key = "" }) => {
                // console.log("val",value,key)
                value = value.toLowerCase();
                key = `${key}`.toLowerCase();
                return value.includes(this.state.query.toLowerCase())
                    || key.includes(this.state.query.toLowerCase())
                    ;
            });
        }

        return (
            <div className='rl-column table-popup-filter-container' >
                <div className='no-wrap-rl-row table-popup-filter-top'>
                    <TransparentButton
                        onClick={() => this.onSelectAll(_options)}
                        type='primary' label="Select All" />
                    <TransparentButton onClick={() => {
                        this.setState({
                            selected_keys: []
                        })
                    }} style={{
                        marginLeft: 20
                    }} type='primary' label="Clear All" />
                </div>

                <TextInput
                    value={query}
                    onChange={(val) => {
                        this.setState({
                            query: val
                        },()=>{
                            this.onSearch(val)
                        })
                    }}
                    inputClassName='no-border-input'
                    placeholder="Search Contacts"
                    leftIcon={<SearchOutlined />}
                />

                <div className='rl-column table-popup-filter-body'>
                    {_options.map((item, index) => {
                        return <div
                            title={item.value}
                            onClick={() => {
                                this.onClickItem(`${item.key}`)
                            }}
                            className='no-wrap-rl-row table-popup-filter-body-item hand-cursor'>
                            <Checkbox checked={selected_keys.includes(`${item.key}`)} />
                            <span className='label'>{item.value}</span>
                        </div>
                    })}
                    {_options.length == 0 ? <span>No data found</span> : null}
                </div>
                <div className='no-wrap-rl-row table-popup-filter-footer'>
                    <CustomButton 
                    onClick={()=>{
                        this.onOKPressed()
                    }}
                    style={{
                        width: '100%'
                    }} filled type='primary' label="OK" />
                    <TransparentButton 
                    onClick={this.onCancelPressed}
                    style={{
                        width: '100%',
                        textAlign: 'center'
                    }} label="Clear" />

                </div>
            </div>
        );
    }
}

export default PopupFilterContainer;
