import React, { useState, useEffect } from 'react';
import Modal from 'antd/lib/modal/Modal';
import SmallForm from './small_form';
import { Drawer } from 'antd';
import { Transition, animated } from 'react-spring/renderprops'

export const FormModal = ({
    visible,
    destroyOnClose,
    onClose,
    schema,
    onChange,
    onSave,
    data,
    idKey,
    title = "",
    leftBottom,
    networkState = {},
    forEdit,
    asDrawer = false
}) => {
    useEffect(() => {
        console.log("value changed")
        if (asDrawer) {
            if (visible) {
                console.log("prevent body overflow")
                document.body.style.overflow = 'hidden'
            } else {
                document.body.style.overflow = null
                console.log("make  scroll")
            }
        }
    }, [visible]);
    // if(!visible){
    //     return null;
    // }
    return asDrawer ?
        <Drawer
            className='form-drawer'
            visible={visible}
            width='auto'
            destroyOnClose={true}
            getContainer={window.subdomainContainer?.current}
            closeIcon={null}
            closable

            onClose={() => {
                typeof onClose === 'function' && onClose();
            }}
        >
            {visible&&
            <SmallForm
                forEdit={forEdit}
                title={title}
                schema={schema}
                data={data}
                idKey={idKey}
                networkState={networkState}
                onChange={(d) => {
                    typeof onChange === 'function' && onChange(d)
                }}
                onSave={() => {
                    typeof onSave === 'function' && onSave(data)
                    // submitToggle(true)
                }}
                leftBottom={leftBottom}
            />
            }
        </Drawer>
        :<Modal
                centered
                visible={visible}
                destroyOnClose={true}
                className='form-modal'
                onCancel={() => {
                    typeof onClose === 'function' && onClose();
                }}
                footer={[]}>

                <SmallForm
                    forEdit={forEdit}
                    title={title}
                    schema={schema}
                    data={data}
                    idKey={idKey}
                    networkState={networkState}
                    onChange={(d) => {
                        typeof onChange === 'function' && onChange(d)
                    }}
                    onSave={() => {
                        typeof onSave === 'function' && onSave(data)
                        // submitToggle(true)
                    }}
                    leftBottom={leftBottom}
                />

                {/* {children} */}
            </Modal>

}