import React from 'react';

import 'antd/dist/antd.css';
import '../src/styles/css/antd.css'

import './theme.scss'
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';


// import { useEffect } from "react";
// import { useLocation } from "react-router-dom";
import { withDomains } from './lib/domain';
import MainApplication from "./pages";
import { refreshLayoutManager } from "./util/layout_manager";
import LandingPage from "./pages/landing/landing";
import LoginPage from "./pages/landing/login";
import RegisterPage from './pages/register/register'
import EditPage from './pages/edit/edit'
import ChangePasswordPage from './pages/change_password'
import VerifyEmailPage from "./pages/landing/accept_invitation";
import ForgotPasswordPage from './pages/landing/forgot_password';
import ResetPasswordPage from './pages/landing/reset_password';
import verifyCodePage from './pages/register/verifyCode';
import SignupPage from './pages/signup/signup';
import SignupDetailPage from './pages/signup/signup_details';
import AccountSettingPage from './pages/account_settings/account_settings';
import NewCompanyPage from './pages/add_company/new-company';
import MaintenancePage from './pages/maintenance/maintenance';

// function ScrollToTop() {
//   const { pathname } = useLocation();

//   useEffect(() => {
//     window.scrollTo(0, 0);
//     document.body.setAttribute('h-shown', true)
//   }, [pathname]);

//   return null;
// }


class App extends React.Component {

  render() {
    let { authenticator } = this.props;
    // let meta = authenticator.getTokens().meta || {};

    return <HashRouter>
      {
        authenticator.isAuthenticated()
          ?
          <Switch>
            <Route path={'/change-password'} component={ChangePasswordPage} />
            <Route path={'/reset-password'} component={ResetPasswordPage} />
            <Route path={'/edit'} component={EditPage} />
            <Route path={'/account-settings'} component={AccountSettingPage} />
            <Route path={'/new-company'} component={NewCompanyPage} />
            <Route path={'/forgot-password'} component={ForgotPasswordPage} />
            <Route path={'/reset-password'} component={ResetPasswordPage} />
            <Route component={MainApplication} />
            <Redirect to={'/'} />
          </Switch>
          :
          <div className={'application-container'}>
            <Switch>
              <Route path={'/maintenance'} component={MaintenancePage} />
              <Route path={'/email-verify/:id'} component={VerifyEmailPage} />
              <Route path={'/forgot-password'} component={ForgotPasswordPage} />
              <Route path={'/reset-password'} component={ResetPasswordPage} />

              <Route path={'/login'} component={LoginPage} />
              <Route path={'/register'} component={RegisterPage} />
              <Route path={'/sign-up'} component={SignupPage} />
              <Route path={'/signup-details'} component={SignupDetailPage} />

              <Route path={'/verify-code'} component={verifyCodePage} />
              <Route path={'/'} component={LandingPage} />
              <Redirect to={'/'} />
            </Switch>
          </div>
      }

    </HashRouter>
  }
  resizeListener = (e) => {
    console.log("RESIZE DONE", e)
    refreshLayoutManager()
  }



  componentDidMount() {
    window.addEventListener('resize', this.resizeListener);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeListener);

  }
}

export default withDomains(App, "auth");
