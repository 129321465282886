
export const VAT_OPTIONS = [
  { key: "No Vat", value: "No Vat" },
  { key: "0%", value: "0 Vat" },
  { key: "13%", value: "13% Vat" }
]
export const COST_TERMS_METHOD_OPTIONS = [
  { key: 'value', value: "Value" }, { key: 'quantity', value: 'Quantity' }
]

export const CREDIT_TERMS_SMALL_SCHEMA=[
    {label:"Name",type:'text',index:'name',required:true},
    {label:"No of Days",type:'number',index:'days',required:true}
]

export const ADDITIONAL_FIELD_SMALL_SCHEMA = [
  { label: "Name", type: 'text', index: 'name', required: true,multiple:true,min:1,max:5 },
  {
    label: "Field Type", type: 'select', index: 'type',
    required: true,
    options: [
      { key: "text", value: "Text" },
      { key: "number", value: "Number" },
      { key: "choice", value: "Choices" },
      { key: "networkselect", value: "Lookup" },
      { key: 'bool', value: "Boolean" },
      { key: 'textarea', value: "Description" }
      ], 
    conditional: true,
      optional_schema: {
      'choice': [
        { label: "Choices", index: 'choices', type: 'tags', block: true }
      ],
      'networkselect': [
        {
          label: "Lookup Table", type: 'select', index: 'apiURL', options: [
            { key: '/contacts?type=Customer', value: "Customer Table" },
            { key: '/contacts?type=Supplier', value: "Supplier Table" }
          ]
        }
      ]
    }
  }
];
export const BANK_ACCOUNT_SMALL_SCHEMA = [
  { label: "Bank Name", index: 'name', type: 'text' },
  { label: "Account Type", index: 'account_type', type: 'choice', options: ["Current", "Saving"] },
  { label: "Account Name", index: 'account_name', type: 'text' },
  { label: "Account Number", index: 'account_number', type: 'text' },
  { label: "Description", index: 'description', type: 'textarea', block: true },
  { label: "Opening Balance", index: 'opening_balance', type: 'amount', secondary_index: 'opening_balance_type' },
]
export const CONTACT_SMALL_SCHEMA = [
  {
    label: "Type",
    type: 'select', options: [
      { key: "None", value: "None" },
      { key: "Customer", value: "Customer" },
      { key: "Supplier", value: "Supplier" },
    ],
    index: 'type',
    conditional: true,
    allowClear: false,
    default: "None",
    forEdit: false,
    optional_schema: {
      "None": [
        { type: 'space' },
        { label: "Name", type: 'text', index: 'name', placeholder: "Contact Name", required: true },
        { label: "Phone Number", placeholder: "Contact Number", type: 'text', index: 'phone_number' },
        { label: "Email", placeholder: "Email Address", type: 'text', index: 'email' },
        { label: "Address", type: 'textarea', index: 'address' },
        {
          label: "Organization", placeholder: "Organization Name", type: 'networkselect',
          index: 'organization_id',
          apiURL: '/contacts?type=Supplier&type=Customer',
          addAPI: '/contacts',
          idKey:'code',
          addSchema: [
            {
              label: "Type", index: 'type', type: 'select', options: [
                { key: "Customer", value: "Customer" },
                { key: "Supplier", value: "Supplier" },
              ],
              default: "Customer"
            },
            { label: "Name", index: 'name', placeholder: "Organisation Name", type: 'text' }
          ]
        },
        { label: "Title", placeholder: "Organisation Title", type: 'text', index: 'organization_title' }
      ],
      undefined: [
        { type: 'space' },
        { label: "Name", type: 'text', index: 'name', placeholder: "Contact Name", required: true },
        { label: "Phone Number", placeholder: "Contact Number", type: 'text', index: 'phone_number' },
        { label: "Email", placeholder: "Email Address", type: 'text', index: 'email' },
        { label: "Address", type: 'textarea', index: 'address' },
        {
          label: "Organization", placeholder: "Organization Name", type: 'networkselect',
          index: 'organization',
          apiURL: '/contacts?type=Supplier&type=Customer',
          addAPI: '/contacts',
          addSchema: [
            { label: "Name", index: 'name', placeholder: "Organisation Name", type: 'text' }
          ]
        },
        { label: "Title", placeholder: "Organisation Title", type: 'text', index: 'organization_title' }
      ],
      "Customer": [
        { type: 'space' },
        { label: "Name", type: 'text', index: 'name', placeholder: "Contact Name", required: true },
        { label: "Phone Number", placeholder: "Contact Number", type: 'text', index: 'phone_number' },
        { label: "Email", placeholder: "Email Address", type: 'text', index: 'email' },
        { label: "Address", type: 'textarea', index: 'address', required: true },

      ],
      "Supplier": [
        { type: 'space' },
        { label: "Name", type: 'text', index: 'name', placeholder: "Contact Name", required: true },
        { label: "Phone Number", placeholder: "Contact Number", type: 'text', index: 'phone_number' },
        { label: "Email", placeholder: "Email Address", type: 'text', index: 'email' },
        { label: "Address", type: 'textarea', index: 'address' },
      ]
    }
  },
]

export const TASK_SMALL_SCHEMA = [
  { label: "Title", index: 'title', placeholder: "Title", type: 'text', block: true },
  { label: "Description", required: true, index: 'description', type: 'textarea', rows: 3 },
  { label: "Assign To", type: 'networkselect', index: 'assign_to', mode: 'multiple', apiURL: "/contacts" },
  { label: "Due Date", required: true, type: 'date', index: 'due_date' },
  {
    label: "Priority", required: true, type: 'select', index: 'priority',
    default: "Normal",
    options: [
      { key: "Urgent", value: "Urgent" },
      { key: "Normal", value: "Normal" },
      { key: "Low", value: "Low" }
    ]
  },
  { label: "Tags", index: 'tags', type: 'tags', block: true },
  { label: "Add Files", index: 'files', type: 'file_upload', block: true }

]



export const LEADS_SMALL_SCHEMA = [
  { label: "Name", index: 'name', placeholder: "Name", type: 'text', required: true },
  { label: "Phone no.", index: 'phone_number', placeholder: "Phone Number", type: 'text', required: true },
  { label: "Email", index: 'email', placeholder: "Email Address", type: 'text' },
  // { label: "Description", index: 'description', type: 'textarea', rows: 3 },
  { label: "Assign To", type: 'networkselect', index: 'assign_to', required: true, mode: 'multiple', apiURL: "/contacts", },
  { label: "Lead Source", type: 'networkselect', index: 'lead_source_id', apiURL: '/lead-sources' },
  { label: "Lead Title", type: 'text', index: 'lead_title', required: true, block: true },
  { label: "Lead Description", type: 'textarea', index: 'lead_description', block: true },
  {
    label: "Status", type: 'select', index: 'status', default: "Open",
    options: [
      { key: "Open", value: "Open" },
      { key: "Closed", value: "Closed" }
    ]
  },
  { label: "Tags", index: 'tags', type: 'tags', block: true },
  { label: "Add Files", index: 'files', type: 'file_upload', block: true }

]

export const LEAD_SOURCE_SMALL_SCHEMA = [
  { label: "Name", index: 'name', type: 'text', required: true },
  { label: "Description", index: 'description', type: 'textarea' },
  {
    index: 'active',
    type: 'cardradio',
    block: true,
    showForAdd: false,
    options: [
      { title: `Active  `, key: true },
      { title: "Inactive", key: false }
    ]
  }

]
export const OPPORTUNITY_SMALL_SCHEMA = [

  {block:true,
    label:"Type",index:'source_type',type:'cardradio',options: [
      { title:"Lead", key: "Lead" },
      { title: "Customer", key: "Customer" }
    ],
    conditional:true,
    default:'Lead',
    optional_schema:{
      'Customer':[
        {
          label: "Customer", placeholder: "Customer", type: 'networkselect',
          index: 'source_id',
          apiURL: '/contacts?type=Customer',
          // addAPI: '/contacts',
          // addSchema: [
          //   {
          //     label: "Type", index: "type", type: 'select', default: "Customer", required: true, options: [
          //       { key: "Customer", value: "Customer" }
          //     ]
          //   },
          //   { label: "Name", index: 'name', placeholder: "Organisation Name", type: 'text' }
          // ]
        },
      ],
      'Lead':[
        {
          label: "Lead", placeholder: "Lead", type: 'networkselect',
          index: 'source_id',
          apiURL: '/leads',
          // addAPI: '/leads',
          // addSchema: LEADS_SMALL_SCHEMA
        },
      ]
    }
  },
  { label: "Assign To", type: 'networkselect', index: 'assign_to', required: true, mode: 'multiple', apiURL: "/contacts", },
  { label: "Lead Source", type: 'networkselect', index: 'lead_source_id', apiURL: '/lead-sources' },

  { label: "Opportunity Title", type: 'text', index: 'opportunity_title', required: true, block: true },
  { label: "Opportunity Description", type: 'textarea', index: 'opportunity_description', block: true },

  { label: "Expected Revenue", index: 'expected_revenue', placeholder: "Expected Revenue", type: 'number' },
  { label: "Expected Closing Date", index: 'expected_closing_date', placeholder: "Expected Closing Date", type: 'date' },
  {
    label: "Status", type: 'select', index: 'status', default: "Pending", options: [
      { key: "Pending", value: "Pending" },
      { key: "Won", value: "Won" },
      { key: "Lost", value: "Lost" }
    ]
  },
  { label: "Tags", index: 'tags', type: 'tags', block: true },
  { label: "Add Files", index: 'files', type: 'file_upload', block: true }

]

export const REMINDER_SMALL_SCHEMA = [
  { label: "Title", index: 'title', placeholder: "Title", type: 'text', block: true },
  { label: "Description", required: true, index: 'description', type: 'textarea', block: true, rows: 3 },
  { label: "Remind Date", required: true, type: 'date', index: 'remind_date' },
  { label: "Remind To", type: 'networkselect', index: 'remind_to', mode: 'multiple', apiURL: "/contacts" },
  { label: "Tags", index: 'tags', type: 'tags', block: true }
]



export const PRODUCT_CATEGORY_SMALL_SCHEMA = [
  { label: "Name", index: 'name', placeholder: "Product Name", type: 'text', required: true },
  { label: "Under", index: 'under', placeholder: "Parent Category", type: 'networkselect', apiURL: '/product-categories' },
  { label: "Description", index: 'description', type: 'textarea', block: true, rows: 3 },
  {
    index: 'active',
    type: 'cardradio',
    block: true,
    showForAdd: false,
    options: [
      { title: `Active  `, key: true },
      { title: "Inactive", key: false }
    ]
  }

]
export const MEASUREMENT_UNIT_SMALL_SCHEMA = [
  { label: "Name", index: 'name', placeholder: "Unit Name", type: 'text', required: true },
  { label: "Short Name", index: 'symbol', placeholder: "Symbol", type: 'text', required: true },
  { label: "Description", index: 'description', type: 'textarea', block: true, rows: 3 },
  {
    type: 'cardradio',
    block: true,
    index: 'accept_fraction',
    allowClear: true,
    options: [
      { title: "Accept Fraction", key: true }
    ]
  },
  {
    index: 'active',
    type: 'cardradio',
    block: true,
    showForAdd: false,
    options: [
      { title: `Active  `, key: true },
      { title: "Inactive", key: false }
    ]
  }

]


export const DOCUMENT_BANK_SMALL_SCHEMA = [
  { label: "Add Files", index: 'files', type: 'file_upload', block: true },
  { label: "Title", index: 'title', placeholder: "Title", type: 'text', block: true },
  { label: "Description", required: true, index: 'description', type: 'textarea', rows: 3, block: true },

]
export const ACCOUNT_SINGLE_SCHEMA = [
  {
    index: 'accounts', multiple: true, max: 1, count: 1, min: 1, type: 'form', block: true, schema: [
      { label: "Name", placeholder: "Account Name", type: 'text', required: true, index: 'name' },
      {
        label: "Under", placeholder: "Parent Account",
        type: 'networkselect',
        index: 'parent_id',
        required: true,
        apiURL: "/account-groups"
      },
      { label: "Description", placeholder: "Account Description", type: 'textarea', block: true, index: 'description' },
      { label: "Opening Balance", index: 'opening_balance', type: 'amount', secondary_index: 'opening_balance_type' },
      {
        index: 'active',
        type: 'cardradio',
        block: true,
        showForAdd: false,
        options: [
          { title: `Active  `, key: true },
          { title: "Inactive", key: false }
        ]
      }
    ]
  }
];
export const ACCOUNT_MULTIPLE_SCHEMA = [
  {
    index: 'accounts', multiple: true, count: 1, min: 0, type: 'form', block: true, schema: [
      { label: "Name", placeholder: "Account Name", type: 'text', required: true, index: 'name' },
      {
        label: "Under", placeholder: "Parent Account", type: 'networkselect',
        index: 'parent_id',
        required: true,
        apiURL: "/account-groups"
      },
    ]
  }
];

export const GROUP_ACCOUNT_SINGLE_SCHEMA = [
  {
    index: 'account-groups', multiple: true, max: 1, count: 1, min: 1, type: 'form', block: true, schema: [
      { label: "Group Name", placeholder: "Group Name", type: 'text', required: true, index: 'name' },
      {
        label: "Under", placeholder: "Parent Account",
        type: 'networkselect',
        apiURL: '/account-groups',
        affecting_data: [{
          host: 'level',
          target: 'level',
          enricher: "INCREMENT"
        },
        {
          host: 'class_id',
          target: 'class_id'
        }],

        index: 'parent_id'
      },
      { label: "Description", placeholder: "Account Description", type: 'textarea', block: true, index: 'description' },
      {
        index: 'active',
        type: 'cardradio',
        block: true,
        showForAdd: false,
        options: [
          { title: `Active  `, key: true },
          { title: "Inactive", key: false }
        ]
      }
    ]
  }
];
export const GROUP_ACCOUNT_MULTIPLE_SCHEMA = [
  {
    index: 'account-groups', multiple: true, count: 1, min: 1, type: 'form', block: true, schema: [
      { label: "Group Name", placeholder: "Group Name", type: 'text', required: true, index: 'name' },
      {
        label: "Under", placeholder: "Parent Account", type: 'networkselect',
        apiURL: '/account-groups',
        affecting_data: [{
          host: 'level',
          target: 'level',
          enricher: "INCREMENT"
        },
        {
          host: 'class_id',
          target: 'class_id'
        }],

        index: 'parent_id'
      },
    ]
  }
];

export const SUBACCOUNT_SINGLE_SCHEMA = [
  {
    index: 'sub-accounts', multiple: true, max: 1, count: 1, min: 1, type: 'form', block: true, schema: [
      { label: "Sub Account Name", type: 'text', required: true, index: 'name' },
      {
        label: "Under", placeholder: "Parent Account", type: 'networkselect',
        index: 'parent_id',
        required: true,
        apiURL: "/accounts"
      },
      { label: "Description", placeholder: "Sub Account Description", type: 'textarea', block: true, index: 'description' },
      {
        index: 'active',
        type: 'cardradio',
        block: true,
        showForAdd: false,
        options: [
          { title: `Active  `, key: true },
          { title: "Inactive", key: false }
        ]
      }
    ]
  }
];
export const SUBACCOUNT_MULTIPLE_SCHEMA = [
  {
    index: 'sub-accounts', multiple: true, count: 1, min: 1, type: 'form', block: true, schema: [
      { label: "Sub Account Name", type: 'text', required: true, index: 'name' },
      {
        label: "Under", placeholder: "Parent Account", type: 'networkselect',
        index: 'parent_id',
        required: true,
        apiURL: "/accounts"
      },
    ]
  }
];


export const CHEQUE_RECEIVED_SMALL_SCHEMA = [
  { label: "Received From", index: 'received_from', placeholder: "Name", type: 'text' },
  { label: "Received Date", required: true, index: 'received_date', type: 'date' },
  { label: "Chq Date", type: 'date', index: 'cheque_date' },
  { label: "Bank", index: 'cheque_bank', placeholder: "Name", type: 'text' },
  { label: "Chq No", index: 'cheque_no', placeholder: "Number", type: 'text' },
  { label: "Amount", index: 'cheque_amount', placeholder: "Number", type: 'number' },
  { label: "Note", index: 'note', placeholder: "note", type: 'textarea', rows: 3, block: true },
  { label: "Add Files", index: 'files', type: 'file_upload', block: true }
];


export const CHEQUE_ISSUED_SMALL_SCHEMA = [
  { label: "Paid To", index: 'cheque_bank_sub_account_id', placeholder: "Name", type: 'text' },
  { label: "Issued Date", required: true, index: 'issued_date', type: 'date' },
  { label: "Chq Date", type: 'date', index: 'cheque_date' },
  { label: "Bank", index: 'cheque_bank_account', placeholder: "Name", type: 'text' },
  { label: "Chq No", index: 'cheque_no', placeholder: "Number", type: 'text' },
  { label: "Amount", index: 'cheque_amount', placeholder: "Number", type: 'number' },
  {
    type: 'cardradio',
    block: true,
    index: 'payee',
    allowClear: true,
    options: [
      { title: "A/C payee only", key: true }
    ]
  },

  { label: "Note", index: 'note', placeholder: "note", type: 'textarea', rows: 3, block: true },
  { label: "Add Files", index: 'files', type: 'file_upload', block: true }
];

export const CHEQUE_BOOK_SMALL_SCHEMA = [
  { label: "Bank", index: 'cheque_bank_account_id', placeholder: 'Name', type: 'text', rows: 1 },
  { label: "Start Number", index: 'start_number', placeholder: 'Number', type: 'number' },
  { label: "End Number", index: 'end_number', placeholder: 'Number', type: 'number' },
];


export const WAREHOUSE_SMALL_FORM = [
  { label: "Name", type: 'text', index: 'name', placeholder: "Warehouse Name", required: true },
  { label: "Phone Number", placeholder: "Contact Number", type: 'text', index: 'phone_number' },
  { label: "Email", placeholder: "Email Address", type: 'text', index: 'email' },
  { label: "Address", type: 'textarea', index: 'address' },
  {
    index: 'active',
    type: 'cardradio',
    block: true,
    showForAdd: false,
    options: [
      { title: `Active  `, key: true },
      { title: "Inactive", key: false }
    ]
  }
]

export const SHIPPING_METHOD_SMALL_FORM = [
  { label: "Name", type: 'text', index: 'name', placeholder: "Name", required: true },
  { label: "Description", type: 'textarea', index: 'description' },
  {
    index: 'active',
    type: 'cardradio',
    block: true,
    showForAdd: false,
    options: [
      { title: `Active  `, key: true },
      { title: "Inactive", key: false }
    ]
  }
]

export const COST_TERMS_SMALL_FORM = [
  { label: "Name", index: 'name', type: 'text' },
  { label: "Method", index: 'method', type: 'select', options: COST_TERMS_METHOD_OPTIONS },
  { label: 'Mapped Account', index: 'account_id', secondary_index: 'sub_account_id', type: 'account' },
  { label: "Description", index: 'description', type: 'textarea' }
]


export const STATUS_TO_COLORTYPE = {
  "Urgent": 'danger',
  "Normal": 'success',
  "Not Started": 'assent1',
  "Pending": 'assent1',
  "Started": 'assent2',
  "Done": 'success',
  "Cleared": 'success',
  "Cancelled": 'danger',
  'active': 'success'
}


export const PRODUCT_SERVICE_SMALL_SCHEMA = [
  {
    index: 'type',
    type: 'cardradio',
    block: true,
    default: "Product",
    options: [
      { title: `Goods  `, key: 'Product' },
      { title: "Service", key: 'Service' }
    ]
  },

  { label: "Name", type: 'text', index: 'name', placeholder: "Product Name", required: true },
  {
    label: "Category", placeholder: "Category", type: 'networkselect',
    apiURL: '/product-categories',
    index: 'category_id', required: true,
    addAPI: '/product-categories',
    addSchema: PRODUCT_CATEGORY_SMALL_SCHEMA
  },
  {
    label: "Tax", index: 'tax', type: 'select',
    default: "No Vat",
    options: VAT_OPTIONS
  },
  {
    label: "Primary Unit", placeholder: "Primary Unit",
    type: 'networkselect',
    apiURL: '/measurement-units',
    addAPI: '/measurement-units',
    addSchema: MEASUREMENT_UNIT_SMALL_SCHEMA,
    index: 'unit_id', required: true
  }


]