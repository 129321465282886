import React, { useState } from 'react'
import SmallForm from "../../components/forms/small_form";
import { withDomains } from "../../lib/domain";
import { Link } from "react-router-dom";

const LoginPage = ({ authenticator }) => {

    const [login, setLogin] = useState({})

    return (
        <div>
            Login page
            <br />
            <br />
            <SmallForm
                networkState={authenticator.response()}
                data={
                    login
                }

                onChange={(val) => {
                    setLogin({
                        ...login,
                        ...val
                    })
                }}
                saveText={'LOGIN'}
                title="Login"
                schema={LOGIN_SCHEMA}
                onSave={() => {
                    authenticator.login(login)
                }}
            />
            <br />
            <Link to={'/forgot-password'}>
                Forgot password
            </Link>
        </div>
    )
}

const LOGIN_SCHEMA = [
    { label: "Email", type: 'text', index: 'email', required: true },
    { label: "Password", type: 'password', index: 'password', required: true }

]

export default withDomains(LoginPage, 'auth')