

import React, { Component } from 'react'
import { CheckOutlined } from '@ant-design/icons'

const RadioCards=({
    value,defaultValue,onChange,options=[],size='small',label,error_message,
    allowClear
})=>{
    return <div><div style={{
        display:'flex',
        flexDirection:'row'
    }}>
        {options.map((item,index)=>{
            return <RadioCardItem  
            data={item}
            key={`${index}-item`}
            selectedKey={value}
            size={size}
            onChange={onChange}
            allowClear={allowClear}
            style={{
                marginRight:20
            }}/>
        })}
    </div>
    <span className='t-meta-info error-color'>{error_message}</span>
    </div>
}

const RadioCardItem=({style,data={},selectedKey,size='small',onChange,allowClear})=>{
    return <div 
    title={data.title}
    tabIndex={'0'}
    onKeyDown={(e)=>{
        if(e.keyCode==13){
            typeof onChange==='function'&&onChange(allowClear?selectedKey==data.key?null:data.key:data.key,data)
        }
    }}
    onClick={()=>{
        typeof onChange==='function'&&onChange(allowClear?selectedKey==data.key?null:data.key:data.key,data)
    }}
    className={`disable-text-selection  radio-card radio-card-${size} ${selectedKey==data.key&&selectedKey!=null?'radio-card-selected':''} hand-cursor`} style={{
        ...style
    }}>
    <div className='radio-card-selector'>
    <CheckOutlined className='radio-card-selector-icon'/>
    </div>
    <div style={{
        display:'flex',
        flexDirection:'column'
    }}>
        <span className={`radio-card-title ${size=='large'?'form-switcher':'form-input'}`}>{data.title}</span>
        {/* {data.subtitle&&<span className='radio-card-subtitle info'>{data.subtitle}</span>} */}
    </div>

    </div>
}

export {RadioCards,RadioCardItem};