import React from 'react';
import { RoundedIconButton, TransparentButton } from './forms/buttons';
import { TaskIcon, GlobeIcon, MessageIcon, ScanIcon, CallIcon, OrganisationIcon, FileJPGIcon, DeleteIcon, EyeIcon, DownloadIcon, FilePDFIcon, FileDOCIcon, FileXLSIcon, LinkSourceIcon, UndoIcon } from '../assets/icons';
import { Popover, Space, Spin } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Spinner from 'react-spinners/MoonLoader';
import { THEME_COLOR } from '../assets/colors';
import CustomAvatar from './custom_avatar';
const ContactTypeCircular = ({
    type = "Normal",
    size = ""
}) => {

    return <div title={type} className={`contact-type-circular ${size} ${(type.charAt(0) || "").toUpperCase()} `} >
        {(type.charAt(0) || "").toUpperCase()}
    </div>
}

const renderFileIcon = (index) => {
    console.log("rendering for", index)
    switch (index) {
        case 0:
            return <FileJPGIcon />
        case 1:
            return <FilePDFIcon />
        case 2:
            return <FileDOCIcon />
        case 3:
            return <FileXLSIcon />
        default:
            return <FileJPGIcon />
    }
}
const FileDisplayItem = ({
    file_type = "JPG",
    uploading,
    data = {},
    error,
    onRetry
}) => {
    return <div className={`file-display-item ${error ? 'error' : ''}`}>

        <div className='preview-body'>
            <div className='thumbnail'>
                {uploading ?
                    <Spinner
                        size={50}
                        color={THEME_COLOR.primary}
                        loading={true}
                        css={`border-color:red`}
                    />
                    :
                    error ? <UndoIcon title="Retry" className='file-error-icon hand-cursor' onClick={() => {
                        typeof onRetry === 'function' && onRetry();
                    }} /> :
                        renderFileIcon((Math.ceil(Math.random() * 100)) % 4)

                }
            </div>
            <div className='detail'>
                <div title={data.name} className='gray90 font-sm' style={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    // width: '85%'
                }}>{data.name || "Filename"}</div>
                <div style={{
                    marginTop: 6
                }}
                    className='gray60 font-xs'>{Math.round(data.size / 1024) || "N/A"} KB</div>
            </div>
        </div>
        <div className='preview-footer' style={{
            opacity: uploading || error ? 0.6 : null,
            pointerEvents: uploading || error ? 'none' : null
        }}>
            <TransparentButton label="Add as" hint="Add as" />
            <TransparentButton label="Done" hint="Done" />
            <TransparentButton label="Download" hint="Download" />
            <TransparentButton label="View" hint="View" />
            <TransparentButton label="Delete" hint="Delete" type='danger' />
        </div>
    </div>
}

const ColoredTag = ({ type, label }) => {
    return <div className={`colored-tag ${type}`}>
        <div className='indicator'></div>
        {label}
    </div>
}

const SmallIconTag = ({ icon, label }) => {
    return <div className='small-icon-tag'>
        {icon}
        {label != null && <span className='label-title'>{label}</span>}
    </div>
}


const GeneralCard = ({ title, rightAction, ...props }) => {
    return <div className='custom-card-item-1'>
        {title &&
            <div className='custom-card-header'>
                <div className='title'>{title}</div>
                {rightAction}
            </div>
        }
        {props.children}
    </div>
}

const StatisticInfo = ({
    label, title = "", titleClassName = '',
    style
}) => {
    return <div className='statistic-card' style={{
        ...style
    }}>
        <div className={`label`}>{label}</div>
        <div className={`title ${titleClassName}`}>{title}</div>
    </div>
}

const DayDifferenceOutline = ({
    dateFormat = "YYYY-MM-DD",
    date, dateFrom, maxSuccessDay = 7
}) => {
    let _dateFrom = (dateFrom && moment(dateFrom, dateFormat).isValid()) ? moment(dateFrom, dateFormat) : moment();
    let _dateTo = date && moment(date, dateFormat) ? moment(date, dateFormat) : moment();

    let diff_day = _dateFrom.diff(_dateTo, 'day');
    return (
        <div title={date || "N/A"} className={`day-difference-tag 
        ${diff_day < 0 ? 'danger' : ''}
        ${maxSuccessDay && diff_day >= maxSuccessDay ? 'danger' : ''}
        ${date == null ? 'disabled' : ''}
        `}>
            {date == null ?
                "-" :
                diff_day == 0 ? `Today` :
                    `${diff_day}d`
            }
        </div>
    )
}
const SourceBanner = ({ data = {} }) => {
    return <Link to="#" className='source-banner'>
        <LinkSourceIcon className='icon' />
        <div className='title'>
            Go to Source : BIC Technology Pvt Ltd (C-0045)
    </div>
    </Link>
}
const TitleContentStatus = ({ label, ...props }) => {
    return <div>
        <div className='t-meta-info'>{label}</div>
        <div className='t-table-content' style={{
            marginTop: 12
        }}>
            {props.children}
        </div>
    </div>
}
const ContactAvatarGroup = ({ children = [], maxCount = 3, spacing = 4, size = 'small' }) => {
    return <div className='custom-avatar-group'>
        <Space size={spacing}>
            {children.map((it, c_i) => {
                console.log("mapping", c_i, it)
                if (c_i < maxCount) {
                    return it;
                }
                if (c_i == maxCount) {
                    return <Popover arrowPointAtCenter placement='left' trigger="hover" title={`${children.length - maxCount} more`} content={<div>
                        <Space size={spacing}>
                            {children.map((it, c_i) => {
                                console.log("mapping", c_i, it)
                                if (c_i >= maxCount) {
                                    return it;
                                }
                            })}
                        </Space>
                    </div>}>
                        <div>
                            <CustomAvatar
                                size={size}
                                showHint={false}
                                name={`+ ${children.length - maxCount}`}
                                style={{
                                    backgroundColor: THEME_COLOR.normalGrey,
                                    borderColor: THEME_COLOR.normalGrey
                                }}
                            />
                        </div>
                    </Popover>
                }
            })}
        </Space>
    </div>
}

export { ContactTypeCircular, FileDisplayItem, ColoredTag, SmallIconTag, GeneralCard, StatisticInfo, SourceBanner, TitleContentStatus, ContactAvatarGroup }