export const objectbyString = function (o = {}, s) {
    let obj = JSON.parse(JSON.stringify(o));

    console.log("OBJBYSTRING", o, s)
    try {
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, '');           // strip a leading dot
        var a = s.split('.');
        // console.log("SEARCH FOR",a)
        for (var i = 0, n = a.length; i < n; ++i) {
            var k = a[i];
            if (k in o) {
                // console.log("DATA FOUND")
                o = o[k];
            } else {
                return;
            }
        }
    } catch (e) {
        // data not found
        console.log(`DATA FOR ${s} not found`)
        return null;
    }
    console.log("got data", o, s)
    return o;
}

export const objectAssignerByString = function (o = {}, s, value, stringyfy = true) {
    // console.log(object)
    console.log("CALLED ASSIGNER ,", o, s, value)
    let obj = {};
    if (stringyfy) {
        obj = JSON.parse(JSON.stringify(o));
    } else {
        obj = o
    }
    try {
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, '');           // strip a leading dot
        var a = s.split('.');

        // for next recursive assigner
        let list_keys = JSON.parse(JSON.stringify(a));
        list_keys.splice(0, 1)

        for (var i = 0, n = a.length; i < n; ++i) {
            var key = a[i];
            var next_key = a[i + 1];
            // console.log("NEXT KEY", next_key)
            if (!isNaN(next_key)) {
                // if next key is integer, then this obj is array type
                console.log("ARRAY")
                if (Array.isArray(obj[key])) {
                    // Already array, push items

                } else {
                    console.log("create new array")
                    obj[key] = []
                }
                let rem_data = (parseInt(next_key) - obj[key].length);

                for (i = 0; i <= (rem_data - 1); i++) {
                    console.log("filling remaining datqa", next_key, key, i, a.length)
                    obj[key].push(null)
                    console.log("ASD")
                }


                obj[key] = objectAssignerByString(obj[key] || [], list_keys.join('.'), value, stringyfy)
                // console.log("RECURRING", obj)
                return obj
            }
            else if (typeof next_key === 'string' && isNaN(next_key)) {
                // if next key is string, then is is object
                // console.log("CREATE OBJ")
                if (!obj[key]) {
                    obj[key] = {}
                }
                obj[key] = objectAssignerByString(obj[key], list_keys.join('.'), value, stringyfy)
                return obj
            }
            else if (next_key == undefined) {
                // if no next key, this is the final value, so assign and return
                obj[key] = value;
                // console.log("ASSIGN KEY",key,obj)
                return obj
            }
            else return;
        }
    } catch (e) {
        console.log("Cannot assign value", e)
    }
    // console.log("FINAL DATA",obj)
    return obj;
}


export const arrayReplaceAndPush = function (arr = [], elem, shouldRemove = true) {
    if (elem != null) {
        let new_arr = JSON.parse(JSON.stringify(arr));
        return new_arr.includes(elem) ?
            shouldRemove ? new_arr.filter((a) => a != elem) : new_arr : [...new_arr, elem]
        return new_arr;
    } else {
        return arr;
    }
}



export const validateFormData = function (schema = [], data = {}) {
    console.log("validating", schema, data)
    let _errors = {};
    schema.map((_schemaItem, _schemaIndex) => {
        let _value = data[_schemaItem.index];

        if (_schemaItem.required == true && (_value == null || _value == "")) {
            _errors[_schemaItem.index] = [`${_schemaItem.label||_schemaItem.index} is required`]
        }
        if (_schemaItem.conditional) {
            let optional_Schemas = _schemaItem.optional_schema || {};
            if (_schemaItem.append_key) {
                _errors = validateFormData(optional_Schemas[_value] || [], data[_schemaItem.append_key])
            } else {
                _errors = {
                    ..._errors,
                    ...validateFormData(optional_Schemas[_value] || [], data)
                }
            }
        }
        if (_schemaItem.multiple) {
            if (_schemaItem.type == 'form' && _schemaItem.count > 0) {
                let form_array_error = [];
                let error_count=0;
                for (let i = 0; i <= (_schemaItem.count - 1 || 0); i++) {
                    let indi_error = validateFormData(_schemaItem.schema || [], _value[i])
                    if(Object.keys(indi_error).length>0){
                        error_count++;
                    }
                    form_array_error.push(indi_error)
                }
                console.log("validating for multiple form", _value, _schemaItem, form_array_error)

                _errors = {
                    ..._errors,
                    ...error_count>0?{[_schemaItem.index]:form_array_error}:{}
                }
            }
        }
        // if(_schemaItem.schema_options)
    })
    console.log("validating errors", _errors)

    return _errors
}

const validateForMultiple = (dataArray = [], schemaItem = {}, count = 0) => {
    let error = {};

}