
import { getCSSVariable } from '../util/ui_util';

export const THEME_COLOR={

    black:getCSSVariable('--gray90'),
    darkGrey:getCSSVariable('--gray80'),
    mediumGrey:getCSSVariable('--gray60'),
    normalGrey:getCSSVariable('--gray50'),
    lighterGrey:getCSSVariable('--gray20'),
    lightGrey:getCSSVariable('--gray10'),
    
    


    primary:getCSSVariable('--primary'),
    primaryLight:getCSSVariable('--primary-light'),
    primaryTint:getCSSVariable('--primary-tint'),

    secondary:getCSSVariable('--secondary'),
    secondaryLight:getCSSVariable('--secondary-light'),
    background:getCSSVariable('--background'),

    danger:getCSSVariable('--danger'),
    dangerLight:getCSSVariable('--danger-light'),
    success:getCSSVariable('--success'),
    successLight:getCSSVariable('--success-light'),
    warning:getCSSVariable('--warning'),
    warningLight:getCSSVariable('--warning-light'),
    info:getCSSVariable('--info'),
    infoLight:getCSSVariable('--info-light'),


    assent1:getCSSVariable('--assent1'),
    assent1Light:getCSSVariable('--assent1-light'),

    assent2:getCSSVariable('--assent2'),
    assent2Light:getCSSVariable('--assent2-light'),

    assent3:getCSSVariable('--assent3'),
    assent3Light:getCSSVariable('--assent3-light')
}