import React from "react";
import "antd/dist/antd.css";
import { Form, Input, Button, Divider, message, Row, Col } from "antd";
import { RESTExecutor, withDomains } from "../../lib/domain";
import { LockOutlined, InfoCircleOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom";


let password = '';
const ChangePasswordForm = ({ domains, ...props }) => {

    const [form] = Form.useForm();
    let change_password = RESTExecutor.post()
        .config({
            label: 'change_password'
        }).callbacks(
            (success) => {
                message.success("Password changed successfully");
                form.resetFields();
            },
            (error) => {
                message.error(error.error_message);
            }
        )
        .connect(domains.password)

    const onFinish = values => {

        console.log("Received values of form: ", values);
        password = values.new_password;
        change_password.execute(values);
    };


    let { validation_errors = {}, error_message } = change_password.response()
    let validateStatus = Object.keys(validation_errors || {}).length > 0 ? 'error' : ''


    return (
        <div className="edit-form-container">
            <p className="edit-form-header">Change Password</p>
            <Form
                form={form}
                title="Change Password"
                onFinish={onFinish}
                requiredMark={false}
            >

                <Form.Item
                    name="old_password"
                    label="Current Password"
                    labelCol={{ span: 24 }}
                    rules={[
                        {
                            required: true,
                            message: "Please input your current password",
                            validateStatus: 'error'
                        }
                    ]}

                >
                    <Input.Password
                        prefix={<LockOutlined />}

                        allowClear={true}
                    />
                </Form.Item>


                <Form.Item
                    name="new_password"
                    label="New Password"
                    hasFeedback
                    labelCol={{ span: 24 }}
                    rules={[
                        {
                            required: true,
                            message: "Please input your new password",
                            validateStatus: 'error'
                        },
                        {
                            pattern: /^(?=.*\d)(?=.*[a-zA-Z]).{6,20}$/,
                            message: "Must contain at least one number and at least 6 to 20 characters. Special characters are also allowed."
                        }
                    ]}

                >
                    <Input.Password
                        prefix={<LockOutlined />}
                        pattern="^(?=.*\d)(?=.*[a-zA-Z]).{6,20}$"
                        title="Must contain at least one number and at least 6 to 20 characters. Special characters are also allowed."
                        allowClear={true}
                    />
                </Form.Item>


                <Form.Item
                    name="confirm_password"
                    label="Retype New Password"
                    hasFeedback
                    labelCol={{ span: 24 }}
                    rules={[
                        {

                            required: true,
                            message: "Please retype your new password",
                            validateStatus: 'error'
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('new_password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                            },
                        }),
                    ]}

                >
                    <Input.Password
                        prefix={<LockOutlined />}

                        allowClear={true}
                    />
                </Form.Item>

                <Divider />

                <div style={{ display: 'flex', flexDirection: "row", flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Form.Item>
                        <Button
                            size="large"
                            htmlType="submit" className="login-form-button">
                            Update Password
                        </Button>
                    </Form.Item>
                    <Col style={{ marginBottom: '24px', fontFamily: 'Inter', fontSize: '12.8px', color: '#91979F' }}>
                        <InfoCircleOutlined />
                        &nbsp;Can’t remember your current password.<Link to='/forgot-password'> Reset your password by email</Link>
                    </Col>
                </div>

            </Form>
        </div>
    );
};

export default withDomains(ChangePasswordForm, 'password');