// import { icons } from 'antd/lib/image/PreviewGroup';
import React, { useState } from 'react'
import SmallForm from "../../components/forms/small_form";
import { RESTExecutor, withDomains } from "../../lib/domain";
import { Link } from "react-router-dom";


import 'antd/dist/antd.css';
import { Form, Input, Button, Checkbox, message } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";


const LandingPage = ({ authenticator }) => {



    const onFinish = (values) => {
        authenticator.login(values, () => { }, (error) => {
            error.error_message && message.error(error.error_message)
        });


        //remember me previous implementationcls


        // const rmCheck = document.getElementById('normal_login_remember');
        // const emailInput = document.getElementById('normal_login_email');
        // const password = document.getElementById('normal_login_password');

        // RememberMe();

        // function RememberMe() {
        //     if (rmCheck.checked && emailInput.value !== "") {
        //         localStorage.username = emailInput.value;
        //         localStorage.checkbox = rmCheck.checked;
        //         localStorage.password = password.value;
        //     } else {
        //         localStorage.username = "";
        //         localStorage.checkbox = "";
        //         localStorage.password = "";
        //     }
        // }
    };

    let { validation_errors = {}, error_message } = authenticator.response()
    let validateStatus = Object.keys(validation_errors || {}).length > 0 ? 'error' : ''
    let auth_resp = authenticator.response();

    return (
        <div>
            <div className="container">
                <div className="header-menu">
                    < img src='images/logo.svg' className='header-menu-item' />
                    <span className='header-menu-item'>
                        Need an account?
                        <Link to={'/register'}>
                            &nbsp;Sign up
                        </Link>
                    </span>
                </div>
            </div>
            <div className='main-container'>


                <div className="container">
                    <div className="form-container">
                        <img src="images/logo2.svg" className="form-logo" />
                        <p className="form-header">Sign in to Tigg App</p>
                        <p className="form-sub-header">me.tigg.app</p>


                        < Form
                            name="normal_login"
                            className="login-form"
                            size="large"
                            initialValues={{
                                remember: true
                            }
                            }

                            onFinish={
                                onFinish
                            }
                        >
                            <Form.Item
                                name="email"
                                initialValue={localStorage.username}
                                validateStatus={validateStatus}
                                help={(validation_errors?.email ?? [null])[0]}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your Username!",
                                        validateStatus: 'error'
                                    }
                                ]}
                            >
                                <Input
                                    prefix={<img src="images/user.svg" />}
                                    placeholder="name@email.com"
                                />
                            </Form.Item>
                            {/* <span>

                </span> */}
                            <Form.Item
                                validateTrigger='onSubmit'
                                name="password"
                                initialValue={localStorage.password}
                                validateStatus={validateStatus}
                                help={(validation_errors?.password ?? [null])[0]}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your Password!"
                                    }
                                ]}
                            >
                                <Input.Password
                                    prefix={<img src='images/lock.svg' />}
                                    placeholder="Password"
                                />

                            </Form.Item>

                            <Form.Item>
                                <Button
                                    loading={auth_resp?.fetching ?? false}
                                    size="large"
                                    htmlType="submit" className="login-form-button">
                                    LOGIN
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                {/* <Form.Item name="remember" valuePropName="checked" noStyle>
                                    <Checkbox>Remember me on this device</Checkbox>
                                </Form.Item> */}
                                <Form.Item >
                                    <Link to={'/forgot-password'}>
                                        Forgot password?
                                    </Link>
                                </Form.Item>
                                Problem signing in?
                                <a className="login-form-help" href="https://tiggapp.com">
                                    &nbsp;Get help
                                </a>
                            </Form.Item>
                        </Form >
                    </div >
                </div >
            </div >
        </div >

    )
}

export default withDomains(LandingPage, 'auth')
//         <div className="application-container">
//             <div className="header-menu">
//                 <img src='images/logo.svg' className='header-menu-item' />
//                 <span className='header-menu-item'>
//                     Need an account? <a href="">Sign up</a>
//                 </span>
//             </div>

//             <div className="main-container">
//                 <div className="container" id="login">
//                     <img src='images/logo2.svg' />



//                     <SmallForm
//                         networkState={authenticator.response()}
//                         data={
//                             login
//                         }

//                         onChange={(val) => {
//                             setLogin({
//                                 ...login,
//                                 ...val
//                             })
//                         }}
//                         saveText={'LOGIN'}
//                         title="Sign in to Tigg App"
//                         subtitle="web.tigg.app"
//                         schema={LOGIN_SCHEMA}
//                         onSave={() => {
//                             authenticator.login(login)
//                         }}
//                     />
//                 </div>

//                 <div className='left-container'>
//                     <span>
//                         <Checkbox></Checkbox>
//                     &nbsp;&nbsp;Remember me on this device
//                 </span>
//                     <span>
//                         <Link to={'/forgot-password'}>
//                             Forgot password?
//                     </Link>
//                     </span>


//                 </div>

//             </div>

//         </div>
//     )
// }


// const LOGIN_SCHEMA = [
//     { placeholder: 'name@email.com', type: 'text', index: 'email', required: true },
//     { placeholder: 'Password', type: 'password', index: 'password', required: true },

// ]

