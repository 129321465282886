import React, { Component, useState, useEffect } from 'react';
import { Space, Typography, Table, InputNumber, message, Input } from 'antd';
import FormEditorsContainer, { LeftFormContainerWrapper } from './form-editors-container';
import { LayoutManager } from '../../util/layout_manager';
// import Text from 'antd/lib/typography/Text';
import { CheckCircleFilled, DeleteOutlined, CopyFilled } from '@ant-design/icons';
import { InlineDropdownSelect, RoundedIconButton, TransparentButton, SmallRoundedIcon,CustomButton } from './buttons';
import { NumberInput, NumberInputWithDropdown, TextArea, TextInput } from './inputs';
import { AddIcon, DeleteIcon, EyeIcon } from '../../assets/icons';
import { createVariation, formatNumber } from '../../util/ui_util';
import { RadioCards } from './radio_cards';
import { Transition, animated } from 'react-spring/renderprops'
import { RESTExecutor, withDomains } from '../../lib/domain';
import { AccountSelect, CustomSelect, NetworkSelect } from './select';
import { PRODUCT_SERVICE_SMALL_SCHEMA, VAT_OPTIONS, COST_TERMS_METHOD_OPTIONS } from '../../assets/constants';
import OrderTable from '../tables/order_table';
import BaseTable from '../tables/base_table';
import { showErrorInNotification } from '../../lib/util';
import Modal from 'antd/lib/modal/Modal';


const { Text } = Typography;


const BASIC_FORM_SEGMENT = ({
  title, subtitle, expandable, data, onChangeData, schema = [],
  defaultExpanded = false, validation_errors,
  onRef, forEdit
}) => {
  const [expanded, onChangeExpand] = useState(expandable ? defaultExpanded : true);

  return <div>
    <div className={`
          no-wrap-rl-row
          segment-header
       ${expandable ? 'left-form-container-left-expandable-header hand-cursor' : ''}
      `} onClick={() => {
        expandable && onChangeExpand(!expanded)
      }}>

      <span className='section-title'>{title}</span>
      {expandable && <RoundedIconButton direction={expanded ? 'right' : 'down'} onClick={() => {
        expandable && onChangeExpand(!expanded)
      }} />
      }
    </div>
    <Transition
      native
      immediate={true}
      items={expanded}
      from={{ overflow: 'hidden', height: 0 }}
      enter={[{ height: 'auto' }]}
      leave={{ height: 0 }}
    >
      {show =>
        show && (props => <animated.div className='no-wrap-rl-row form-segment-container' style={{
          alignItems: 'flex-start',
          ...props
        }}>
          <div className='form-segment-left-side'>
            <div className='t-meta-info gray60'>{subtitle}</div>
          </div>

          <LeftFormContainerWrapper
            className={`right-form-container-left`}
            children={<FormEditorsContainer
              onRef={onRef}
              forEdit={forEdit}
              validation_errors={validation_errors}
              data={data}
              onChange={(data) => {
                typeof onChangeData === 'function' && onChangeData(data)
                // this.setState({
                //   data
                // })
              }}
              fields={schema}
            />}
          />
        </animated.div>)
      }
    </Transition>


    {/* </div> */}
  </div>
}
const MAIN_FORM_SEGMENT = ({
  label, value, valueKey, onChangeValue, data, onChangeData, schema = [], validation_errors,
  onRef,
  forEdit,
  editable,
  stringify
}) => {
  return <div>
    <div className='no-wrap-rl-row form-segment-container' style={{
      alignItems: 'inherit'
    }}>
      <div className='form-segment-left-side' style={{
        // backgroundColor: 'red'
        flexDirection: 'column',
        display: 'flex'
      }} >
        <span className='form-label'>{label}</span>
        <Text editable={editable ? {
          onChange: (value) => {
            typeof onChangeValue === 'function' && onChangeValue(value)
            // typeof onChangeData === 'function' && onChangeData({
            //   ...data,
            //   [valueKey]:value
            // })
          },
        } : null}
          style={{
            marginTop: 20
          }} className='t-form-id ant-typography-edit-content'>{value}</Text>
        {Array.isArray(validation_errors['code']) && typeof validation_errors['code'][0] === 'string' &&
          <div className='t-meta-info error-color'>{validation_errors['code'][0]}</div>
        }

      </div>

      <LeftFormContainerWrapper
        className="right-form-container-left"
        children={<FormEditorsContainer
          onRef={onRef}
          stringify={stringify}
          forEdit={forEdit}
          validation_errors={validation_errors}
          data={data}
          onChange={(data) => {
            typeof onChangeData === 'function' && onChangeData(data)
            // this.setState({
            //   data
            // })
          }}
          fields={schema}
        />}
      />
    </div>
  </div>
}

const CONTACT_TABLE_SEGMENT = ({
  title, subtitle, expandable, data, onChangeData, schema = [],
  defaultExpanded = false
}) => {
  const [expanded, onChangeExpand] = useState(defaultExpanded);
  const [new_contact, onChangeNewContact] = useState({});

  let _data = data || [];
  return <div>
    <div className='no-wrap-rl-row'
      style={{
      }}>
      <Space size={LayoutManager.globalSpacing}>
        <span className='section-title'>{title}</span>
        {expandable && <RoundedIconButton
          direction={expanded ? 'right' : 'down'}
          onClick={() => {
            expandable && onChangeExpand(!expanded)
          }} />
        }
      </Space>
    </div>
    {expanded && <div style={{
      paddingTop: LayoutManager.globalPadding
    }}>
      <Table
        className={`main-table base-table no-hover-table`}
        dataSource={[..._data, new_contact]}
        pagination={false}
        bordered
        columns={[
          {
            title: "FULL NAME",
            dataIndex: 'name',
            render: (value, record, index) => {
              let obj = {
                children: value,
                props: {
                  className: ''
                }
              }
              if (index == _data.length) {
                obj.children = <TextInput
                  autoComplete='off'
                  value={new_contact.name}
                  onChange={(val) => {
                    onChangeNewContact(s => Object.assign(s, { "name": val }))
                  }}
                  inputClassName='no-border-input'
                  placeholder="Full Name"
                />
                obj.props.className = 'input-table-cell'
              }
              return obj;
            }
          },
          {
            title: "Phone",
            dataIndex: 'phone_number',
            width: 200,
            render: (value, record, index) => {
              let obj = {
                children: value,
                props: {
                  className: ''
                }
              }
              if (index == _data.length) {
                obj.children = <TextInput
                  autoComplete='off'
                  value={new_contact.phone_number}
                  onChange={(val) => {
                    onChangeNewContact(s => Object.assign(s, { "phone_number": val }))
                  }}
                  inputClassName='no-border-input'
                  placeholder="Phone"
                />
                obj.props.className = 'input-table-cell'
              }
              return obj;
            }
          },
          {
            title: "Email",
            dataIndex: 'email',
            width: 200,
            render: (value, record, index) => {
              let obj = {
                children: value,
                props: {
                  className: ''
                }
              }
              if (index == _data.length) {
                obj.children = <TextInput
                  autoComplete='off'
                  value={new_contact.email}
                  onChange={(val) => {
                    onChangeNewContact(s => Object.assign(s, { "email": val }))
                  }}
                  inputClassName='no-border-input'
                  placeholder="Email"
                />
                obj.props.className = 'input-table-cell'
              }
              return obj;
            }
          },
          {
            title: "Title",
            dataIndex: 'organization_title',
            width: 200,
            render: (value, record, index) => {
              let obj = {
                children: value,
                props: {
                  className: ''
                }
              }
              if (index == _data.length) {
                obj.children = <TextInput
                  value={new_contact.organization_title}
                  autoComplete='off'
                  onChange={(val) => {
                    console.log("on change title", val, new_contact)
                    onChangeNewContact(s => Object.assign(s, { "organization_title": val }))
                  }}
                  inputClassName='no-border-input'
                  placeholder="Title"
                  onPressEnter={() => {
                    typeof onChangeData === 'function' && onChangeData([..._data, new_contact]);
                    onChangeNewContact({})
                  }}
                />
                obj.props.className = 'input-table-cell'
              }
              return obj;
            }
          },
          {
            title: "",
            width: 40,
            render: (value, record, index) => {
              if (index == _data.length) {
                return <TransparentButton onClick={() => {
                  typeof onChangeData === 'function' && onChangeData([..._data, new_contact]);
                  onChangeNewContact({})
                }} type='success' icon={<CheckCircleFilled />} />
              } else {
                return <TransparentButton onClick={() => {
                  if (typeof onChangeData === 'function') {
                    _data.splice(index, 1);
                    onChangeData(_data);
                    onChangeNewContact({})
                  }


                }} type='danger' icon={<DeleteIcon />} />
              }
            }

          }
        ]}

      />
    </div>}
  </div>
}

const VARIANT_SCHEMA = [
  {
    name: "Variations", index: 'variation', type: 'form', multiple: true, count: 1,
    block: true,
    schema: [
      { label: "Variant", type: 'text', index: 'varient' },
      { label: "Options", type: 'tags', index: 'options' }
    ]
  }
]
const SINGLE_PRODUCT_VARIANT = [
  {
    label: "Selling Price",
    index: 'selling_price',
    type: 'number'
  },
  {
    label: "Purchase Price",
    index: 'purchase_price',
    type: 'number'
  },
  {
    label: "Default Warehouse",
    index: 'default_warehouse',
    type: 'text'
  },
  {
    label: "Reorder Level",
    index: 'reorder_level',
    type: 'number'
  }
]

const PRODUCT_VARIATION_SEGMENT = ({
  title, subtitle, expandable, data, onChangeData, schema = [],
  defaultExpanded = false, validation_errors = {}
}) => {
  let _products = data.items || [];
  const [type, onChangetype] = useState("SINGLE");
  const [variations, onChangeVariation] = useState({});
  useEffect(() => {
    console.log("value changed")
    if (type == "SINGLE") {
      onChangeData([{
        ..._products[0],
        name: data.name,
        code: data.id,

      }])
    }
  }, [data.name, data.id]);

  const copyAllField = (key) => {
    let firstItem = _products[0] || {};
    let copyValue = firstItem[key];
    let new_products = _products.map((item) => {
      return {
        ...item,
        [key]: copyValue
      }
    })
    onChangeData(new_products);
    // console.log("new producrts",new_products);
  }

  return <div>
    {Array.isArray(validation_errors['items']) && typeof validation_errors['items'][0] === 'string' &&
      <div className='t-meta-info error-color'>{validation_errors['items'][0]}</div>
    }
    <Space direction='vertical' size={0} style={{
      width: '100%'
    }}>
      <div className='no-wrap-rl-row form-segment-container' style={{
        alignItems: 'inherit',
        paddingTop: 0
      }}>
        <div className={`form-segment-left-side`}></div>
        <div className='no-wrap-rl-row' style={{
          // justifyContent: 'flex-end'
        }}>
          <RadioCards
            value={type}
            size='large'
            onChange={(_type) => {
              onChangetype(_type);

              if (_type == "SINGLE") {
                onChangeData([{
                  name: data.name,
                  code: data.id
                }])
                // Clone name and code from main form
              }
              onChangeVariation({})
            }}
            options={[
              { key: "SINGLE", title: "Simple Product" },
              { key: "MULTIPLE", title: "Variant Product" }
            ]}
          />
          {/* <CustomToggler
          value={type}
          options={
            [
              { key: 'SINGLE', value: "SINGLE" },
              { key: 'MULTIPLE', value: "MULTIPLE" },
            ]
          }
          onChange={(val) => {
            onChangetype(val)
          }}
        /> */}
        </div>
      </div>
      {type == "MULTIPLE" && <div className='no-wrap-rl-row form-segment-container' style={{
        alignItems: 'inherit'
      }}>
        <div className={`form-segment-left-side`}></div>
        <LeftFormContainerWrapper
          className={`right-form-container-left`}
          children={<FormEditorsContainer
            data={variations}
            onChange={(_d) => {
              onChangeVariation(_d)
              // typeof onChangeData === 'function' && onChangeData(data)
              // this.setState({
              //   data
              // })
            }}
            fields={VARIANT_SCHEMA}
          />}
        />
      </div>}
      {/* />} */}

      {type == "SINGLE" && <div className='no-wrap-rl-row form-segment-container' style={{
        alignItems: 'inherit'
      }}>
        <div className={`form-segment-left-side`}></div>
        <LeftFormContainerWrapper
          className={`right-form-container-left`}
          children={<FormEditorsContainer
            data={_products[0]}
            onChange={(_d) => {

              typeof onChangeData === 'function' && onChangeData([_d])
              // this.setState({
              //   data
              // })
            }}
            fields={SINGLE_PRODUCT_VARIANT}
          />}
        />
      </div>}
      {type == "MULTIPLE" && <div >
        <center>
          <TransparentButton onClick={() => {
            console.log("creating variants for", variations, data)
            let vars = variations.variation || [];
            typeof onChangeData === 'function' && onChangeData(createVariation(data.name, vars))

          }} type='primary' label="COMPUTE VARIANTS" />
        </center>

        <Table
          className={`main-table base-table no-hover-table`}

          dataSource={_products}
          pagination={false}
          style={{
            backgroundColor: 'white'
          }}
          bordered
          columns={[
            {
              title: "ITEM NAME",
              dataIndex: 'name',
              width: 130,
              render: (value, record, index) => {
                let obj = {
                  children: value,
                  props: {
                    className: ''
                  }
                }
                // if (index == _data.length) {
                obj.children = <TextInput
                  autoComplete='off'
                  value={value}
                  onChange={(val) => {
                    let new_products = _products;
                    new_products[index]["name"] = val;
                    onChangeData(new_products)
                    // onChangeNewContact(s => Object.assign(s, { "name": val }))
                  }}
                  inputClassName='no-border-input'
                  placeholder="Name"
                />
                obj.props.className = 'input-table-cell'
                // }
                return obj;
              }
            },
            {
              title: "Code/SKU/Barcode",
              dataIndex: 'code',
              width: 200,
              render: (value, record, index) => {
                let obj = {
                  children: value,
                  props: {
                    className: ''
                  }
                }
                // if (index == _data.length) {
                obj.children = <TextInput
                  autoComplete='off'
                  value={value}
                  onChange={(val) => {
                    let new_products = _products;
                    new_products[index]["code"] = val;
                    onChangeData(new_products)
                    // onChangeNewContact(s => Object.assign(s, { "phone_number": val }))
                  }}
                  inputClassName='no-border-input'
                  placeholder="Code"
                />
                obj.props.className = 'input-table-cell'
                // }
                return obj;
              }
            },
            {
              title: <div>Selling Price <CopyFilled title="Copy to all" onClick={() => {
                copyAllField('selling_price')
              }} /></div>,
              dataIndex: 'selling_price',
              width: 200,
              render: (value, record, index) => {
                let obj = {
                  children: value,
                  props: {
                    className: ''
                  }
                }
                // if (index == _data.length) {
                obj.children = <InputNumber
                  style={{
                    width: '100%'
                  }}
                  autoComplete='off'
                  value={value}
                  onChange={(val) => {
                    let new_products = _products;
                    new_products[index]["selling_price"] = val;
                    onChangeData(new_products)
                    // onChangeNewContact(s => Object.assign(s, { "phone_number": val }))
                  }}
                  className='no-border-input'
                  placeholder="Sale Price"
                />
                obj.props.className = 'input-table-cell'
                // }
                return obj;
              }
            },
            {
              title: <div>Purchase Price <CopyFilled title="Copy to all" onClick={() => {
                copyAllField('purchase_price')
              }} /></div>,
              dataIndex: 'purchase_price',
              width: 200,
              render: (value, record, index) => {
                let obj = {
                  children: value,
                  props: {
                    className: ''
                  }
                }
                // if (index == _data.length) {
                obj.children = <InputNumber
                  style={{
                    width: '100%'
                  }}
                  autoComplete='off'
                  value={value}
                  onChange={(val) => {
                    let new_products = _products;
                    new_products[index]["purchase_price"] = val;
                    onChangeData(new_products)
                    // onChangeNewContact(s => Object.assign(s, { "phone_number": val }))
                  }}
                  className='no-border-input'
                  placeholder="Purchase Price"
                />
                obj.props.className = 'input-table-cell'
                // }
                return obj;
              }
            },
            {
              title: <div>Default Warehouse <CopyFilled title="Copy to all" onClick={() => {
                copyAllField('default_warehouse')
              }} /></div>,
              dataIndex: 'default_warehouse',
              width: 200,
              render: (value, record, index) => {
                let obj = {
                  children: value,
                  props: {
                    className: ''
                  }
                }
                // if (index == _data.length) {
                obj.children = <TextInput
                  autoComplete='off'
                  value={value}
                  onChange={(val) => {
                    let new_products = _products;
                    new_products[index]["default_warehouse"] = val;
                    onChangeData(new_products)
                    // onChangeNewContact(s => Object.assign(s, { "phone_number": val }))
                  }}
                  inputClassName='no-border-input'
                  placeholder="Default Warehouse"
                />
                obj.props.className = 'input-table-cell'
                // }
                return obj;
              }
            },
            {
              title: <div>Reorder Level <CopyFilled title="Copy to all" onClick={() => {
                copyAllField('reorder_level')
              }} /></div>,
              dataIndex: 'reorder_level',
              width: 200,
              render: (value, record, index) => {
                let obj = {
                  children: value,
                  props: {
                    className: ''
                  }
                }
                // if (index == _data.length) {
                obj.children = <InputNumber
                  style={{
                    width: '100%'
                  }}
                  autoComplete='off'
                  value={value}
                  onChange={(val) => {
                    let new_products = _products;
                    new_products[index]["reorder_level"] = val;
                    onChangeData(new_products)
                    // onChangeNewContact(s => Object.assign(s, { "phone_number": val }))
                  }}
                  className='no-border-input'
                  placeholder="Reorder Level"
                />
                obj.props.className = 'input-table-cell'
                // }
                return obj;
              }
            },
            {
              title: "",
              width: 40,
              render: (value, record, index) => {

                return <TransparentButton onClick={() => {
                  if (typeof onChangeData === 'function') {
                    console.log("deleting")
                    let new_product_list = JSON.parse(JSON.stringify(_products));
                    new_product_list.splice(index, 1);
                    console.log("final producsrt", new_product_list)
                    onChangeData(new_product_list);
                    // onChangeNewContact({})
                  }


                }} type='danger' icon={<DeleteIcon />} />

              }

            }
          ]}

        />
      </div>
      }
    </Space>
  </div>

}

class _PRODUCT_ORDER_TABLE_SEGMENT extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: [{}],
      new_data: {},
      accounts: [],
      subaccounts: {},
      units_and_subunits: {

      }, //{product_id:{unit:"Name",subunit:"Name"}},
      expanded_keys: [],
      productsSaved: {

      },
      warehouse_list: [{
        key: null,
        value: "Default"
      }]
    }

    this.list_warehouse = RESTExecutor.list().config({
      label: 'warehouselist'
    }).callbacks((success) => {
      let warehouses = [{
        key: null,
        value: "Default"
      }];
      let result = success.result || [];
      result.map((item) => {
        let _data = item.data || {};
        warehouses.push({
          key: item.id,
          value: _data.name
        })
      });

      this.setState({
        warehouse_list: warehouses
      });

    }).connect(props.domains.inventory);
    this.list_accounts = RESTExecutor.list().config({
      url: '/accounts'
    }).callbacks((success) => {
      let accounts = [];
      let subaccounts = {};
      success.result.map((acc_item) => {
        accounts.push({
          id: acc_item.id,
          ...acc_item.data
        });
        let temp_subs = []
        if (Array.isArray(acc_item.sub_accounts) && acc_item.sub_accounts.length > 0) {
          acc_item.sub_accounts.map((sub_acc_item) => {
            temp_subs.push({
              id: sub_acc_item.id,
              ...sub_acc_item.data
            })
          });
          subaccounts[acc_item.id] = temp_subs

        }
      });
      this.setState({
        accounts, subaccounts
      })
      console.log("success d ata", success)
    }).connect(props.domains.dynamic);

    this.list_accounts.execute();
    this.list_warehouse.execute();
  }

  changeMainDataHandler = (value, key, main_data) => {

  }
  productAddHandler = (new_item) => {

  }
  productRemoveHandler = (index) => {

  }
  recalculateAllData = (main_data = {}) => {
    /** ORDER: 
     * quantity->
     * uom->
     * rate->
     * discount-> 
     * <total> ->
     * {partition_additional_discount(as per total)}-> 
     * temp_total_2
     * {parttion_additioan_cost(as  per temp_total_2)} ->
     * temp_total_2 ->
     * vat as per temp_total_2
     * */
    console.log("initial data is", main_data);

    let additional_total_discount = main_data.discount || 0;
    let additional_total_charge = main_data.additional_charge || 0;
    let sub_total = 0;
    let vat = 0;
    let data = main_data[this.props.append_key || 'items'] || [];

    // for additional discount distribution
    let temp_data1 = [];
    let all_temp_total_1 = 0;
    // for additional charge  distribution
    let temp_data2 = [];
    let all_temp_total_2 = 0;
    let temp_data3 = [];
    let all_temp_total_3 = 0;
    let new_data = [];
    temp_data1 = data.map((data_item) => {
      var temp_total_1 = (data_item.quantity || 0) * ((data_item.rate || 0) - (data_item.discount || 0));
      all_temp_total_1 = all_temp_total_1 + temp_total_1;
      return {
        ...data_item,
        total: temp_total_1
      }
    });
    temp_data2 = temp_data1.map((data_item) => {
      var additional_discount = ((data_item.total / all_temp_total_1) * additional_total_discount);
      var temp_total_2 = data_item.total - additional_discount;
      all_temp_total_2 = all_temp_total_2 + temp_total_2;
      return {
        ...data_item,
        additional_discount,
        temp_total_2: temp_total_2
      }
    });

    temp_data3 = temp_data2.map((data_item) => {
      var additional_charge = ((data_item.temp_total_2 / all_temp_total_2) * additional_total_charge)
      var temp_total_3 = data_item.temp_total_2 + additional_charge;
      all_temp_total_3 = all_temp_total_3 + temp_total_3;
      return {
        ...data_item,
        additional_charge,
        temp_total_3: temp_total_3
      }
    });

    temp_data3.map((data_item) => {
      if (data_item.vat_type == "13%") {
        vat = vat + ((data_item.temp_total_3 || 0) * 0.13)
      }
      new_data.push({
        ...data_item,
        vat_amount: data_item.vat_type == "13%" ? ((data_item.temp_total_3 || 0) * 0.13) : 0
      });
      sub_total = sub_total + data_item.total;
    })

    main_data['vat'] = vat;
    main_data['sub_total'] = sub_total;
    main_data['total'] = vat + sub_total;
    main_data[this.props.append_key || 'items'] = new_data;
    console.log("final data is", main_data);
    this.props.onChangeMainData(main_data);
  }

  changeNewItemHandler = (value, key, data) => {
    data[key] = value;
    switch (key) {
      case 'quantity':
        data['total'] = (value || 0) * ((data.rate || 0) - (data.discount || 0));
        break;
      case 'rate':
        data['total'] = (data.quantity || 0) * ((value || 0) - (data.discount || 0));
        break;
      case 'discount':
        data['total'] = (data.quantity || 0) * ((data.rate || 0) - (value || 0));
        break;
      case 'uom':
        break;
    }
    this.setState({
      new_data: data
    })
  }
  changeItemHandler = (index, value, key, main_data) => {

    /**
     * Not needed if recalculate all data handled properly
     */
    // let product = this.state.productsSaved[main_data.product_id] || {};
    let append_key = this.props.append_key || 'items';
    let new_data = main_data;

    let data = this.props.data || [];
    let record = data[index] || {};
    record[key] = value;

    switch (key) {
      case 'quantity':
        break;
      case 'rate':
        break;
      case 'discount':
        break;
      case 'uom':
        break;
      case 'vat_type':
        break;
    }

    data.splice(index, 1, record);
    new_data[this.props.append_key || "items"] = data;
    // handle change  in  object data as per key

    this.recalculateAllData(main_data);
    // this.props.onChangeMainData(new_data);
  }

  render() {
    let { title, subtitle, expandable, onChangeData, defaultExpanded = false, validation_errors = {}, table_type = "SALES", append_key = 'items' } = this.props;
    // table_type support "SALES" to add sales price of product, "PURCHASE"  to add purchase price
    const { new_data, productsSaved } = this.state;
    const { data = [], main_data = {} } = this.props;


    let table_data = [...data, { __type__: "EDITOR" }];
    let taxable_total = 0;
    data.map((data_item) => {
      if (data_item.vat_type == "13%") {
        taxable_total = taxable_total + ((data_item.total || 0) + (data_item.additional_charge || 0) - (data_item.additional_discount || 0))
      }
    })

    return (
      <Space size={LayoutManager.formSpacing} direction='vertical' style={{
        width: '100%',
        paddingLeft: LayoutManager.globalSpacing,
        paddingRight: LayoutManager.globalSpacing
      }}>

        <OrderTable
          data={table_data}
          columns={[
            {
              title: "Product / service",
              render: (value, record, index) => {
                if (record.__type__ == "EDITOR") {
                  return <NetworkSelect
                    renderKey="CONTACTITEM"
                    dropdownWidth={500}
                    style={{
                      width: '100%'
                    }}
                    value={new_data.product_id}
                    form_title="Product/Service"
                    onChange={(id, _d) => {
                      console.log("on change", id, _d);
                      this.setState({
                        new_data: {
                          ...new_data,
                          product_id: id,
                          product_name: _d.name,
                          product_code: _d.code,
                          rate: _d.selling_price,
                          discount: 0,
                          uom: 'unit',
                          quantity: 1,
                          vat_type: _d.tax || "No Vat",
                          account_id: _d.sales_account_id,
                          sub_account_id: _d.sales_sub_account_id,
                          warehouse_id: null,
                          total: _d.selling_price
                        }
                      })
                    }}
                    apiURL='/product-services'
                    addAPI='/product-services'
                    withAdd={true}
                    addSchema={PRODUCT_SERVICE_SMALL_SCHEMA}
                    className='no-border-input'
                    placeholder="Add Code or Product"
                  />

                } else {
                  return <div style={{
                    padding: '8px 13px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: '100%'
                  }}>
                    <Text strong>{record.product_name}</Text>

                    <Text type='secondary' style={{
                      marginTop: LayoutManager.globalSpacing / 2
                    }}>{record.product_code}</Text>
                  </div>

                }
              }
            },
            {
              title: "Qty",
              width: 140,
              render: (value, record, index) => {
                if (record.__type__ == "EDITOR") {
                  return <Input.Group compact style={{
                    display: 'flex'
                  }}>
                    <NumberInput
                      disabled={new_data.product_id == null}
                      style={{
                        width: '55%'
                      }}
                      min={1}
                      value={new_data.quantity}
                      onChange={(_v) => {
                        this.changeNewItemHandler(_v, 'quantity', new_data);
                      }}
                      inputClassName='no-border-input'
                      placeholder="Qty"
                    />
                    <CustomSelect
                      disabled={new_data.product_id == null}
                      style={{
                        width: '45%'
                      }}
                      inputClassName='no-border-input'
                      placeholder="UN"
                      options={[
                        { key: "unit", value: "UN" },
                        { key: "subunit", value: "SUB" }
                      ]}
                      value={new_data.uom}
                      onChange={(_v) => {
                        this.changeNewItemHandler(_v, 'uom', new_data);
                      }}
                    />
                  </Input.Group>
                } else {
                  return <Input.Group compact style={{
                    display: 'flex'
                  }}>
                    <NumberInput
                      style={{
                        width: '55%'
                      }}
                      min={1}
                      value={record.quantity}
                      onChange={(_v) => {
                        this.changeItemHandler(index, _v, 'quantity', main_data);
                        // let new_d = record;
                        // new_d['quantity'] = _v
                        // data.splice(index, 1, new_d);
                        // this.props.onChangeData(data);
                        // this.recalculateAllData({
                        //   ...main_data,
                        //   'data':data
                        // })
                      }}
                      inputClassName='no-border-input'
                      placeholder="Qty"
                    />
                    <CustomSelect
                      style={{
                        width: '45%'
                      }}
                      inputClassName='no-border-input'
                      placeholder="UN"
                      options={[
                        { key: "unit", value: "UN" },
                        { key: "subunit", value: "SUB" }
                      ]}
                      value={record.uom}
                      onChange={(_v) => {
                        this.changeItemHandler(index, _v, 'uom', main_data);

                        // let new_d = record;
                        // new_d['uom'] = _v
                        // data.splice(index, 1, new_d);
                        // this.props.onChangeData(data);
                      }}
                    />
                  </Input.Group>
                }
              }
            },
            {
              title: "Rate",
              width: 120,
              render: (value, record, index) => {
                if (record.__type__ == "EDITOR") {
                  return <NumberInput
                    disabled={new_data.product_id == null}
                    inputClassName='no-border-input'
                    min={0}
                    placeholder="Rate"
                    value={new_data.rate}
                    onChange={(_v) => {
                      this.changeNewItemHandler(_v, 'rate', new_data);
                    }}
                  />
                } else {
                  return <NumberInput
                    inputClassName='no-border-input'
                    min={0}
                    placeholder="Rate"
                    value={record.rate}
                    onChange={(_v) => {
                      this.changeItemHandler(index, _v, 'rate', main_data);

                      // let new_d = record;
                      // new_d['rate'] = _v
                      // data.splice(index, 1, new_d);
                      // this.props.onChangeData(data);
                    }}
                  />
                }
              }
            },
            {
              title: "Discount",
              width: 120,
              render: (value, record, index) => {
                if (record.__type__ == "EDITOR") {
                  return <NumberInput
                    disabled={new_data.product_id == null}
                    inputClassName='no-border-input'
                    min={0}
                    placeholder="Discount"
                    value={new_data.discount}
                    onChange={(_v) => {
                      this.changeNewItemHandler(_v, 'discount', new_data);
                    }}
                  />
                } else {
                  return <NumberInput
                    inputClassName='no-border-input'
                    min={0}
                    placeholder="Discount"
                    value={record.discount}
                    onChange={(_v) => {
                      this.changeItemHandler(index, _v, 'discount', main_data);
                      // let new_d = record;
                      // new_d['discount'] = _v
                      // data.splice(index, 1, new_d);
                      // this.props.onChangeData(data);
                    }}
                  />
                }
              }
            },
            {
              title: "Tax",
              width: 100,
              render: (value, record, index) => {

                if (record.__type__ == "EDITOR") {
                  return <CustomSelect
                    disabled={new_data.product_id == null}
                    inputClassName='no-border-input'
                    placeholder="Tax"
                    options={VAT_OPTIONS}
                    value={new_data.vat_type}
                    onChange={(_v) => {
                      this.changeNewItemHandler(_v, 'vat_type', new_data);
                    }}
                  />
                } else {
                  return <CustomSelect
                    inputClassName='no-border-input'
                    placeholder="Tax"
                    options={VAT_OPTIONS}
                    value={record.vat_type}
                    onChange={(_v) => {
                      this.changeItemHandler(index, _v, 'vat_type', main_data);
                      // let new_d = record;
                      // new_d['vat_type'] = _v
                      // data.splice(index, 1, new_d);
                      // this.props.onChangeData(data);
                    }}
                  />
                }
              }
            },
            {
              title: "Amount",
              width: 100,
              render: (value, record, index) => {

                if (record.__type__ == "EDITOR") {
                  return <div style={{
                    padding: '8px 13px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: '100%'
                  }}>
                    <Text strong>{formatNumber(new_data.total)}</Text>
                  </div>

                } else {
                  return <div style={{
                    padding: '8px 13px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: '100%'
                  }}>
                    <Text strong>{formatNumber(record.total)}</Text>
                  </div>;
                  // <NumberInput
                  //     disabled
                  //     min={0}
                  //     inputClassName='no-border-input'
                  //     placeholder="Amount"
                  //     value={record.total}
                  //   />;

                }
              }
            },
            {
              title: "",
              width: 80,
              render: (value, record, index) => {
                if (record.__type__ == "EDITOR") {
                  return <SmallRoundedIcon onClick={() => {
                    if (new_data.product_id == null || new_data.quantity == 0) {
                      message.error("Fill Missing Field")
                    } else {
                      let new_index = data.length;
                      this.recalculateAllData({
                        ...main_data,
                        [this.props.append_key || 'items']: [...data, { rate: 0, uom: "unit", discount: 0, discount_type: 'value', ...new_data }]
                      })
                      this.setState({
                        new_data: {},
                        expanded_keys: [new_index]
                      })
                    }
                  }} type='success' icon={<AddIcon />} />
                } else {
                  return <div style={{
                    display: 'flex',
                    flexDirection: 'row'
                  }}>
                    <Space size={10}>
                      <SmallRoundedIcon direction={this.state.expanded_keys.includes(index) ? 'right' : 'down'}
                        onClick={() => {
                          this.setState({
                            expanded_keys: this.state.expanded_keys.includes(index) ? [] : [index]
                          })
                        }} />
                      <SmallRoundedIcon onClick={() => {
                        data.splice(index, 1)
                        this.recalculateAllData({
                          ...main_data,
                          [this.props.append_key || 'items']: data
                        })
                        // this.props.onChangeData(data);
                        this.setState({
                          expanded_keys: []
                        })
                      }} type='danger' icon={<DeleteIcon />} />
                      <div title={JSON.stringify(record)}>™</div>
                    </Space>
                  </div>
                }
              }

            }
          ]}
          expandRowRender={(record, index) => {
            return <div style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              padding: '10px 0px'
            }}>
              <div style={{
                flex: 1
              }}>
                <TextArea
                  rows={4}
                  autoSize={false}
                  placeholder="Product Description"
                  label="Product Description"
                  value={record.product_description}
                  onChange={(_v) => {
                    let new_d = record;
                    new_d['product_description'] = _v
                    data.splice(index, 1, new_d);
                    this.props.onChangeData(data);
                  }}
                />
              </div>
              <div style={{
                flex: 1,
                display: 'flex',
                flexWrap: 'nowrap',
                justifyContent: 'space-around'

              }}>
                <InlineDropdownSelect
                  label="WAREHOUSE"
                  value={record.warehouse_id}
                  onChange={(_v) => {
                    let new_d = record;
                    new_d['warehouse_id'] = _v
                    data.splice(index, 1, new_d);
                    console.log("new data", data);
                    this.props.onChangeData(data);

                  }}
                  selectClassName='small-inline-select'
                  options={this.state.warehouse_list}
                />

                <div style={{
                  width: '50%'
                }}>
                  <AccountSelect
                    label="ACCOUNT"
                    level_one_options={this.state.accounts}
                    level_two_options={this.state.subaccounts}
                    value1={record.account_id}
                    value2={record.sub_account_id}
                    onChange={(val1, val2) => {
                      let new_d = record;
                      new_d['account_id'] = val1
                      new_d['sub_account_id'] = val2
                      data.splice(index, 1, new_d);
                      this.props.onChangeData(data);

                    }}
                  />
                </div >
              </div>
            </div>
          }}
          expanded_keys={this.state.expanded_keys}
          onExpand={(keys) => {
            this.setState({
              expanded_keys: keys
            })
          }}
        />
        <div className='no-wrap-rl-row' style={{
          alignItems: 'flex-start',
          justifyContent: 'space-between',
        }}>
          <TextArea
            label="Note to Customer"
            value={main_data.notes}
            onChange={(_v) => {
              this.props.onChangeMainData({
                ...main_data,
                notes: _v
              })
            }}
            rows={4}
            autoSize={false}
            style={{
              width: '50%'
            }}
          />
          <div className='rl-column' style={{
            width: '50%',
            alignItems: 'flex-end'
          }}>
            <div className='no-wrap-rl-row invoice-table-extra'
              style={{
                justifyContent: 'space-between'
              }}>
              <div className='invoice-table-extra-left'>
                Sub-Total
            </div>
              <div className='invoice-table-extra-right'>
                {formatNumber(main_data.sub_total)}
              </div>
            </div>

            <div className='no-wrap-rl-row invoice-table-extra'
              style={{
                justifyContent: 'space-between'
              }}>
              <div className='invoice-table-extra-left'>
                Discount
            </div>
              <div className='invoice-table-extra-right'>
                <NumberInput value={main_data.discount} onChange={(_v) => {
                  let new_main_data = main_data;
                  new_main_data['discount'] = _v;
                  this.recalculateAllData(new_main_data)
                  // this.props.onChangeMainData(main_data);
                }} />
              </div>
            </div>
            <div className='no-wrap-rl-row invoice-table-extra'
              style={{
                justifyContent: 'space-between'
              }}>
              <div className='invoice-table-extra-left'>
                Additional charge
              </div>
              <div className='invoice-table-extra-right'>
                <NumberInput value={main_data.additional_charge} onChange={(_v) => {
                  let new_main_data = main_data;
                  new_main_data['additional_charge'] = _v;
                  this.recalculateAllData(new_main_data)
                  // this.props.onChangeMainData(main_data);
                }} />
              </div>
            </div>
            <div className='no-wrap-rl-row invoice-table-extra'
              style={{
                justifyContent: 'space-between'
              }}>
              <div className='invoice-table-extra-left'>
                Taxable Total
            </div>
              <div className='invoice-table-extra-right'>
                {formatNumber(taxable_total)}
              </div>
            </div>
            <div className='no-wrap-rl-row invoice-table-extra'
              style={{
                justifyContent: 'space-between'
              }}>
              <div className='invoice-table-extra-left'>
                VAT
            </div>
              <div className='invoice-table-extra-right'>
                {formatNumber(main_data.vat)}
              </div>
            </div>
            <div className='no-wrap-rl-row invoice-table-extra'
              style={{
                justifyContent: 'space-between'
              }}>
              <div className='invoice-table-extra-left'>
                Grand Total
            </div>
              <div className='invoice-table-extra-right'>
                {formatNumber(main_data.total)}
              </div>
            </div>

          </div>
        </div>

      </Space>
    );
  }
}
const PRODUCT_ORDER_TABLE_SEGMENT = withDomains(_PRODUCT_ORDER_TABLE_SEGMENT, "dynamic", "inventory");

class _PRODUCT_AND_QUANTITY_TABLE_SEGMENT extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: [{}],
      new_data: {},
      accounts: [],
      subaccounts: {},
      units_and_subunits: {

      }, //{product_id:{unit:"Name",subunit:"Name"}},
      expanded_keys: [],
      productsSaved: {

      },
      warehouse_list: [{
        key: null,
        value: "Default"
      }]
    }

    this.list_warehouse = RESTExecutor.list().config({
      label: 'warehouselist'
    }).callbacks((success) => {
      let warehouses = [{
        key: null,
        value: "Default"
      }];
      let result = success.result || [];
      result.map((item) => {
        let _data = item.data || {};
        warehouses.push({
          key: item.id,
          value: _data.name
        })
      });

      this.setState({
        warehouse_list: warehouses
      });

    }).connect(props.domains.inventory);

    this.list_warehouse.execute();
  }

  changeMainDataHandler = (value, key, main_data) => {

  }
  productAddHandler = (new_item) => {

  }
  productRemoveHandler = (index) => {

  }
  recalculateAllData = (main_data = {}) => {
    /** ORDER: 
     * quantity->
     * uom->
     * rate->
     * discount-> 
     * <total> ->
     * {partition_additional_discount(as per total)}-> 
     * temp_total_2
     * {parttion_additioan_cost(as  per temp_total_2)} ->
     * temp_total_2 ->
     * vat as per temp_total_2
     * */
    // console.log("initial data is", main_data);

    // let additional_total_discount = main_data.discount || 0;
    // let additional_total_charge = main_data.additional_charge || 0;
    // let sub_total = 0;
    // let vat = 0;
    // let data = main_data[this.props.append_key || 'items'] || [];

    // // for additional discount distribution
    // let temp_data1 = [];
    // let all_temp_total_1 = 0;
    // // for additional charge  distribution
    // let temp_data2 = [];
    // let all_temp_total_2 = 0;
    // let temp_data3 = [];
    // let all_temp_total_3 = 0;
    // let new_data = [];
    // temp_data1 = data.map((data_item) => {
    //   var temp_total_1 = (data_item.quantity || 0) * ((data_item.rate || 0) - (data_item.discount || 0));
    //   all_temp_total_1 = all_temp_total_1 + temp_total_1;
    //   return {
    //     ...data_item,
    //     total: temp_total_1
    //   }
    // });
    // temp_data2 = temp_data1.map((data_item) => {
    //   var additional_discount = ((data_item.total / all_temp_total_1) * additional_total_discount);
    //   var temp_total_2 = data_item.total - additional_discount;
    //   all_temp_total_2 = all_temp_total_2 + temp_total_2;
    //   return {
    //     ...data_item,
    //     additional_discount,
    //     temp_total_2: temp_total_2
    //   }
    // });

    // temp_data3 = temp_data2.map((data_item) => {
    //   var additional_charge = ((data_item.temp_total_2 / all_temp_total_2) * additional_total_charge)
    //   var temp_total_3 = data_item.temp_total_2 + additional_charge;
    //   all_temp_total_3 = all_temp_total_3 + temp_total_3;
    //   return {
    //     ...data_item,
    //     additional_charge,
    //     temp_total_3: temp_total_3
    //   }
    // });

    // temp_data3.map((data_item) => {
    //   if (data_item.vat_type == "13%") {
    //     vat = vat + ((data_item.temp_total_3 || 0) * 0.13)
    //   }
    //   sub_total = sub_total + data_item.total;
    // })

    // main_data['vat'] = vat;
    // main_data['sub_total'] = sub_total;
    // main_data['total'] = vat + sub_total;
    // main_data[this.props.append_key || 'items'] = temp_data3;
    // console.log("final data is", main_data);
    this.props.onChangeMainData(main_data);
  }

  changeNewItemHandler = (value, key, data) => {
    data[key] = value;
    // switch (key) {
    //   case 'quantity':
    //     data['total'] = (value || 0) * ((data.rate || 0) - (data.discount || 0));
    //     break;
    //   case 'rate':
    //     data['total'] = (data.quantity || 0) * ((value || 0) - (data.discount || 0));
    //     break;
    //   case 'discount':
    //     data['total'] = (data.quantity || 0) * ((data.rate || 0) - (value || 0));
    //     break;
    //   case 'uom':
    //     break;
    // }
    this.setState({
      new_data: data
    })
  }
  changeItemHandler = (index, value, key, main_data) => {

    /**
     * Not needed if recalculate all data handled properly
     */
    // let product = this.state.productsSaved[main_data.product_id] || {};
    let append_key = this.props.append_key || 'items';
    let new_data = main_data;

    let data = this.props.data || [];
    let record = data[index] || {};
    record[key] = value;

    switch (key) {
      case 'quantity':
        break;
      case 'rate':
        break;
      case 'discount':
        break;
      case 'uom':
        break;
      case 'vat_type':
        break;
    }

    data.splice(index, 1, record);
    new_data[this.props.append_key || "items"] = data;
    // handle change  in  object data as per key

    this.recalculateAllData(main_data);
    // this.props.onChangeMainData(new_data);
  }

  render() {
    let { title, subtitle, expandable, onChangeData, defaultExpanded = false, validation_errors = {}, table_type = "SALES", append_key = 'items' } = this.props;
    // table_type support "SALES" to add sales price of product, "PURCHASE"  to add purchase price
    const { new_data, productsSaved } = this.state;
    const { data = [], main_data = {} } = this.props;


    let table_data = [...data, { __type__: "EDITOR" }];
    // let taxable_total = 0;
    // data.map((data_item) => {
    //   if (data_item.vat_type == "13%") {
    //     taxable_total = taxable_total + ((data_item.total || 0) + (data_item.additional_charge || 0) - (data_item.additional_discount || 0))
    //   }
    // })

    return (
      <Space size={LayoutManager.formSpacing} direction='vertical' style={{
        width: '100%',
        paddingLeft: LayoutManager.globalSpacing,
        paddingRight: LayoutManager.globalSpacing
      }}>

        <OrderTable
          data={table_data}
          columns={[
            {
              title: "Product / service",
              render: (value, record, index) => {
                if (record.__type__ == "EDITOR") {
                  return <NetworkSelect
                    renderKey="CONTACTITEM"
                    dropdownWidth={500}
                    style={{
                      width: '100%'
                    }}
                    value={new_data.product_id}
                    form_title="Product/Service"
                    onChange={(id, _d) => {
                      console.log("on change", id, _d);
                      this.setState({
                        new_data: {
                          ...new_data,
                          product_id: id,
                          product_name: _d.name,
                          product_code: _d.code,
                          // rate: _d.selling_price,
                          // discount: 0,
                          uom: 'unit',
                          // quantity: 1,
                          // vat_type: _d.tax || "No Vat",
                          // account_id: _d.sales_account_id,
                          // sub_account_id: _d.sales_sub_account_id,
                          // warehouse_id: null,
                          // total: _d.selling_price
                        }
                      })
                    }}
                    apiURL='/product-services'
                    addAPI='/product-services'
                    withAdd={true}
                    addSchema={PRODUCT_SERVICE_SMALL_SCHEMA}
                    className='no-border-input'
                    placeholder="Add Code or Product"
                  />

                } else {
                  return <div style={{
                    padding: '8px 13px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: '100%'
                  }}>
                    <Text strong>{record.product_name}</Text>

                    <Text type='secondary' style={{
                      marginTop: LayoutManager.globalSpacing / 2
                    }}>{record.product_code}</Text>
                  </div>

                }
              }
            },
            {
              title: "Qty",
              width: 140,
              render: (value, record, index) => {
                if (record.__type__ == "EDITOR") {
                  return <Input.Group compact style={{
                    display: 'flex'
                  }}>
                    <NumberInput
                      disabled={new_data.product_id == null}
                      style={{
                        width: '55%'
                      }}
                      min={1}
                      value={new_data.quantity}
                      onChange={(_v) => {
                        this.changeNewItemHandler(_v, 'quantity', new_data);
                      }}
                      inputClassName='no-border-input'
                      placeholder="Qty"
                    />
                    <CustomSelect
                      disabled={new_data.product_id == null}
                      style={{
                        width: '45%'
                      }}
                      inputClassName='no-border-input'
                      placeholder="UN"
                      options={[
                        { key: "unit", value: "UN" },
                        { key: "subunit", value: "SUB" }
                      ]}
                      value={new_data.uom}
                      onChange={(_v) => {
                        this.changeNewItemHandler(_v, 'uom', new_data);
                      }}
                    />
                  </Input.Group>
                } else {
                  return <Input.Group compact style={{
                    display: 'flex'
                  }}>
                    <NumberInput
                      style={{
                        width: '55%'
                      }}
                      min={1}
                      value={record.quantity}
                      onChange={(_v) => {
                        this.changeItemHandler(index, _v, 'quantity', main_data);
                        // let new_d = record;
                        // new_d['quantity'] = _v
                        // data.splice(index, 1, new_d);
                        // this.props.onChangeData(data);
                        // this.recalculateAllData({
                        //   ...main_data,
                        //   'data':data
                        // })
                      }}
                      inputClassName='no-border-input'
                      placeholder="Qty"
                    />
                    <CustomSelect
                      style={{
                        width: '45%'
                      }}
                      inputClassName='no-border-input'
                      placeholder="UN"
                      options={[
                        { key: "unit", value: "UN" },
                        { key: "subunit", value: "SUB" }
                      ]}
                      value={record.uom}
                      onChange={(_v) => {
                        this.changeItemHandler(index, _v, 'uom', main_data);

                        // let new_d = record;
                        // new_d['uom'] = _v
                        // data.splice(index, 1, new_d);
                        // this.props.onChangeData(data);
                      }}
                    />
                  </Input.Group>
                }
              }
            },
            {
              title: "",
              width: 80,
              render: (value, record, index) => {
                if (record.__type__ == "EDITOR") {
                  return <SmallRoundedIcon onClick={() => {
                    if (new_data.product_id == null || new_data.quantity == 0) {
                      message.error("Fill Missing Field")
                    } else {
                      let new_index = data.length;
                      this.recalculateAllData({
                        ...main_data,
                        [this.props.append_key || 'items']: [...data, { rate: 0, uom: "unit", discount: 0, discount_type: 'value', ...new_data }]
                      })
                      this.setState({
                        new_data: {},
                        expanded_keys: [new_index]
                      })
                    }
                  }} type='success' icon={<AddIcon />} />
                } else {
                  return <div style={{
                    display: 'flex',
                    flexDirection: 'row'
                  }}>
                    <Space size={10}>
                      <SmallRoundedIcon direction={this.state.expanded_keys.includes(index) ? 'right' : 'down'}
                        onClick={() => {
                          this.setState({
                            expanded_keys: this.state.expanded_keys.includes(index) ? [] : [index]
                          })
                        }} />
                      <SmallRoundedIcon onClick={() => {
                        data.splice(index, 1)
                        this.recalculateAllData({
                          ...main_data,
                          [this.props.append_key || 'items']: data
                        })
                        // this.props.onChangeData(data);
                        this.setState({
                          expanded_keys: []
                        })
                      }} type='danger' icon={<DeleteIcon />} />
                      <div title={JSON.stringify(record)}>™</div>
                    </Space>
                  </div>
                }
              }

            }
          ]}
          expandRowRender={(record, index) => {
            return <div style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              padding: '10px 0px'
            }}>
              <div style={{
                flex: 1
              }}>
                <TextArea
                  rows={4}
                  autoSize={false}
                  placeholder="Product Description"
                  label="Product Description"
                  value={record.product_description}
                  onChange={(_v) => {
                    let new_d = record;
                    new_d['product_description'] = _v
                    data.splice(index, 1, new_d);
                    this.props.onChangeData(data);
                  }}
                />
              </div>
              <div style={{
                flex: 1,
                display: 'flex',
                flexWrap: 'nowrap',
                justifyContent: 'space-around'

              }}>
                <InlineDropdownSelect
                  label="WAREHOUSE"
                  value={record.warehouse_id}
                  onChange={(_v) => {
                    let new_d = record;
                    new_d['warehouse_id'] = _v
                    data.splice(index, 1, new_d);
                    console.log("new data", data);
                    this.props.onChangeData(data);

                  }}
                  selectClassName='small-inline-select'
                  options={this.state.warehouse_list}
                />


              </div>
            </div>
          }}
          expanded_keys={this.state.expanded_keys}
          onExpand={(keys) => {
            this.setState({
              expanded_keys: keys
            })
          }}
        />
        <div className='no-wrap-rl-row' style={{
          alignItems: 'flex-start',
          justifyContent: 'space-between',
        }}>
          <TextArea
            label="Note to Customer"
            value={main_data.notes}
            onChange={(_v) => {
              this.props.onChangeMainData({
                ...main_data,
                notes: _v
              })
            }}
            rows={4}
            autoSize={false}
            style={{
              width: '100%'
            }}
          />
          {/* <div className='rl-column' style={{
            width: '50%',
            alignItems: 'flex-end'
          }}>
            <div className='no-wrap-rl-row invoice-table-extra'
              style={{
                justifyContent: 'space-between'
              }}>
              <div className='invoice-table-extra-left'>
                Sub-Total
            </div>
              <div className='invoice-table-extra-right'>
                {formatNumber(main_data.sub_total)}
              </div>
            </div>

            <div className='no-wrap-rl-row invoice-table-extra'
              style={{
                justifyContent: 'space-between'
              }}>
              <div className='invoice-table-extra-left'>
                Discount
            </div>
              <div className='invoice-table-extra-right'>
                <NumberInput value={main_data.discount} onChange={(_v) => {
                  let new_main_data = main_data;
                  new_main_data['discount'] = _v;
                  this.recalculateAllData(new_main_data)
                  // this.props.onChangeMainData(main_data);
                }} />
              </div>
            </div>
            <div className='no-wrap-rl-row invoice-table-extra'
              style={{
                justifyContent: 'space-between'
              }}>
              <div className='invoice-table-extra-left'>
                Additional charge
              </div>
              <div className='invoice-table-extra-right'>
                <NumberInput value={main_data.additional_charge} onChange={(_v) => {
                  let new_main_data = main_data;
                  new_main_data['additional_charge'] = _v;
                  this.recalculateAllData(new_main_data)
                  // this.props.onChangeMainData(main_data);
                }} />
              </div>
            </div>
            <div className='no-wrap-rl-row invoice-table-extra'
              style={{
                justifyContent: 'space-between'
              }}>
              <div className='invoice-table-extra-left'>
                Taxable Total
            </div>
              <div className='invoice-table-extra-right'>
                {formatNumber(taxable_total)}
              </div>
            </div>
            <div className='no-wrap-rl-row invoice-table-extra'
              style={{
                justifyContent: 'space-between'
              }}>
              <div className='invoice-table-extra-left'>
                VAT
            </div>
              <div className='invoice-table-extra-right'>
                {formatNumber(main_data.vat)}
              </div>
            </div>
            <div className='no-wrap-rl-row invoice-table-extra'
              style={{
                justifyContent: 'space-between'
              }}>
              <div className='invoice-table-extra-left'>
                Grand Total
            </div>
              <div className='invoice-table-extra-right'>
                {formatNumber(main_data.total)}
              </div>
            </div>

          </div>
        */}
        </div>

      </Space>
    );
  }
}
const PRODUCT_AND_QUANTITY_TABLE_SEGMENT = withDomains(_PRODUCT_AND_QUANTITY_TABLE_SEGMENT, "dynamic", "inventory");

class _ACCOUNT_ITEM_TABLE extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      new_data: {},
      accounts: [],
      subaccounts: {}
    }
    this.list_accounts = RESTExecutor.list().config({
      url: '/accounts'
    }).callbacks((success) => {
      let accounts = [];
      let subaccounts = {};
      success.result.map((acc_item) => {
        accounts.push({
          id: acc_item.id,
          ...acc_item.data
        });
        let temp_subs = []
        if (Array.isArray(acc_item.sub_accounts) && acc_item.sub_accounts.length > 0) {
          acc_item.sub_accounts.map((sub_acc_item) => {
            temp_subs.push({
              id: sub_acc_item.id,
              ...sub_acc_item.data
            })
          });
          subaccounts[acc_item.id] = temp_subs

        }
      });
      this.setState({
        accounts, subaccounts
      })
      console.log("success d ata", success)
    }).connect(props.domains.dynamic);

    this.list_accounts.execute();
  }
  render() {
    let { isJournal, subtitle, expandable, onChangeData, defaultExpanded = false, validation_errors = {}, table_type = "SALES" } = this.props;
    // table_type support "SALES" to add sales price of product, "PURCHASE"  to add purchase price
    const { new_data } = this.state;
    const { data = [], main_data = {} } = this.props;


    let table_data = [...data, { __type__: "EDITOR" }, {}];

    return (
      <Space size={LayoutManager.formSpacing} direction='vertical' style={{
        width: '100%',
        paddingLeft: LayoutManager.globalSpacing,
        paddingRight: LayoutManager.globalSpacing
      }}>
        <Table
          className={`main-table invoice-table no-hover-table`}
          dataSource={table_data}
          // footer={() => <center justify='center'>
          //     <TransparentButton type='primary' label="+ Add Product/Service" />
          // </center>}
          rowClassName={(record, index) => {

            if (index == (table_data.length - 1)) {
              return 'total-row'
            }
          }}
          bordered
          columns={[
            {
              title: "Accounts",
              render: (value, record, index) => {
                let obj = {
                  children: "",
                  props: {
                    className: 'invoice-table-basic-cell'
                  }
                }
                if (index == (table_data.length - 1)) {
                  obj.children = <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}>Total</div>
                  return obj
                }

                if (record.__type__ == "EDITOR") {
                  obj.children = <AccountSelect
                    level_one_options={this.state.accounts}
                    level_two_options={this.state.subaccounts}
                    value1={new_data.account_id}
                    value2={new_data.sub_account_id}
                    onChange={(val1, val2) => {
                      this.setState({
                        new_data: {
                          ...new_data,
                          account_id: val1,
                          sub_account_id: val2
                        }
                      })
                      // new_data['account_id'] = val1
                      // new_data['sub_account_id'] = val2
                      // data.splice(index, 1, new_d);
                      // this.props.onChangeData(data);

                    }}
                  />
                  obj.props = {
                    className: 'input-table-cell'
                  }
                } else {
                  obj.children = <Space size={LayoutManager.globalSpacing} direction='vertical' style={{
                    width: '100%'
                  }}>
                    <AccountSelect
                      level_one_options={this.state.accounts}
                      level_two_options={this.state.subaccounts}
                      value1={record.account_id}
                      value2={record.sub_account_id}
                      onChange={(val1, val2) => {
                        let new_d = record;
                        new_d['account_id'] = val1
                        new_d['sub_account_id'] = val2
                        data.splice(index, 1, new_d);
                        this.props.onChangeData(data);

                      }}
                    />
                    <Text editable={{
                      onChange: (_v) => {
                        let new_d = record;
                        new_d['product_description'] = _v
                        data.splice(index, 1, new_d);
                        this.props.onChangeData(data);
                        // this.setState({
                        //   data: data
                        // })
                      },

                    }}>{record.product_description || "Add Description"}</Text>
                    {/* <TransparentButton type='primary' label="+ Add Description"/> */}


                  </Space>

                }
                return obj
              }
            },
            ...isJournal ? [
              {
                title: "DR Amount",
                width: 120,
                render: (value, record, index) => {
                  let obj = {
                    children: "",
                    props: {
                      className: 'invoice-table-basic-cell'
                    }
                  }
                  if (index == (table_data.length - 1)) {
                    let total_amount = 0;
                    table_data.map((it) => {
                      total_amount = total_amount + (it.amount_type == "DR" ? (it.amount || 0) : 0)
                    })
                    obj.children = total_amount;
                    return obj
                  }


                  if (record.__type__ == "EDITOR") {
                    obj.children = <NumberInput
                      min={0}
                      inputClassName='no-border-input'
                      placeholder="Amount"
                      value={new_data.amount_type == "DR" ? new_data.amount : null}
                      onChange={(_v) => {
                        this.setState({
                          new_data: {
                            ...new_data,
                            amount: _v,
                            amount_type: _v ? "DR" : null
                          }
                        })
                      }}
                    />;
                    obj.props = {
                      className: 'input-table-cell'
                    }
                  } else {
                    obj.children = <NumberInput
                      min={0}
                      inputClassName='no-border-input'
                      placeholder="Amount"
                      value={record.amount_type == "DR" ? record.amount : null}
                      onChange={(_v) => {
                        let new_d = record;
                        new_d['amount'] = _v
                        new_d['amount_type'] = _v ? "DR" : null
                        data.splice(index, 1, new_d);
                        this.props.onChangeData(data);
                      }}
                    />;
                    obj.props = {
                      className: 'input-table-cell'
                    }
                    // obj.children = <div style={{
                    //   display: 'flex',
                    //   flexDirection: 'column',
                    //   height: '100%',
                    //   justifyContent: 'space-between',
                    //   // backgroundColor:'red'
                    // }}>
                    //   <div className='rl-column'>
                    //     <span>{record.dr_amount}</span>
                    //   </div>

                    //   {/* <TransparentButton type='primary' label="+ Add Description"/> */}

                    // </div>
                  }
                  return obj
                }
              },
              {
                title: "Cr Amount",
                width: 120,
                render: (value, record, index) => {
                  let obj = {
                    children: "",
                    props: {
                      className: 'invoice-table-basic-cell'
                    }
                  }

                  if (index == (table_data.length - 1)) {
                    let total_amount = 0;
                    table_data.map((it) => {
                      total_amount = total_amount + (it.amount_type == "CR" ? (it.amount || 0) : 0)
                    })
                    obj.children = total_amount;
                    return obj
                  }

                  if (record.__type__ == "EDITOR") {
                    obj.children = <NumberInput
                      min={0}
                      inputClassName='no-border-input'
                      placeholder="Amount"
                      value={new_data.amount_type == "CR" ? new_data.amount : null}

                      onChange={(_v) => {
                        this.setState({
                          new_data: {
                            ...new_data,
                            amount: _v,
                            amount_type: _v ? "CR" : null
                          }
                        })
                      }}
                    />;
                    obj.props = {
                      className: 'input-table-cell'
                    }
                  } else {
                    obj.children = <NumberInput
                      min={0}
                      inputClassName='no-border-input'
                      placeholder="Amount"
                      value={record.amount_type == "CR" ? record.amount : null}
                      onChange={(_v) => {
                        let new_d = record;
                        new_d['amount'] = _v
                        new_d['amount_type'] = _v ? "CR" : null
                        data.splice(index, 1, new_d);
                        this.props.onChangeData(data);
                      }}
                    />;
                    obj.props = {
                      className: 'input-table-cell'
                    }
                    // obj.children = <div style={{
                    //   display: 'flex',
                    //   flexDirection: 'column',
                    //   height: '100%',
                    //   justifyContent: 'space-between',
                    //   // backgroundColor:'red'
                    // }}>
                    //   <div className='rl-column'>
                    //     <span>{record.cr_amount}</span>
                    //   </div>

                    //   {/* <TransparentButton type='primary' label="+ Add Description"/> */}

                    // </div>
                  }
                  return obj
                }
              }
            ] : [
                {
                  title: "Amount",
                  width: 120,
                  render: (value, record, index) => {
                    let obj = {
                      children: "",
                      props: {
                        className: 'invoice-table-basic-cell'
                      }
                    }

                    if (index == (table_data.length - 1)) {
                      let total_amount = 0;
                      table_data.map((it) => {
                        total_amount = total_amount + (it.amount || 0)
                      })
                      obj.children = total_amount;
                      return obj
                    }

                    if (record.__type__ == "EDITOR") {
                      obj.children = <NumberInput
                        min={0}
                        inputClassName='no-border-input'
                        placeholder="Amount"
                        value={new_data.amount}
                        onChange={(_v) => {
                          this.setState({
                            new_data: {
                              ...new_data,
                              amount: _v,
                            }
                          })
                        }}
                      />;
                      obj.props = {
                        className: 'input-table-cell'
                      }
                    } else {
                      obj.children = <NumberInput
                        min={0}
                        inputClassName='no-border-input'
                        placeholder="Amount"
                        value={record.amount}
                        onChange={(_v) => {
                          let new_d = record;
                          new_d['amount'] = _v
                          data.splice(index, 1, new_d);
                          this.props.onChangeData(data);
                        }}
                      />;
                      obj.props = {
                        className: 'input-table-cell'
                      }
                      // obj.children = <div style={{
                      //   display: 'flex',
                      //   flexDirection: 'column',
                      //   height: '100%',
                      //   justifyContent: 'space-between',
                      //   // backgroundColor:'red'
                      // }}>
                      //   <div className='rl-column'>
                      //     <span>{record.amount}</span>
                      //   </div>

                      //   {/* <TransparentButton type='primary' label="+ Add Description"/> */}

                      // </div>
                    }
                    return obj
                  }
                }
              ]
            ,
            {
              title: "",
              width: 40,
              render: (value, record, index) => {
                if (index == (table_data.length - 1)) {
                  return ""
                }
                if (record.__type__ == "EDITOR") {
                  return <SmallRoundedIcon onClick={() => {
                    if (new_data.account_id == null) {
                      message.error("Fill Missing Field")
                    } else {
                      this.props.onChangeData([...data, { ...new_data }]);
                      this.setState({
                        new_data: {}
                      })
                    }
                  }} type='success' icon={<AddIcon />} />
                } else {
                  return <SmallRoundedIcon onClick={() => {
                    data.splice(index, 1)
                    this.props.onChangeData(data);
                  }} type='danger' icon={<DeleteIcon />} />
                }
              }

            }
          ]}
          pagination={false}

        />
        <div className='no-wrap-rl-row' style={{
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          paddingBottom: LayoutManager.globalSpacing
        }}>
          <TextArea
            label="Note"
            value={main_data.notes}
            onChange={(_v) => {
              this.props.onChangeMainData({
                ...main_data,
                notes: _v
              })
            }}
            rows={4}
            autoSize={false}
            style={{
              width: '100%'
            }}
          />
        </div>

      </Space>
    );
  }

}
const ACCOUNT_ITEM_TABLE = withDomains(_ACCOUNT_ITEM_TABLE, "dynamic");

class _ACCOUNT_EXPENSE_TABLE extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      new_data: {},
      accounts: [],
      subaccounts: {},
      expanded_keys: []
    }
    this.list_accounts = RESTExecutor.list().config({
      url: '/accounts'
    }).callbacks((success) => {
      let accounts = [];
      let subaccounts = {};
      success.result.map((acc_item) => {
        accounts.push({
          id: acc_item.id,
          ...acc_item.data
        });
        let temp_subs = []
        if (Array.isArray(acc_item.sub_accounts) && acc_item.sub_accounts.length > 0) {
          acc_item.sub_accounts.map((sub_acc_item) => {
            temp_subs.push({
              id: sub_acc_item.id,
              ...sub_acc_item.data
            })
          });
          subaccounts[acc_item.id] = temp_subs

        }
      });
      this.setState({
        accounts, subaccounts
      })
      console.log("success d ata", success)
    }).connect(props.domains.dynamic);

    this.list_accounts.execute();
  }
  recalculateAllData = (main_data = {}) => {
    /** ORDER: 
     * quantity->
     * uom->
     * rate->
     * discount-> 
     * <total> ->
     * {partition_additional_discount(as per total)}-> 
     * temp_total_2
     * {parttion_additioan_cost(as  per temp_total_2)} ->
     * temp_total_2 ->
     * vat as per temp_total_2
     * */
    // console.log("initial data is", main_data);

    // let additional_total_discount = main_data.discount || 0;
    // let additional_total_charge = main_data.additional_charge || 0;
    let sub_total = 0;
    let vat = 0;
    let data = main_data[this.props.append_key || 'items'] || [];

    // // for additional discount distribution
    // let temp_data1 = [];
    // let all_temp_total_1 = 0;
    // // for additional charge  distribution
    // let temp_data2 = [];
    // let all_temp_total_2 = 0;
    let temp_data3 = [];
    // let all_temp_total_3 = 0;
    // let new_data = [];
    // temp_data1 = data.map((data_item) => {
    //   var temp_total_1 = (data_item.quantity || 0) * ((data_item.rate || 0) - (data_item.discount || 0));
    //   all_temp_total_1 = all_temp_total_1 + temp_total_1;
    //   return {
    //     ...data_item,
    //     total: temp_total_1
    //   }
    // });
    // temp_data2 = temp_data1.map((data_item) => {
    //   var additional_discount = ((data_item.total / all_temp_total_1) * additional_total_discount);
    //   var temp_total_2 = data_item.total - additional_discount;
    //   all_temp_total_2 = all_temp_total_2 + temp_total_2;
    //   return {
    //     ...data_item,
    //     additional_discount,
    //     temp_total_2: temp_total_2
    //   }
    // });

    // temp_data3 = temp_data2.map((data_item) => {
    //   var additional_charge = ((data_item.temp_total_2 / all_temp_total_2) * additional_total_charge)
    //   var temp_total_3 = data_item.temp_total_2 + additional_charge;
    //   all_temp_total_3 = all_temp_total_3 + temp_total_3;
    //   return {
    //     ...data_item,
    //     additional_charge,
    //     temp_total_3: temp_total_3
    //   }
    // });

    data.map((data_item) => {

      if (data_item.vat_type == "13%") {
        vat = vat + ((data_item.rate || 0) * 0.13)
      }
      temp_data3.push({
        ...data_item,
        vat_amount: data_item.vat_type == "13%" ? ((data_item.rate || 0) * 0.13) : 0
      });
      sub_total = sub_total + data_item.rate;
    })

    main_data['vat'] = vat;
    main_data['sub_total'] = sub_total;
    main_data['total'] = vat + sub_total;
    main_data[this.props.append_key || 'items'] = temp_data3;
    // console.log("final data is", main_data);
    this.props.onChangeMainData(main_data);
  }

  changeNewItemHandler = (value, key, data) => {
    data[key] = value;
    // switch (key) {
    //   case 'quantity':
    //     data['total'] = (value || 0) * ((data.rate || 0) - (data.discount || 0));
    //     break;
    //   case 'rate':
    //     data['total'] = (data.quantity || 0) * ((value || 0) - (data.discount || 0));
    //     break;
    //   case 'discount':
    //     data['total'] = (data.quantity || 0) * ((data.rate || 0) - (value || 0));
    //     break;
    //   case 'uom':
    //     break;
    // }
    this.setState({
      new_data: data
    })
  }
  changeItemHandler = (index, value, key, main_data) => {

    /**
     * Not needed if recalculate all data handled properly
     */
    // let product = this.state.productsSaved[main_data.product_id] || {};
    let append_key = this.props.append_key || 'items';
    let new_data = main_data;

    let data = this.props.data || [];
    let record = data[index] || {};
    record[key] = value;

    switch (key) {
      case 'quantity':
        break;
      case 'rate':
        break;
      case 'discount':
        break;
      case 'uom':
        break;
      case 'vat_type':
        break;
    }

    data.splice(index, 1, record);
    new_data[this.props.append_key || "items"] = data;
    // handle change  in  object data as per key

    this.recalculateAllData(main_data);
    // this.props.onChangeMainData(new_data);
  }

  render() {
    let { subtitle, expandable, onChangeData, defaultExpanded = false, validation_errors = {}, table_type = "SALES" } = this.props;
    // table_type support "SALES" to add sales price of product, "PURCHASE"  to add purchase price
    const { new_data } = this.state;
    const { data = [], main_data = {} } = this.props;


    let table_data = [...data, { __type__: "EDITOR" }];
    let taxable_total = 0;
    data.map((data_item) => {
      if (data_item.vat_type == "13%") {
        taxable_total = taxable_total + ((data_item.rate || 0))
      }
    })
    return (
      <Space size={LayoutManager.formSpacing} direction='vertical' style={{
        width: '100%',
        paddingLeft: LayoutManager.globalSpacing,
        paddingRight: LayoutManager.globalSpacing
      }}>
        <OrderTable
          data={table_data}
          // rowClassName={(record, index) => {

          //   if (index == (table_data.length - 1)) {
          //     return 'total-row'
          //   }
          // }}
          bordered
          columns={[
            {
              title: "Accounts",
              render: (value, record, index) => {
                let obj = {
                  children: "",
                  props: {
                    className: 'invoice-table-basic-cell'
                  }
                }

                if (record.__type__ == "EDITOR") {
                  return <AccountSelect
                    style={{
                      width: '100%'
                    }}
                    level_one_options={this.state.accounts}
                    level_two_options={this.state.subaccounts}
                    value1={new_data.account_id}
                    value2={new_data.sub_account_id}
                    onChange={(val1, val2) => {
                      this.setState({
                        new_data: {
                          ...new_data,
                          account_id: val1,
                          sub_account_id: val2
                        }
                      })
                    }}
                  />
                  obj.props = {
                    className: 'input-table-cell'
                  }
                } else {
                  obj.children =
                    <AccountSelect
                      style={{
                        width: '100%'
                      }}
                      level_one_options={this.state.accounts}
                      level_two_options={this.state.subaccounts}
                      value1={record.account_id}
                      value2={record.sub_account_id}
                      onChange={(val1, val2) => {
                        let new_d = record;
                        new_d['account_id'] = val1
                        new_d['sub_account_id'] = val2
                        data.splice(index, 1, new_d);
                        this.props.onChangeData(data);

                      }}
                    />

                }
                return obj.children
              }
            },
            {
              title: "Rate",
              width: 120,
              render: (value, record, index) => {
                let obj = {
                  children: "",
                  props: {
                    className: 'invoice-table-basic-cell'
                  }
                }


                if (record.__type__ == "EDITOR") {
                  obj.children = <NumberInput
                    min={0}
                    inputClassName='no-border-input'
                    placeholder="Amount"
                    value={new_data.rate}
                    onChange={(_v) => {
                      this.setState({
                        new_data: {
                          ...new_data,
                          rate: _v,
                        }
                      })
                    }}
                  />;
                  obj.props = {
                    className: 'input-table-cell'
                  }
                } else {
                  obj.children = <NumberInput
                    min={0}
                    inputClassName='no-border-input'
                    placeholder="Amount"
                    value={record.rate}
                    onChange={(_v) => {
                      this.changeNewItemHandler(_v, 'rate', new_data);
                      // let new_d = record;
                      // new_d['rate'] = _v
                      // data.splice(index, 1, new_d);
                      // this.props.onChangeData(data);
                    }}
                  />;
                  obj.props = {
                    className: 'input-table-cell'
                  }
                  // obj.children = <div style={{
                  //   display: 'flex',
                  //   flexDirection: 'column',
                  //   height: '100%',
                  //   justifyContent: 'space-between',
                  //   // backgroundColor:'red'
                  // }}>
                  //   <div className='rl-column'>
                  //     <span>{record.amount}</span>
                  //   </div>

                  //   {/* <TransparentButton type='primary' label="+ Add Description"/> */}

                  // </div>
                }
                return obj.children
              }
            },
            {
              title: "Tax",
              width: 100,
              render: (value, record, index) => {

                if (record.__type__ == "EDITOR") {
                  return <CustomSelect
                    // disabled={new_data.product_id == null}
                    inputClassName='no-border-input'
                    placeholder="Tax"
                    options={VAT_OPTIONS}
                    value={new_data.vat_type}
                    onChange={(_v) => {
                      this.changeNewItemHandler(_v, 'vat_type', new_data);
                    }}
                  />
                } else {
                  return <CustomSelect
                    inputClassName='no-border-input'
                    placeholder="Tax"
                    options={VAT_OPTIONS}
                    value={record.vat_type}
                    onChange={(_v) => {
                      this.changeItemHandler(index, _v, 'vat_type', main_data);
                      // let new_d = record;
                      // new_d['vat_type'] = _v
                      // data.splice(index, 1, new_d);
                      // this.props.onChangeData(data);
                    }}
                  />
                }
              }
            },
            {
              title: "Amount",
              width: 100,
              render: (value, record, index) => {

                if (record.__type__ == "EDITOR") {
                  return <div style={{
                    padding: '8px 13px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: '100%'
                  }}>
                    <Text strong>{formatNumber(new_data.rate)}</Text>
                  </div>

                } else {
                  return <div style={{
                    padding: '8px 13px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: '100%'
                  }}>
                    <Text strong>{formatNumber(record.rate)}</Text>
                  </div>;
                  // <NumberInput
                  //     disabled
                  //     min={0}
                  //     inputClassName='no-border-input'
                  //     placeholder="Amount"
                  //     value={record.total}
                  //   />;

                }
              }
            },
            {
              title: "",
              width: 40,
              render: (value, record, index) => {

                if (record.__type__ == "EDITOR") {
                  return <SmallRoundedIcon onClick={() => {
                    if (new_data.account_id == null) {
                      message.error("Fill Missing Field")
                    } else {
                      let new_index = data.length;
                      this.recalculateAllData({
                        ...main_data,
                        [this.props.append_key || 'items']: [...data, { ...new_data }]
                      })
                      this.setState({
                        new_data: {},
                        expanded_keys: [new_index]
                      })
                    }
                  }} type='success' icon={<AddIcon />} />
                } else {
                  return <div style={{
                    display: 'flex',
                    flexDirection: 'row'
                  }}>
                    <Space size={10}>
                      <SmallRoundedIcon direction={this.state.expanded_keys.includes(index) ? 'right' : 'down'}
                        onClick={() => {
                          this.setState({
                            expanded_keys: this.state.expanded_keys.includes(index) ? [] : [index]
                          })
                        }} />
                      <SmallRoundedIcon onClick={() => {
                        data.splice(index, 1)
                        this.recalculateAllData({
                          ...main_data,
                          [this.props.append_key || 'items']: data
                        })
                        // this.props.onChangeData(data);
                        this.setState({
                          expanded_keys: []
                        })
                      }} type='danger' icon={<DeleteIcon />} />
                      <div title={JSON.stringify(record)}>™</div>
                    </Space>
                  </div>
                }
              }

            }
          ]}
          expanded_keys={this.state.expanded_keys}
          onExpand={(keys) => {
            this.setState({
              expanded_keys: keys
            })
          }}
          expandRowRender={(record, index) => {
            return <div style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              padding: '10px 0px'
            }}>
              <div style={{
                flex: 1
              }}>
                <TextArea
                  rows={4}
                  autoSize={false}
                  placeholder="Description"
                  label="Description"
                  value={record.description}
                  onChange={(_v) => {
                    let new_d = record;
                    new_d['description'] = _v
                    data.splice(index, 1, new_d);
                    this.props.onChangeData(data);
                  }}
                />
              </div>
            </div>
          }}

        />
        <div className='no-wrap-rl-row' style={{
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          paddingBottom: LayoutManager.globalSpacing
        }}>
          <TextArea
            label="Note"
            value={main_data.notes}
            onChange={(_v) => {
              this.props.onChangeMainData({
                ...main_data,
                notes: _v
              })
            }}
            rows={4}
            autoSize={false}
            style={{
              width: '50%'
            }}
          />
          <div className='rl-column' style={{
            width: '50%',
            alignItems: 'flex-end'
          }}>
            <div className='no-wrap-rl-row invoice-table-extra'
              style={{
                justifyContent: 'space-between'
              }}>
              <div className='invoice-table-extra-left'>
                Sub-Total
            </div>
              <div className='invoice-table-extra-right'>
                {formatNumber(main_data.sub_total)}
              </div>
            </div>
            <div className='no-wrap-rl-row invoice-table-extra'
              style={{
                justifyContent: 'space-between'
              }}>
              <div className='invoice-table-extra-left'>
                Taxable Total
            </div>
              <div className='invoice-table-extra-right'>
                {formatNumber(taxable_total)}
              </div>
            </div>
            <div className='no-wrap-rl-row invoice-table-extra'
              style={{
                justifyContent: 'space-between'
              }}>
              <div className='invoice-table-extra-left'>
                VAT
            </div>
              <div className='invoice-table-extra-right'>
                {formatNumber(main_data.vat)}
              </div>
            </div>
            <div className='no-wrap-rl-row invoice-table-extra'
              style={{
                justifyContent: 'space-between'
              }}>
              <div className='invoice-table-extra-left'>
                Grand Total
            </div>
              <div className='invoice-table-extra-right'>
                {formatNumber(main_data.total)}
              </div>
            </div>

          </div>

        </div>

      </Space>
    );
  }

}
const ACCOUNT_EXPENSE_TABLE = withDomains(_ACCOUNT_EXPENSE_TABLE, "dynamic");

class _PAYMENT_ALLOCATION_TABLE_SEGMENT extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      expanded: this.props.defaultExpanded,
      filter_query: {},
      rowsData: [],
      rowsMeta: {}
    }

    this.list_allocations = RESTExecutor.list().config({
      url: this.props.allocationAPI || '/amount-allocations'
    }).callbacks((success) => {
      this.setState({
        rowsData: success.result || [],
        rowsMeta: success.meta
      })
    }).connect(props.domains.dynamic);
    this.main_data = JSON.parse(JSON.stringify(props.main_data || {}));


  }

  componentDidUpdate(prevProps) {
    let prevData = prevProps.main_data || {};
    let newData = this.props.main_data || {};

    console.log("comparing ss", this.main_data, newData)
    if (
      (this.main_data.contact != newData.contact)
    ) {
      this.main_data = JSON.parse(JSON.stringify(newData));;
      console.log("comparing amount not equal")
      this.list_allocations.config({
        params: {
          ...this.state.filter_query,
          contact: newData.contact
        }
      }).execute()
    }
  }
  onChangeExpand = (v) => {
    this.setState({
      expanded: v
    })
  }
  onChangeAllocation = (newAllocation = {}) => {
    let new_allocation_data = [];
    Object.keys(newAllocation).map((_k) => {
      let val = newAllocation[_k];
      if (val) {
        new_allocation_data.push({
          id: _k,
          allocation_amount: val
        })
      }
    });
    typeof this.props.onChangeData === 'function' && this.props.onChangeData(new_allocation_data)
  }

  onFIFO = (allRecord, amount = 0) => {
    let allocs = {};
    let remainingBalance = amount;

    if (amount > 0) {
      allRecord.map((record) => {
        let _data = record.data || {};
        let remaining = _data.allocation_total || 0;
        console.log("fifo for", _data, record.id, remainingBalance);
        if (remainingBalance > 0) {
          let amount_to_allocate = remaining < remainingBalance ? remaining : remainingBalance;
          allocs[record.id] = amount_to_allocate;
          remainingBalance = remainingBalance - amount_to_allocate;
        } else {
          // break;

        }
      });
      this.onChangeAllocation(allocs);
    }

  }

  render() {
    const { expandable, title, data = [], main_data = {} } = this.props;
    let allocations = {};
    let total_amount = main_data.amount || 0;
    let total_remaining = total_amount;

    data.map((it) => {
      allocations[it.id] = it.allocation_amount
      total_remaining = total_remaining - (it.allocation_amount || 0);
    });
    const { expanded, rowsData, rowsMeta } = this.state;
    let list_allocations_resp = this.list_allocations.response();
    // let rowsData = list_allocations_resp.result || [];
    // let rowsMeta = list_allocations_resp.meta || {};
    return <div>
      <div className={`
          no-wrap-rl-row
          segment-header
       ${expandable ? 'left-form-container-left-expandable-header hand-cursor' : ''}
      `} onClick={() => {
          expandable && this.onChangeExpand(!expanded)
        }}>

        <span className='section-title'>{title}</span>
        {expandable && <RoundedIconButton direction={expanded ? 'right' : 'down'} onClick={() => {
          expandable && this.onChangeExpand(!expanded)
        }} />
        }
      </div>
      <Transition
        native
        immediate={true}
        items={expanded}
        from={{ overflow: 'hidden', height: 0 }}
        enter={[{ height: 'auto' }]}
        leave={{ height: 0 }}
      >
        {show =>
          show && (props => <animated.div className='no-wrap-rl-row form-segment-container' style={{
            alignItems: 'flex-start',
            ...props
          }}>
            <BaseTable
              removeRightTopAction={true}
              selectionActions={[]}
              defaultSize='small'
              meta={rowsMeta}
              loading={list_allocations_resp.fetching}
              data={rowsData}
              columns={[
                {
                  title: "Type", dataIndex: 'type', render: (val, record, index) => {
                    let _data = record.data || {};
                    return _data.source_type
                  }
                },
                {
                  title: "Date", dataIndex: 'date', render: (val, record, index) => {
                    let _data = record.data || {};
                    return "13/07/2020"
                  }
                },
                {
                  title: "Due Date", dataIndex: 'due_date', render: (val, record, index) => {
                    let _data = record.data || {};
                    return "13/07/2020"
                  }
                },
                {
                  title: "No#", dataIndex: 'no', render: (val, record, index) => {
                    let _data = record.data || {};
                    return "INV-000001-075/76"
                  }
                },
                {
                  title: "Amount", dataIndex: 'amount', render: (val, record, index) => {
                    let _data = record.data || {};
                    return formatNumber(_data.source_total)
                  }
                },
                {
                  title: "Allocated", dataIndex: 'allocated', render: (val, record, index) => {
                    let _data = record.data || {};
                    return formatNumber((_data.source_total || 0) - (_data.allocation_total || 0))
                  }
                },
                {
                  title: "Left to allocate", dataIndex: 'remaining', render: (val, record, index) => {
                    let _data = record.data || {};
                    return formatNumber(_data.allocation_total)
                  }
                },
                {
                  title: <div><span>This Allocation</span>&nbsp;&nbsp;<a onClick={() => {
                    this.onFIFO(rowsData, total_amount)
                  }}>FIFO</a> </div>,
                  width: 220,
                  dataIndex: 'remaining', render: (val, record, index) => {
                    let _data = record.data || {};
                    let remaining = _data.allocation_total || 0;

                    return <div className='no-wrap-rl-row' style={{
                      flexWrap: 'nowrap',
                      alignItems: 'cennnt'
                    }}>
                      <Space>
                        <NumberInput
                          value={allocations[record.id]}
                          placeholder="Amount"
                          style={{
                            width: 100
                          }}
                          onChange={(_v) => {
                            allocations[record.id] = _v;
                            this.onChangeAllocation(allocations)
                          }}
                        />
                        <TransparentButton onClick={() => {
                          if (total_remaining > 0) {
                            allocations[record.id] = remaining < total_remaining ? remaining : total_remaining
                            this.onChangeAllocation(allocations);
                          }
                        }} label="All" type='primary' />
                        <span>|</span>
                        <TransparentButton onClick={() => {
                          delete allocations[record.id];
                          this.onChangeAllocation(allocations)
                        }} label="None" type='primary' />
                      </Space>

                    </div>
                  }
                }
              ]}
            />
          </animated.div>)
        }
      </Transition>


      {/* </div> */}
    </div>
  }


}
const PAYMENT_ALLOCATION_TABLE_SEGMENT = withDomains(_PAYMENT_ALLOCATION_TABLE_SEGMENT, "dynamic");

class _BOM_ITEM_SEGMENT extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      expanded1: true,
      expanded2: true,
      new_by: {},
      new_consumed: {},
      older_products: {},//has  id to data mapped object
    }
  }

  recalculateAllData = (main_data = {}) => {
    this.props.onChangeMainData(main_data);
  }

  changeNewItemHandler = (value, key, data, stateKey) => {
    data[key] = value;
    // switch (key) {
    //   case 'quantity':
    //     data['total'] = (value || 0) * ((data.rate || 0) - (data.discount || 0));
    //     break;
    //   case 'rate':
    //     data['total'] = (data.quantity || 0) * ((value || 0) - (data.discount || 0));
    //     break;
    //   case 'discount':
    //     data['total'] = (data.quantity || 0) * ((data.rate || 0) - (value || 0));
    //     break;
    //   case 'uom':
    //     break;
    // }
    this.setState({
      [stateKey]: data
    })
  }
  changeItemHandler = (index, value, key, main_data, append_key) => {

    /**
     * Not needed if recalculate all data handled properly
     */
    // let product = this.state.productsSaved[main_data.product_id] || {};
    let new_data = main_data;

    let data = main_data[append_key] || [];
    let record = data[index] || {};
    record[key] = value;


    data.splice(index, 1, record);
    new_data[append_key] = data;
    // handle change  in  object data as per key

    this.recalculateAllData(main_data);
    // this.props.onChangeMainData(new_data);
  }

  render() {
    const { expandable, title, data = {}, main_data = {} } = this.props;
    const { expanded1, expanded2, new_by, new_consumed } = this.state;
    let by_products = main_data.by_products || [];
    let consumed_products = main_data.consumed_products || [];

    return <div>
      <div className={`
          no-wrap-rl-row
          segment-header
       ${'left-form-container-left-expandable-header hand-cursor'}
      `} onClick={() => {
          this.setState({
            expanded1: !expanded1
          })
        }}>

        <span className='section-title'>{"Product To Be Consumed"}</span>
        <RoundedIconButton direction={expanded1 ? 'right' : 'down'} onClick={() => {
          this.setState({
            expanded1: !expanded1
          })
        }} />
      </div>
      <Transition
        native
        immediate={true}
        items={expanded1}
        from={{ overflow: 'hidden', height: 0 }}
        enter={[{ height: 'auto' }]}
        leave={{ height: 0 }}
      >
        {show =>
          show && (props => <animated.div className='no-wrap-rl-row form-segment-container' style={{
            alignItems: 'flex-start',
            width: '100%',
            ...props
          }}>

            <OrderTable
              data={[...consumed_products, { __type__: "EDITOR" }]}
              columns={[
                {
                  title: "Product",
                  render: (value, record, index) => {
                    if (record.__type__ == "EDITOR") {
                      return <NetworkSelect
                        renderKey="CONTACTITEM"
                        dropdownWidth={500}
                        style={{
                          width: '100%'
                        }}
                        value={new_consumed.product_id}
                        form_title="Product"
                        onChange={(id, _d) => {
                          console.log("on change", id, _d);
                          this.setState({
                            new_consumed: {
                              ...new_consumed,
                              product_id: id,
                              product_name: _d.name,
                              product_code: _d.code,
                              uom: 'unit',
                              quantity: 1
                            }
                          })
                        }}
                        apiURL='/product-services'
                        addAPI='/product-services'
                        withAdd={true}
                        addSchema={PRODUCT_SERVICE_SMALL_SCHEMA}
                        className='no-border-input'
                        placeholder="Add Code or Product"
                      />

                    } else {
                      return <div style={{
                        padding: '8px 13px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        width: '100%'
                      }}>
                        <Text strong>{record.product_name}</Text>

                        <Text type='secondary' style={{
                          marginTop: LayoutManager.globalSpacing / 2
                        }}>{record.product_code}</Text>
                      </div>

                    }
                  }
                },
                {
                  title: "Qty",
                  width: 140,
                  render: (value, record, index) => {
                    if (record.__type__ == "EDITOR") {
                      return <Input.Group compact style={{
                        display: 'flex'
                      }}>
                        <NumberInput
                          disabled={new_consumed.product_id == null}
                          style={{
                            width: '55%'
                          }}
                          min={1}
                          value={new_consumed.quantity}
                          onChange={(_v) => {
                            this.setState({
                              new_consumed: {
                                ...new_consumed,
                                quantity: _v
                              }
                            })
                          }}
                          inputClassName='no-border-input'
                          placeholder="Qty"
                        />
                        <CustomSelect
                          disabled={new_consumed.product_id == null}
                          style={{
                            width: '45%'
                          }}
                          inputClassName='no-border-input'
                          placeholder="UN"
                          options={[
                            { key: "unit", value: "UN" },
                            { key: "subunit", value: "SUB" }
                          ]}
                          value={new_consumed.uom}
                          onChange={(_v) => {
                            this.setState({
                              new_consumed: {
                                ...new_consumed,
                                uom: _v
                              }
                            })
                          }}
                        />
                      </Input.Group>
                    } else {
                      return <Input.Group compact style={{
                        display: 'flex'
                      }}>
                        <NumberInput
                          style={{
                            width: '55%'
                          }}
                          min={1}
                          value={record.quantity}
                          onChange={(_v) => {
                            this.changeItemHandler(index, _v, 'quantity', main_data, 'consumed_products');
                            // let new_d = record;
                            // new_d['quantity'] = _v
                            // data.splice(index, 1, new_d);
                            // this.props.onChangeData(data);
                            // this.recalculateAllData({
                            //   ...main_data,
                            //   'data':data
                            // })
                          }}
                          inputClassName='no-border-input'
                          placeholder="Qty"
                        />
                        <CustomSelect
                          style={{
                            width: '45%'
                          }}
                          inputClassName='no-border-input'
                          placeholder="UN"
                          options={[
                            { key: "unit", value: "UN" },
                            { key: "subunit", value: "SUB" }
                          ]}
                          value={record.uom}
                          onChange={(_v) => {
                            this.changeItemHandler(index, _v, 'uom', main_data, 'consumed_products');

                            // let new_d = record;
                            // new_d['uom'] = _v
                            // data.splice(index, 1, new_d);
                            // this.props.onChangeData(data);
                          }}
                        />
                      </Input.Group>
                    }
                  }
                },
                {
                  title: "",
                  width: 80,
                  render: (value, record, index) => {
                    if (record.__type__ == "EDITOR") {
                      return <SmallRoundedIcon onClick={() => {
                        if (new_consumed.product_id == null || new_consumed.quantity == 0) {
                          message.error("Fill Missing Field")
                        } else {
                          let new_index = consumed_products.length;
                          this.recalculateAllData({
                            ...main_data,
                            ['consumed_products']: [...consumed_products, { uom: "unit", ...new_consumed }]
                          })
                          this.setState({
                            new_consumed: {},
                          })
                        }
                      }} type='success' icon={<AddIcon />} />
                    } else {
                      return <div style={{
                        display: 'flex',
                        flexDirection: 'row'
                      }}>
                        <Space size={10}>
                          <SmallRoundedIcon onClick={() => {
                            consumed_products.splice(index, 1)
                            this.recalculateAllData({
                              ...main_data,
                              ['consumed_products']: by_products
                            })
                            // this.props.onChangeData(data);
                          }} type='danger' icon={<DeleteIcon />} />
                          <div title={JSON.stringify(record)}>™</div>
                        </Space>
                      </div>
                    }
                  }

                }
              ]}
            />
          </animated.div>)
        }
      </Transition>
      <div style={{

      }}></div>
      <div className={`
          no-wrap-rl-row
          segment-header
       ${'left-form-container-left-expandable-header hand-cursor'}
      `} onClick={() => {
          this.setState({
            expanded2: !expanded2
          })
        }}>

        <span className='section-title'>{"By Product"}</span>
        <RoundedIconButton direction={expanded2 ? 'right' : 'down'} onClick={() => {
          this.setState({
            expanded2: !expanded2
          })
        }} />
      </div>
      <Transition
        native
        immediate={true}
        items={expanded2}
        from={{ overflow: 'hidden', height: 0 }}
        enter={[{ height: 'auto' }]}
        leave={{ height: 0 }}
      >
        {show =>
          show && (props => <animated.div className='no-wrap-rl-row form-segment-container' style={{
            alignItems: 'flex-start',
            width: '100%',
            ...props
          }}>
            <OrderTable
              data={[...by_products, { __type__: "EDITOR" }]}
              columns={[
                {
                  title: "Product",
                  render: (value, record, index) => {
                    if (record.__type__ == "EDITOR") {
                      return <NetworkSelect
                        renderKey="CONTACTITEM"
                        dropdownWidth={500}
                        style={{
                          width: '100%'
                        }}
                        value={new_by.product_id}
                        form_title="Product"
                        onChange={(id, _d) => {
                          console.log("on change", id, _d);
                          this.setState({
                            new_by: {
                              ...new_by,
                              product_id: id,
                              product_name: _d.name,
                              product_code: _d.code,
                              uom: 'unit',
                              quantity: 1,
                              cost_percentage: 0
                            }
                          })
                        }}
                        apiURL='/product-services'
                        addAPI='/product-services'
                        withAdd={true}
                        addSchema={PRODUCT_SERVICE_SMALL_SCHEMA}
                        className='no-border-input'
                        placeholder="Add Code or Product"
                      />

                    } else {
                      return <div style={{
                        padding: '8px 13px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        width: '100%'
                      }}>
                        <Text strong>{record.product_name}</Text>

                        <Text type='secondary' style={{
                          marginTop: LayoutManager.globalSpacing / 2
                        }}>{record.product_code}</Text>
                      </div>

                    }
                  }
                },
                {
                  title: "% OF COST",
                  width: 120,
                  render: (value, record, index) => {
                    if (record.__type__ == "EDITOR") {
                      return <Input.Group compact style={{
                        display: 'flex'
                      }}>
                        <NumberInput
                          disabled={new_by.product_id == null}
                          style={{
                            width: '100%'
                          }}
                          min={0}
                          value={new_by.cost_percentage}
                          onChange={(_v) => {
                            this.setState({
                              new_by: {
                                ...new_by,
                                cost_percentage: _v
                              }
                            })
                          }}
                          inputClassName='no-border-input'
                          placeholder="% OF COST"
                        />
                        {/* <CustomSelect
                      disabled={new_by.product_id == null}
                      style={{
                        width: '45%'
                      }}
                      inputClassName='no-border-input'
                      placeholder="UN"
                      options={[
                        { key: "unit", value: "UN" },
                        { key: "subunit", value: "SUB" }
                      ]}
                      value={new_by.uom}
                      onChange={(_v) => {
                        this.setState({
                          new_by: {
                            ...new_by,
                            uom:_v
                          }
                        })
                      }}
                    /> */}
                      </Input.Group>
                    } else {
                      return <Input.Group compact style={{
                        display: 'flex'
                      }}>
                        <NumberInput
                          style={{
                            width: '100%'
                          }}
                          min={1}
                          value={record.cost_percentage}
                          onChange={(_v) => {
                            this.changeItemHandler(index, _v, 'cost_percentage', main_data, 'by_products');
                            // let new_d = record;
                            // new_d['quantity'] = _v
                            // data.splice(index, 1, new_d);
                            // this.props.onChangeData(data);
                            // this.recalculateAllData({
                            //   ...main_data,
                            //   'data':data
                            // })
                          }}
                          inputClassName='no-border-input'
                          placeholder="% OF COST"
                        />
                        {/* <CustomSelect
                      style={{
                        width: '45%'
                      }}
                      inputClassName='no-border-input'
                      placeholder="UN"
                      options={[
                        { key: "unit", value: "UN" },
                        { key: "subunit", value: "SUB" }
                      ]}
                      value={record.uom}
                      onChange={(_v) => {
                        this.changeItemHandler(index, _v, 'uom', main_data,'by_products');
                      }}
                    /> */}
                      </Input.Group>
                    }
                  }
                },
                {
                  title: "Qty",
                  width: 140,
                  render: (value, record, index) => {
                    if (record.__type__ == "EDITOR") {
                      return <Input.Group compact style={{
                        display: 'flex'
                      }}>
                        <NumberInput
                          disabled={new_by.product_id == null}
                          style={{
                            width: '55%'
                          }}
                          min={1}
                          value={new_by.quantity}
                          onChange={(_v) => {
                            this.setState({
                              new_by: {
                                ...new_by,
                                quantity: _v
                              }
                            })
                          }}
                          inputClassName='no-border-input'
                          placeholder="Qty"
                        />
                        <CustomSelect
                          disabled={new_by.product_id == null}
                          style={{
                            width: '45%'
                          }}
                          inputClassName='no-border-input'
                          placeholder="UN"
                          options={[
                            { key: "unit", value: "UN" },
                            { key: "subunit", value: "SUB" }
                          ]}
                          value={new_by.uom}
                          onChange={(_v) => {
                            this.setState({
                              new_by: {
                                ...new_by,
                                uom: _v
                              }
                            })
                          }}
                        />
                      </Input.Group>
                    } else {
                      return <Input.Group compact style={{
                        display: 'flex'
                      }}>
                        <NumberInput
                          style={{
                            width: '55%'
                          }}
                          min={1}
                          value={record.quantity}
                          onChange={(_v) => {
                            this.changeItemHandler(index, _v, 'quantity', main_data, 'by_products');
                            // let new_d = record;
                            // new_d['quantity'] = _v
                            // data.splice(index, 1, new_d);
                            // this.props.onChangeData(data);
                            // this.recalculateAllData({
                            //   ...main_data,
                            //   'data':data
                            // })
                          }}
                          inputClassName='no-border-input'
                          placeholder="Qty"
                        />
                        <CustomSelect
                          style={{
                            width: '45%'
                          }}
                          inputClassName='no-border-input'
                          placeholder="UN"
                          options={[
                            { key: "unit", value: "UN" },
                            { key: "subunit", value: "SUB" }
                          ]}
                          value={record.uom}
                          onChange={(_v) => {
                            this.changeItemHandler(index, _v, 'uom', main_data, 'by_products');

                            // let new_d = record;
                            // new_d['uom'] = _v
                            // data.splice(index, 1, new_d);
                            // this.props.onChangeData(data);
                          }}
                        />
                      </Input.Group>
                    }
                  }
                },
                {
                  title: "",
                  width: 80,
                  render: (value, record, index) => {
                    if (record.__type__ == "EDITOR") {
                      return <SmallRoundedIcon onClick={() => {
                        if (new_by.product_id == null || new_by.quantity == 0) {
                          message.error("Fill Missing Field")
                        } else {
                          let new_index = by_products.length;
                          this.recalculateAllData({
                            ...main_data,
                            ['by_products']: [...by_products, { uom: "unit", ...new_by }]
                          })
                          this.setState({
                            new_by: {},
                          })
                        }
                      }} type='success' icon={<AddIcon />} />
                    } else {
                      return <div style={{
                        display: 'flex',
                        flexDirection: 'row'
                      }}>
                        <Space size={10}>
                          <SmallRoundedIcon onClick={() => {
                            by_products.splice(index, 1)
                            this.recalculateAllData({
                              ...main_data,
                              ['by_products']: by_products
                            })
                            // this.props.onChangeData(data);
                          }} type='danger' icon={<DeleteIcon />} />
                          <div title={JSON.stringify(record)}>™</div>
                        </Space>
                      </div>
                    }
                  }

                }
              ]}
            />
          </animated.div>)
        }
      </Transition>
    </div>
  }
}
const BOM_ITEM_SEGMENT = withDomains(_BOM_ITEM_SEGMENT, "dynamic");

class _PRODUCTION_VOUCHER_ITEM_SEGMENT extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      expanded1: true,
      expanded2: true,
      expanded_main: true,
      new_by: {},
      new_consumed: {},
      productsSaved: {},//has  id to data mapped object
      warehouse_list: [{
        key: null,
        value: "Default"
      }]
    }
    let pro_ids = [];
    let main_data = this.props.main_data || {};
    pro_ids.push(main_data.product_id);
    Array.isArray(main_data.by_products) && main_data.by_products.map((item) => {
      pro_ids.push(item.product_id)
    })
    Array.isArray(main_data.consumed_products) && main_data.consumed_products.map((item) => {
      pro_ids.push(item.product_id)
    })

    this.list_previousProduct = RESTExecutor.list().config({
      url: '/product-services',
    }).callbacks((success) => {
      let results = success.result || [];
      let _productsSaved = {};
      results.map((item) => {
        _productsSaved[item.id] = item.data
      });
      this.setState({
        productsSaved: _productsSaved
      })

    }, (error) => {
      showErrorInNotification(error)
    }).connect(props.domains.dynamic);
    this.list_previousProduct.config({
      params: {
        includes: pro_ids
      }
    }).execute();


    this.list_warehouse = RESTExecutor.list().config({
      label: 'warehouselist'
    }).callbacks((success) => {
      let warehouses = [{
        key: null,
        value: "Default"
      }];
      let result = success.result || [];
      result.map((item) => {
        let _data = item.data || {};
        warehouses.push({
          key: item.id,
          value: _data.name
        })
      });

      this.setState({
        warehouse_list: warehouses
      });

    }).connect(props.domains.inventory);


    this.list_warehouse.execute();
  }

  recalculateAllData = (main_data = {}) => {
    let consumed = main_data.consumed_products || [];
    let by = main_data.by_products || [];
    let total = 0;
    consumed.map((item) => {
      total = total + ((item.rate || 0) * (item.quantity));
    })
    by.map((item) => {
      if (item.quantity > 0) {
        item['rate'] = (((item.cost_percentage || 0) * 0.01 * total) / item.quantity)
      }
    })
    main_data['by_products'] = by;
    console.log("new main data", main_data)
    this.props.onChangeMainData(main_data);
  }

  changeNewItemHandler = (value, key, data, stateKey) => {
    data[key] = value;
    // switch (key) {
    //   case 'quantity':
    //     data['total'] = (value || 0) * ((data.rate || 0) - (data.discount || 0));
    //     break;
    //   case 'rate':
    //     data['total'] = (data.quantity || 0) * ((value || 0) - (data.discount || 0));
    //     break;
    //   case 'discount':
    //     data['total'] = (data.quantity || 0) * ((data.rate || 0) - (value || 0));
    //     break;
    //   case 'uom':
    //     break;
    // }
    this.setState({
      [stateKey]: data
    })
  }
  changeItemHandler = (index, value, key, main_data, append_key) => {

    /**
     * Not needed if recalculate all data handled properly
     */
    // let product = this.state.productsSaved[main_data.product_id] || {};
    let new_data = main_data;

    let data = main_data[append_key] || [];
    let record = data[index] || {};
    record[key] = value;


    data.splice(index, 1, record);
    new_data[append_key] = data;
    // handle change  in  object data as per key

    this.recalculateAllData(main_data);
    // this.props.onChangeMainData(new_data);
  }

  render() {
    const { expandable, title, data = {}, main_data = {} } = this.props;
    const { expanded1, expanded2, new_by, new_consumed, expanded_main, productsSaved } = this.state;
    let by_products = main_data.by_products || [];
    let consumed_products = main_data.consumed_products || [];
    let produced_product = [{
      product_id: main_data.product_id,
      product_quantity: main_data.product_quantity
    }]

    return <div>
      <div className={`
          no-wrap-rl-row
          segment-header
       ${'left-form-container-left-expandable-header hand-cursor'}
      `} onClick={() => {
          this.setState({
            expanded_main: !expanded_main
          })
        }}>

        <span className='section-title'>{"Item To Be Produced"}</span>
        <RoundedIconButton direction={expanded_main ? 'right' : 'down'} onClick={() => {
          this.setState({
            expanded_main: !expanded_main
          })
        }} />
      </div>
      <Transition
        native
        immediate={true}
        items={expanded_main}
        from={{ overflow: 'hidden', height: 0 }}
        enter={[{ height: 'auto' }]}
        leave={{ height: 0 }}
      >
        {show =>
          show && (props => <animated.div className='no-wrap-rl-row form-segment-container' style={{
            alignItems: 'flex-start',
            width: '100%',
            ...props
          }}>

            <OrderTable
              data={produced_product}
              columns={[
                {
                  title: "Product",
                  render: (value, record, index) => {
                    if (record.__type__ == "EDITOR") {
                      return <NetworkSelect
                        renderKey="CONTACTITEM"
                        dropdownWidth={500}
                        style={{
                          width: '100%'
                        }}
                        value={new_consumed.product_id}
                        form_title="Product"
                        onChange={(id, _d) => {
                          console.log("on change", id, _d);
                          this.setState({
                            new_consumed: {
                              ...new_consumed,
                              product_id: id,
                              product_name: _d.name,
                              product_code: _d.code,
                              uom: 'unit',
                              quantity: 1
                            },
                            productsSaved: {
                              ...productsSaved,
                              [id]: _d
                            }
                          })
                        }}
                        apiURL='/product-services'
                        addAPI='/product-services'
                        withAdd={true}
                        addSchema={PRODUCT_SERVICE_SMALL_SCHEMA}
                        className='no-border-input'
                        placeholder="Add Code or Product"
                      />

                    } else {
                      let product = productsSaved[record.product_id] || {};

                      return <div style={{
                        padding: '8px 13px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        width: '100%'
                      }}>
                        <Text strong>{product.name}</Text>

                        <Text type='secondary' style={{
                          marginTop: LayoutManager.globalSpacing / 2
                        }}>{product.code}</Text>
                      </div>

                    }
                  }
                },
                {
                  title: "Qty",
                  width: 140,
                  render: (value, record, index) => {
                    if (record.__type__ == "EDITOR") {
                      return <Input.Group compact style={{
                        display: 'flex'
                      }}>
                        <NumberInput
                          disabled={new_consumed.product_id == null}
                          style={{
                            width: '55%'
                          }}
                          min={1}
                          value={new_consumed.quantity}
                          onChange={(_v) => {
                            this.setState({
                              new_consumed: {
                                ...new_consumed,
                                quantity: _v
                              }
                            })
                          }}
                          inputClassName='no-border-input'
                          placeholder="Qty"
                        />
                        <CustomSelect
                          disabled={new_consumed.product_id == null}
                          style={{
                            width: '45%'
                          }}
                          inputClassName='no-border-input'
                          placeholder="UN"
                          options={[
                            { key: "unit", value: "UN" },
                            { key: "subunit", value: "SUB" }
                          ]}
                          value={new_consumed.uom}
                          onChange={(_v) => {
                            this.setState({
                              new_consumed: {
                                ...new_consumed,
                                uom: _v
                              }
                            })
                          }}
                        />
                      </Input.Group>
                    } else {
                      return <Input.Group compact style={{
                        display: 'flex'
                      }}>
                        <NumberInput
                          style={{
                            width: '100%'
                          }}
                          min={1}
                          value={record.product_quantity}
                          onChange={(_v) => {
                            this.recalculateAllData({
                              ...main_data,
                              ['product_quantity']: _v
                            })
                            // this.changeItemHandler(index, _v, 'quantity', main_data,'consumed_products');
                          }}
                          inputClassName='no-border-input'
                          placeholder="Qty"
                        />
                        {/* <CustomSelect
                      style={{
                        width: '45%'
                      }}
                      inputClassName='no-border-input'
                      placeholder="UN"
                      options={[
                        { key: "unit", value: "UN" },
                        { key: "subunit", value: "SUB" }
                      ]}
                      value={record.uom}
                      onChange={(_v) => {
                        this.changeItemHandler(index, _v, 'uom', main_data,'consumed_products');
                      }}
                    /> */}
                      </Input.Group>
                    }
                  }
                },
                {
                  title: "Warehouse",
                  width: 140,
                  render: (value, record, index) => {
                    if (record.__type__ == "EDITOR") {
                      return <InlineDropdownSelect
                        // label="WAREHOUSE"
                        value={record.warehouse_id}
                        onChange={(_v) => {
                          this.recalculateAllData({
                            ...main_data,
                            ['warehouse_id']: _v
                          })

                        }}
                        selectClassName='small-inline-select'
                        options={this.state.warehouse_list}
                      />

                    } else {
                      return <InlineDropdownSelect
                        // label="WAREHOUSE"
                        value={record.warehouse_id}
                        selectStyle={{
                          width: 120
                        }}
                        onChange={(_v) => {
                          this.recalculateAllData({
                            ...main_data,
                            ['warehouse_id']: _v
                          })

                        }}
                        selectClassName='small-inline-select'
                        options={this.state.warehouse_list}
                      />
                    }
                  }
                },
                // {
                //   title: "",
                //   width: 80,
                //   render: (value, record, index) => {
                //     if (record.__type__ == "EDITOR") {
                //       return <SmallRoundedIcon onClick={() => {
                //         if (new_consumed.product_id == null || new_consumed.quantity == 0) {
                //           message.error("Fill Missing Field")
                //         } else {
                //           let new_index = consumed_products.length;
                //           this.recalculateAllData({
                //             ...main_data,
                //             ['consumed_products']: [...consumed_products, { uom: "unit", ...new_consumed }]
                //           })
                //           this.setState({
                //             new_consumed: {},
                //           })
                //         }
                //       }} type='success' icon={<AddIcon />} />
                //     } else {
                //       return <div style={{
                //         display: 'flex',
                //         flexDirection: 'row'
                //       }}>
                //         <Space size={10}>
                //           <SmallRoundedIcon onClick={() => {
                //             consumed_products.splice(index, 1)
                //             this.recalculateAllData({
                //               ...main_data,
                //               ['consumed_products']: by_products
                //             })
                //             // this.props.onChangeData(data);
                //           }} type='danger' icon={<DeleteIcon />} />
                //           <div title={JSON.stringify(record)}>™</div>
                //         </Space>
                //       </div>
                //     }
                //   }

                // }
              ]}
            />
          </animated.div>)
        }
      </Transition>
      <div className={`
          no-wrap-rl-row
          segment-header
       ${'left-form-container-left-expandable-header hand-cursor'}
      `} onClick={() => {
          this.setState({
            expanded1: !expanded1
          })
        }}>

        <span className='section-title'>{"Product To Be Consumed"}</span>
        <RoundedIconButton direction={expanded1 ? 'right' : 'down'} onClick={() => {
          this.setState({
            expanded1: !expanded1
          })
        }} />
      </div>
      <Transition
        native
        immediate={true}
        items={expanded1}
        from={{ overflow: 'hidden', height: 0 }}
        enter={[{ height: 'auto' }]}
        leave={{ height: 0 }}
      >
        {show =>
          show && (props => <animated.div className='no-wrap-rl-row form-segment-container' style={{
            alignItems: 'flex-start',
            width: '100%',
            ...props
          }}>

            <OrderTable
              data={[...consumed_products, { __type__: "EDITOR" }]}
              columns={[
                {
                  title: "Product",
                  render: (value, record, index) => {
                    if (record.__type__ == "EDITOR") {
                      return <NetworkSelect
                        renderKey="CONTACTITEM"
                        dropdownWidth={500}
                        style={{
                          width: '100%'
                        }}
                        value={new_consumed.product_id}
                        form_title="Product"
                        onChange={(id, _d) => {
                          console.log("on change", id, _d);
                          this.setState({
                            new_consumed: {
                              ...new_consumed,
                              product_id: id,
                              product_name: _d.name,
                              product_code: _d.code,
                              uom: 'unit',
                              quantity: 1
                            },
                            productsSaved: {
                              ...productsSaved,
                              [id]: _d
                            }
                          })
                        }}
                        apiURL='/product-services'
                        addAPI='/product-services'
                        withAdd={true}
                        addSchema={PRODUCT_SERVICE_SMALL_SCHEMA}
                        className='no-border-input'
                        placeholder="Add Code or Product"
                      />

                    } else {
                      let product = productsSaved[record.product_id] || {};

                      return <div style={{
                        padding: '8px 13px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        width: '100%'
                      }}>
                        <Text strong>{product.name}</Text>

                        <Text type='secondary' style={{
                          marginTop: LayoutManager.globalSpacing / 2
                        }}>{product.code}</Text>
                      </div>

                    }
                  }
                },
                {
                  title: "Qty",
                  width: 140,
                  render: (value, record, index) => {
                    if (record.__type__ == "EDITOR") {
                      return <Input.Group compact style={{
                        display: 'flex'
                      }}>
                        <NumberInput
                          disabled={new_consumed.product_id == null}
                          style={{
                            width: '55%'
                          }}
                          min={1}
                          value={new_consumed.quantity}
                          onChange={(_v) => {
                            this.setState({
                              new_consumed: {
                                ...new_consumed,
                                quantity: _v
                              }
                            })
                          }}
                          inputClassName='no-border-input'
                          placeholder="Qty"
                        />
                        <CustomSelect
                          disabled={new_consumed.product_id == null}
                          style={{
                            width: '45%'
                          }}
                          inputClassName='no-border-input'
                          placeholder="UN"
                          options={[
                            { key: "unit", value: "UN" },
                            { key: "subunit", value: "SUB" }
                          ]}
                          value={new_consumed.uom}
                          onChange={(_v) => {
                            this.setState({
                              new_consumed: {
                                ...new_consumed,
                                uom: _v
                              }
                            })
                          }}
                        />
                      </Input.Group>
                    } else {
                      return <Input.Group compact style={{
                        display: 'flex'
                      }}>
                        <NumberInput
                          style={{
                            width: '55%'
                          }}
                          min={1}
                          value={record.quantity}
                          onChange={(_v) => {
                            this.changeItemHandler(index, _v, 'quantity', main_data, 'consumed_products');
                            // let new_d = record;
                            // new_d['quantity'] = _v
                            // data.splice(index, 1, new_d);
                            // this.props.onChangeData(data);
                            // this.recalculateAllData({
                            //   ...main_data,
                            //   'data':data
                            // })
                          }}
                          inputClassName='no-border-input'
                          placeholder="Qty"
                        />
                        <CustomSelect
                          style={{
                            width: '45%'
                          }}
                          inputClassName='no-border-input'
                          placeholder="UN"
                          options={[
                            { key: "unit", value: "UN" },
                            { key: "subunit", value: "SUB" }
                          ]}
                          value={record.uom}
                          onChange={(_v) => {
                            this.changeItemHandler(index, _v, 'uom', main_data, 'consumed_products');

                            // let new_d = record;
                            // new_d['uom'] = _v
                            // data.splice(index, 1, new_d);
                            // this.props.onChangeData(data);
                          }}
                        />
                      </Input.Group>
                    }
                  }
                },
                {
                  title: "Warehouse",
                  width: 140,
                  render: (value, record, index) => {
                    if (record.__type__ == "EDITOR") {
                      return <InlineDropdownSelect
                        // label="WAREHOUSE"
                        value={new_consumed.warehouse_id}
                        selectStyle={{
                          width: 120
                        }}
                        onChange={(_v) => {
                          this.setState({
                            new_consumed: {
                              ...new_consumed,
                              warehouse_id: _v
                            }
                          })

                        }}
                        selectClassName='small-inline-select'
                        options={this.state.warehouse_list}
                      />

                    } else {
                      return <InlineDropdownSelect
                        // label="WAREHOUSE"
                        value={record.warehouse_id}
                        selectStyle={{
                          width: 120
                        }}
                        onChange={(_v) => {
                          this.changeItemHandler(index, _v, 'warehouse_id', main_data, 'consumed_products');

                        }}
                        selectClassName='small-inline-select'
                        options={this.state.warehouse_list}
                      />
                    }
                  }
                },
                {
                  title: "RATE",
                  width: 120,
                  render: (value, record, index) => {
                    if (record.__type__ == "EDITOR") {
                      return <Input.Group compact style={{
                        display: 'flex'
                      }}>
                        <NumberInput
                          disabled={new_consumed.product_id == null}
                          style={{
                            width: '100%'
                          }}
                          min={0}
                          value={new_consumed.rate}
                          onChange={(_v) => {
                            this.setState({
                              new_consumed: {
                                ...new_consumed,
                                rate: _v
                              }
                            })
                          }}
                          inputClassName='no-border-input'
                          placeholder="RATE"
                        />
                      </Input.Group>
                    } else {
                      return <Input.Group compact style={{
                        display: 'flex'
                      }}>
                        <NumberInput
                          style={{
                            width: '100%'
                          }}
                          min={0}
                          value={record.rate}
                          onChange={(_v) => {
                            this.changeItemHandler(index, _v, 'rate', main_data, 'consumed_products');
                          }}
                          inputClassName='no-border-input'
                          placeholder="RATE"
                        />
                      </Input.Group>
                    }
                  }
                },
                {
                  title: "Amount",
                  width: 100,
                  render: (value, record, index) => {

                    if (record.__type__ == "EDITOR") {
                      let total = ((new_consumed.quantity || 0) * (new_consumed.rate || 0))
                      return <div style={{
                        padding: '8px 13px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        width: '100%'
                      }}>
                        <Text strong>{formatNumber(total)}</Text>
                      </div>

                    } else {
                      let _total = ((record.quantity || 0) * (record.rate || 0))
                      return <div style={{
                        padding: '8px 13px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        width: '100%'
                      }}>
                        <Text strong>{formatNumber(_total)}</Text>
                      </div>;
                      // <NumberInput
                      //     disabled
                      //     min={0}
                      //     inputClassName='no-border-input'
                      //     placeholder="Amount"
                      //     value={record.total}
                      //   />;

                    }
                  }
                },
                {
                  title: "",
                  width: 80,
                  render: (value, record, index) => {
                    if (record.__type__ == "EDITOR") {
                      return <SmallRoundedIcon onClick={() => {
                        if (new_consumed.product_id == null || new_consumed.quantity == 0) {
                          message.error("Fill Missing Field")
                        } else {
                          let new_index = consumed_products.length;
                          this.recalculateAllData({
                            ...main_data,
                            ['consumed_products']: [...consumed_products, { uom: "unit", ...new_consumed }]
                          })
                          this.setState({
                            new_consumed: {},
                          })
                        }
                      }} type='success' icon={<AddIcon />} />
                    } else {
                      return <div style={{
                        display: 'flex',
                        flexDirection: 'row'
                      }}>
                        <Space size={10}>
                          <SmallRoundedIcon onClick={() => {
                            consumed_products.splice(index, 1)
                            this.recalculateAllData({
                              ...main_data,
                              ['consumed_products']: by_products
                            })
                            // this.props.onChangeData(data);
                          }} type='danger' icon={<DeleteIcon />} />
                          <div title={JSON.stringify(record)}>™</div>
                        </Space>
                      </div>
                    }
                  }

                }
              ]}
            />
          </animated.div>)
        }
      </Transition>
      <div style={{

      }}></div>
      <div className={`
          no-wrap-rl-row
          segment-header
       ${'left-form-container-left-expandable-header hand-cursor'}
      `} onClick={() => {
          this.setState({
            expanded2: !expanded2
          })
        }}>

        <span className='section-title'>{"By Product Produced"}</span>
        <RoundedIconButton direction={expanded2 ? 'right' : 'down'} onClick={() => {
          this.setState({
            expanded2: !expanded2
          })
        }} />
      </div>
      <Transition
        native
        immediate={true}
        items={expanded2}
        from={{ overflow: 'hidden', height: 0 }}
        enter={[{ height: 'auto' }]}
        leave={{ height: 0 }}
      >
        {show =>
          show && (props => <animated.div className='no-wrap-rl-row form-segment-container' style={{
            alignItems: 'flex-start',
            width: '100%',
            ...props
          }}>
            <OrderTable
              data={[...by_products, { __type__: "EDITOR" }]}
              columns={[
                {
                  title: "Product",
                  render: (value, record, index) => {
                    if (record.__type__ == "EDITOR") {
                      return <NetworkSelect
                        renderKey="CONTACTITEM"
                        dropdownWidth={500}
                        style={{
                          width: '100%'
                        }}
                        value={new_by.product_id}
                        form_title="Product"
                        onChange={(id, _d) => {
                          console.log("on change", id, _d);
                          this.setState({
                            new_by: {
                              ...new_by,
                              product_id: id,
                              product_name: _d.name,
                              product_code: _d.code,
                              uom: 'unit',
                              quantity: 1,
                              cost_percentage: 0
                            },
                            productsSaved: {
                              ...productsSaved,
                              [id]: _d
                            }
                          })
                        }}
                        apiURL='/product-services'
                        addAPI='/product-services'
                        withAdd={true}
                        addSchema={PRODUCT_SERVICE_SMALL_SCHEMA}
                        className='no-border-input'
                        placeholder="Add Code or Product"
                      />

                    } else {
                      let product = productsSaved[record.product_id] || {};

                      return <div style={{
                        padding: '8px 13px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        width: '100%'
                      }}>
                        <Text strong>{product.name}</Text>

                        <Text type='secondary' style={{
                          marginTop: LayoutManager.globalSpacing / 2
                        }}>{product.code}</Text>
                      </div>


                    }
                  }
                },
                {
                  title: "% OF COST",
                  width: 120,
                  render: (value, record, index) => {
                    if (record.__type__ == "EDITOR") {
                      return <Input.Group compact style={{
                        display: 'flex'
                      }}>
                        <NumberInput
                          disabled={new_by.product_id == null}
                          style={{
                            width: '100%'
                          }}
                          min={0}
                          value={new_by.cost_percentage}
                          onChange={(_v) => {
                            this.setState({
                              new_by: {
                                ...new_by,
                                cost_percentage: _v
                              }
                            })
                          }}
                          inputClassName='no-border-input'
                          placeholder="% OF COST"
                        />
                      </Input.Group>
                    } else {
                      return <Input.Group compact style={{
                        display: 'flex'
                      }}>
                        <NumberInput
                          style={{
                            width: '100%'
                          }}
                          min={1}
                          value={record.cost_percentage}
                          onChange={(_v) => {
                            this.changeItemHandler(index, _v, 'cost_percentage', main_data, 'by_products');
                          }}
                          inputClassName='no-border-input'
                          placeholder="% OF COST"
                        />
                      </Input.Group>
                    }
                  }
                },
                {
                  title: "Qty",
                  width: 140,
                  render: (value, record, index) => {
                    if (record.__type__ == "EDITOR") {
                      return <Input.Group compact style={{
                        display: 'flex'
                      }}>
                        <NumberInput
                          disabled={new_by.product_id == null}
                          style={{
                            width: '55%'
                          }}
                          min={1}
                          value={new_by.quantity}
                          onChange={(_v) => {
                            this.setState({
                              new_by: {
                                ...new_by,
                                quantity: _v
                              }
                            })
                          }}
                          inputClassName='no-border-input'
                          placeholder="Qty"
                        />
                        <CustomSelect
                          disabled={new_by.product_id == null}
                          style={{
                            width: '45%'
                          }}
                          inputClassName='no-border-input'
                          placeholder="UN"
                          options={[
                            { key: "unit", value: "UN" },
                            { key: "subunit", value: "SUB" }
                          ]}
                          value={new_by.uom}
                          onChange={(_v) => {
                            this.setState({
                              new_by: {
                                ...new_by,
                                uom: _v
                              }
                            })
                          }}
                        />
                      </Input.Group>
                    } else {
                      return <Input.Group compact style={{
                        display: 'flex'
                      }}>
                        <NumberInput
                          style={{
                            width: '55%'
                          }}
                          min={1}
                          value={record.quantity}
                          onChange={(_v) => {
                            this.changeItemHandler(index, _v, 'quantity', main_data, 'by_products');
                            // let new_d = record;
                            // new_d['quantity'] = _v
                            // data.splice(index, 1, new_d);
                            // this.props.onChangeData(data);
                            // this.recalculateAllData({
                            //   ...main_data,
                            //   'data':data
                            // })
                          }}
                          inputClassName='no-border-input'
                          placeholder="Qty"
                        />
                        <CustomSelect
                          style={{
                            width: '45%'
                          }}
                          inputClassName='no-border-input'
                          placeholder="UN"
                          options={[
                            { key: "unit", value: "UN" },
                            { key: "subunit", value: "SUB" }
                          ]}
                          value={record.uom}
                          onChange={(_v) => {
                            this.changeItemHandler(index, _v, 'uom', main_data, 'by_products');

                            // let new_d = record;
                            // new_d['uom'] = _v
                            // data.splice(index, 1, new_d);
                            // this.props.onChangeData(data);
                          }}
                        />
                      </Input.Group>
                    }
                  }
                },
                {
                  title: "RATE",
                  width: 120,
                  render: (value, record, index) => {
                    if (record.__type__ == "EDITOR") {
                      return <div style={{
                        padding: '8px 13px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        width: '100%'
                      }}>
                        {/* <Text strong>{formatNumber(record.rate)}</Text> */}

                        <Text strong>{"-"}</Text>
                      </div>
                      return <Input.Group compact style={{
                        display: 'flex'
                      }}>
                        <NumberInput
                          disabled={new_by.product_id == null}
                          style={{
                            width: '100%'
                          }}
                          min={0}
                          value={new_by.rate}
                          onChange={(_v) => {
                            this.setState({
                              new_by: {
                                ...new_by,
                                rate: _v
                              }
                            })
                          }}
                          inputClassName='no-border-input'
                          placeholder="RATE"
                        />
                      </Input.Group>
                    } else {
                      return <div style={{
                        padding: '8px 13px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        width: '100%'
                      }}>
                        <Text strong>{formatNumber(record.rate)}</Text>
                      </div>
                      return <Input.Group compact style={{
                        display: 'flex'
                      }}>

                        {/* <NumberInput
                      style={{
                        width: '100%'
                      }}
                      min={0}
                      value={record.rate}
                      onChange={(_v) => {
                        this.changeItemHandler(index, _v, 'rate', main_data,'by_products');
                      }}
                      inputClassName='no-border-input'
                      placeholder="RATE"
                    /> */}
                      </Input.Group>
                    }
                  }
                },
                {
                  title: "Amount",
                  width: 100,
                  render: (value, record, index) => {

                    if (record.__type__ == "EDITOR") {
                      let total = ((new_by.quantity || 0) * (new_by.rate || 0))
                      return <div style={{
                        padding: '8px 13px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        width: '100%'
                      }}>
                        <Text strong>{formatNumber(total)}</Text>
                      </div>

                    } else {
                      let _total = ((record.quantity || 0) * (record.rate || 0))
                      return <div style={{
                        padding: '8px 13px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        width: '100%'
                      }}>
                        <Text strong>{formatNumber(_total)}</Text>
                      </div>;
                      // <NumberInput
                      //     disabled
                      //     min={0}
                      //     inputClassName='no-border-input'
                      //     placeholder="Amount"
                      //     value={record.total}
                      //   />;

                    }
                  }
                },
                {
                  title: "",
                  width: 80,
                  render: (value, record, index) => {
                    if (record.__type__ == "EDITOR") {
                      return <SmallRoundedIcon onClick={() => {
                        if (new_by.product_id == null || new_by.quantity == 0) {
                          message.error("Fill Missing Field")
                        } else {
                          let new_index = by_products.length;
                          this.recalculateAllData({
                            ...main_data,
                            ['by_products']: [...by_products, { uom: "unit", ...new_by }]
                          })
                          this.setState({
                            new_by: {},
                          })
                        }
                      }} type='success' icon={<AddIcon />} />
                    } else {
                      return <div style={{
                        display: 'flex',
                        flexDirection: 'row'
                      }}>
                        <Space size={10}>
                          <SmallRoundedIcon onClick={() => {
                            by_products.splice(index, 1)
                            this.recalculateAllData({
                              ...main_data,
                              ['by_products']: by_products
                            })
                            // this.props.onChangeData(data);
                          }} type='danger' icon={<DeleteIcon />} />
                          <div title={JSON.stringify(record)}>™</div>
                        </Space>
                      </div>
                    }
                  }

                }
              ]}
            />
          </animated.div>)
        }
      </Transition>
    </div>
  }
}
const PRODUCTION_VOUCHER_ITEM_SEGMENT = withDomains(_PRODUCTION_VOUCHER_ITEM_SEGMENT, "dynamic", "inventory");
const getMainAmount=(_d)=>{
  return ((_d.total||0) + (_d.additional_charge||0) - (_d.additional_discount||0));
}
class _LANDED_COST_TABLE_SEGMENT extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      expanded_main: true,
      expanded1: true,
      expanded2: true,
      active_item_id: null,
      cost_terms_list: [],
      new_cost_term: {},
      new_individual_cost_term: {}
    }
    this.list_cost_terms = RESTExecutor.list().config({
      label: 'cost_terms'
    }).callbacks((success) => {
      let costTerms = [];
      let result = success.result || [];
      result.map((item) => {
        let _data = item.data || {};
        costTerms.push({
          key: item.id,
          value: _data.name,
          method: _data.method
        });
      });
      this.setState({
        cost_terms_list: costTerms
      });
    }).connect(props.domains.inventory);
    this.list_cost_terms.execute();
  }


  render() {
    const { expanded_main, expanded1, expanded2, active_item_id, new_cost_term, new_individual_cost_term } = this.state;
    const { main_data = {} } = this.props;
    let items = main_data.orders || [];
    let landed = main_data.items || [];
    let general_landed=landed.filter((item)=>item.product_id==null)


    return <div>
      <Modal
        centered
        visible={active_item_id != null}
        destroyOnClose={true}
        className='form-modal individual-cost-term-table-modal'
        onClose={() => {
          this.setState({
            active_item_id: null
          })
        }}
        footer={[]}>
        <OrderTable
          excludeRow={(record,index)=>record.product_id!=active_item_id}
          data={[...landed, { __type__: "EDITOR" }]}
          columns={[
            {
              title: "COST TERMS",
              // width: 200,
              notRender: (record) => record.product_id != null,
              render: (value, record, index) => {
                if (record.__type__ == "EDITOR") {
                  return <InlineDropdownSelect
                    // label="WAREHOUSE"
                    value={new_individual_cost_term.cost_term_id}
                    style={{
                      width: '100%'
                    }}
                    onChange={(_v, data) => {
                      console.log("on inline change", data)
                      this.setState({
                        new_individual_cost_term: {
                          ...new_individual_cost_term,
                          cost_term_id: _v,
                        }
                      })

                    }}
                    placeholder="Cost Term"
                    selectClassName='small-inline-select'
                    options={this.state.cost_terms_list}
                  />

                } else {
                  return <InlineDropdownSelect
                    // label="WAREHOUSE"
                    placeholder="Cost Term"
                    value={record.cost_term_id}
                    style={{
                      width: '100%'
                    }}
                    onChange={(_v, data) => {
                      landed[index] = {
                        ...landed[index],
                        cost_term_id: _v,
                      };
                      this.props.onChangeMainData({
                        ...main_data,
                        ['items']: landed
                      });
                      // let all_landed=
                      // this.changeItemHandler(index, _v, 'cost_term_id', main_data, 'consumed_products');

                    }}
                    selectClassName='small-inline-select'
                    options={this.state.cost_terms_list}
                  />
                }
              }
            },
            {
              title: "AMOUNT",
              dataIndex: 'amount',
              width: 120,
              render: (value, record, index) => {
                if (record.__type__ == "EDITOR") {
                  return <NumberInput
                    min={0}
                    inputClassName='no-border-input'
                    placeholder="Amount"
                    value={new_individual_cost_term.amount}
                    onChange={(_v) => {
                      this.setState({
                        new_individual_cost_term: {
                          ...new_individual_cost_term,
                          amount: _v,
                        }
                      })
                    }}
                  />;
                } else {
                  return <NumberInput
                    min={0}
                    inputClassName='no-border-input'
                    placeholder="Amount"
                    value={record.amount}
                    onChange={(_v) => {
                      landed[index] = {
                        ...landed[index],
                        method: _v
                      };
                      this.props.onChangeMainData({
                        ...main_data,
                        ['items']: landed
                      });
                    }}
                  />
                }
              }
            },
            {
              title: "",
              width: 80,
              render: (value, record, index) => {
                if (record.__type__ == "EDITOR") {
                  return <SmallRoundedIcon onClick={() => {
                    if (new_individual_cost_term.cost_term_id == null) {
                      message.error("Fill Missing Field")
                    } else {
                      this.props.onChangeMainData({
                        ...main_data,
                        ['items']: [...landed, { ...new_individual_cost_term,'product_id':active_item_id}]
                      })
                      this.setState({
                        new_individual_cost_term: {},
                      })
                    }
                  }} type='success' icon={<AddIcon />} />
                } else {
                  return <div style={{
                    display: 'flex',
                    flexDirection: 'row'
                  }}>
                    <Space size={10}>
                      <SmallRoundedIcon onClick={() => {
                        landed.splice(index, 1)
                        this.props.onChangeMainData({
                          ...main_data,
                          ['items']: landed
                        })
                        // this.props.onChangeData(data);
                      }} type='danger' icon={<DeleteIcon />} />
                      <div title={JSON.stringify(record)}>™</div>
                    </Space>
                  </div>
                }
              }

            }
          ]}
        />
        <div className='main-form-small-footer'>
          <div className='left'>
          </div>
          <div className='right'>
            <CustomButton
              filled label="DONE" onClick={() => {
                this.setState({
                  active_item_id: null
                })
                // typeof this.props.onSave === 'function' && this.props.onSave()
              }} />
          </div>
        </div>
      </Modal>
      <div className={`
       no-wrap-rl-row
       segment-header
    ${'left-form-container-left-expandable-header hand-cursor'}
   `} onClick={() => {
          this.setState({
            expanded_main: !expanded_main
          })
        }}>

        <span className='section-title'>{"Product Wise Landed Cost"}</span>
        <RoundedIconButton direction={expanded_main ? 'right' : 'down'} onClick={() => {
          this.setState({
            expanded_main: !expanded_main
          })
        }} />
      </div>
      <Transition
        native
        immediate={true}
        items={expanded_main}
        from={{ overflow: 'hidden', height: 0 }}
        enter={[{ height: 'auto' }]}
        leave={{ height: 0 }}
      >
        {show =>
          show && (props => <animated.div className='no-wrap-rl-row form-segment-container' style={{
            alignItems: 'flex-start',
            width: '100%',
            ...props
          }}>
            <OrderTable
              data={items}
              columns={[
                {
                  title: "PRODUCT/SERVICE",
                  dataIndex: 'product',
                  render: (value, record, index) => {
                    let product = record || {};
                    return <div style={{
                      padding: '8px 13px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      width: '100%'
                    }}>
                      <Text strong>{product.product_name}</Text>

                      <Text type='secondary' style={{
                        marginTop: LayoutManager.globalSpacing / 2
                      }}>{product.product_code}</Text>
                    </div>
                  }
                },
                {
                  title: "QTY",
                  dataIndex: 'quantity',
                  width: 120,
                  render: (value, record, index) => {
                    return <div style={{
                      padding: '8px 13px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      width: '100%'
                    }}>
                      <Text>{record.quantity}</Text>

                      <Text type='secondary' style={{
                        marginTop: LayoutManager.globalSpacing / 2
                      }}>{record.uom}</Text>
                    </div>
                  }

                },
                {
                  title: "AMOUNT",
                  dataIndex: 'rate',
                  width: 120,
                  render: (value, record, index) => formatNumber(getMainAmount(record))

                },
                {
                  title: "ADDITIONAL COST",
                  dataIndex: 'product_id',
                  width: 200,
                  render: (value, record, index) => {
                    let total = 0;
                    let has_individual_landed = false;
                    landed.map((item) => {
                      if (item.product_id == value) {
                        has_individual_landed = true;
                        total = total + item.amount;
                      }
                    });

                    if (has_individual_landed) {
                      return <div className='hand-cursor' onClick={() => {
                        this.setState({
                          active_item_id: value
                        })
                      }}>{formatNumber(total)} <EyeIcon className='info' /> </div>
                    } else {
                      return <div className='info hand-cursor' onClick={() => {
                        this.setState({
                          active_item_id: value
                        })
                      }}>+ Additional Cost</div>
                    }
                  }
                }
                ,
                {
                  title: "TOTAL",
                  dataIndex: 'product_id',
                  width: 120,
                  render: (value, record, index) =>{
                    let total = 0;
                    let has_individual_landed = false;
                    landed.map((item) => {
                      if (item.product_id == value) {
                        has_individual_landed = true;
                        total = total + item.amount;
                      }
                    });
                    return formatNumber(total + getMainAmount(record))
                    
                  } 

                }
              ]}
            />
          </animated.div>)
        }
      </Transition>
      <div className={`
       no-wrap-rl-row
       segment-header
    ${'left-form-container-left-expandable-header hand-cursor'}
   `} onClick={() => {
          this.setState({
            expanded1: !expanded1
          })
        }}>

        <span className='section-title'>{"General Landed Cost"}</span>
        <RoundedIconButton direction={expanded1 ? 'right' : 'down'} onClick={() => {
          this.setState({
            expanded1: !expanded1
          })
        }} />
      </div>
      <Transition
        native
        immediate={true}
        items={expanded1}
        from={{ overflow: 'hidden', height: 0 }}
        enter={[{ height: 'auto' }]}
        leave={{ height: 0 }}
      >
        {show =>
          show && (props => <animated.div className='no-wrap-rl-row form-segment-container' style={{
            alignItems: 'flex-start',
            width: '100%',
            ...props
          }}>

            <OrderTable
              excludeRow={(record,index)=>record.product_id!=null}
              data={[...landed, { __type__: "EDITOR" }]}
              columns={[
                {
                  title: "COST TERMS",
                  // width: 200,
                  render: (value, record, index) => {
                    if (record.__type__ == "EDITOR") {
                      return <InlineDropdownSelect
                        // label="WAREHOUSE"
                        value={new_cost_term.cost_term_id}
                        selectStyle={{
                          // width: 180
                        }}
                        style={{
                          width: '100%'
                        }}
                        onChange={(_v, data) => {
                          console.log("on inline change", data)
                          this.setState({
                            new_cost_term: {
                              ...new_cost_term,
                              cost_term_id: _v,
                              method: data.method
                            }
                          })

                        }}
                        placeholder="Cost Term"
                        selectClassName='small-inline-select'
                        options={this.state.cost_terms_list}
                      />

                    } else {
                      return <InlineDropdownSelect
                        // label="WAREHOUSE"
                        placeholder="Cost Term"
                        value={record.cost_term_id}
                        style={{
                          width: '100%'
                        }}
                        onChange={(_v, data) => {
                          landed[index] = {
                            ...landed[index],
                            cost_term_id: _v,
                            method: data.method
                          };
                          this.props.onChangeMainData({
                            ...main_data,
                            ['items']: landed
                          });
                          // let all_landed=
                          // this.changeItemHandler(index, _v, 'cost_term_id', main_data, 'consumed_products');

                        }}
                        selectClassName='small-inline-select'
                        options={this.state.cost_terms_list}
                      />
                    }
                  }
                },
                {
                  title: "METHOD",
                  width: 140,
                  notRender: (record) => record.product_id != null,
                  render: (value, record, index) => {
                    if (record.__type__ == "EDITOR") {
                      return <InlineDropdownSelect
                        // label="WAREHOUSE"
                        value={new_cost_term.method}
                        selectStyle={{
                          width: 120
                        }}
                        onChange={(_v) => {
                          this.setState({
                            new_cost_term: {
                              ...new_cost_term,
                              method: _v
                            }
                          })

                        }}
                        placeholder="Method"
                        selectClassName='small-inline-select'
                        options={COST_TERMS_METHOD_OPTIONS}
                      />

                    } else {
                      return <InlineDropdownSelect
                        // label="WAREHOUSE"
                        placeholder="Method"
                        value={record.method}
                        selectStyle={{
                          width: 120
                        }}
                        onChange={(_v) => {
                          landed[index] = {
                            ...landed[index],
                            method: _v
                          };
                          this.props.onChangeMainData({
                            ...main_data,
                            ['items']: landed
                          });
                          // let all_landed=
                          // this.changeItemHandler(index, _v, 'cost_term_id', main_data, 'consumed_products');

                        }}
                        selectClassName='small-inline-select'
                        options={COST_TERMS_METHOD_OPTIONS}
                      />
                    }
                  }
                },
                {
                  title: "AMOUNT",
                  dataIndex: 'amount',
                  width: 120,
                  render: (value, record, index) => {
                    if (record.__type__ == "EDITOR") {
                      return <NumberInput
                        min={0}
                        inputClassName='no-border-input'
                        placeholder="Amount"
                        value={new_cost_term.amount}
                        onChange={(_v) => {
                          this.setState({
                            new_cost_term: {
                              ...new_cost_term,
                              amount: _v,
                            }
                          })
                        }}
                      />;
                    } else {
                      return <NumberInput
                        min={0}
                        inputClassName='no-border-input'
                        placeholder="Amount"
                        value={record.amount}
                        onChange={(_v) => {
                          landed[index] = {
                            ...landed[index],
                            method: _v
                          };
                          this.props.onChangeMainData({
                            ...main_data,
                            ['items']: landed
                          });
                        }}
                      />
                    }
                  }
                },
                {
                  title: "",
                  width: 80,
                  render: (value, record, index) => {
                    if (record.__type__ == "EDITOR") {
                      return <SmallRoundedIcon onClick={() => {
                        if (new_cost_term.cost_term_id == null) {
                          message.error("Fill Missing Field")
                        } else {
                          this.props.onChangeMainData({
                            ...main_data,
                            ['items']: [...landed, { ...new_cost_term }]
                          })
                          this.setState({
                            new_cost_term: {},
                          })
                        }
                      }} type='success' icon={<AddIcon />} />
                    } else {
                      return <div style={{
                        display: 'flex',
                        flexDirection: 'row'
                      }}>
                        <Space size={10}>
                          <SmallRoundedIcon onClick={() => {
                            landed.splice(index, 1)
                            this.props.onChangeMainData({
                              ...main_data,
                              ['items']: landed
                            })
                            // this.props.onChangeData(data);
                          }} type='danger' icon={<DeleteIcon />} />
                          <div title={JSON.stringify(record)}>™</div>
                        </Space>
                      </div>
                    }
                  }

                }
              ]}
            />
          </animated.div>)
        }
      </Transition>
      <div style={{

      }}></div>
      <div className={`
       no-wrap-rl-row
       segment-header
    ${'left-form-container-left-expandable-header hand-cursor'}
   `} onClick={() => {
          this.setState({
            expanded2: !expanded2
          })
        }}>

        <span className='section-title'>{"Landed Cost"}</span>
        <RoundedIconButton direction={expanded2 ? 'right' : 'down'} onClick={() => {
          this.setState({
            expanded2: !expanded2
          })
        }} />
      </div>
      <Transition
        native
        immediate={true}
        items={expanded2}
        from={{ overflow: 'hidden', height: 0 }}
        enter={[{ height: 'auto' }]}
        leave={{ height: 0 }}
      >
        {show =>
          show && (props => <animated.div className='no-wrap-rl-row form-segment-container' style={{
            alignItems: 'flex-start',
            width: '100%',
            ...props
          }}>
            <OrderTable 
            data={[...items,{}]}
            rowClassName={(record,index)=>{
              if(index==items.length){
                return 'total-row';
              }else{
                return ''
              }
            }}
            columns={[
              {
                title: "PRODUCT/SERVICE",
                dataIndex: 'product',
                render: (value, record, index) => {
                  if(index==items.length){
                    return "Total"
                  }
                  let product = record || {};
                  return <div style={{
                    padding: '8px 13px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: '100%'
                  }}>
                    <Text strong>{product.product_name}</Text>

                    <Text type='secondary' style={{
                      marginTop: LayoutManager.globalSpacing / 2
                    }}>{product.product_code}</Text>
                  </div>
                }
              },
              {
                title: "QTY",
                dataIndex: 'quantity',
                width: 120,
                render: (value, record, index) => {
                  if(index==items.length){
                    let total=0;
                    items.map((item)=>{
                      total+=(item.quantity||0)
                    });
                    return total;
                  }
                  return <div style={{
                    padding: '8px 13px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: '100%'
                  }}>
                    <Text>{record.quantity}</Text>

                    <Text type='secondary' style={{
                      marginTop: LayoutManager.globalSpacing / 2
                    }}>{record.uom}</Text>
                  </div>
                }

              },
              {
                title: "INVOICE \nAMOUNT",
                dataIndex: 'rate',
                width: 120,
                render: (value, record, index) => {
                  if(index==items.length){
                    let total=0;
                    items.map((item)=>{
                      total+=(getMainAmount(item))
                    });
                    return total;
                  }
                  return formatNumber(getMainAmount(record))
                }
              },
              {
                title: `PRODUCT WISE \nADDITIONAL COST`,
                dataIndex: 'product_id',
                width: 140,
                render: (value, record, index) => {
                  if(index==items.length){
                    let total=0;
                    landed.map((item) => {
                      if (item.product_id != null) {
                        total = total + (item.amount||0);
                      }
                    });
                    return total;
                  }
                  let total = 0;
                  let has_individual_landed = false;
                  landed.map((item) => {
                    if (item.product_id == value) {
                      has_individual_landed = true;
                      total = total + item.amount;
                    }
                  });

                  return formatNumber(total)
                }
              },
              {
                title:"GENERAL \nADDITIONAL COST",
                dataIndex:'product_id',
                width:140,
                render:(value,record,index)=>{
                  // let indv_total = 0;
                  // landed.map((item) => {
                  //   if (item.product_id == value) {
                  //     indv_total = indv_total + item.amount;
                  //   }
                  // });
                  if(index==items.length){
                    let total=0;
                    landed.map((item) => {
                      if (item.product_id == null) {
                        total = total + (item.amount||0);
                      }
                    });
                    return total;
                  }
                  let total_quantity=0;
                  let total_amount=0;
                  items.map((item)=>{
                    total_quantity+=(item.quantity||0);
                    total_amount+=getMainAmount(item);
                  })
                  let general_v_total=0;
                  let general_q_total=0;
                  general_landed.map((item)=>{
                      if(item.method=='value'){
                        general_v_total+=item.amount
                      }else{
                        general_q_total+=item.amount
                      }
                  });
                  let q_total=total_quantity==0?0:(((record.quantity||0)/total_quantity) * general_q_total);
                  let v_total=total_amount==0?0:((getMainAmount(record)/total_amount) * general_v_total);
                  return formatNumber(v_total+q_total)
                }
              },
              {
                title:"TOTAL \nLANDED COST",
                dataIndex:'product_id',
                width:140,
                render:(value,record,index)=>{
                  if(index==items.length){
                    let total=0;
                    landed.map((item) => {
                        total = total + (item.amount||0);
                    });
                    return total;
                  }
                  let indv_total = 0;
                  landed.map((item) => {
                    if (item.product_id == value) {
                      indv_total = indv_total + item.amount;
                    }
                  });
                  let total_quantity=0;
                  let total_amount=0;
                  items.map((item)=>{
                    total_quantity+=(item.quantity||0);
                    total_amount+=getMainAmount(item);
                  })
                  let general_v_total=0;
                  let general_q_total=0;
                  general_landed.map((item)=>{
                      if(item.method=='value'){
                        general_v_total+=item.amount
                      }else{
                        general_q_total+=item.amount
                      }
                  });
                  let q_total=total_quantity==0?0:(((record.quantity||0)/total_quantity) * general_q_total);
                  let v_total=total_amount==0?0:((getMainAmount(record)/total_amount) * general_v_total);
                  console.log("total addi",{
                    clg:((record.quantity||0)/total_quantity),
                    record,
                    indv_total,total_quantity,total_amount,general_v_total,general_q_total,v_total,q_total
                  })
                  return formatNumber(indv_total +v_total+q_total)
                }
              }

            ]}
            />
       </animated.div>)
        }
      </Transition>
    </div>
  }
}
const LANDED_COST_TABLE_SEGMENT = withDomains(_LANDED_COST_TABLE_SEGMENT, "dynamic", "inventory");

class _TRANSFER_PRODUCT_TABLE_SEGMENT extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: [{}],
      new_data: {},
      accounts: [],
      subaccounts: {},
      units_and_subunits: {

      }, //{product_id:{unit:"Name",subunit:"Name"}},
      expanded_keys: [],
      productsSaved: {

      },
    }


  }

  changeMainDataHandler = (value, key, main_data) => {

  }
  productAddHandler = (new_item) => {

  }
  productRemoveHandler = (index) => {

  }
  recalculateAllData = (main_data = {}) => {
    this.props.onChangeMainData(main_data);
  }

  changeNewItemHandler = (value, key, data) => {
    data[key] = value;
    this.setState({
      new_data: data
    })
  }
  changeItemHandler = (index, value, key, main_data) => {

    /**
     * Not needed if recalculate all data handled properly
     */
    // let product = this.state.productsSaved[main_data.product_id] || {};
    let append_key = this.props.append_key || 'items';
    let new_data = main_data;

    let data = this.props.data || [];
    let record = data[index] || {};
    record[key] = value;

    switch (key) {
      case 'quantity':
        break;
      case 'rate':
        break;
      case 'discount':
        break;
      case 'uom':
        break;
      case 'vat_type':
        break;
    }

    data.splice(index, 1, record);
    new_data[this.props.append_key || "items"] = data;
    // handle change  in  object data as per key

    this.recalculateAllData(main_data);
    // this.props.onChangeMainData(new_data);
  }

  render() {
    let { title, subtitle, expandable, onChangeData, defaultExpanded = false, validation_errors = {}, table_type = "SALES", append_key = 'items' ,isAdjustment} = this.props;
    // table_type support "SALES" to add sales price of product, "PURCHASE"  to add purchase price
    const { new_data, productsSaved } = this.state;
    const { data = [], main_data = {} } = this.props;


    let table_data = [...data, { __type__: "EDITOR" }];
    // let taxable_total = 0;
    // data.map((data_item) => {
    //   if (data_item.vat_type == "13%") {
    //     taxable_total = taxable_total + ((data_item.total || 0) + (data_item.additional_charge || 0) - (data_item.additional_discount || 0))
    //   }
    // })

    return (
      <Space size={LayoutManager.formSpacing} direction='vertical' style={{
        width: '100%',
        paddingLeft: LayoutManager.globalSpacing,
        paddingRight: LayoutManager.globalSpacing
      }}>

        <OrderTable
          data={table_data}
          columns={[
            {
              title: "Product / service",
              render: (value, record, index) => {
                if (record.__type__ == "EDITOR") {
                  return <NetworkSelect
                    renderKey="CONTACTITEM"
                    dropdownWidth={500}
                    style={{
                      width: '100%'
                    }}
                    value={new_data.product_id}
                    form_title="Product/Service"
                    onChange={(id, _d) => {
                      console.log("on change", id, _d);
                      this.setState({
                        new_data: {
                          ...new_data,
                          product_id: id,
                          product_name: _d.name,
                          product_code: _d.code,
                          // rate: _d.selling_price,
                          // discount: 0,
                          uom: 'unit',
                          // quantity: 1,
                          // vat_type: _d.tax || "No Vat",
                          // account_id: _d.sales_account_id,
                          // sub_account_id: _d.sales_sub_account_id,
                          // warehouse_id: null,
                          // total: _d.selling_price
                        }
                      })
                    }}
                    apiURL='/product-services'
                    addAPI='/product-services'
                    withAdd={true}
                    addSchema={PRODUCT_SERVICE_SMALL_SCHEMA}
                    className='no-border-input'
                    placeholder="Add Code or Product"
                  />

                } else {
                  return <div style={{
                    padding: '8px 13px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: '100%'
                  }}>
                    <Text strong>{record.product_name}</Text>

                    <Text type='secondary' style={{
                      marginTop: LayoutManager.globalSpacing / 2
                    }}>{record.product_code}</Text>
                  </div>

                }
              }
            },
            ...isAdjustment?[
              {
                title: "CURRENT BALANCE",
                width: 140,
                render: (value, record, index) => {
                  if (record.__type__ == "EDITOR") {
                    return <Input.Group compact style={{
                      display: 'flex'
                    }}>
                      <NumberInput
                        disabled={new_data.product_id == null}
                        style={{
                          width: '55%'
                        }}
                        min={1}
                        value={new_data.current_quantity}
                        onChange={(_v) => {
                          this.changeNewItemHandler(_v, 'current_quantity', new_data);
                        }}
                        inputClassName='no-border-input'
                        placeholder="Qty"
                      />
                      <CustomSelect
                        disabled={new_data.product_id == null}
                        style={{
                          width: '45%'
                        }}
                        inputClassName='no-border-input'
                        placeholder="UN"
                        options={[
                          { key: "unit", value: "UN" },
                          { key: "subunit", value: "SUB" }
                        ]}
                        value={new_data.uom}
                        onChange={(_v) => {
                          this.changeNewItemHandler(_v, 'uom', new_data);
                        }}
                      />
                    </Input.Group>
                  } else {
                    return <Input.Group compact style={{
                      display: 'flex'
                    }}>
                      <NumberInput
                        style={{
                          width: '55%'
                        }}
                        min={1}
                        value={record.current_quantity}
                        onChange={(_v) => {
                          this.changeItemHandler(index, _v, 'current_quantity', main_data);
                        }}
                        inputClassName='no-border-input'
                        placeholder="Qty"
                      />
                      <CustomSelect
                        style={{
                          width: '45%'
                        }}
                        inputClassName='no-border-input'
                        placeholder="UN"
                        options={[
                          { key: "unit", value: "UN" },
                          { key: "subunit", value: "SUB" }
                        ]}
                        value={record.uom}
                        onChange={(_v) => {
                          this.changeItemHandler(index, _v, 'uom', main_data);
  
                          // let new_d = record;
                          // new_d['uom'] = _v
                          // data.splice(index, 1, new_d);
                          // this.props.onChangeData(data);
                        }}
                      />
                    </Input.Group>
                  }
                }
              },
              {
                title: "NEW BALANCE",
                width: 140,
                render: (value, record, index) => {
                  if (record.__type__ == "EDITOR") {
                    return <Input.Group compact style={{
                      display: 'flex'
                    }}>
                      <NumberInput
                        disabled={new_data.product_id == null}
                        style={{
                          width: '100%'
                        }}
                        min={1}
                        value={new_data.new_quantity}
                        onChange={(_v) => {
                          this.changeNewItemHandler(_v, 'new_quantity', new_data);
                        }}
                        inputClassName='no-border-input'
                        placeholder="Qty"
                      />
                      {/* <CustomSelect
                        disabled={new_data.product_id == null}
                        style={{
                          width: '45%'
                        }}
                        inputClassName='no-border-input'
                        placeholder="UN"
                        options={[
                          { key: "unit", value: "UN" },
                          { key: "subunit", value: "SUB" }
                        ]}
                        value={new_data.uom}
                        onChange={(_v) => {
                          this.changeNewItemHandler(_v, 'uom', new_data);
                        }}
                      /> */}
                    </Input.Group>
                  } else {
                    return <Input.Group compact style={{
                      display: 'flex'
                    }}>
                      <NumberInput
                        style={{
                          width: '100%'
                        }}
                        min={1}
                        value={record.new_quantity}
                        onChange={(_v) => {
                          this.changeItemHandler(index, _v, 'new_quantity', main_data);
                        }}
                        inputClassName='no-border-input'
                        placeholder="Qty"
                      />
                      {/* <CustomSelect
                        style={{
                          width: '45%'
                        }}
                        inputClassName='no-border-input'
                        placeholder="UN"
                        options={[
                          { key: "unit", value: "UN" },
                          { key: "subunit", value: "SUB" }
                        ]}
                        value={record.uom}
                        onChange={(_v) => {
                          this.changeItemHandler(index, _v, 'uom', main_data);
                        }}
                      /> */}
                    </Input.Group>
                  }
                }
              },
              {
                title:"ADJUSTED QUANTITY",
                render:(value,record,index)=>{
                  if (record.__type__ == "EDITOR") {
                    return <div style={{
                      padding: '8px 13px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      width: '100%'
                    }}>
                      <Text strong>{((new_data.current_quantity||0)-(new_data.new_quantity||0))*-1}</Text>
                    </div>
  
                  } else {
                    return <div style={{
                      padding: '8px 13px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      width: '100%'
                    }}>
                      <Text strong>{((record.current_quantity||0)-(record.new_quantity||0))*-1}</Text>
                    </div>;
                  }
                }
              }
            ]:[
              {
                title: "Qty",
                width: 140,
                render: (value, record, index) => {
                  if (record.__type__ == "EDITOR") {
                    return <Input.Group compact style={{
                      display: 'flex'
                    }}>
                      <NumberInput
                        disabled={new_data.product_id == null}
                        style={{
                          width: '55%'
                        }}
                        min={1}
                        value={new_data.quantity}
                        onChange={(_v) => {
                          this.changeNewItemHandler(_v, 'quantity', new_data);
                        }}
                        inputClassName='no-border-input'
                        placeholder="Qty"
                      />
                      <CustomSelect
                        disabled={new_data.product_id == null}
                        style={{
                          width: '45%'
                        }}
                        inputClassName='no-border-input'
                        placeholder="UN"
                        options={[
                          { key: "unit", value: "UN" },
                          { key: "subunit", value: "SUB" }
                        ]}
                        value={new_data.uom}
                        onChange={(_v) => {
                          this.changeNewItemHandler(_v, 'uom', new_data);
                        }}
                      />
                    </Input.Group>
                  } else {
                    return <Input.Group compact style={{
                      display: 'flex'
                    }}>
                      <NumberInput
                        style={{
                          width: '55%'
                        }}
                        min={1}
                        value={record.quantity}
                        onChange={(_v) => {
                          this.changeItemHandler(index, _v, 'quantity', main_data);
                          // let new_d = record;
                          // new_d['quantity'] = _v
                          // data.splice(index, 1, new_d);
                          // this.props.onChangeData(data);
                          // this.recalculateAllData({
                          //   ...main_data,
                          //   'data':data
                          // })
                        }}
                        inputClassName='no-border-input'
                        placeholder="Qty"
                      />
                      <CustomSelect
                        style={{
                          width: '45%'
                        }}
                        inputClassName='no-border-input'
                        placeholder="UN"
                        options={[
                          { key: "unit", value: "UN" },
                          { key: "subunit", value: "SUB" }
                        ]}
                        value={record.uom}
                        onChange={(_v) => {
                          this.changeItemHandler(index, _v, 'uom', main_data);
  
                          // let new_d = record;
                          // new_d['uom'] = _v
                          // data.splice(index, 1, new_d);
                          // this.props.onChangeData(data);
                        }}
                      />
                    </Input.Group>
                  }
                }
              }
            ],
            {
              title: "",
              width: 80,
              render: (value, record, index) => {
                if (record.__type__ == "EDITOR") {
                  return <SmallRoundedIcon onClick={() => {
                    if (new_data.product_id == null || new_data.quantity == 0) {
                      message.error("Fill Missing Field")
                    } else {
                      let new_index = data.length;
                      this.recalculateAllData({
                        ...main_data,
                        [this.props.append_key || 'items']: [...data, { rate: 0, uom: "unit", discount: 0, discount_type: 'value', ...new_data }]
                      })
                      this.setState({
                        new_data: {},
                      })
                    }
                  }} type='success' icon={<AddIcon />} />
                } else {
                  return <div style={{
                    display: 'flex',
                    flexDirection: 'row'
                  }}>
                    <Space size={10}>
                      <SmallRoundedIcon onClick={() => {
                        data.splice(index, 1)
                        this.recalculateAllData({
                          ...main_data,
                          [this.props.append_key || 'items']: data
                        })
                        // this.props.onChangeData(data);
                        this.setState({
                          expanded_keys: []
                        })
                      }} type='danger' icon={<DeleteIcon />} />
                      <div title={JSON.stringify(record)}>™</div>
                    </Space>
                  </div>
                }
              }

            }
          ]}
        />
        <div className='no-wrap-rl-row' style={{
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          paddingBottom:LayoutManager.globalPadding
        }}>
          <TextArea
            label="Notes"
            value={main_data.notes}
            onChange={(_v) => {
              this.props.onChangeMainData({
                ...main_data,
                notes: _v
              })
            }}
            rows={4}
            autoSize={false}
            style={{
              width: '100%'
            }}
          />
        </div>

      </Space>
    );
  }
}
const TRANSFER_PRODUCT_TABLE_SEGMENT = withDomains(_TRANSFER_PRODUCT_TABLE_SEGMENT, "dynamic");

export {
  BASIC_FORM_SEGMENT,
  MAIN_FORM_SEGMENT,
  CONTACT_TABLE_SEGMENT,
  PRODUCT_VARIATION_SEGMENT,
  PRODUCT_ORDER_TABLE_SEGMENT,
  ACCOUNT_ITEM_TABLE,
  PAYMENT_ALLOCATION_TABLE_SEGMENT,
  PRODUCT_AND_QUANTITY_TABLE_SEGMENT,
  ACCOUNT_EXPENSE_TABLE,
  BOM_ITEM_SEGMENT,
  PRODUCTION_VOUCHER_ITEM_SEGMENT,
  LANDED_COST_TABLE_SEGMENT,
  TRANSFER_PRODUCT_TABLE_SEGMENT
};