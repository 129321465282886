import React from "react";
import "antd/dist/antd.css";
import { RESTExecutor, withDomains } from "../../lib/domain";


const NotificationsPage = ({ domains, ...props }) => {
    return (
        <div className="account-overview-container">
            <p className="edit-form-header">Under Construction</p>
            <img style={{ width: "75%" }} src='images/undercons.svg' />
        </div>
    );
};

export default withDomains(NotificationsPage);