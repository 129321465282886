import React, { useEffect, useState } from 'react'
import SmallForm from "../../components/forms/small_form";
import { RESTExecutor, withDomains } from "../../lib/domain";
import { Link } from "react-router-dom";
import { Form, Input, Button, Checkbox, message, Modal } from "antd";
import CryptoJS from 'crypto-js';
import { BASE_API_URL } from '../../config';

let email = "";


const key = '981239812312312323';
const persistedState = loadFromSessionStorage();


function loadFromSessionStorage() {
    try {
        const serializedState = sessionStorage.getItem("regId");
        if (serializedState === null) {
            return undefined;
        }
        const decrypted = CryptoJS.AES.decrypt(serializedState, key).toString(CryptoJS.enc.Utf8);
        return JSON.parse(decrypted);
    } catch {
        return undefined;
    }
}

function saveToSessionStorage(state) {
    try {
        const serializedState = JSON.stringify(state);
        const encrypted = CryptoJS.AES.encrypt(serializedState, key);
        sessionStorage.setItem('regId', encrypted)
    } catch (e) {
        console.log(e)
    }
}

const RegisterPage = ({
    domains,
    ...props
}) => {

  
    const [isVisibleWarningModal, setIsVisibleWarningModal] = useState(false);
    const [formDataState, setFormData] = useState({});

    const [initialEmail,setInitialEmail]=useState();

    const queryStringToObject = url =>
  [...new URLSearchParams(url.split('?')[1])].reduce(
    (a, [k, v]) => ((a[k] = v), a),
    {}
  );

    useEffect(()=>{
        //check for email in query
        console.log("propsData",props);
        const queryString=props?.location?.search;
        const p=queryStringToObject(queryString);
        console.log("propsData",p);
        if(queryString){
            const p=queryStringToObject(queryString);
            setInitialEmail(p?.email);
            
        }
    },[])
    let register_user = RESTExecutor.post()
        .config({
            label: 'register',
        })
        .callbacks(
            (success) => {

                saveToSessionStorage({ 'id': success.result.id, 'email': email });
                props.history.push({ pathname: `/verify-code` });
            },
            (error) => {
                console.log(error);
                error.error_message && message.error(error.error_message);
            }
        )
        .connect(domains.register)

    const onFinish = (formData) => {
        if (BASE_API_URL.includes("uat.tigg")) {
            email = formData?.email;
            register_user.execute(formData)
        } else {
            setIsVisibleWarningModal(true);
            setFormData(formData);
        }
    };

    const onContinueFinish = () => {
        email = formDataState?.email;
        register_user.execute(formDataState)
    }

    let register_resp = register_user.response();
    let { validation_errors = {}, error_message } = register_user.response()
    let validateStatus = Object.keys(validation_errors || {}).length > 0 ? 'error' : ''


    return (
        <div>
            <Modal className="policy-modal" id="terms-modal"
                maskClosable={false}
                centered
                title="You are signing up for paid verison of Tigg" visible={isVisibleWarningModal} onCancel={() => { setIsVisibleWarningModal(false) }}
                bodyStyle={{ overflow: 'auto' }}
                footer={[
                   
                   
                     <Button key="submit"  onClick={() => {
                        window.open(`https://me.tiggapp.com/demo/#/register?email=${formDataState?.email}`)
                        setIsVisibleWarningModal(false);
                    }}>
                        Go to Demo Version
                    </Button>,
                     <Button key="submit" type="primary" onClick={() => {
                        setIsVisibleWarningModal(false);
                        onContinueFinish();
                    }}>
                        Continue to Paid Version
                    </Button>,
                ]}>
                <p>You are about to register for paid version of Tigg. </p>

                <b>If you are signing up for a Free Demo version, please <a href={`https://me.tiggapp.com/demo/#/register?email=${formDataState?.email}`}>click here.</a></b>
            </Modal>
            <div className="container">
                <div className="header-menu">
                    < img src='images/logo.svg' className='header-menu-item' />
                    <span className='header-menu-item'>
                        Already have an account?
                        <a onClick={() => { props.history.push('/') }}>
                            &nbsp;Log In
                        </a>
                    </span>
                </div>
            </div>
            <div className="main-container">
                <div className="container">
                    <div className="form-container">
                        <img src="images/logo2.svg" className="form-logo" />
                        <p className="form-header" style={{ paddingBottom: "3%" }}>Create New Account</p>

                        < Form
                            name="register"
                            className="login-form"
                            key={`${initialEmail}`}
                            initialValues={{
                                remember: true,
                                email:initialEmail
                            }
                            }

                            onFinish={
                                onFinish
                            }
                        // onFinishFailed={
                        //     onFinishFailed
                        // }
                        >
                            <Form.Item
                                name="email"
                                validateStatus={validateStatus}
                                help={(validation_errors?.email ?? [null])[0]}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your Email!"
                                    }
                                ]}
                            >
                                <Input
                                    prefix={<img src="images/user.svg" />}
                                    placeholder="name@email.com"
                                />
                            </Form.Item>

                            <Form.Item>
                                <Button
                                    loading={register_resp?.fetching ?? false}
                                    size="large"
                                    htmlType="submit" className="login-form-button">
                                    CONTINUE
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                By continuing, you’re agreeing to our Customer Terms of Service, Privacy Policy, and Cookie Policy.
                            </Form.Item>


                        </Form >
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withDomains(RegisterPage, 'register')