import React, { useState } from 'react';
import { Select, Tag, Divider, Checkbox } from 'antd';
import { CloseOutlined, UndoOutlined } from '@ant-design/icons';
import lodash from 'lodash';
import { TransparentButton, CustomButton } from './buttons';
import { FormModal } from './form_modal';
import { LayoutManager } from '../../util/layout_manager';
import MainForm from './form';

import { withDomains, RESTExecutor } from '../../lib/domain'
import { showErrorInNotification, NetworkDelaySearch } from '../../lib/util';
import { THEME_COLOR } from '../../assets/colors';

const LEVEL_ONE = 0;
const LEVEL_TWO = 1;

let level_two_options = {
    1: [
        { title: "Accounting Fees 1", id: 1 },
        { title: "Accounting Fees 2", id: 2 },
        { title: "Accounting Fees 3", id: 3 },
        { title: "Accounting Fees 4", id: 4 },
        { title: "Accounting Fees 5", id: 5 }
    ],
    2: [
        { title: "Advertising1", id: 1 },
        { title: "Advertising2", id: 2 },
        { title: "Advertising3", id: 3 },
        { title: "Advertising4", id: 4 },
        { title: "Advertising5", id: 5 }
    ],
    3: [
        { title: "Bank Service Charge 1", id: 1 },
        { title: "Bank Service Charge 2", id: 2 },
        { title: "Bank Service Charge 3", id: 3 },
        { title: "Bank Service Charge 4", id: 4 },
        { title: "Bank Service Charge 5", id: 5 }
    ]
}
let level_one_options = [
    {
        title: "Accounting Fees",
        id: 1,
        code: '0011'
    },
    {
        title: "Advertising",
        id: 2,
        code: '0012'
    },
    {
        title: "Bank Service Charge",
        id: 3,
        code: '0013'
    },
    {
        title: "Computer-Hardware",
        id: 4,
        code: '0014'
    },
    {
        title: "Computer Hosting",
        id: 5,
        code: '0015'
    },
    {
        title: "Depriciation Expenses",
        id: 6,
        code: '0016'
    }
]

const CUSTOM_RENDERER = (rendererName, data = {}, keyName = 'key', valueName = 'value', value) => {
    switch (rendererName) {
        case "MULTISELECT":
            return <Select.Option
                text={JSON.stringify(data)}
                key={data[keyName]}
                value={data[keyName]}
                title={data[valueName]}
            >
                <div className='multiselect-list-item'>
                    <Checkbox className='multiselect-list-checkbox' checked={value.includes(data[keyName])} />
                    <div className='title'>{data[valueName]}</div>
                </div>
            </Select.Option>
        case "CONTACTITEM":
            return <Select.Option
                key={data[keyName]}
                value={data[keyName]}
                text={JSON.stringify(data)}
                data={data}
                title={data.name}
            >
                <div className='no-wrap-rl-row contact-select-item'>
                    <div className='contact-name'>{data.name}</div>
                    <div className='contact-code'>{data.code}</div>
                </div>
            </Select.Option>
        default:
            return <Select.Option
                key={data[keyName]}
                value={data[keyName]}
                text={JSON.stringify(data)}
                data={data}
                title={data[valueName]}
            >
                {data[valueName]}
            </Select.Option>
    }
}

class _NetworkSelect extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            selected_options: [],
            new_data: [],
            loading: true,
            form_data: {},
            form_modal: false,
            search_value: null

        }

        this.list_previous_data = RESTExecutor.list().config({
            url: props.apiURL || "/noAPI",

        }).callbacks((success) => {
            let selected_options = [];
            success.result.map((_it) => {
                selected_options.push({
                    ..._it.data,
                    __id: _it.id
                })
            })
            this.setState({
                loading: false,
                selected_options: selected_options
            })
        }, (error) => {
            showErrorInNotification(error)
        }).connect(props.domains.dynamic);

        this.list_new_data = RESTExecutor.list().config({
            url: props.apiURL || "/noAPI",
            params: {
                // limit: 8
            }
        }).callbacks((success) => {
            let new_options = [];
            success.result.map((_it) => {
                new_options.push({
                    // ..._it.data,
                    ..._it.data?_it.data:_it,
                    __id: _it.id
                })
            })
            this.setState({
                loading: false,
                new_data: new_options
            })
        }, (error) => {
            showErrorInNotification(error)
            this.setState({
                loading: false
            })
        }).connect(props.domains.dynamic);


        this.post_data = RESTExecutor.post().config({
            url: props.addAPI || "/noAPI"
        }).callbacks((success) => {
            this.list_new_data.execute()
            console.log("respo", success);
            let response_data = success.result || {};
            let added_id = response_data.id;
            if (added_id) {
                // New data added from  modal;
                let newValue = props.value;

                if (Array.isArray(props.value)) {
                    newValue = [...newValue, added_id]
                } else {
                    newValue = added_id;
                }
                typeof this.props.onChange === 'function' && this.props.onChange(newValue, []);
            }
            this.setState({
                form_data: {},
                form_modal: false,
                search_value: null
            })
        }, (error) => {
            console.log("error", error)
        }).connect(props.domains.dynamic)


        this.delay_search = new NetworkDelaySearch();

    }

    getOldData = (value) => {
        let ids = [];
        if (typeof value === 'string') {
            ids = [value]
        } else if (Array.isArray(value)) {
            ids = value
        }

        console.log("to get values for", ids)
        // if (ids.length > 0) {
        //     this.list_previous_data.config({
        //         params: {
        //             id: ids
        //         }
        //     }).execute()
        // }
    }

    componentDidMount() {
        let value=this.props.value;
        let ids = [];
        if (typeof value === 'string') {
            ids = [value]
        } else if (Array.isArray(value)) {
            ids = value
        }
        this.list_new_data.config({
            params:{
                includes:ids
            }
        }).execute();
        // if (this.props.value) {
        //     this.getOldData(this.props.value)
        // }
    }

    componentDidUpdate(prevProps, prevState) {
        console.log("network select state update",prevProps,this.props)
        let value=this.props.value;
        let ids = [];
        if (typeof value === 'string') {
            ids = [value]
        } else if (Array.isArray(value)) {
            ids = value
        }

        if(this.props.apiURL!=prevProps.apiURL){
            this.list_new_data.config({
                url:this.props.apiURL,
                params:{
                    includes:ids
                }
            }).execute()
        }
        if (typeof this.props.value === 'string' && this.props.value != prevProps.value) {
            // console.log("network select string and value changed")
            this.getOldData(this.props.value)
        } else if (Array.isArray(this.props.value) && lodash.differenceBy(this.props.value, prevProps.value).length > 0) {
            // console.log("network select array and value changed")
            this.getOldData(this.props.value)

        }
    }

    render() {
        const { selected_options, new_data, loading, search_value, form_data, form_modal } = this.state;
        let rowsData = lodash.uniqBy(Array().concat(new_data, selected_options), '__id');
        // console.log("rows Data", rowsData)

        const { value,
            onChange,
            placeholder,
            error_message,
            label,
            required,
            className = '',
            disabled,
            inputClassName = '',
            options = [],
            keyName = 'key',
            valueName = 'value',
            allowClear,
            allowSearch,
            addSchema = [],
            idKey,
            withAdd = false,
            addAppendKey = 'name',form_title } = this.props;

        let post_data_resp = this.post_data.response();
        var prev_ids=[];
        if (typeof value === 'string') {
            prev_ids = [value]
        } else if (Array.isArray(value)) {
            prev_ids = value
        }
        return <>
            {form_modal && <FormModal
                idKey={idKey}
                visible={form_modal}
                destroyOnClose={true}
                onClose={() => {
                    this.setState({
                        form_modal: false
                    })
                }}
                onChange={(data) => {
                    this.setState({
                        form_data: data
                    })
                    console.log("data change", data)
                }}

                onSave={() => {
                    this.post_data.execute({
                        ...form_data,
                    })
                }}
                networkState={post_data_resp}
                data={form_data}
                title={`New ${form_title||label}`}
                schema={[
                    ...addSchema,
                    // { label: "Full Name", type: 'text', block: true,index:'name' },
                    // { label: "Phone No.", type: 'text',index:'phone' },
                    // { label: "Email", type: 'text',index:'email ' },
                    // { label: "Address", type: 'textarea', block: true,index:'address' }
                ]}
            />} <CustomSelect
                {...this.props}
                onChange={(vl, dt) => {
                    typeof this.props.onChange === 'function' && this.props.onChange(vl, dt);

                }}
                allowSearch
                onSearch={(v) => {
                    if (v == "") {
                        v = undefined
                    }
                    this.setState({
                        search_value: v
                    }, () => {
                        this.delay_search.onSearch(() => {
                            this.setState({ loading: true })
                            this.list_new_data.config({
                                params: {
                                    search_phrase: v,
                                    includes:prev_ids
                                }
                            }).execute()
                        })
                    })

                }}
                options={rowsData}
                keyName={'__id'}
                valueName={'name'}
                inputClassName={className}
                filterOption={false}
                loading={loading}
                dropdownRender={withAdd ? (menu) => {
                    return <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{
                            paddingTop: 8,
                            paddingBottom: 8
                        }}>
                            <center>
                                <TransparentButton label={`Add ${search_value == "" || search_value == null ? "New" : `"${search_value}"`}`} type='primary' onClick={() => {
                                    this.setState({
                                        form_modal: true
                                    });
                                    this.setState({
                                        form_data: {
                                            [addAppendKey]: search_value
                                        }
                                    })
                                }} />
                            </center>
                        </div>
                    </div>
                } : null}

            />
        </>
    }
}
const NetworkSelect = withDomains(_NetworkSelect, "dynamic")

class _NetworkAccountSelect extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            accounts: [],
            subaccounts: {}
        }

        this.list_accounts = RESTExecutor.list().config({
            url:props.apiURL||'/accounts'
        }).callbacks((success) => {
            let accounts=[];
            let subaccounts={};
            success.result.map((acc_item)=>{
                accounts.push({
                    id:acc_item.id,
                    ...acc_item.data
                });
                let temp_subs=[]
                if(Array.isArray(acc_item.sub_accounts)&&acc_item.sub_accounts.length>0){
                    acc_item.sub_accounts.map((sub_acc_item)=>{
                        temp_subs.push({
                            id:sub_acc_item.id,
                            ...sub_acc_item.data
                        })
                    });
                    subaccounts[acc_item.id]=temp_subs
                   
                }
            });
            this.setState({
                accounts,subaccounts
            })
            console.log("success d ata", success)
        }).connect(props.domains.dynamic);

        this.list_accounts.execute();
    }

    render() {
        const { accounts, subaccounts } = this.state;
        return (
            <AccountSelect {...this.props} level_one_options={accounts} level_two_options={subaccounts} />
        )
    }
}
const NetworkAccountSelect = withDomains(_NetworkAccountSelect, "dynamic");

class AccountSelect extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            selected_level: LEVEL_ONE,
            value1: this.props.value1,
            value2: this.props.value2,
            openSecond: false,
            isFocused: false,
        }
    }


    render() {
        const { value1, value2, openSecond, selected_level, isFocused } = this.state;
        const { label, required, level_one_options = [], level_two_options = {},error_message } = this.props;

        let label1 = (lodash.find(level_one_options, (d) => {
            return d.id == value1
        }) || {}).name;
        let label2 = (lodash.find(level_two_options[value1] || [], (d) => {
            return d.id == value2
        }) || {}).name;
        console.log("checking label 2", label2, level_two_options, value2)

        return <div className='rl-form-item' style={{
            ...this.props.style
        }}>
            {label && <h4><span className='form-label'>{label}</span>{required && <span className='error-color'> *</span>}</h4>}
            {!isFocused ?
                <div tabIndex={'0'} 
                onKeyDown={(e)=>{
                    if(e.keyCode==13){
                        this.setState({
                            isFocused:true
                        })
                    }
                }}
                className='account-select-wrapper-div' style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    flexWrap: 'nowrap',
                    paddingLeft: 10,
                    // color: THEME_COLOR.mediumGrey
                }} onClick={() => {
                    this.setState({
                        isFocused: true
                    }, () => {
                        // setTimeout(()=>{
                        //     try {
                        //         if(value1){
                        //             this.setState({
                        //                 openSecond:true
                        //             })
                        //         }
                        //         console.log("focused for first")
                        //     } catch (e) {
                        //         console.log("failed to focused",e)
                        //     }
                        // },200)
                        
                    })
                }}>
                    {`${label1 ? label1 : "Account"} ${label2 ? `> ${label2}` : label1 ? "" : "> Sub-Account"}`}
                </div> :
                <div className={`account-select selecting-${value1 != null ? 'secondary' : 'primary'}`}>
                    {value1 != null &&
                        <Tag title={label1} className='value-1-tag'
                            icon={<CloseOutlined onClick={() => {
                                this.setState({
                                    value1: null,
                                    value2: null
                                })
                                typeof this.props.onChange === 'function' && this.props.onChange(null, null)
                            }} className='hand-cursor' />}
                        // closable 
                        // onClose={()=>{
                        //     this.setState({
                        //         value1:null
                        //     })
                        // }}
                        >{`${label1} :`}</Tag>}
                    <Select
                        style={{
                            width: '100%'
                        }} size='large'
                        placeholder="Search Accounts"
                        showSearch
                        allowClear
                        value={value1}
                        filterOption={true}
                        ref={(first_opt) => {
                            this.first_opt = first_opt;
                        }}
                        // onBlur={()=>{
                        //     if(value1)
                        //     this.setState({
                        //         isFocused:false
                        //     })
                        // }}
                        optionFilterProp='text'
                        onChange={(value) => {
                            if (Array.isArray(level_two_options[value]) && level_two_options[value].length > 0) {
                                // has sub account 
                                this.setState({ value1: value, openSecond: true });
                            } else {
                                this.setState({ value1: value });
                                this.setState({ isFocused: false })
                            }
                            typeof this.props.onChange === 'function' && this.props.onChange(value)

                            // this.secondary_account_select.focus()
                        }}
                        className='account-select-child primary custom-select'>
                        {level_one_options.map((item) => {
                            return <Select.Option text={JSON.stringify(item)} value={item.id} key={item.id}
                            >{item.name}</Select.Option>
                        })}
                    </Select>

                    <Select
                        open={openSecond}
                        value={value2}
                        filterOption={true}
                        style={{
                            width: '100%',
                            display: value1 == null ? 'none' : 'block'
                        }} size='large'
                        showSearch
                        allowClear
                        onChange={(value) => {
                            this.setState({
                                openSecond: false,
                                value2: value
                            });
                            this.setState({ isFocused: false })
                            typeof this.props.onChange === 'function' && this.props.onChange(value1, value)
                            typeof this.props.onBlur === 'function' && this.props.onBlur()
                        }}

                        onBlur={() => {
                            this.setState({
                                openSecond: false,
                                isFocused: false
                            })
                            console.log("blurred second")
                            typeof this.props.onChange === 'function' && this.props.onChange(value1, value2)
                            typeof this.props.onBlur === 'function' && this.props.onBlur()
                        }}
                        optionFilterProp='text'
                        onDropdownVisibleChange={(open) => {
                            console.log("open is", open);
                            this.setState({
                                openSecond: open,
                                isFocused: open
                            })
                            if (!open) {
                                console.log("closing data")
                            }
                        }}
                        placeholder="Search Sub Account"
                        className='account-select-child secondary custom-select'>
                        {Array.isArray(level_two_options[value1]) && level_two_options[value1].map((item) => {
                            return <Select.Option text={JSON.stringify(item)} key={item.id} value={item.id}>{item.name}</Select.Option>
                        })}
                    </Select>
                </div>
            }
                    <span className='t-meta-info error-color'>{error_message}</span>

        </div>
    }
}
const CustomSelect = ({
    value,
    onChange,
    placeholder,
    error_message,
    label,
    required,
    disabled,
    inputClassName = '',
    options = [],
    keyName = 'key',
    valueName = 'value',
    allowClear,
    allowSearch,
    tagRender,
    renderKey,
    filterOption = true,
    mode,
    dropdownRender,
    onSearch,
    loading,
    dropdownWidth,style={}

}) => {

    return <div className='rl-form-item' style={{
        ...style
    }}>
        {label && <h4><span className='form-label'>{label}</span>{required && <span className='error-color'> *</span>}</h4>}
        <Select

            aria-autocomplete='inline'
            value={value}
            disabled={disabled}
            loading={loading}
            size='large'
            mode={mode}
            placeholder={placeholder}
            dropdownMatchSelectWidth={dropdownWidth}
            maxTagTextLength={20}
            maxTagCount={3}
            allowClear={allowClear}
            onSearch={onSearch}
            onChange={(value, option_data = {}) => {
                console.log("changed data", value, option_data.data)
                typeof onChange === 'function' && onChange(value, option_data.data || {});
            }}
            showSearch={allowSearch}
            optionFilterProp='text'
            filterOption={filterOption}
            className={`${error_message != null ? 'error-select' : ''} ${inputClassName} custom-select`}
            menuItemSelectedIcon={null}
            dropdownRender={dropdownRender}
            tagRender={(props) => {
                console.log("props", props);
                const { closable, onClose } = props;
                return <Tag className='multiselect-list-tag-render' style={{
                    display: 'flex',
                    alignItems: 'center'
                }}
                    onClose={onClose}
                    closable={closable}>{props.label}</Tag>
            }}
            >
            {options.map((option_item) => {
                return CUSTOM_RENDERER(renderKey, option_item, keyName, valueName, value || [])
            })}

        </Select>
        <span className='t-meta-info error-color'>{error_message}</span>
    </div>
}



class _CustomSelectWithAdd extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            form_data: {},
            form_modal: false,
            search_value: null
        }

        this.post_data = RESTExecutor.post().config({
            url: props.addAPI || "/noAPI"
        }).callbacks((success) => {
            console.log("respo", success);
            this.setState({
                form_data: {},
                form_modal: false,
                search_value: null
            })
        }, (error) => {
            console.log("error", error)
        }).connect(props.domains.dynamic)
    }



    render() {
        const { form_data, form_modal, search_value } = this.state;
        // const [form_data, onChangeFormData] = useState({});
        // const [form_modal, onToggleModal] = useState(false);
        // const [search_value, onChangeSearchValue] = useState(null)
        const { value,
            onChange,
            placeholder,
            error_message,
            label,
            required,
            disabled,
            inputClassName = '',
            options = [],
            keyName = 'key',
            valueName = 'value',
            allowClear,
            allowSearch,
            addSchema = [],
            addAPI = '/add',
            addAppendKey = 'name' } = this.props;

        let post_data_resp = this.post_data.response();
        return <>
            {form_modal && <FormModal
                visible={form_modal}
                destroyOnClose={true}
                onClose={() => {
                    this.setState({
                        form_modal: false
                    })
                }}
                onChange={(data) => {
                    this.setState({
                        form_data: data
                    })
                    console.log("data change", data)
                }}
                onSave={() => {
                    this.post_data.execute(form_data)
                }}
                networkState={post_data_resp}
                data={form_data}
                title={`New ${label}`}
                schema={[
                    ...addSchema,
                    // { label: "Full Name", type: 'text', block: true,index:'name' },
                    // { label: "Phone No.", type: 'text',index:'phone' },
                    // { label: "Email", type: 'text',index:'email ' },
                    // { label: "Address", type: 'textarea', block: true,index:'address' }
                ]}
            />}
            <CustomSelect
                dropdownRender={(menu) => {
                    return <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{
                            paddingTop: 8,
                            paddingBottom: 8
                        }}>
                            <TransparentButton label={`Add ${search_value == "" || search_value == null ? "New" : `"${search_value}"`}`} type='primary' onClick={() => {
                                this.setState({
                                    form_modal: true
                                });
                                this.setState({
                                    form_data: {
                                        [addAppendKey]: search_value
                                    }
                                })
                            }} />
                        </div>
                    </div>
                }}
                {...this.props}
                onSearch={(_v) => {
                    this.setState({
                        search_value: _v
                    })
                }}

            />

        </>
    }
}



const CustomSelectWithAdd = withDomains(_CustomSelectWithAdd, "dynamic");

export { NetworkSelect, AccountSelect, CustomSelect, CustomSelectWithAdd,NetworkAccountSelect }