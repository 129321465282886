import React, {Component} from 'react';
import {Space} from 'antd';
import {TransparentButton, RoundedIconButton} from './forms/buttons';
import {LeftCircleFilled, RightCircleFilled} from '@ant-design/icons';

export const CustomPagination = ({
    meta = {},
    onChange
}) => {
    let total_data = meta.total || 0;
    let requested_count = meta.limit||0 ;
    let data_count = meta.current_page_total || 0;
    let currentPage = meta.page_number||0;
    let total_page = meta.total_pages;
    let startIndexOfPage = data_count==0 ?0 :((currentPage - 1) * requested_count + 1);
    let endIndexOfPage =((currentPage - 1) * requested_count + data_count)

    return <div className='no-wrap-rl-row custom-pagination'>
        <Space size={12}>
            <RoundedIconButton
                hint={`Previous Page`}
                onClick={() => {
                typeof onChange === 'function' && onChange(currentPage - 1, requested_count)
            }}
                direction='left'
                disabled={currentPage == 1||currentPage == 0}/>
            <div className='t-table-option'>{startIndexOfPage}&nbsp;-&nbsp;{endIndexOfPage}&nbsp;/&nbsp;{total_data}</div>
            <RoundedIconButton
                hint={`Next Page`}
                onClick={() => {
                    console.log("next page",currentPage+1,requested_count)
                typeof onChange === 'function' && onChange(currentPage + 1, requested_count)
            }}
                direction='right'
                disabled={currentPage == total_page||total_data==0}/>
        </Space>
    </div>
}