class Persistor{
  constructor(domain_name, cache_for_seconds, executor){
    this.domain_name = domain_name;
    this.cache_for_seconds = cache_for_seconds;
    this.executor = executor;

    this._state = {};

    if(this.cache_for_seconds){
      let value = this.executor.readAll(this.domain_name);
      if(value){
        try{
          this._state = JSON.parse(value);
        }
        catch(e){
          console.log('Non JSON value saved for Persistor');
        }
      }
    }
    else{
      this.executor.purge(this.domain_name);
    }


    this.write = this.write.bind(this);
    this.remove = this.remove.bind(this);
    this.read = this.read.bind(this);
    this.readAll = this.readAll.bind(this);
  }

  write(key, value){
    this._state[key] = value;
    this.executor.writeAll(this.domain_name, JSON.stringify(this._state));
  }

  remove(key){
    if(this._state[key]){
      delete this._state[key];
      this.executor.writeAll(this.domain_name, JSON.stringify(this._state));
    }
  }

  purge(){
    this.executor.purge(this.domain_name);
  }

  read(key){
    return this._state[key];
  }

  readAll(){
    return this._state;
  }
}

class WebPersistor{
  static readAll(domain_name){
    return localStorage.getItem(domain_name);
  }

  static writeAll(domain_name, value){
    localStorage.setItem(domain_name, value);
  }

  static purge(domain_name){
    localStorage.removeItem(domain_name);
  }

  static create(domain_name, cache_for_seconds){
    return new Persistor(domain_name, cache_for_seconds, WebPersistor);
  }
}

class ReactNativePersistor{
  static readAll(domain_name){
  }

  static writeAll(domain_name, value){
  }

  static purge(domain_name){
  }

  static create(domain_name, cache_for_seconds){
    return new Persistor(domain_name, cache_for_seconds, WebPersistor);
  }
}

export {
  WebPersistor,
  ReactNativePersistor,
};
