import React, { useState } from 'react'
import { useForm, useStep } from "react-hooks-helper";
import { RESTExecutor, withDomains } from "../../lib/domain";
import { Link } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import { number, size } from 'mathjs';
import { Steps, Button, message, Form } from 'antd';
import First from './first';
import Second from './second';
import Third from './third';
import Final from './final';




const steps = [
    { id: "first" },
    { id: "second" },
    { id: "third" },
    { id: "final" },
];

const defaultData = {
    display_name: null,
    email: null,
    name: null,
    pan_no: null,
    phone_number: null,
    registered_address: null,
    slug: null,
    website: null,
};

const extraData = {
    accounting_start_date: "",
    vat_registered: false,
    logo: null
}


const NewCompanyPage = ({ domains, ...props }) => {


    const [formData, setForm] = useForm(defaultData);
    const [extra, setExtra] = useState(extraData);
    const [regId, setRegId] = useState('');
    const { step, navigation } = useStep({ initialStep: 0, steps });
    const { id } = step;
    const dataProps = { formData, setForm, extra, setExtra, navigation };
    const thirdProps = { formData, setForm, extra, setExtra, navigation, setRegId };
    const finalProps = { regId, props };

    return (
        <div className={"application-container"}>

            <div className="company-container">
                <div className="abstract-container">
                    <div className="container">
                        <div className="header-menu">
                            < img style={{ cursor: 'pointer' }} src='images/logo.svg' className='header-menu-item' onClick={() => { window.open('https://tiggapp.com', "_self") }} />
                            <div ><CloseOutlined className="step-back" onClick={() => { props.history.goBack() }} /></div>

                        </div>
                    </div>

                    <div className="form-nav-container">
                        <div className="step-count">
                            {(() => {
                                switch (id) {
                                    case "first":
                                        return "Step 1 of 3";
                                    case "second":
                                        return "Step 2 of 3";
                                    case "third":
                                        return "Step 3 of 3";
                                    case "final":
                                        return "";
                                    default:
                                        break;
                                }
                            })()}
                        </div>
                        <div className="step-header">
                            {(() => {
                                switch (id) {
                                    case "first":
                                        return "Add New Organisation";
                                    case "second":
                                        return "Accounting Details";
                                    case "third":
                                        return "Organisation Details";
                                    case "final":
                                        return "Your request has been submitted";
                                    default:
                                        break;
                                }
                            })()}
                        </div>
                        <>
                            {(() => {
                                switch (id) {
                                    case "first":
                                        return <First {...dataProps} />;
                                    case "second":
                                        return <Second {...dataProps} />;
                                    case "third":
                                        return <Third {...thirdProps} />;
                                    case "final":
                                        return <Final {...finalProps} />;
                                    default:
                                        break;
                                }
                            })()}
                        </>
                    </div>
                </div>


                <div className="background-container">
                    {/* <img src="images/add_company.svg" /> */}
                </div>
            </div>

        </div>
    )
}

export default withDomains(NewCompanyPage, 'register')