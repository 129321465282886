import React from 'react'
import 'antd/dist/antd.css';
import { RESTExecutor, withDomains } from "../../lib/domain";


const MaintenancePage = ({ domains, ...props }) => {
    return (
        <div className='rlcolumn a-c f-1 j-b' style={{
            padding: 60,
            height: '100vh',
            alignSelf: 'center'
        }}>
            <div className='rlcolumn a-c j-c f-1 g40' style={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: 400
            }}>
                <img alt="MAINTAIN" src='images/error_icon.svg' />
                <div className='rlcolumn g10 text-center' style={{
                    textAlign: 'center',
                }}>
                    <div className='gray80 font-lg w600' style={{ fontWeight: '600', paddingTop: '20px', paddingBottom: '20px' }}>We are down for a bit for maintenance</div>
                    <div className='gray60 font-md w400' style={{ paddingBottom: '40px' }}>We’ll be back up and running again shortly. Please check in after a while.</div>
                </div>

                <center>
                    <a className='link font-md w600' href="https://tiggapp.com" style={{ fontWeight: '600', paddingTop: '20px', paddingBottom: '20px' }}>Go back to website</a>
                    <img alt="TIGG" style={{
                        height: 36,
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                    }} src='images/logo.svg' className='header-menu-item' />
                </center>

            </div>

        </div >
    );
};

export default withDomains(MaintenancePage);