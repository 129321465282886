import { Empty } from 'antd';
import React, { Component } from 'react';

class OrderTable extends Component {
  render() {
    let columns = this.props.columns || [];
    let data = this.props.data || [];
    let expanded_keys=this.props.expanded_keys||[];


    return (
      <div className='order-table'>
        <div className='col-group' style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap'
        }}>
          {columns.map((col_item) => {
            return <div title={col_item.title} className='col-item' style={{
              flex: 1,
              maxWidth: col_item.width,
              minWidth: col_item.width,
              width:col_item.width
            }}><span>{col_item.title}</span></div>
          })}
        </div>
        <div className='body'>
          {data.length==0?<Empty/>:null}
          {data.map((row_item={},row_index) => {
            if(typeof this.props.excludeRow==='function'&&row_item.__type__!="EDITOR"&&this.props.excludeRow(row_item,row_index)==true){
              return null;
            }
            let rowClassName=``;
            if(typeof this.props.rowClassName==='function'){
              rowClassName=this.props.rowClassName(row_item,row_index)||''
            }
            return <div key={row_index}  className={`row-wrapper ${rowClassName}`} >
              <div className='row-item-group'>
                {columns.map((col_item={},col_index) => {
                  // return <div>data</div>;
                  // console.log("fot col",col_item,typeof col_item.render==='function'?col_item.render(row_item[col_item.dataIndex]||row_item,row_item,row_index):"no  rendered")
                  return <div  className='cell-item' key={col_index}  style={{
                    flex: 1,
                    maxWidth: col_item.width,
                    minWidth: col_item.width,
                    width:col_item.width
                  }}>
                  {typeof col_item.render==='function'?col_item.render(row_item[col_item.dataIndex]||row_item,row_item,row_index):row_item[col_item.dataIndex]}
                  </div>
                })}
              </div>
              {expanded_keys.includes(row_index)&&
              <div className='row-item-expanded-container' style={{
                padding:10
              }}>
                {typeof this.props.expandRowRender==='function'&&this.props.expandRowRender(row_item,row_index)}
              </div>
              }
            </div>
          })}
        </div>
      </div>
    );
  }
}

export default OrderTable;
