import { RESTExecutor } from './networkDomain';

class Authenticator extends RESTExecutor {
  constructor(config, initializer) {
    super('post', 'auth_url');

    this.initializer = initializer;
    this.cache = true;
    this.auth_token_request_formatter = config.auth_token_request_formatter;
    this.auth_token_response_formatter = config.auth_token_response_formatter;
    this.refresh_token_request_formatter = config.refresh_token_request_formatter;
    this.refresh_token_response_formatter = config.refresh_token_response_formatter;
  }

  savetoCache(value) {
    this.cache = value;
  }
  persistTokens(data) {

    this.domain.write('access_token', data.access_token, this.cache);
    this.domain.write('refresh_token', data.refresh_token, this.cache);
    this.domain.write('isChanged', data.isChanged, this.cache);
    this.domain.write('meta', data.meta, this.cache);
    this.domain.write('logout_time', data.logout_time, this.cache);
  }

  isAuthenticated() {
    return Boolean(this.domain.read('access_token'));
  }

  hasChangedPassword() {
    // return true;
    return Boolean(this.domain.read('isChanged'))
  }
  updatehasChangedPassword(value) {
    this.domain.write('isChanged', value, true);
  }

  getTokens() {
    return {
      access_token: this.domain.read('access_token'),
      refresh_token: this.domain.read('refresh_token'),
      meta: this.domain.read('meta'),
      logout_time: this.domain.read('logout_time')
    };
  }


  login(data, successCallback, errorCallback) {
    this.config({ label: 'auth_url' }).callbacks(
      (resp) => {
        this.persistTokens(this.auth_token_response_formatter(resp));
        typeof successCallback === 'function' && successCallback(resp)
      },
      (error) => {
        typeof errorCallback === 'function' && errorCallback(error)
      },
    ).execute(this.auth_token_request_formatter(data));
  }

  refresh({ data, successCallback, errorCallback }) {
    let refresh_token = this.domain.read('refresh_token');
    this.config({ label: 'refresh_url', headers: { 'Authorization': `Bearer ${refresh_token}` } }).callbacks(
      (resp) => {

        this.persistTokens(this.refresh_token_response_formatter(resp))
        typeof successCallback === 'function' && successCallback(resp);
      },
      (val, error) => {
        console.log("ERROR", val);
        this.logout();
        // if(error.response){
        //   console.log("GOT RESPONSE",error)
        //   if (error.response.status===401 || error.response.status===402)
        //     {
        //       this.logout();
        //     }
        // }
        typeof errorCallback === 'function' && errorCallback(val, error);

      }
    ).execute({});
  }

  logout() {
    this.initializer();
  }
}

export default Authenticator;
