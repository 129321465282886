import React, { Component, useState } from "react";
import { Form, Input, Button } from "antd";
import { useForm } from "react-hooks-helper";

const First = ({ setForm, formData, navigation }) => {
    const { name, display_name, slug } = formData;

    const [First] = Form.useForm();
    const { next } = navigation;



    const defaultStatus = {
        "name": "validating",
        "display_name": "validating",
        "slug": "validating"
    };

    const helpMessageDefault = {
        "name": "Your registered business name",
        "display_name": "Name to display across the app",
        "slug": "Workspace name for your organisation. This may change as per availability"
    }

    const [validateStatus, setValidateStatus] = useState(defaultStatus);
    const [helpMessage, setHelpMessage] = useState(helpMessageDefault);

    return (
        <Form
            form={First}
            name="first"
            className="steps-content"

        >
            <Form.Item
                initialValue={name}
                name="name"
                validateStatus={validateStatus.name}
                help={helpMessage.name}
                onFocus={() => {
                    setValidateStatus(prevState => ({
                        ...prevState,
                        "name": "validating"
                    }));
                    setHelpMessage(prevState => ({
                        ...prevState,
                        "name": "Your registered business name"
                    }))
                }}
                rules={[
                    {
                        required: true,
                        message: "Your registered business name",
                        help: "Your registered business name",
                    }
                ]}
            >
                <Input
                    value={name}
                    name="name"
                    onChange={setForm}
                    style={{ "padding": "20px" }}
                    placeholder="Company Name"
                />
            </Form.Item>

            <Form.Item
                initialValue={display_name}
                name="display_name"
                validateStatus={validateStatus.display_name}
                help={helpMessage.display_name}
                onFocus={() => {
                    setValidateStatus(prevState => ({
                        ...prevState,
                        "display_name": "validating"
                    }));
                    setHelpMessage(prevState => ({
                        ...prevState,
                        "display_name": "Name to display across the app"
                    }))
                }}
                rules={[
                    {
                        required: true,
                        message: "Name to display across the app",
                    }
                ]}
            >
                <Input
                    value={display_name}
                    name="display_name"
                    onChange={setForm}
                    style={{ "padding": "20px" }}
                    placeholder="Display Name"

                />
            </Form.Item>


            <Form.Item
                name="slug"
                initialValue={slug}
                validateStatus={validateStatus.slug}
                help={helpMessage.slug}
                onFocus={() => {
                    setValidateStatus(prevState => ({
                        ...prevState,
                        "slug": "validating"
                    }));
                    setHelpMessage(prevState => ({
                        ...prevState,
                        "slug": "Workspace name for your organisation. This may change as per availability"
                    }))
                }}

                rules={[
                    {
                        required: true,
                        message: "Workspace name for your organisation. This may change as per availability",
                        help: "Workspace name for your organisation. This may change as per availability",
                    },
                    {
                        message: "please input in lowercase without whitespaces and special characters and numbers",
                        help: "please input in lowercase without whitespaces and special characters and numbers",
                        pattern: "^[a-z]+$",

                    },
                    {
                        min: 5,
                        message: "Workspace name must be atleast 5 characters long."
                    }
                ]}
            >
                <Input
                    type="text"
                    value={slug}
                    name="slug"

                    onKeyPress={(event) => {
                        if (!/[a-z]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }}
                    onChange={setForm}
                    style={{ "padding": "20px" }}
                    placeholder="your-organisation.tigg.app"
                    addonAfter=".tigg.app"
                />
            </Form.Item>

            <Form.Item>
                <Button
                    onClick={() => {
                        First.validateFields().then(() => {
                            next();
                        }).catch(error => {
                            error.errorFields.map(
                                (item) => {
                                    setValidateStatus(prevState => ({
                                        ...prevState,
                                        [item.name['0']]: "error"
                                    }));

                                    setHelpMessage(prevState => ({
                                        ...prevState,
                                        [item.name['0']]: item.errors['0']
                                    }))
                                }
                            )
                        })
                    }}
                    size="large"
                    htmlType="submit" className="login-form-button">
                    Next : Accounting Details
                </Button>
            </Form.Item>


        </Form>
    );
}

export default First;
