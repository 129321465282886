import React, { Component } from 'react';
import { Checkbox, Spin, Empty } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { TextInput } from './forms/inputs';
import { TransparentButton, CustomButton } from './forms/buttons';
import { RESTExecutor, withDomains } from '../lib/domain';
import { showErrorInNotification, NetworkDelaySearch } from '../lib/util';
import lodash from 'lodash';


class NetworkPopupFilterContainer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selected_options: [],
            new_data: [],
            loading: true,
            selected_keys: [],
            options: [],
            query: null
        }

        this.delay_search = new NetworkDelaySearch();


        this.list_previous_data = RESTExecutor.list().config({
            url: props.apiURL || "/noAPI",

        }).callbacks((success) => {
            let selected_options = [];
            success.result.map((_it) => {
                selected_options.push({
                    ..._it.data,
                    __id: _it.id
                })
            })
            this.setState({
                loading: false,
                selected_options: selected_options,
            })
        }, (error) => {
            showErrorInNotification(error)
        }).connect(props.domains.dynamic);

        this.list_new_data = RESTExecutor.list().config({
            url: props.apiURL || "/noAPI",
            params: {
                limit: 100
            }
        }).callbacks((success) => {
            let new_options = [];
            let selected_keys=[];
            success.result.map((_it) => {
                new_options.push({
                    ..._it.data,
                    __id: _it.id
                })
                selected_keys.push(_it.id)
            })
            this.setState({
                loading: false,
                new_data: new_options,
                selected_keys:selected_keys
            })
        }, (error) => {
            showErrorInNotification(error)
            this.setState({
                loading: false
            })
        }).connect(props.domains.dynamic);
    }

    // componentWillReceiveProps(nextProps){
    //     if(this.props.value!=nextProps.value&&Array.isArray(nextProps.value)){
    //         this.setState({
    //             selected_keys:nextProps.value
    //         })
    //     }
    // }

    onClickItem = (k) => {
        let old_keys = this.state.selected_keys;
        this.setState({
            selected_keys: old_keys.includes(k) ? old_keys.filter((a) => a != k) : [...old_keys, k]
        })
    }

    onSelectAll = (available_options) => {
        // let filter_items = _filter_items;
        this.setState({
            selected_keys: available_options.map((it) => `${it.__id}`)
        })
    }
    onOKPressed = () => {
        typeof this.props.onOK === 'function' && this.props.onOK(this.state.selected_keys)
    }
    onCancelPressed = () => {

        typeof this.props.onCancel === 'function' && this.props.onCancel();
        this.setState({
            selected_keys: Array.isArray(this.props.value) ? this.props.value : []
        })
    }

    getOldData = (value) => {
        let ids = [];
        if (typeof value === 'string') {
            ids = [value]
        } else if (Array.isArray(value)) {
            ids = value
        }

        if (ids.length > 0) {
            this.list_previous_data.config({
                params: {
                    id: ids
                }
            }).execute()
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (typeof this.props.value === 'string' && this.props.value != prevProps.value) {
            // this.getOldData(this.props.value)
            this.setState({
                selected_keys: this.props.value
            })
        } else if (Array.isArray(this.props.value) && lodash.differenceBy(this.props.value, prevProps.value).length > 0) {
            // this.getOldData(this.props.value)
            this.setState({
                selected_keys: this.props.value
            })

        }
    }

    componentDidMount() {
        this.list_new_data.execute();
        if (this.props.value) {
            // this.getOldData(this.props.value)
        }
    }

    render() {
        const { selected_keys, options, query, selected_options, new_data, loading } = this.state;
        let rowsData = lodash.uniqBy(Array().concat(new_data, selected_options), '__id');

        // let _options = options;
        // if (query && typeof query == 'string' && query.length > 0) {
        //     _options = _options.filter(({ value = "", key = "" }) => {
        //         // console.log("val",value,key)
        //         value = value.toLowerCase();
        //         key = `${key}`.toLowerCase();
        //         return value.includes(this.state.query.toLowerCase())
        //             || key.includes(this.state.query.toLowerCase())
        //             ;
        //     });
        // }

        return (
            <div className='rl-column table-popup-filter-container' >
                <div className='no-wrap-rl-row table-popup-filter-top'>
                    <TransparentButton
                        onClick={() => this.onSelectAll(rowsData)}
                        type='primary' label="Select All" />
                    <TransparentButton onClick={() => {
                        this.setState({
                            selected_keys: []
                        })
                    }} style={{
                        marginLeft: 20
                    }} type='primary' label="Clear All" />
                </div>

                <TextInput
                    // value={query}
                    onChange={(v) => {
                        if (v == "") {
                            v = undefined
                        }
                        this.delay_search.onSearch(() => {
                            this.setState({ loading: true })
                            this.list_new_data.config({
                                params: {
                                    search_phrase: v
                                }
                            }).execute()
                        })
                    }}
                    inputClassName='no-border-input'
                    placeholder="Search Contacts"
                    leftIcon={<SearchOutlined />}
                />
                <Spin spinning={loading}>
                    <div className='rl-column table-popup-filter-body'>
                        {rowsData.map((item, index) => {
                            return <div
                                title={item.name}
                                onClick={() => {
                                    this.onClickItem(`${item.__id}`)
                                }}
                                className='no-wrap-rl-row table-popup-filter-body-item hand-cursor'>
                                <Checkbox checked={selected_keys.includes(`${item.__id}`)} />
                                <span className='label'>{item.name}</span>
                            </div>
                        })}
                        {rowsData.length == 0 ? <Empty description="No match found"/> : null}
                    </div>
                </Spin>
                <div className='no-wrap-rl-row table-popup-filter-footer'>
                    <CustomButton
                        onClick={() => {
                            this.onOKPressed()
                        }}
                        style={{
                            width: '100%'
                        }} filled type='primary' label="OK" />
                    <TransparentButton
                        onClick={this.onCancelPressed}
                        style={{
                            width: '100%',
                            textAlign: 'center'
                        }} label="Cancel" />

                </div>
            </div>
        );
    }
}

export default withDomains(NetworkPopupFilterContainer, "dynamic");
