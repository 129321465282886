import React, { useMemo } from 'react';
import moment, { weekdays } from 'moment';
import lodash from 'lodash';
import { notification } from 'antd';
import Axios from 'axios';


export const defaultDateFormat = "YYYY-MM-DD"

export const copyStringToClipboard = function (str) {
    // Create new element
    var el = document.createElement('textarea');
    // Set value (string to be copied)
    el.value = str;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '');
    el.style = { position: 'absolute', left: '-9999px' };
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand('copy');
    // Remove temporary element
    document.body.removeChild(el);

}

export const objectToFormData = function (obj, form, namespace) {

    var fd = form || new FormData();
    var formKey;

    for (var property in obj) {
        if (obj.hasOwnProperty(property)) {

            if (namespace) {
                formKey = namespace + '[' + property + ']';
            } else {
                formKey = property;
            }

            // if the property is an object, but not a File, use recursivity.
            if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {

                objectToFormData(obj[property], fd, property);

            } else {

                // if it's a string or a File object
                fd.append(formKey, obj[property]);
            }

        }
    }

    return fd;

};

export function toFormData(obj, form, namespace) {
    let fd = form || new FormData();
    let formKey;

    for (let property in obj) {
        if (obj.hasOwnProperty(property) && obj[property]) {
            if (namespace) {
                formKey = namespace + '[' + property + ']';
            } else {
                formKey = property;
            }

            // if the property is an object, but not a File, use recursivity.
            if (obj[property] instanceof Date) {
                fd.append(formKey, obj[property].toISOString());
            } else if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
                toFormData(obj[property], fd, formKey);
            } else { // if it's a string or a File object
                fd.append(formKey, obj[property]);
            }
        }
    }

    return fd;
}






export function schema_to_field(_schema) {
    let fields = [];

    if (Array.isArray(_schema)) {
        let schema_array = JSON.parse(JSON.stringify(_schema));
        schema_array.map((schemaObj) => {
            // Object.keys(schemaObj).map((key)=>{
            fields.push(getFieldObject(schemaObj, schemaObj.name))
            // })
        })
    } else {
        // not maintained order
        let schema_obj = JSON.parse(JSON.stringify(_schema));
        Object.keys(schema_obj).map((key) => {
            let schema_item = schema_obj[key];
            let schemaKey = key;
            fields.push(getFieldObject(schema_item, schemaKey))
        })
    }

    return fields;
}

function getFieldObject(schema_item, schemaKey) {

    let schema_validation = schema_item.validation || {};
    let field_object = {};
    let list_prop = {};
    let select_props = {};
    let json_props = {};
    let extra_props = {};
    // console.log("schema item is",schema_item)

    if (schema_validation.type === 'jsondata' && schema_validation.schema) {
        json_props = {
            type: 'form',
            schema: schema_to_field(schema_validation.schema)
        }
    }

    if (schema_validation.type === 'list') {
        if (schema_validation.list_type === 'jsondata') {
            list_prop = {
                type: 'json'
            }
        } else {
            list_prop = {
                'multiple': true,
                count: 1,
            }
        }
        if (schema_validation.list_type === 'jsondata' && schema_validation.schema) {
            list_prop = {
                type: 'form',
                multiple: true,
                count: 1,
                schema: schema_to_field(schema_validation.schema)
            }
        }
        if (schema_validation.list_type === 'jsondata' && schema_item.name == 'config') {
            console.log("config changing", schema_item)
            list_prop = {
                type: 'complexconfig',
                toggle_ui_json: true,
                multiple: true,
                count: 1,
                isDraggable: true,
                toggle_main_json: true,
                main_json_size: 600,
                extra_fields: schema_to_field(schema_item.required_params || [])
            }
        }

    }

    if (schema_validation.type === 'choice') {
        let list = schema_validation.choices || [];
        let options = [];
        list.map((item) => {
            options.push({
                text: item,
                value: item
            })
        })
        select_props = {
            options: options,
            filterable: true,
        }
    }
    if (schema_validation.type === 'select') {
        let list = schema_validation.options || [];
        let options = [];
        list.map((item) => {
            options.push({
                text: item.text,
                value: item.value
            })
        })
        select_props = {
            options: options,
            filterable: true,
        }
    }

    if (schema_validation.type === 'FK' || (schema_validation.type == 'list' && schema_validation.list_type == 'FK')) {
        select_props = {
            type: 'networkselect',
            url: schema_validation.url || "/no-url",
            send_params: schema_validation.send_params
        }
    }
    if (schema_validation.conditional && (
        schema_validation.type == 'FK' ||
        schema_validation.type == 'boolean' ||
        schema_validation.type == 'select' ||
        schema_validation.type == 'choice'
    )) {
        let optional_schema = schema_validation.schema_options || {};
        extra_props = {
            additional: true,
            optional_schema: optional_schema,
            append_key: `${schemaKey}_values`
        }
    }

    field_object = {
        index: schemaKey,
        name: schema_item.display_name || schemaKey,
        help_text: schema_item.help_text,
        default: schema_validation && schema_validation.default,
        type: typeRepresentation(schema_validation.type),
        required: schema_validation.required,
        ...list_prop,
        ...select_props,
        ...json_props,
        ...extra_props
    }
    return field_object;

}

export function typeRepresentation(type) {
    switch (type) {
        case 'string':
        case 'email':
            return 'text';
        case 'integer':
        case 'decimal':
        case 'float':
        case 'double':
            return 'number';
        case 'list':
            return 'text';
        case 'jsondata':
            return 'json';
        case 'boolean':
            return 'bool';
        case 'choice':
        case 'select':
            return 'select';
        case 'password':
            return 'password';
        case 'datetime':
            return 'datetime'
        case 'date':
            return 'date'
        default:
            return type;
    }

}

export function groupByDate({ list_data = [], dateKey = 'follow_up_date', dateFormat = 'YYYY-MM-DD', receiveFormat = 'YYYY-MM-DD' }) {
    let groupedData = lodash.groupBy(list_data, (item) => {
        return moment(item[dateKey], dateFormat).format(receiveFormat)
    })
    console.log(groupedData);
    return groupedData

}

export function showSuccessInNotification(success = {}, defaultMessage = "Action Completed") {
    let result = success.result || {};
    if (typeof result.message === 'string') {
        notification.success({
            message: result.message,
            duration: 1
        })
    } else {
        notification.success({
            message: defaultMessage,
            duration: 1

        })
    }
}
export function showErrorInNotification(error = {}) {
    console.log("erorr is", error)

    if (typeof error.error_message === 'string') {
        notification.error({
            message: error.error_message,

        });
    }
    else if (
        typeof error.validation_errors === 'string'
    ) {
        notification.error({
            message: "Validation Error",
            description: error.validation_errors
        })
    }
    else if (Array.isArray(error.validation_errors)) {
        notification.error({
            message: "Validation Error",
            description: error.validation_errors[0]
        })

    } else if (typeof error.validation_errors) {
        let error_messages = errorsFromObject(error.validation_errors)
        notification.error({
            message: "Validation Error",
            description: <div style={{
                display: 'flex',
                flexDirection: 'column',
                color: 'red'
            }}> {error_messages}</div>
        })
    }
}

function errorsFromObject(error_obj = {}) {
    let error_list = [];

    Object.keys(error_obj).map((key, index) => {
        let error_item = error_obj[key];
        if (typeof error_item === 'string') {
            error_list.push(
                <span>{error_item}</span>
            )
        }
        else if (Array.isArray(error_item)) {
            error_list.push(
                <span>{key} : {error_item[0]}</span>
            )
        } else if (typeof error_item === 'object') {
            error_list.push(...errorsFromObject(error_item))
        }
    });
    return error_list;
}



function stringToArray(string_list = "") {
    let array_list = [];
    array_list = string_list.split(",").filter(t => (t != "")).map((item) => parseInt(item)).sort((a, b) => (a - b));
    return array_list;
}


export function _getSimilarStringUpto(string1, string2) {
    console.log("matching types", typeof string1, string1, typeof string2, string2)
    if (typeof string1 === 'number' && typeof string2 === 'number') {
        if (string1 == string2) {
            // both have same number
            return {
                value: `${string2}`,
                index: `${string2}`.length - 1
            }
        }
    }
    if (typeof string1 != 'string' || typeof string2 != 'string') {
        return {
            value: "",
            index: 0
        }
    }
    let str_array_1 = string1.split("");
    let str_array_2 = string2.split("");
    let matching_string = [];
    let highlight_upto_index = 0;
    str_array_1.map((st1c, index_1) => {
        if (st1c == str_array_2[index_1]) {
            matching_string.push(st1c);
            highlight_upto_index = index_1
        }
    })
    return {
        value: matching_string.join(""),
        index: highlight_upto_index
    }
}






// console.log({ A: ([2, 3, 4].join(',')) })



export class NetworkDelaySearch {
    constructor(props) {

        let my_props = props || {};

        // console.log("network delay search", props)
        this.state = {
        }
        this.timeout = null
        this.onSearch = this.onSearch.bind(this);
        this.delay = my_props.delay || 400
    }

    onSearch = (callback) => {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
            typeof callback === 'function' && callback()
        }, this.delay);
    }

}




const isValidHex = (hex) => /^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex)

const getChunksFromString = (st, chunkSize) => st.match(new RegExp(`.{${chunkSize}}`, "g"))

const convertHexUnitTo256 = (hexStr) => parseInt(hexStr.repeat(2 / hexStr.length), 16)

const getAlphafloat = (a, alpha) => {
    if (typeof a !== "undefined") { return a / 256 }
    if (typeof alpha !== "undefined") {
        if (1 < alpha && alpha <= 100) { return alpha / 100 }
        if (0 <= alpha && alpha <= 1) { return alpha }
    }
    return 1
}

export const getDownloadedFile = (_url, as_text = false) => {
    let promise = new Promise((resolve, reject) => {
        Axios({
            url: _url,
            method: 'GET',
            responseType: as_text ? 'text' : 'blob', // important
        }).then((response) => {

            resolve(response.data);
        })
            .catch(error => {
                reject(error)
            })
    })
    return promise;

}

export const hexToRGBA = (hex, alpha) => {
    if (!isValidHex(hex)) { throw new Error("Invalid HEX") }
    const chunkSize = Math.floor((hex.length - 1) / 3)
    const hexArr = getChunksFromString(hex.slice(1), chunkSize)
    const [r, g, b, a] = hexArr.map(convertHexUnitTo256)
    return `rgba(${r}, ${g}, ${b}, ${getAlphafloat(a, alpha)})`
}


String.prototype.insert = function (index, string) {
    if (index > 0) {
        return this.substring(0, index) + string + this.substring(index, this.length);
    }

    return string + this;
};



let side_menu_hidden = localStorage.getItem("sliderCollapse") == 'false' ? false : true;

// console.log("slider collapse",side_menu_hidden)
export const getSliderState = () => {
    return side_menu_hidden;
}

export const setSliderState = (val) => {
    localStorage.setItem("sliderCollapse", val)
    side_menu_hidden = val;
}


const list = [1, 2, 3, 4, 5] //...an array filled with values

const functionWithPromise = item => { //a function that returns a promise
    return Promise.resolve('ok')
}

const anAsyncFunction = async item => {
    return functionWithPromise(item)
}

const getData = async () => {
    return Promise.all(list.map(item => anAsyncFunction(item)))
}

// getData().then(data => {
//   console.log(data)
// })

function create_UUID() {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
}
