
import React,{useRef,useEffect} from 'react';
const queryString = require('query-string');
const STATIC_KEY_TO_REMOVE = ['_id', 'add_modal', 'form_data', 'view_type', 'show_activity']
const USER_COLORS = ["#BDB2FF", "#FFD670", "#7FD8BE", "#7FC8F8", "#E27396", "#FF9B85"];

export function useOuterClick(callback) {
    const innerRef = useRef();
    const callbackRef = useRef();

    // set current callback in ref, before second useEffect uses it
    useEffect(() => { // useEffect wrapper to be safe for concurrent mode
        callbackRef.current = callback;
    });

    useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => document.removeEventListener("click", handleClick);

        // read most recent callback and innerRef dom node from refs
        function handleClick(e) {
            if (
                innerRef.current &&
                callbackRef.current &&
                !innerRef.current.contains(e.target)
            ) {
                callbackRef.current(e);
            }
        }
    }, []); // no need for callback + innerRef dep

    return innerRef; // return ref; client can omit `useRef`
}



export const generateURL = (data) => {
    let params = data.params;
    if (url_mapper[data.id]) {
        return url_mapper[data.id]({
            id: params.id
        })
    } else {
        return "#"
    }
}

const url_mapper = {
    "Contacts": ({ id }) => `/crm/contacts/${id}`,
    "Tasks": ({ id }) => `/task-manager/task/${id}`,
}



export const getStringColor = (str = "") => {

    return USER_COLORS[parseInt(str.length % USER_COLORS.length)]
}

export const getPathDepth = (location) => {
    let pathArr = location.pathname.split("/");
    pathArr = pathArr.filter(n => n !== "");
    return pathArr.length;
}

export const formatNumber = (value = 0, isCurrency) => {
    return `${(isCurrency && value < 0) ? '- ' : ''}${isCurrency ? 'रू ' : ''}
    ${(isCurrency ? Math.abs(value) : value).toLocaleString('en-IN')}`
}

export const getLabelFromName = (name = "") => {
    let name_arr = name.split(" ");
    // console.log("name  arr", name_arr)
    return `${name_arr[0][0] || ""}${name_arr.length > 1 ? name_arr[name_arr.length - 1][0] || "" : ""}`
}

export const getCSSVariable = (varName) => {
    return getComputedStyle(document.documentElement)
        .getPropertyValue(varName);
}


export const queryToFilterObject = (search = "", key_to_remove = []) => {
    // TODO  key to remove implementation remaining
    let parsedData = queryString.parse(search, {
        arrayFormat: 'bracket'
    });

    STATIC_KEY_TO_REMOVE.map((remove_key) => {
        delete parsedData[remove_key]
    })
    return parsedData;
}


// const queryString = require('query-string');
export const filterToQueryString = (filterObj = {}) => {
    console.log("filter obj", filterObj)
    let o = JSON.parse(JSON.stringify(filterObj))
    STATIC_KEY_TO_REMOVE.forEach(k => delete o[k]);
    let sending_string = queryString.stringify(o, { arrayFormat: 'bracket', skipNull: true });
    // console.log("sending string",sending_string);
    return sending_string
}

export const extractRequiredFields = (fields = [], search = "") => {
    let parsedData = queryString.parse(search, {
        arrayFormat: 'bracket'
    });
    let new_fields = {};
    fields.map((f_key) => {
        if (parsedData[f_key] != null) {
            new_fields[f_key] = parsedData[f_key]
        }
    })
    return new_fields
}




export function invertColor(hex = "", bw) {

    if (hex.includes("rgb")) {
        let rgb_string = hex.split("(")[1].split(")")[0];
        let rgb_arr = rgb_string.split(",")
        var r = rgb_arr[0],
            g = rgb_arr[1],
            b = rgb_arr[2];
        if (bw) {
            // http://stackoverflow.com/a/3943023/112731
            return (r * 0.299 + g * 0.587 + b * 0.114) > 186
                ? '#000000'
                : '#FFFFFF';
        }
        // invert color components
        r = (255 - r).toString(16);
        g = (255 - g).toString(16);
        b = (255 - b).toString(16);
        // pad each with zeros and return
        return "#" + padZero(r) + padZero(g) + padZero(b);

    }
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {

        // throw new Error('Invalid HEX color.');
    }
    var r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
    if (bw) {
        // http://stackoverflow.com/a/3943023/112731
        return (r * 0.299 + g * 0.587 + b * 0.114) > 186
            ? '#000000'
            : '#FFFFFF';
    }
    // invert color components
    r = (255 - r).toString(16);
    g = (255 - g).toString(16);
    b = (255 - b).toString(16);
    // pad each with zeros and return
    return "#" + padZero(r) + padZero(g) + padZero(b);
}

function padZero(str, len) {
    len = len || 2;
    var zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
}

let data = { name: "Shirt" };
let variations = {
    variation: [
        { varient: "Size", options: ["S", "M", "L"] },
        { varient: "Colors", options: ["Red", "Blue", "Green"] },
    ]
}
export function createVariation(name = "", variations = []) {
    let new_data = [];
    let var_options = [];
    variations.map((_var) => {
        if (Array.isArray(_var.options) && _var.options.length > 0) {
            var_options.push(_var.options)
        }
    });
    console.log("variations are", var_options)
    return changeNamesAsPerVariations([{ name: name }], var_options)
}

const changeNamesAsPerVariations = (names = [], options = []) => {
    let new_names = [];
    if (options.length == 0) {
        return names;
    } else {
        let init_options = options[0] || [];
        console.log("for option", init_options)
        init_options.map((_n) => {
            names.map((_name = {}) => {
                new_names.push({ name: `${_name.name || ""}-${_n}` })
            })
        });
        if (options[1]) {
            options.splice(0, 1)
            console.log("new options are", options)
            return changeNamesAsPerVariations(new_names, options)
        } else {
            return new_names
        }
    }
}

export const showLeftDrawer = () => {
    document.body.setAttribute('d-shown', 'true')
}

export const hideLeftDrawer = () => {
    document.body.setAttribute('d-shown', 'false')
}

