import React, { Component } from 'react';
import { RadioCards } from './radio_cards';
import { TextInput, TextArea, DateInput, TagInput, NumberInputWithDropdown, NumberInput, PasswordInput } from './inputs';

import lodash from 'lodash';
import { objectbyString, objectAssignerByString, validateFormData } from '../../util/form_util';
import { Button, Space } from 'antd';
import { LayoutManager } from '../../util/layout_manager';
import { TransparentButton } from './buttons';
import { DeleteOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import { CustomSelect, CustomSelectWithAdd, NetworkAccountSelect, NetworkSelect } from './select';
import UploadInput from './upload_input';
import FileUploadInput from './file_upload_input';

class FormEditorsContainer extends Component {
  constructor(props) {
    super(props)
    console.log("FORM constructed", props.initialData)

    this.state = {
      additional_forms: {},
      data: props.data ? props.data : {},
      schema: this.props.fields || [],
      oldSchema: JSON.parse(JSON.stringify(this.props.fields || [])),
      f_validation_errors: {},
      has_f_validation_error: false
    }
    this.validateFormInternally = this.validateFormInternally.bind(this);
    this.handleDefaultValue = this.handleDefaultValue.bind(this);
  }


  handleDefaultValue = () => {
    let schema = this.state.schema;
    let new_data = this.props.data;
    console.log("handle default value for data", new_data)
    schema.map((schm_item) => {
      let form_value = null;
      form_value = objectbyString(new_data, schm_item.index);
      if (form_value == null && schm_item.default != null) {
        new_data[schm_item.index] = schm_item.default
      }
    });
    // this.setState(
    //   { data: new_data },
    //   () => {
    typeof this.props.onChange === 'function' && this.props.onChange(new_data)
    // }
    // );
  }

  resetFormSchema = () => {
    this.setState({
      schema: JSON.parse(JSON.stringify(this.state.oldSchema))
    })
  }
  validateFormInternally = () => {
    let errors = validateFormData(this.state.schema, this.props.data);
    if (typeof errors === 'object' && Object.keys(errors).length > 0) {
      this.setState({
        f_validation_errors: errors,
        has_f_validation_error: true
      })
      return true;
      // has error
    } else {
      this.setState({
        f_validation_errors: {},
        has_f_validation_error: false
      })
      return false
      // ready to submit this editor
    }
    console.log("errors", errors)
  }


  _changeWithAffectingValues = (value, d_key, affecting_data = [], reference_data = {}) => {
    let data = this.props.data;

    let a = objectAssignerByString(data, d_key, value, this.props.stringyfy) || {};
    if (Array.isArray(affecting_data)) {
      affecting_data.map(affecting_item => {
        let _val = this._getValueForAffectedHost(reference_data[affecting_item.host], affecting_item.enricher);
        a = objectAssignerByString(a, affecting_item.target, _val, this.props.stringify) || {};
      })
    }
    typeof this.props.onChange === 'function' && this.props.onChange(a)

  }

  _getValueForAffectedHost = (value, enricher) => {
    switch (enricher) {
      case "INCREMENT":
        return value > 0 ? value + 1 : value
      default:
        return value
    }
  }

  _changeMultiple(items = []) {
    let data = this.props.data;
    let a = data;
    items.map((change_item) => {
      a = objectAssignerByString(a, change_item.d_key, change_item.value, this.props.stringyfy) || {}
    })
    typeof this.props.onChange === 'function' && this.props.onChange(a)
  }
  _change(value, d_key = 'null', callback) {
    // value = value ? value : '';

    let data = this.props.data;

    let a = objectAssignerByString(data, d_key, value, this.props.stringyfy) || {}
    console.log("new data is", a)
    // this.setState(
    //   { data: a },
    //   () => {
    typeof callback === 'function' && callback(a)
    typeof this.props.onChange === 'function' && this.props.onChange(a)
    //   }
    // );
  }


  // s_d_key is secondary d_key required for secondary index in same form field
  _renderFormItem = (item = {}, d_key, showLabel = true) => {

    let validation_errors = this.state.has_f_validation_error ? this.state.f_validation_errors : (this.props.validation_errors || {})

    let s_d_key = item.secondary_index;

    let form_value = null;
    form_value = objectbyString(this.props.data, d_key);

    let s_form_value = null;
    if (s_d_key) {
      console.log("checking for s_d_key", s_d_key)
      s_form_value = objectbyString(this.props.data, s_d_key);
    }


    console.log("rendering item value is", form_value, d_key, item.default)
    if (form_value == null && item.default != null) {
      this._change(item.default, d_key);

    }
    let isDisabled = (this.props.forEdit == true && item.forEdit == false) || item.disabled;


    switch (item.type) {
      case 'cardradio':
        return <RadioCards
          size='large'
          value={form_value}
          onChange={(value) => {
            this._change(value, item.index)
          }}

          options={item.options || []}
          allowClear={item.allowClear}
          error_message={validation_errors[item.index] && validation_errors[item.index][0]}
        />
      case 'text':
        return <TextInput
          value={form_value}
          error_message={validation_errors[item.index] && validation_errors[item.index][0]}
          onChange={(value) => {
            this._change(value, d_key)
          }}
          label={showLabel && item.label}
          required={item.required}
          placeholder={item.placeholder || item.label}
        />
      case 'password':
        return <PasswordInput
          value={form_value}
          error_message={validation_errors[item.index] && validation_errors[item.index][0]}
          onChange={(value) => {
            this._change(value, d_key)
          }}
          label={showLabel && item.label}
          required={item.required}
          placeholder={item.placeholder || item.label}
        />
      case 'number':
        return <NumberInput
          value={form_value}
          error_message={validation_errors[item.index] && validation_errors[item.index][0]}
          onChange={(value) => {
            this._change(value, d_key)
          }}
          disabled={isDisabled}
          label={showLabel && item.label}
          required={item.required}
          placeholder={item.placeholder || item.label}
        />
      case 'textarea':
        return <TextArea
          value={form_value}
          error_message={validation_errors[item.index] && validation_errors[item.index][0]}
          onChange={(value) => {
            console.log("textarea in form", value)
            this._change(value, item.index)
          }}
          label={item.label}
          required={item.required}
          placeholder={item.placeholder || item.label}
        />
      case 'form':
        return <LeftFormContainerWrapper
          style={{
            width: item.extraActionsLeft ? '100%' : null
          }}
          className="right-form-container-left"
          children={<FormEditorsContainer
            showLabel={showLabel}
            forEdit={this.props.forEdit}
            validation_errors={objectbyString(validation_errors, d_key)}
            fields={item.schema}
            data={form_value}
            parentData={this.props.parentData || this.props.data}
            onChange={(data) => {
              this._change(data, d_key)
            }}
          />} />

      case 'choice':
        var choices = item.options || [];
        var options = choices.map((c_i) => {
          return { key: c_i, value: c_i }
        });
        return <CustomSelect
          disabled={isDisabled}
          // error_message="sdas"
          error_message={validation_errors[item.index] && validation_errors[item.index][0]}
          value={form_value}
          onChange={(value, data) => {
            console.log("got data", data)
            this._changeWithAffectingValues(value, d_key, item.affecting_data, data)
            // this._change(value, item.index, () => {
            //   if (Array.isArray(item.affecting_data) && item.affecting_data.length > 0) {
            //     item.affecting_data.map((affecting_item) => {
            //       console.log("changing value",data[affecting_item.host], affecting_item.target)
            //       this._change(data[affecting_item.host], affecting_item.target)
            //     })
            //   }
            // });

          }}
          label={showLabel && item.label}
          required={item.required}
          placeholder={item.placeholder || item.label}
          options={options}
          loading={item.loading}
          allowClear={item.allowClear}
          mode={item.mode}
        />

      case 'select':
        return <CustomSelect
          disabled={isDisabled}
          // error_message="sdas"
          error_message={validation_errors[item.index] && validation_errors[item.index][0]}
          value={form_value}
          onChange={(value, data) => {
            console.log("got data", data)
            this._changeWithAffectingValues(value, d_key, item.affecting_data, data)
            // this._change(value, item.index, () => {
            //   if (Array.isArray(item.affecting_data) && item.affecting_data.length > 0) {
            //     item.affecting_data.map((affecting_item) => {
            //       console.log("changing value",data[affecting_item.host], affecting_item.target)
            //       this._change(data[affecting_item.host], affecting_item.target)
            //     })
            //   }
            // });

          }}
          label={showLabel && item.label}
          required={item.required}
          placeholder={item.placeholder || item.label}
          options={item.options}
          loading={item.loading}
          allowClear={item.allowClear}
          mode={item.mode}
        />



      case 'networkselect':
        console.log("network select", item)
        return <NetworkSelect
          // error_message="sdas"
          error_message={validation_errors[item.index] && validation_errors[item.index][0]}
          value={form_value}
          onChange={(value, data) => {
            console.log("got data", data)
            this._changeWithAffectingValues(value, d_key, item.affecting_data, data)
            // this._change(value, item.index, () => {
            //   if (Array.isArray(item.affecting_data) && item.affecting_data.length > 0) {
            //     item.affecting_data.map((affecting_item) => {
            //       this._change(data[affecting_item.host], affecting_item.target)
            //     })
            //   }
            // });

          }}
          label={showLabel && item.label}
          required={item.required}
          placeholder={item.placeholder || item.label}
          withAdd={item.addSchema ? true : false}
          options={item.options}
          loading={item.loading}
          idKey={item.idKey}
          allowClear={item.allowClear}
          mode={item.mode}
          addSchema={item.addSchema}
          addAPI={item.addAPI}
          apiURL={item.apiURL}
          pastURL={item.pastURL}

        />

      case 'selectandadd':
        return <CustomSelectWithAdd
          error_message={validation_errors[item.index] && validation_errors[item.index][0]}
          label={item.label}
          value={form_value}
          placeholder={item.placeholder || item.label}
          onChange={(value, data) => {
            console.log("got data", data)
            this._change(value, item.index, () => {
              if (Array.isArray(item.affecting_data) && item.affecting_data.length > 0) {
                item.affecting_data.map((affecting_item) => {
                  this._change(data[affecting_item.host], affecting_item.target)
                })
              }
            });

          }}
          options={item.options}
          allowSearch
          addSchema={item.addSchema}
        />

      case 'date':
        return <DateInput
          error_message={validation_errors[item.index] && validation_errors[item.index][0]}
          label={showLabel && item.label}
          required={item.required}
          value={form_value}
          onChange={(val) => {
            this._change(val, item.index)
          }} />
      case 'tags':
        return <TagInput
          error_message={validation_errors[item.index] && validation_errors[item.index][0]}
          label={showLabel && item.label}
          placeholder={item.placeholder || item.label}
          required={item.required}
          value={form_value}
          onChange={(tags) => {
            this._change(tags, item.index)
          }}
        />

      case 'file_upload':

        return <FileUploadInput
          label={item.label}
          required={item.required}

        />
      case 'space':
        return <div></div>

      case 'amount':
        return <NumberInputWithDropdown
          error_message={validation_errors[item.index] && validation_errors[item.index][0]}
          label={item.label}
          placeholder={item.placeholder || item.label}
          value={form_value}
          optionValue={s_form_value}
          onChangeOption={(val) => {
            this._change(val, item.secondary_index)
          }}
          optionPlaceholder="TYPE"
          onChange={(val) => {
            this._change(val, item.index)
          }}
          options={[
            { key: "DR", value: "DR" },
            { key: "CR", value: "CR" }
          ]}
        />
      case 'account':
        return <NetworkAccountSelect
          error_message={validation_errors[item.index] && validation_errors[item.index][0]}
          label={item.label}
          apiURL={item.apiURL}
          required={item.required}
          value1={form_value}
          value2={s_form_value}
          onChange={(val1, val2) => {
            this._changeMultiple([
              {
                d_key: item.index,
                value: val1
              },
              {
                d_key: item.secondary_index,
                value: val2
              }
            ])
            // this._change(val1, d_key,()=>{
            //   this._change(val2, s_d_key)
            // });

            // this.setState({
            //     val1,val2
            // })
            console.log("on change", val1, val2)
          }}
        />

      case "IMAGE":
        return <div>
          <img className='right-side-form-image' src={item.src} />
        </div>
      default:
        return <div>Renderer not found for {item.type}</div>
    }
  }



  _renderConditionalForm = (item = {}) => {
    let affecting_value = null;
    let current_value = {};
    let currentParentValue = {};

    let append_key = item.append_key;
    let _additional_forms = this.state.additional_forms;
    let validation_errors = this.state.has_f_validation_error ? this.state.f_validation_errors : (this.props.validation_errors || {})



    affecting_value = objectbyString(this.props.data, item.index);
    current_value = objectbyString(this.props.data, append_key);
    currentParentValue = objectbyString(this.props.parentData || {}, append_key);


    let schema_options = item.optional_schema || {};
    let new_schema = ((schema_options[affecting_value] || []));

    console.log("object to compare", _additional_forms[item.index], new_schema, !lodash.isEqual(_additional_forms[item.index], new_schema));
    if (!lodash.isEqual(_additional_forms[item.index], new_schema)) {
      console.log("object not equal");
      this.setState({
        additional_forms: {
          ..._additional_forms,
          [item.index]: new_schema
        }
      }, () => {
        this.setState({
          key: new Date()
        })
      })
    }

    // if(_additional_forms[item.index]!=new_schema){
    //   console.log("additional repeated")
    //   // this.setState({
    //   //   additional_forms:{
    //   //     ..._additional_forms,
    //   //     [item.index]:new_schema
    //   //   }
    //   // })
    // }

    console.log("form  created created", this.state.additional_forms, new_schema)
    return <FormEditorsContainer
      validation_errors={append_key ? validation_errors[append_key] : validation_errors}
      fields={this.state.additional_forms[item.index] || new_schema}
      className="right-form-container-left"
      data={append_key ? current_value : this.props.data}
      forEdit={this.props.forEdit}
      // parentData={this.props.parentData || this.props.data}
      onChange={(received_value) => {
        // console.log("received valus from nested",received_value,append_key);
        if (!append_key) {
          typeof this.props.onChange === 'function' && this.props.onChange(received_value)
          // this.setState(
          //   { data: received_value },
          //   () => {
          //     typeof this.props.onChange === 'function' && this.props.onChange(this.state.data)
          //   }
          // );
        } else {
          this._change(received_value, append_key)
        }
      }}
    />
  }


  reconcileSchemaBasedOnForm = (_schema = [], _data = {}) => {
    console.log("reconciling...")
    let old_schema = JSON.parse(JSON.stringify(_schema));

    _schema.map(async (item, schemaIndex) => {
      if (item.multiple) {
        console.log("found multiple for", item)
        // field has multiple value so lets check for data now
        console.log("dataa is", _data[item.index])
        if (Array.isArray(_data[item.index])) {
          console.log("data is array")
          old_schema = this.reconcileChildrenForMultiple(`[${schemaIndex}]`, _data[item.index].length == 0 ? 0 : _data[item.index].length - 1, old_schema);
          console.log("new schema received", old_schema)
        }
      }
    });
    console.log("reconciled schema is", old_schema)
    this.setState({
      schema: old_schema
    })


  }

  reconcileChildrenForMultiple = (s_key, items_to_add = 1, old_schema) => {
    console.log("async adding children", items_to_add, s_key, old_schema)
    let _schema = JSON.parse(JSON.stringify(old_schema));
    // console.log(schema[parentIndex],parentIndex)
    let data_to_change = objectbyString(_schema, s_key);
    // console.log("PREV DATA TO CHANGe", data_to_change);
    if (data_to_change.count != null) {
      data_to_change.count += items_to_add;
      objectAssignerByString(_schema, s_key, data_to_change);
    }
    console.log("async new schema", _schema)
    return _schema;
    // this.setState({
    //   schema: _schema
    // }, () => {
    //   console.log("async updated schema", this.state.schema, _schema);
    //   resolve(`Success For ${s_key},${items_to_add}`);
    // })
  }

  addChildrenField = (s_key, items_to_add = 1) => {
    console.log("adding children", items_to_add, s_key)
    let _schema = JSON.parse(JSON.stringify(this.state.schema));
    // console.log(schema[parentIndex],parentIndex)
    let data_to_change = objectbyString(_schema, s_key);
    // console.log("PREV DATA TO CHANGe", data_to_change);
    if (data_to_change.count != null) {
      data_to_change.count += items_to_add;
      objectAssignerByString(_schema, s_key, data_to_change);
    }
    console.log("new schema", _schema)

    this.setState({
      schema: _schema
    }, () => {
      console.log("updated schema", this.state.schema, _schema)
    })
  }


  removeChildrenField = (s_key, d_key, child_index) => {
    // console.log("REmoving child val", d_key, s_key, child_index);

    let schema = JSON.parse(JSON.stringify(this.state.schema));
    let data = JSON.parse(JSON.stringify(this.props.data));

    let schema_to_change = objectbyString(schema, s_key);
    let dataarray_to_remove = objectbyString(data, d_key);
    // console.log("GOT", schema_to_change, dataarray_to_remove)

    if (schema_to_change.count != null && schema_to_change.count > 0) {
      // console.log("Schema  found")
      schema_to_change.count -= 1;
    }
    if (Array.isArray(dataarray_to_remove) && dataarray_to_remove.length > 0) {
      // console.log("Data  found")
      dataarray_to_remove.splice(child_index, 1);
    }
    // objectAssignerByString(schema,s_key,schemaarray_to_remove);
    objectAssignerByString(data, d_key, dataarray_to_remove);
    // console.log("AFTER DELETE", data, schema)

    this.setState({
      // data: data,
      schema: schema
    }, () => {
      typeof this.props.onChange === 'function' && this.props.onChange(data)
    })


  }
  toggleChildrenField = (s_key, d_key, index1, index2) => {
    console.log("toggling", index1, index2)
    let data = JSON.parse(JSON.stringify(this.props.data));
    // let schema_to_change = objectbyString(schema, s_key);
    let dataarray_to_change = objectbyString(data, d_key);
    // console.log("GOT", schema_to_change, dataarray_to_remove)

    if (Array.isArray(dataarray_to_change) && dataarray_to_change.length > 0) {
      // console.log("Data  found")
      let tmp_data = dataarray_to_change[index1];

      dataarray_to_change.splice(index1, 1, dataarray_to_change[index2]);
      dataarray_to_change.splice(index2, 1, tmp_data);

    }
    // objectAssignerByString(schema,s_key,schemaarray_to_remove);
    objectAssignerByString(data, d_key, dataarray_to_change);
    // console.log("AFTER DELETE", data, schema)

    // this.setState({
    //   data: data,
    // }, () => {
    typeof this.props.onChange === 'function' && this.props.onChange(data)
    // })
  }


  _renderMultipleFormByType = (data, s_key, d_key) => {
    let min_children = data.min == null ? 1 : data.min;
    let max_children = data.max || Infinity;
    let count = data.count == null ? 1 : data.count;
    let multiple = data.multiple || false;
    let validation_errors = this.state.has_f_validation_error ? this.state.f_validation_errors : (this.props.validation_errors || {})

    // console.log("get multiple")

    return <div style={{ display: 'flex', flexDirection: 'column' }}>
      {Array(count).fill("").map((i, childIndex) => {
        // console.log("render")
        return <div><div
          style={{
            // flexWrap: 'nowrap',
            // display: 'flex',
            // flexDirection: 'row',
            // justifyContent: 'space-between',
            // alignItems: 'center',
            position: 'relative'
          }}>
          {/* <div className='left-extra-actions'> */}
          {count > min_children &&
            <TransparentButton
              className='multi-item-delete-btn'
              icon={<DeleteOutlined />}
              disabled={count <= min_children}
              onClick={() => this.removeChildrenField(`${s_key}`, `${d_key}`, childIndex)}
            />}
          {/* <TransparentButton
                icon={<UpOutlined />}
                disabled={childIndex == 0}
                onClick={() => this.toggleChildrenField(`${s_key}`, `${d_key}`, childIndex, childIndex - 1)}
              // onClick={() => this.removeChildrenField(`${s_key}`, `${d_key}`, childIndex)}
              />
              <TransparentButton
                icon={<DownOutlined />}
                disabled={childIndex == count - 1}
                onClick={() => this.toggleChildrenField(`${s_key}`, `${d_key}`, childIndex, childIndex + 1)}
              /> */}
          {/* </div> */}
          {/* <Space size={LayoutManager.formSpacing}> */}

          {this._renderFormItem(data, `${d_key}[${childIndex}]`, childIndex == 0)}
          {/* </Space> */}
          {/*  */}
        </div>
          {Array.isArray(objectbyString(validation_errors || {}, `${d_key}[${childIndex}]`)) && typeof objectbyString(validation_errors || {}, `${d_key}[${childIndex}]`)[0] === 'string' && <div className='t-meta-info error-color'>{objectbyString(validation_errors || {}, `${d_key}[${childIndex}]`)[0]}</div>}
        </div>

      })}



      {/* <br></br> */}
      {multiple && (count < max_children) && <TransparentButton
        type='primary'
        label={data.add_more || "Add More"}
        onClick={() => this.addChildrenField(s_key)} />
      }
      {Array.isArray(objectbyString(validation_errors || {}, d_key)) && typeof objectbyString(validation_errors || {}, d_key)[0] === 'string' && <div className='t-meta-info error-color'>{objectbyString(validation_errors || {}, d_key)[0]}</div>}

    </div>


  }

  render() {
    let schema = this.state.schema || [];
    let className = this.props.className || "";
    let allBlock = this.props.allBlock;
    let form_type = this.props.form_type || "NORMAL";
    console.log("editor form rendered", this.props.data);
    // switch(form_type){
    //   case 'NORMAL':
    return schema.map((schema_item, schema_index) => {
      if (
        this.props.forEdit != true && schema_item.showForAdd == false
        || this.props.forEdit == true && schema_item.showForEdit == false
      ) {
        return;
      }
      return <> <div className={`
          form-col
          ${schema_item.small == true ? 'form-col-small' : ''}
          `} style={{
          width: (schema_item.block || allBlock) ? '100%' : null,
          ...schema_item.style
        }}>
        {schema_item.multiple ?
          this._renderMultipleFormByType(schema_item, `[${schema_index}]`, schema_item.index)
          : this._renderFormItem(schema_item, schema_item.index, this.props.showLabel)}
      </div>
        {schema_item.conditional && this._renderConditionalForm(schema_item)}
      </>
    });
    // default:
    // return <div>FORM TYPE NOT AVAILABLE FOR {form_type}</div>
    // }

  }


  componentDidMount() {
    console.log("FORM mounted", this.props.data)
    this.reconcileSchemaBasedOnForm(this.state.schema, this.props.data)

    typeof this.props.onRef === 'function' && this.props.onRef(this);
    // this.handleDefaultValue()
  }
  componentWillUnmount() {
    typeof this.props.onRef === 'function' && this.props.onRef(null);
  }

  // componentWillUpdate(nextProps) {
  //   console.log("IS NEW INITIAL DATA EQUA:",lodash.isEqual(nextProps.initialData, this.state.data),nextProps.initialData, this.state.data)
  //   if (!lodash.isEqual(nextProps.initialData, this.state.data)) {
  //     console.log("NEW INITIAL DATA", nextProps.initialData, Object.keys(nextProps.initialData).length)
  //     if (nextProps.initialData && Object.keys(nextProps.initialData).length >= 0) {
  //       this.setState({ data: nextProps.initialData },()=>{
  //         // this.handleDefaultValue()
  //       });
  //     }
  //   }
  // if (!lodash.isEqual(this.props.fields, nextProps.fields)) {
  //   console.log("schema changed")
  //   // console.log("shodn't change schema for",this.props.should_change_schema,nextProps.fields)
  //   if (Array.isArray(nextProps.fields)) {
  //     this.setState({
  //       schema: nextProps.fields
  //     })
  //   }
  // }

  // }

  // componentDidUpdate()

  componentDidUpdate(prevProps) {
    // if (!lodash.isEqual(this.props.initialData, prevProps.initialData)) {
    //   console.log("NEW INITIAL DATA", nextProps.initialData, this.props.initialData, Object.keys(nextProps.initialData).length)
    //   if (nextProps.initialData && Object.keys(nextProps.initialData).length >= 0) {
    //     this.setState({ data: nextProps.initialData });
    //   }
    // }
    console.log("schema comapared", this.props.fields, prevProps.fields)
    if (!lodash.isEqual(this.props.fields, prevProps.fields)) {
      console.log("schema changed", this.props.fields, prevProps.fields)
      // console.log("shodn't change schema for",this.props.should_change_schema,nextProps.fields)
      if (Array.isArray(this.props.fields)) {
        this.setState({
          schema: this.props.fields
        })
      }
    }

  }

}

export default FormEditorsContainer;

export const LeftFormContainerWrapper = ({ className = '', children, style }) => {
  return <div className={`rl-column ${className}`} style={{
    justifyContent: 'space-between',
    ...style
    // paddingBottom:8
    // width:'100%'
  }}>
    {children}
  </div>
}