import React, { Component } from 'react';
import { DownOutlined, MoreOutlined, MailOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Menu, Dropdown } from 'antd';

let title = "";
class CompanyDetailCard extends Component {
    constructor(props) {
        super(props)

    }

    render() {
        title = this.props.title;
        let key = this.props.slug;


        return (


            <div className="company-detail-container">

                <div className="company-detail-avatar-container">
                    <img className="company-detail-avatar" src={this.props.avatar !== null ? this.props.avatar : null} />
                </div>

                <div className="company-detail-content-container">
                    <div className="company-detail-content">

                        <span className="company-detail-content-header">

                            <div className="company-detail-content-title">
                                {title}
                            </div>

                            <div className="company-detail-content-subtitle">
                                {this.props.subtitle}
                            </div>

                        </span>

                        <span className="company-detail-content-menu">
                            <Dropdown overlay={this.props.menu} trigger={['click']}>
                                <MoreOutlined />
                            </Dropdown>
                        </span>
                    </div>

                    <div className="company-detail-action">
                        <span className="company-detail-action-button">
                            <Button
                                loading={this.props.loading ?? false}
                                className={this.props.submitStyle ?? "company-detail-action-button-open"}
                                onClick={this.props.onClick}
                            >
                                {this.props.actionName ?? "Open"}
                            </Button>
                        </span>

                        <span className="company-detail-info">
                            {
                                this.props.detailInfo ?? null
                            }
                        </span>

                    </div>

                </div>

            </div>

            // <div className={`expandable-card`}>
            //     <div className='header' style={{
            //         display: 'flex',
            //         flexDirection: 'row',
            //         flexWrap: 'nowrap',
            //         justifyContent: 'space-between'
            //     }}>
            //         <div className='section-title'>{title}</div>
            //         <RoundedIconButton direction={expanded ? 'right' : 'down'} onClick={() => {
            //             this.setState({
            //                 expanded: !expanded
            //             })
            //         }} />
            //     </div>
            //     <Transition
            //         native
            //         items={expanded}
            //         from={{ overflow: 'hidden', height: 0 }}
            //         enter={[{ height: 'auto' }]}
            //         leave={{ height: 0 }}>
            //         {show =>
            //             show && (props => <animated.div style={props}>
            //                 {this.props.children}
            //             </animated.div>
            //             )
            //         }
            //     </Transition>
            //     {/* <div className='expandable-card-body'>
            //         {this.props.children}
            //     </div> */}
            // </div>
        );
    }

    componentWillReceiveProps(nextProps) {
        // Any time props.email changes, update state.
        if (nextProps.expanded !== this.props.expanded) {
            this.setState({
                expanded: nextProps.expanded
            });
        }
    }
}

export default CompanyDetailCard;
