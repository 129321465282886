import React,{useState} from 'react'
import SmallForm from "../components/forms/small_form";
import {RESTExecutor, withDomains} from "../lib/domain";

const ChangePasswordPage = ({
    domains,
    ...props
}) => {
    let edit_user = RESTExecutor.post()
        .config({
            label:'change_password',
        })
        .callbacks(
            (success)=>{
                props.history.goBack()
            }
        )
        .connect(domains.password)


    const [formData,setFormData] = useState({

    })


    return(
        <div>
            <SmallForm
                networkState={edit_user.response()}
                data={
                    formData
                }

                onChange={(val)=>{
                    setFormData({
                        ...formData,
                        ...val
                    })
                }}

                title="Change Password"
                schema={PASSWORD_SCHEMA}
                onSave={() => {
                    edit_user.execute(formData)
                }}
            />
        </div>
    )
}

const PASSWORD_SCHEMA =  [
    { label: "Current Password", type: 'password', index: 'old_password', required: true },
    { label: "New Password", type: 'password', index: 'new_password', required: true },
    { label: "Confirm New Password", type: 'password', index: 'confirm_password', required: true },

]

export default withDomains(ChangePasswordPage,'password')