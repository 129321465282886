import React, { useState, useEffect } from 'react'
import SmallForm from "../../components/forms/small_form";
import { RESTExecutor, withDomains } from "../../lib/domain";
import { Link } from "react-router-dom";
import { Form, Input, Button, Checkbox, message, Modal } from "antd";
import CryptoJS from 'crypto-js';

const key = '981239812312312323';

function loadFromSessionStorage(data) {
    try {
        const serializedState = sessionStorage.getItem(data);
        if (serializedState === null) {
            return undefined;
        }
        const decrypted = CryptoJS.AES.decrypt(serializedState, key).toString(CryptoJS.enc.Utf8);
        return JSON.parse(decrypted);
    } catch {
        return undefined;
    }
}

function saveToSessionStorage(state) {
    try {
        const serializedState = JSON.stringify(state);
        const encrypted = CryptoJS.AES.encrypt(serializedState, key);
        sessionStorage.setItem('regId', encrypted)
    } catch (e) {
        console.log(e)
    }
}

const VerifyCodePage = ({
    domains,
    ...props
}) => {


    let { id, email } = loadFromSessionStorage('regId') || {};

    let register_user = RESTExecutor.post()
        .config({
            label: 'register',
        })
        .callbacks(
            (success) => {
                saveToSessionStorage({ 'id': success.result.id, 'email': email });
                message.success("Your code has been sent again. Please check your inbox or spam just in case.")
            },
            (error) => {
                console.log(error);
                error.error_message && message.error(error.error_message);
            }
        )
        .connect(domains.register)

    let verify_code = RESTExecutor.post()
        .config({
            label: 'verify_code',
        })
        .callbacks(
            (success) => {
                props.history.push({ pathname: `/sign-up` })
            },
            (error) => {
                console.log(error.validation_errors);
                error.error_message && message.error(error.error_message);
                error.validation_errors && message.error(error.validation_errors['id']);
            }
        )
        .connect(domains.register)


    let verify_resp = verify_code.response();

    const [termsChecked, setTermsChecked] = useState(false);
    const [policyChecked, setPolicyChecked] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [isTermsModalVisible, setIsTermsModalVisible] = useState(false);
    const [isPolicyModalVisible, setIsPolicyModalVisible] = useState(false);

    const showTermsModal = (value) => {
        setIsTermsModalVisible(value);
    };
    const showPolicyModal = (value) => {
        setIsPolicyModalVisible(value);
    };

    const onTermsCheckboxChange = () => {
        if (termsChecked) {
            setTermsChecked(false);
        } else {
            showTermsModal(true)
        }
    };
    const onPolicyCheckboxChange = () => {
        if (policyChecked) {
            setPolicyChecked(false)
        } else {
            showPolicyModal(true)
        }
    };

    useEffect(() => {
        // action on update of checkbox
        if (termsChecked && policyChecked) {
            setSubmitDisabled(false);
        } else {
            setSubmitDisabled(true);
        }


        // const comp = document.getElementsByClassName("terms-modal")[0].childNodes[1].childNodes[2]
        // const isBottom = comp.scrollHeight - comp.scrollTop === comp.clientHeight;
        // console.log(comp.scrollHeight, comp.scrollTop, comp.clientHeight);
        // setBottom(isBottom);
        // console.log(bottom);

    }, [termsChecked, policyChecked]);



    const onFinish = (formData) => {
        let data = { ...formData, id: id };
        verify_code.execute(data);
    };

    const [bottom, setBottom] = useState(false);


    return (
        <div>
            <div className='container'>
                <div className="header-menu">
                    < img src='images/logo.svg' className='header-menu-item' />
                    <span className='header-menu-item'>
                        Already have an account?
                        <a onClick={() => { props.history.push('/') }}>
                            &nbsp;Log In
                        </a>
                    </span>
                </div>
            </div>
            <div className="main-container">

                <Modal className="terms-modal" id="terms-modal"
                    maskClosable={false}
                    title="Terms of Service" visible={isTermsModalVisible} onCancel={() => { showTermsModal(false) }} bodyStyle={{ height: 500, backgroundColor: "#EEF2F7", margin: '20px', overflow: 'auto' }}
                    footer={[
                        <Button key="submit" type="primary" onClick={() => {
                            setTermsChecked(true);
                            setIsTermsModalVisible(false);
                        }}>
                            Accept and Continue
                        </Button>,
                        <Button key="submit" onClick={() => {
                            showTermsModal(false);
                        }}>
                            I do not accept
                        </Button>
                    ]}>

                    <p>B.I.C. TECHNOLOGY PRIVATE LIMITED AND/OR ITS AFFILIATES (“BIC”) IS WILLING TO GRANT ACCESS TO THE SAAS PRODUCTS TO YOU AS THE COMPANY OR THE LEGAL ENTITY THAT WILL BE UTILIZING THE SAAS PRODUCTS (REFERENCED BELOW AS “CUSTOMER”) ON THE CONDITION THAT YOU ACCEPT ALL OF THE TERMS OF THIS AGREEMENT (AS DEFINED BELOW). BY ENTERING INTO THIS AGREEMENT ON BEHALF OF AN ENTITY OR ORGANIZATION, YOU REPRESENT THAT YOU HAVE THE LEGAL AUTHORITY TO BIND THAT ENTITY OR ORGANIZATION TO THIS AGREEMENT. CUSTOMER AND BIC MAY EACH ALSO BE REFERRED TO AS A “PARTY” AND TOGETHER, THE “PARTIES”.</p>

                    <p>PLEASE READ THIS AGREEMENT CAREFULLY BEFORE USING THE SAAS PRODUCTS. THIS TERMS OF SERVICE (“AGREEMENT”) CONSTITUTES A LEGAL AND ENFORCEABLE CONTRACT BETWEEN THE CUSTOMER AND BIC. BY INDICATING CONSENT ELECTRONICALLY, OR ACCESSING OR OTHERWISE USING THE SAAS PRODUCTS, THE CUSTOMER AGREES TO THE TERMS AND CONDITIONS OF THIS AGREEMENT. IF THE CUSTOMER DOES NOT AGREE TO THIS AGREEMENT, DO NOT INDICATE CONSENT ELECTRONICALLY AND MAKE NO FURTHER USE OF THE SAAS PRODUCTS.</p>
                    <p>&nbsp;</p>

                    <h1><strong>DESCRIPTION OF SERVICE</strong></h1>
                    <p>We provide Cloud-Based business process management solutions like Accounting, CRM, Payroll and productivity tools including task managers, Document management Etc. You may use the Services for your personal and business use or for internal business purposes in the organization that you represent. You may connect to the Services using any Internet browser supported by the Services. You are responsible for obtaining access to the Internet and the equipment necessary to use the Services.</p>

                    <h1><strong>SUBSCRIPTIONS & RENEWALS</strong></h1>
                    <p>The customer shall pay a subscription fee in advance without any deduction or set-off (except for Tax withholding if applicable ) INVOICE will be generated only after payment of the subscription fee in advance.  </p>

                    <p>Without prejudice to Customer’s rights set out elsewhere in this Agreement, all subscription fees are non-refundable and payable in advance.</p>

                    <p>From time to time, we may change the price of any Service or charge for use of Services that are currently available free of charge. Any increase in charges will not apply until the expiry of your then-current billing cycle. You will not be charged for using any Service unless you have opted for a paid subscription plan.</p>

                    <p>In order to provide continuous service, we automatically renew all paid subscriptions for the Services on the date such subscriptions expire. Such renewals are for the same duration as the original subscription term (for example, a 1-year subscription will renew on a yearly basis). By entering into this Agreement, you acknowledge that your account will be subject to the above-described automatic renewals. In all cases, if you do not wish your account to renew automatically, please contact BIC'S support before the expiration of such a subscription.</p>

                    <p>Except as otherwise stated in this section, you may cancel your paid subscription at any time during the term of such subscription or any renewal period by completely cancelling the account.</p>

                    <p>In such a case, your subscription will terminate, and you will not receive any refund for any unused days of such subscription term.</p>

                    <h1><strong>REGISTRATION DATA AND PRIVACY</strong></h1>
                    <p>In order to access some of the services, you will be required to use an account and password that can be obtained by completing our online registration form, which requests certain information and data ("Sign Up"), and maintaining and updating your Registration Data as required. By registering, you agree that all information provided in the Registration Data is true and accurate and that you will maintain and update this information as required in order to keep it current, complete, and accurate.</p>

                    <p>You also grant us the right to disclose to third parties certain Registration Data about you. The information we obtain through your use of this site, including your Registration Data, is subject to our Privacy Policy, which is specifically incorporated by reference into these Terms of Use.</p>
                    <h1><strong>ORGANIZATION ACCOUNTS AND ADMINISTRATORS</strong></h1>
                    <p>When you sign up for an account for your organization you may specify one or more administrators. The administrators will have the right to configure the Services based on your requirements and manage end users in your organization account. If your organization account is created and configured on your behalf by a third party, it is likely that such a third party has assumed an administrator role for your organization. Make sure that you enter into a suitable agreement with such a third party specifying such party’s roles and restrictions as an administrator of your organization account.</p>

                    <p>You are responsible for:</p>
                    <p> i) ensuring confidentiality of your organization account password,</p>
                    <p> ii) appointing competent individuals as administrators for managing your organization account, and</p>
                    <p> iii) ensuring that all activities that occur in connection with your organization account comply with this Agreement.</p>
                    <p>You understand that BIC is not responsible for account administration and internal management of the Services for you. You are responsible for taking the necessary steps for ensuring that your organization does not lose control of the administrator accounts.</p>


                    <h1><strong>DATA OWNERSHIP</strong></h1>
                    <p>We respect your right to ownership of content created or stored by you. You own the content created or stored by you. Unless specifically permitted by you, your use of the Services does not grant BIC the license to use, reproduce, adapt, modify, publish or distribute the content created by you or stored in your user account for BIC’s commercial, marketing or any similar purpose. But you grant BIC permission to access, copy, distribute, store, transmit, reformat, publicly display and publicly perform the content of your user account solely as required for the purpose of providing the Services to you.</p>

                    <h1><strong>TRADEMARK</strong></h1>
                    <p>BIC, TIGG, BIC logo, TIGG logo the names of individual Services and their logos are trademarks of B.I.C. Technology. You agree not to display or use, in any manner, the BIC and TIGG trademark, without BIC’s prior permission.</p>


                    <h1><strong>CONFIDENTIAL INFORMATION </strong></h1>
                    <p>The Parties acknowledge that each may disclose certain valuable confidential and proprietary information to the other Party. The receiving Party may only use the disclosing Party’s Confidential Information to fulfil the purposes of this Agreement. The receiving Party will protect the disclosing Party’s Confidential Information by using at least the same degree of care as the receiving Party uses to protect its own Confidential Information of a like nature (but no less than a reasonable degree of care) to prevent the unauthorized use, dissemination, disclosure or publication of such Confidential Information. Notwithstanding the foregoing, the receiving Party may disclose Confidential Information to its (and its Affiliates) employees, advisors, consultants, and agents on a need-to-know basis and provided that such party is bound by obligations of confidentiality substantially similar to those contained herein.</p>

                    <h1><strong>DISCLOSURE OF INFORMATION</strong></h1>
                    <p>You own all of your business’s private data, content and all information which you enter and use in connection with the Services. We do not claim any rights, proprietary or otherwise over any data or information which you may use or disclose in connection with the Service and Wave. Notwithstanding the above, there may be circumstances in which we may be required to disclose data, such as the following:</p>

                    <p>For the purposes of fraud prevention and law enforcement;</p>
                    <p>i)To comply with any legal, governmental or regulatory requirement;</p>
                    <p>ii)To our lawyers in connection with any legal proceedings; and</p>
                    <p>iii)To comply with a court order.</p>

                    <p>If we are required to disclose your data or information, we will use our best efforts to provide you with reasonable notice in the circumstances and if appropriate the right to challenge any such request.</p>

                    <h1><strong>FORCE MAJEURE</strong></h1>
                    <p>Neither party should be held liable for a delay or failure in performance of the agreement for services caused by reason of any occurrence of unforeseen event beyond its reasonable control, including but not limited to, acts of God, natural disasters, power failures, server failures, third party service provider failures or service interruptions, embargo, labour disputes, lockouts and strikes, riots, war, floods, insurrections, legislative changes, and governmental actions. The party so affected by the unforeseen event shall be so excused on a day-to-day basis for the period of time equal to that of the underlying cause of delay.</p>

                    <h1><strong>RESTRICTIOINS ON USE</strong></h1>
                    <p>You agree to be solely responsible for the contents of your transmissions through the Services. You agree not to use the Services for illegal purposes or for the transmission of material that is unlawful, defamatory, harassing or may infringe intellectual property.</p>

                    <p>We reserve the right to terminate your access to the Services if there are reasonable grounds to believe that you have used the Services for any illegal or unauthorized activity.</p>


                    <h1><strong>TERMS AND TERMINATION</strong></h1>

                    <p>This Agreement is effective as of the Effective Date and continues until expiration of all subscription Terms, unless earlier terminated as set forth herein.</p>

                    <p>You may choose to stop using the Software and terminate this Agreement at any time for any reason upon written notice to BIC, but you will not be entitled to a refund of any pre-paid fees and if you have not already paid all applicable fees for the then-current subscription Term or related services period (as applicable), any such fees that are outstanding will become immediately due and payable.</p>

                    <h1><strong>DISCLAIMER OF WARRANTIES</strong></h1>
                    <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE USE OF THE SERVICES IS AT YOUR SOLE RISK. THE SERVICES ARE PROVIDED ON AN AS-IS-AND-AS-AVAILABLE BASIS. BIC EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. BIC MAKES NO WARRANTY THAT THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE. USE OF ANY MATERIAL DOWNLOADED OR OBTAINED THROUGH THE USE OF THE SERVICES SHALL BE AT YOUR OWN DISCRETION AND RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM, MOBILE TELEPHONE, WIRELESS DEVICE OR DATA THAT RESULTS FROM THE USE OF THE SERVICES OR THE DOWNLOAD OF ANY SUCH MATERIAL. NO ADVICE OR INFORMATION, WHETHER WRITTEN OR ORAL, OBTAINED BY YOU FROM BIC, ITS EMPLOYEES OR REPRESENTATIVES SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE TERMS.</p>

                    <h1><strong>INDEMNIFICATION</strong></h1>
                    <p>Upon a request by us, you agree to defend, indemnify, and hold us and our Affiliates harmless from all liabilities, claims, and expenses, including attorneys fees, that arise from your use or misuse of this site or service. We reserve the right, at our own expense, to assume the exclusive defence and control of any matter otherwise subject to indemnification by you, in which event you will cooperate with us in asserting any available defences.</p>

                    <h1><strong>END OF TERMS OF SERVICE</strong></h1>
                    <p>If you have any questions or concerns regarding this Agreement, please contact us at hello@tiggapp.com.</p>
                </Modal>


                <Modal className="policy-modal" id='policy-modal'
                    maskClosable={false}
                    title="Privacy Policy" visible={isPolicyModalVisible} onCancel={() => { showPolicyModal(false) }} bodyStyle={{ height: 500, backgroundColor: "#EEF2F7", margin: '20px', overflow: 'auto' }}
                    footer={[
                        <Button key="submit" type="primary" onClick={() => {
                            setPolicyChecked(true);
                            setIsPolicyModalVisible(false);
                        }}>
                            Accept and Continue
                        </Button>,
                        <Button key="submit" onClick={() => {
                            showPolicyModal(false);
                        }}>
                            I do not accept
                        </Button>
                    ]}>

                    <h1>Introduction:</h1>

                    <p>This Privacy Policy covers the information we collect about you when you use our products or services, or otherwise interact with us.  </p>

                    <p>Our products and services are offered by <strong>B.I.C. Technology Private Limited</strong><strong> </strong> (<strong>BIC</strong>” or “<strong>we</strong>”), a Nepali Company (Reg No.240710/077/078).</p>

                    <p>This privacy policy applies to:</p>

                    <ul>
                        <li>tiggapp.com or tigg.app and its subdomains (the “Site”);</li>
                        <li>our mobile apps that incorporate this privacy policy; and</li>
                        <li>products and services branded with the “TIGG” or “BIC” name (“Services”).</li>
                    </ul>

                    <p>We treat your personal information with the same respect we want our own to be treated with—and when you deal with finances like we do, there’s a lot of personal information involved. Here’s a list of exactly what we collect.</p>

                    <h1>What information we collect about you</h1>
                    <p>We collect information about you when you provide it to us, when you use our Services, and when other sources provide it to us, as further described below.    </p>

                    <p><strong>Account and Profile Information:</strong> We collect information about you when you register for an account, create or modify your profile, set preferences. We keep track of your preferences when you select settings within the Services.</p>

                    <p><strong>Content you provide through our products:</strong> The Services include the BIC’s products you use This content includes any information about you that you may choose to include. </p>
                    <p>we collect content using analytics techniques that hash, filter or otherwise scrub the information to exclude information that might identify you or your organization; and we collect clickstream data about how you interact with and use features in the Services.</p>

                    <p><strong>Content you provide through our websites:</strong> The Services also include our websites owned or operated by us. We collect other content that you submit to these websites, which include social media or social networking websites operated by us.</p>

                    <p><strong>Information you provide through our support channels:</strong> The Services also include our customer support, where you may choose to submit information regarding a problem you are experiencing with a Service.</p>

                    <p><strong>Payment Information:</strong>  We collect payment and billing information when you register for certain paid Services.</p>
                    <p>    </p>
                    <p><strong>Device and Connection Information:</strong> We collect information about your computer, phone, tablet, or other devices you use to access the Services. This device information includes your connection type and settings when you install, access, update or use our Services. We also collect information through your device about your operating system, browser type, IP address, URLs of referring/exit pages, device identifiers, and crash data. </p>

                    <p><strong>Cookies and Other Tracking Technologies:</strong> We use cookies and other tracking technologies to provide functionality and to recognize you across different Services and devices. </p>

                    <p><strong>Information we receive from other sources</strong></p>

                    <p>We receive information about you from other Service users, from third-party services, from our related companies, social media platforms, public databases, and from our business and channel partners. We may combine this information with information we collect through other means described above. This helps us to update and improve our records, identify new customers, create more personalized advertising and suggest services that may be of interest to you. </p>

                    <p><strong>BIC’s Partners:</strong>  We work with a network of partners who provide consulting, implementation, training and other services around our products.  Some of these partners also help us to market and promote our Services, generate leads for us, and resell our Services.  We receive information from these partners, such as billing information, billing and technical contact information, company name, what BIC’s Services you have purchased or may be interested in, evaluation information you have provided. </p>

                    <p><strong>Other Partners: </strong>We receive information about you and your activities on and off the Services from third-party partners, such as advertising and market research partners who provide us with information about your interest in and engagement with, our Services and online advertisements.   </p>


                    <h1>How we use information we collect</h1>
                    <p>How we use the information we collect depends in part on which Services you use, how you use them, and any preferences you have communicated to us.  Below are the specific purposes for which we use the information we collect about you.</p>

                    <p><strong>To provide the Services and personalize your experience:</strong> We use information about you to provide the Services to you, including to process transactions with you, authenticate you when you log in, provide customer support, and operate, maintain, and improve the Services.  </p>

                    <p><strong>For research and development:</strong>  We are always looking for ways to make our Services smarter, faster, secure, integrated, and useful.  We use information and collective learnings (including feedback) about how people use our Services to troubleshoot, to identify trends, usage, activity patterns, and areas for integration and to improve our Services and to develop new products, features and technologies that benefit our users and the public. We also test and analyze certain new features with some users before rolling the feature out to all users.  </p>

                    <p><strong>To communicate with you about the Services:</strong> We use your contact information to send transactional communications via email and within the Services, including confirming your purchases, reminding you of subscription expirations, responding to your comments, questions and requests, providing customer support, and sending you technical notices, updates, security alerts, and administrative messages.  We send you email notifications when you or others interact with you on the Services.</p>

                    <p><strong>To market, promote and drive engagement with the Services:</strong> We use your contact information and information about how you use the Services to send promotional communications that may be of specific interest to you, including by email and by displaying BIC’s ads on other companies' websites and applications. </p>

                    <p><strong>Customer support:</strong> We use your information to resolve technical issues you encounter, to respond to your requests for assistance, to analyze crash information, and to repair and improve the Services. Where you give us express permission to do so, we share information with a third party expert for the purpose of responding to support-related requests.</p>

                    <p><strong>For safety and security: </strong>We use information about you and your Service use to verify accounts and activity, to detect, prevent, and respond to potential or actual security incidents and to monitor and protect against other malicious, deceptive, fraudulent or illegal activity. </p>

                    <p><strong>To protect our legitimate business interests and legal rights:</strong> Where required by law or where we believe it is necessary to protect our legal rights, interests and the interests of others, we use information about you in connection with legal claims, compliance, regulatory, and audit functions.  </p>

                    <h1>How we share information we collect</h1>
                    <p>We share information we collect about you in the ways discussed below. We are not in the business of selling information about you to advertisers or other third parties.</p>

                    <p><strong>Sharing with other Service users </strong></p>

                    <p>When you use the Services, we share certain information about you with other Service users.</p>

                    <p><strong>For collaboration:</strong> You can create content, which may contain information about you, and grant permission to others to see, share, edit, copy and download that content based on settings you or your administrator (if applicable) select.</p>

                    <p><strong>Managed accounts and administrators:</strong> If you register or access the Services using an email address with a domain that is owned by your employer or organization or associate that email address with your existing account and such organization wishes to establish an account or site, certain information about you including your name, profile picture, contact info, content and past use of your account may become accessible to that organization’s administrator and other Service users sharing the same domain.  If you are an administrator for a particular organisation or group of users within the Services, we may share your contact information with current or past Service users, for the purpose of facilitating Service-related requests.  </p>


                    <p><strong>Sharing with third parties</strong></p>

                    <p>We share information with third parties that help us operate, provide, improve, integrate, customize, support and market our Services.</p>

                    <p><strong>Service Providers:</strong> We work with third-party service providers to provide website and application development, hosting, maintenance, backup, storage, virtual infrastructure, payment processing, analysis and other services for us, which may require them to access or use information about you.  If a service provider needs to access information about you to perform services on our behalf, they do so under close instruction from us, including appropriate security and confidentiality procedures designed to protect your information.</p>

                    <p><strong>Partners:</strong> We work with third parties who provide consulting, sales, support, and technical services to deliver and implement customer solutions around the Services. We may share your information with these third parties in connection with their services, such as to assist with billing and collections, to provide localized support, and to provide customizations.  We may also share information with these third parties where you have agreed to that sharing.</p>

                    <p><strong>With your consent: </strong>We share information about you with third parties when you give us consent to do so.  For example, we often display personal testimonials of satisfied customers on our public websites. With your consent, we may post your name alongside the testimonial. </p>

                    <p><strong>Sharing with affiliated companies</strong></p>

                    <p>We share information we collect with affiliated companies and, in some cases, with prospective affiliates.  Affiliated companies are companies owned or operated by us.  The protections of this privacy policy apply to the information we share in these circumstances.</p>

                    <p><strong>Business Transfers:</strong> We may share or transfer information we collect under this privacy policy in connection with any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company. You will be notified via email and/or a prominent notice on the Services if a transaction takes place, as well as any choices you may have regarding your information.</p>

                    <p></p>
                    <h1>How we store and secure information we collect</h1>

                    <p><strong>Information storage and security</strong></p>

                    <p>We use industry-standard technical and organizational measures to secure the information we store. While we implement safeguards designed to protect your information, no security system is impenetrable and due to the inherent nature of the Internet, we cannot guarantee that information, during transmission through the Internet or while stored on our systems or otherwise in our care, is absolutely safe from intrusion by others.</p>


                    <p><strong>How long we keep information</strong></p>

                    <p>How long we keep information we collect about you depends on the type of information, as described in further detail below.  After such time, we will either delete or de-identify your information or, if this is not possible (for example, because the information has been stored in backup archives), then we will securely store your information and isolate it from any further use until deletion is possible.  </p>
                    <p></p>

                    <h1>Changes to our Privacy Policy</h1>

                    <p>We may change this privacy policy from time to time. We will post any privacy policy changes on this page and, if the changes are significant, we will provide a more prominent notice by sending you an email notification. We encourage you to review our privacy policy whenever you use the Services to stay informed about our information practices and the ways you can help protect your privacy.</p>

                    <p>If you disagree with any changes to this privacy policy, you will need to stop using the Services and deactivate your account(s), as outlined above.</p>

                    <p>Your information is controlled by B.I.C. Technology Private Limited.  If you have questions or concerns about how your information is handled, please direct your inquiry to B.I.C. Technology Private Limited, which we have appointed to be responsible for facilitating such inquiries.</p>

                    <p>B.I.C. Technology Private Limited</p>
                    <p>Thashikhel-14, Lalitpur</p>
                    <p>E-Mail: hello@tiggapp.com</p>

                </Modal>


                <div className="container">
                    <div className="form-container">
                        <img src="images/logo2.svg" className="form-logo" />
                        <p className="form-header" style={{ paddingBottom: "3%" }}>Email Verification Code</p>

                        < Form
                            name="verifyCode"
                            className="login-form"
                            onFinish={
                                onFinish
                            }
                        >
                            <Form.Item
                                name="code"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input the verification code or request a new one."
                                    }
                                ]}
                            >
                                <Input
                                    style={{ "padding": "20px" }}
                                    placeholder="Verification Code"
                                />
                            </Form.Item>

                            <Form.Item
                                name="terms" style={{ margin: 0 }}>
                                <Checkbox checked={termsChecked} onChange={onTermsCheckboxChange}>
                                    I agree to the <a>Terms of Service</a>
                                </Checkbox>
                            </Form.Item>

                            <Form.Item
                                name="policy">
                                <Checkbox checked={policyChecked} onChange={onPolicyCheckboxChange}>
                                    I agree to the <a>Privacy Policy</a>
                                </Checkbox>
                            </Form.Item>

                            <Form.Item>
                                <Button
                                    id='submit'
                                    type="primary"
                                    loading={verify_resp?.fetching}
                                    size="large"
                                    disabled={submitDisabled}
                                    htmlType="submit"
                                    className="login-form-button">
                                    CONTINUE
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                Code didn't arrive? <a onClick={() => { register_user.execute({ 'email': email }) }}>Ask again</a>
                            </Form.Item>


                        </Form >
                    </div>
                </div>
            </div >
        </div >
    )
}


export default withDomains(VerifyCodePage, 'register')