import React, { useEffect, useState } from "react";
import { Form, Input, Button, Upload, message, DatePicker, Divider, Select, InputNumber, Modal } from "antd";
import { InfoCircleOutlined, UserOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { RESTExecutor, withDomains } from "../../lib/domain";
import axios from "axios";
import { Row, Col } from "antd";
import moment from "moment";
import { BASE_API_URL } from "../../config";
const { Option } = Select;






function checkImageWH(file, width, height, resolve, reject) {
    let filereader = new FileReader();
    filereader.onload = e => {
        let src = e.target.result;
        const image = new Image();
        image.onload = function () {
            if ((width && this.width < width) || (height && this.height < height)) {
                if ((width && this.width < height) || (height && this.height < width)) {
                    Modal.error({
                        title: 'The resolution should not be less than ' + width + '*' + height + 'px. Image could not be uploaded. Please select another image of higher resolution.'
                    })
                    reject()
                } else {
                    resolve();
                }
            } else {
                resolve();
            }
        };
        image.onerror = reject;
        image.src = src;
    };
    filereader.readAsDataURL(file);
}


const beforeUpload = (file) => {

    return new Promise(function (resolve, reject) {
        const isJPG = file.type === 'image/jpg';
        const isJPEG = file.type === 'image/jpeg';
        const isPNG = file.type === 'image/png';
        const bigLt2M = file.size / 1024 / 1024 < 2;
        if (!(isJPG || isJPEG || isPNG)) {
            Modal.error({
                title: 'Only upload images in JPG, JPEG, PNG format. Image could not be uploaded. Please select another image.',
            });
            reject()
        } else if (!bigLt2M) {
            Modal.error({
                title: 'Image must be smaller than 2MB. Image could not be uploaded. Please select another image of lower resolution.',
            });
            reject()
        } else {
            checkImageWH(file, 300, 300, resolve, reject)
        }

    })
}



function disabledDate(current) {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
}


const ProfileEditForm = ({
    domains,
    get_user,
    ...props }) => {

    //form instance
    const [Editform] = Form.useForm();

    const [imgPath, setImgPath] = useState('');



    let user_list = RESTExecutor.list()
        .config({
            label: 'me'
        }).callbacks(
            (success) => {
                let data = success.result;

                Editform.setFieldsValue({
                    email: data?.email,
                    name: data?.name,
                    phone_number: data?.phone_number,
                    date_of_birth: data?.date_of_birth == '' ? '' : moment(data.date_of_birth),
                    address: data?.address
                });

                let id = data.profile_picture_id;
                setImgPath(id);


                (data.profile_picture_id !== "" && data.profile_picture_id !== null) ? setFileList([{
                    name: "avatar",
                    uid: "1",
                    status: "done",
                    url: data.profile_picture_id_url
                }]) : setFileList([{
                    name: "avatar",
                    uid: "1",
                    status: "done",
                    url: `https://ui-avatars.com/api/?name=${data.name.split(" ")[0]}+${data.name.split(" ")[1]}&background=1ED760&color=fff`
                }]);


            }
        ).connect(domains.user)

    let edit_user = RESTExecutor.post()
        .config({
            label: 'edit'
        }).callbacks(
            (success) => {
                message.success("Profile Updated successfully");
                get_user.execute(true);
                user_list.execute(true)
            }
        )
        .connect(domains.user)


    useEffect(() => {
        user_list.execute(true)
    }, [])


    let list_resp = user_list.response().result || {};
    let edit_resp = edit_user.response();

    const [fileList, setFileList] = useState([]);
    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    const onPreview = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    const uploadImage = async options => {
        const { onSuccess, onError, file, onProgress } = options;

        const config = {
            headers: { 'Accept': 'binary/octet-stream', "content-type": "multipart/form-data", "Authorization": `Bearer ${props.authenticator.getTokens().access_token}` },
        };
        const config2 = {
            headers: { 'Accept': 'binary/octet-stream', "content-type": "multipart/form-data" },
        };
        try {
            const res = await axios.post(
                `${BASE_API_URL}/generate-upload-url`,
                // fmData,
                { "collection": "UserProfile" },
                config
            );

            setImgPath(res.data.data.key);

            try {
                const res2 = await axios.put(
                    res.data.data.url,
                    file,
                    config2
                );
                onSuccess("OK");

            } catch (err) {
                console.log("Error: ", err);
                const error = new Error("Some error");
                onError({ err });
            }

        } catch (err) {
            console.log("Eroor: ", err);
            const error = new Error("Some error");
            onError({ err });
        }
    };


    const dateFormat = 'DD-MM-YYYY';

    const onFinish = (values) => {
        values.date_of_birth = (values.date_of_birth == '') ? "" : values.date_of_birth.format('DD-MM-YYYY');
        values.profile_picture_id = imgPath;
        edit_user.execute(values)
    }

    let { validation_errors = {}, error_message } = edit_user.response()
    let validateStatus = Object.keys(validation_errors || {}).length > 0 ? 'error' : ''


    return (
        <div className="edit-form-container">
            <p className="edit-form-header">Edit Profile</p>
            <Form
                form={Editform}
                title="Edit Profile"
                onFinish={onFinish}
                requiredMark={false}
            >
                <Row>
                    <Col span={16} className="edit-form-input-content">


                        <Form.Item
                            name="email"
                            label="Email Address"
                            labelCol={{ span: 24 }}
                            initialValue={list_resp.email}
                        >
                            <Input
                                disabled={true}
                                style={{ "padding": "20px" }}
                            />
                        </Form.Item>

                        <Form.Item
                            name="name"
                            label="Full Name"
                            labelCol={{ span: 24 }}
                            initialValue={list_resp.name}
                            rules={[
                                {
                                    required: true,
                                    message: "Your name cannot be empty",
                                    validateTrigger: onchange,
                                    validateStatus: 'error'
                                }
                            ]}

                        >
                            <Input
                                style={{ "padding": "20px" }}
                            />
                        </Form.Item>

                        <Form.Item
                            name="phone_number"
                            label="Phone Number"
                            labelCol={{ span: 24 }}
                            initialValue={list_resp.phone_number}
                        >
                            < Input
                                style={{ "padding": "20px" }}
                                placeholder="Phone Number"
                            />

                        </Form.Item>


                        <Form.Item
                            name="date_of_birth"
                            label="Date of Birth"
                            labelCol={{ span: 24 }}
                            initialValue={list_resp.date_of_birth ? moment(list_resp.date_of_birth) : null}
                        >
                            <DatePicker className="form-datepicker"
                                format={dateFormat}
                                placeholder="dd-mm-yyyy"
                                disabledDate={disabledDate}
                            />
                        </Form.Item>


                        <Form.Item
                            name="address"
                            label="Address"
                            labelCol={{ span: 24 }}
                            initialValue={list_resp.address}
                        >
                            <Input.TextArea
                                style={{ "padding": "20px" }}
                                placeholder="Address"
                            />
                        </Form.Item>

                    </Col>
                    <Col span={2} className="edit-form-spacing">
                    </Col>
                    <Col span={6} className='profile-picture-avatar-item'>

                        <Form.Item
                            name="profile_picture_id"
                            label="Profile Picture"
                            labelCol={{ span: 24 }}
                        >
                            <ImgCrop
                                aspect={1024 / 1024}
                            >
                                <Upload
                                    className="edit-form-upload"
                                    name="pic"
                                    listType="picture-card"
                                    fileList={fileList}
                                    onChange={onChange}
                                    onPreview={onPreview}
                                    beforeUpload={beforeUpload}
                                    onRemove={() => {
                                        setImgPath('')
                                    }}
                                    maxCount={1}
                                    customRequest={uploadImage}
                                >
                                    {fileList < 1 && <UserOutlined />}
                                </Upload>
                            </ImgCrop>
                            <Col span={14} style={{ marginBottom: '24px', fontFamily: 'Inter', fontSize: '12.8px', color: '#91979F' }}>
                                <InfoCircleOutlined />
                                &nbsp;image size should be minimum 300px by 300px
                            </Col>
                        </Form.Item>

                    </Col>
                </Row>
                <Divider />

                <div style={{ display: 'flex', flexDirection: "row", flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Form.Item>
                        <Button
                            loading={edit_resp?.fetching}
                            size="large"
                            htmlType="submit" className="login-form-button">
                            Update Profile
                        </Button>
                    </Form.Item>
                    <Col style={{ marginBottom: '24px', fontFamily: 'Inter', fontSize: '12.8px', color: '#91979F' }}>
                        <InfoCircleOutlined />
                        &nbsp;Changes to account settings will apply to to all of your associated organization
                    </Col>
                </div>
            </Form>
        </div>
    );
}

export default withDomains(ProfileEditForm, 'fileUpload', 'user');
