import React, { useState } from 'react';
import { Button, Dropdown, Menu, Select } from 'antd';
import { DownOutlined, UserOutlined, RightOutlined } from '@ant-design/icons';
import { ArrowIcon } from '../../assets/icons';
import { AccountSelect } from './select';


export const SmallRoundedIcon = ({ direction = 'right', onClick, disabled = false, hint, icon = <ArrowIcon />, size = '', type = '', filled = false, style }) => {
    return <div
        tabIndex={'0'}
        onKeyDown={(e) => {
            if (e.keyCode == 13) {
                if (!disabled) {
                    typeof onClick === 'function' && onClick()
                }
            }
        }}
        title={hint}
        onClick={() => {
            if (!disabled) {
                typeof onClick === 'function' && onClick()
            }
        }}
        className={`small-rounded-icon-btn  
        small-icon-btn-${direction}
        ${type}
        ${filled ? 'filled' : ''}
    ${disabled ? 'disabled' : ''}
    ${size}
    `} style={{
            ...style
        }}>
        {icon}
    </div>
}

export const RoundedIconButton = ({ direction = 'right', onClick, disabled = false, hint, icon = <ArrowIcon />, size = '' }) => {
    return <div
        title={hint}
        onClick={() => {
            if (!disabled) {
                typeof onClick === 'function' && onClick()
            }
        }}
        className={`rounded-icon-btn  rounded-icon-btn-${direction}
    ${disabled ? 'disabled' : ''}
    ${size}
    `}>
        {icon}
    </div>
}

export const TransparentButton = ({
    label,
    icon,
    type = 'default',
    disabled,
    onClick,
    hint,
    style,
    className = '',
    ignoreDefault
}) => {
    return <div
        tabIndex={'0'}
        onKeyDown={(e) => {
            if (e.keyCode == 13) {
                if (!disabled) {
                    typeof onClick === 'function' && onClick()
                }
            }
        }}
        title={hint}
        onClick={(e) => {
            if (!ignoreDefault) {
                e.preventDefault();
                e.stopPropagation();
            }
            if (!disabled) {
                typeof onClick === 'function' && onClick()
            }
        }}
        disabled={disabled ? 'disabled' : ''}
        className={`wrap-rl-row 
    hand-cursor 
    transparent-btn 
    transparent-btn-${type}
    ${className}
    ${disabled ? 'no-hand-cursor' : ''}

    `}
        style={{
            opacity: disabled ? 0.6 : 1,
            ...style
        }}
    >
        {icon != null ? React.cloneElement(icon, {
            className: `transparent-btn-icon ${icon.props.className}`
        }) : null}
        {label &&
            <div className='button-text transparent-btn-label' style={{
                marginLeft: icon == null ? 0 : 8,
            }}>{label}</div>
        }
    </div>
}

export const CustomButton = ({
    label = "SAVE",
    type = "primary",
    filled = false,
    disabled = false,
    onClick,
    loading,
    iconLeft, iconRight, style, block, size = '',
    ...props
}) => {
    return <Button
        {...props}
        loading={loading}
        block={block}
        disabled={disabled}
        style={{
            ...style,
        }}
        onClick={() => {
            typeof onClick === 'function' && onClick()
        }}
        className={`custom-btn custom-btn-${type} ${size} custom-btn-${type}-${filled ? 'filled' : ''}`}
    >
        {iconLeft != null ? React.cloneElement(iconLeft, {
            className: 'transparent-btn-icon'
        }) : null}
        <span className='button-text'>{label}</span>
        {iconRight != null ? React.cloneElement(iconRight, {
            className: 'transparent-btn-icon'
        }) : null}
    </Button>
}
export const InlineDropdownSelect = ({
    label, value, options = [
        // {key,value}
    ], onChange, placeholder = "Select", allowClear = false, style, selectClassName = '',
    onFocus, onBlur,
    selectStyle
}) => {
    return <div className={`rl-form-item`} style={{
        ...style
    }}>
        {label && <h4><span className='form-label'>{label}</span></h4>}

        <Select placeholder={placeholder}
            showSearch
            className={selectClassName}
            allowClear={allowClear}
            defaultActiveFirstOption
            value={value}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={(v, option) => {
                typeof onChange === 'function' && onChange(v, option.data)
            }}
            style={{
                minWidth: 100,
                width: '100%',
                ...selectStyle
            }} bordered={false}>
            {options.map((item) => {
                return <Select.Option title={item.value} key={item.key} data={item} value={item.key}>{item.value}</Select.Option>
            })}
        </Select>
    </div>
    return <div>INLINE DROPDOWN SELECT</div>
}
export const AccountInlineDropdownSelect = ({
    account1, account2,
    inlineStyle,
    style
}) => {
    const [focused, onFocusChange] = useState(false)


    return !focused ? <InlineDropdownSelect
        label="ACCOUNTS"
        onFocus={() => onFocusChange(true)}
        value={`Accounting>Label`} />
        : <AccountSelect
            label="ACCOUNTS"
            autoFocus={true}
            onBlur={() => onFocusChange(false)}
        />

}

export const AccountDropdownSelect = ({
    label, value1, options = [
        // {key,value}
    ], onChange, placeholder = "Select", allowClear = false, style, selectClassName = '',
    onFocus, onBlur, autoFocus,
    value2

}) => {

    return <div className={`rl-form-item`} style={{
        ...style
    }}>
        {label && <h4><span className='form-label'>{label}</span></h4>}

        <Select placeholder={placeholder}
            showSearch
            autoFocus={autoFocus}
            className={selectClassName}
            allowClear={allowClear}
            value={value1}
            onFocus={onFocus}
            onBlur={onBlur}
            style={{
                minWidth: 100,
                width: '100%'
            }}
        // bordered={false}
        >
            {options.map((item) => {
                return <Select.Option key={item.key} value={item.key}>{item.value}</Select.Option>
            })}
        </Select>
    </div>
}


export const CustomButtonWithDropdownOnly = ({
    label = "LABEL",
    type = "primary",
    filled = false,
    disabled = false,
    loading,
    iconLeft,
    iconRight,
    children
}) => {
    const menu = (
        <Menu className='custom-dropdown-btn-menu' onClick={(e) => {
            console.log("clicked menu", e)
        }}>
            <Menu.Item className='custom-dropdown-btn-menu-item' key="1" icon={<UserOutlined />}>
                1st menu
          </Menu.Item>
            <Menu.Item className='custom-dropdown-btn-menu-item' key="2" icon={<UserOutlined />}>
                2nd menu
          </Menu.Item>
            <Menu.Item className='custom-dropdown-btn-menu-item' key="3" icon={<UserOutlined />}>
                3rd item
          </Menu.Item>
        </Menu>
    );

    return <Dropdown
        className='custom-dropdown-btn'
        // buttonsRender={([leftButton, rightButton]) => [
        //     React.cloneElement(leftButton, {
        //         disabled: disabled,
        //         className: `custom-btn custom-btn-${type} custom-btn-${type}-${filled ? 'filled' : ''} dropdown-attached-btn-left`,
        //         loading: loading
        //     }),
        //     React.cloneElement(rightButton, {
        //         disabled: disabledDropdown,
        //         className: `custom-btn custom-btn-${type} custom-btn-${type}-${filled ? 'filled' : ''} dropdown-attached-btn-right`,
        //         loading: dropdownLoading,
        //         style: {
        //             // borderLeftColor:'white'
        //         }
        //     }),
        // ]}
        // className={`custom-btn custom-btn-${type} custom-btn-${type}-${filled ? 'filled' : ''}`}
        disabled={disabled}
        overlay={menu}>
        {/* <CustomButton type={type} label={label} icon={icon} /> */}
        {children != null ? children : <Button
            loading={loading}
            disabled={disabled}
            // onClick={() => {
            //     typeof onClick === 'function' && onClick()
            // }}
            className={`custom-btn custom-btn-${type} custom-btn-${type}-${filled ? 'filled' : ''}`}
        >
            {/* <span className='button-text'>{label}</span> */}
            {iconLeft != null ? React.cloneElement(iconLeft, {
                className: 'transparent-btn-icon'
            }) : null}
            <span className='button-text'>{label}</span>
            {iconRight != null ? React.cloneElement(iconRight, {
                className: 'transparent-btn-icon'
            }) : null}

        </Button>}
        {/* <span className='button-text'>{label}</span> */}
    </Dropdown>
}

export const CustomButtonWithDropdown = ({
    label = "LABEL",
    type = "primary",
    filled = false,
    disabled = false,
    loading,
    dropdownLoading,
    disabledDropdown = false,
    options = [],
    onClick
}) => {

    const menu = (
        <Menu className='custom-dropdown-btn-menu' onClick={(e) => {
            console.log("clicked menu", e)
        }}>
            {options.map((item, _in) => {
                return <Menu.Item onClick={() => {
                    typeof item.onClick === 'function' && item.onClick()
                }} key={_in}>{item.label}</Menu.Item>
            })}
            {/* <Menu.Item className='custom-dropdown-btn-menu-item' key="1" icon={<UserOutlined />}>
                1st menu
          </Menu.Item>
            <Menu.Item className='custom-dropdown-btn-menu-item' key="2" icon={<UserOutlined />}>
                2nd menu
          </Menu.Item>
            <Menu.Item className='custom-dropdown-btn-menu-item' key="3" icon={<UserOutlined />}>
                3rd item
          </Menu.Item> */}
        </Menu>
    );

    return <Dropdown.Button
        className='custom-dropdown-btn'
        buttonsRender={([leftButton, rightButton]) => [
            React.cloneElement(leftButton, {
                disabled: disabled,
                className: `custom-btn custom-btn-${type} custom-btn-${type}-${filled ? 'filled' : ''} dropdown-attached-btn-left`,
                loading: loading,
                onClick: onClick
            }),
            React.cloneElement(rightButton, {
                disabled: disabledDropdown,
                className: `custom-btn custom-btn-${type} custom-btn-${type}-${filled ? 'filled' : ''} dropdown-attached-btn-right`,
                loading: dropdownLoading,
                style: {
                    // borderLeftColor:'white'
                }
            }),
        ]}
        // className={`custom-btn custom-btn-${type} custom-btn-${type}-${filled ? 'filled' : ''}`}
        disabled={disabled}
        overlay={menu} placement='bottomRight' icon={<DownOutlined />}>
        <span className='button-text'>{label}</span>
    </Dropdown.Button>
}
