import React, { Component, useState } from 'react';
import { Input, Select, InputNumber, Checkbox, Tag, Modal } from 'antd';
import { StepBackwardOutlined, SearchOutlined } from '@ant-design/icons';
import ReactTagInput from "@pathofdev/react-tag-input";
import moment from 'moment';
import { NepaliDatePicker } from '../nepali_date_picker';
import Hotkeys from 'react-hot-keys';
import {
    atan2, chain, derivative, e, evaluate, log, pi, pow, round, sqrt
} from 'mathjs'
import { formatNumber } from '../../util/ui_util';
const { Option } = Select;

class DescriptionInputText extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            focused: false
        }


    }
    render() {
        const { focused } = this.state;
        return (
            <Input.TextArea
                {...this.props}
                className={`${this.props.className || ''} ${focused ? 'selected' : ''}`}
                onFocus={() => {
                    this.setState({
                        focused: true
                    })
                }}
                onBlur={(e) => {
                    this.setState({
                        focused: false
                    })
                    typeof this.props.onBlur === 'function' && this.props.onBlur(e);
                }}
            />
        )
    }

}
const DateInput = ({ value, onChange, placeholder, error_message, label, required, disabled }) => {
    return <div className='rl-form-item'>
        {label && <h4><span className='form-label'>{label}</span>{required && <span className='error-color'> *</span>}</h4>}
        <NepaliDatePicker value={value}
            disabled={disabled}
            onChange={(dt) => {
                typeof onChange === 'function' && onChange(dt)
            }} />
        <span className='t-meta-info error-color'>{error_message}</span>

    </div>
}
const TextInput = ({ value, onChange, placeholder, error_message, label, required, disabled, inputClassName = '', leftIcon, onPressEnter, autoComplete, autoFocus, suffix }) => {
    let injected_props = {};

    if (typeof onPressEnter === 'function') {
        injected_props = {
            onKeyDown: (e) => {
                if (e.key == 'Enter') {
                    onPressEnter(e)
                }
            }
        }
    }

    return <div className='rl-form-item'>
        {label && <h4><span className='form-label'>{label}</span>{required && <span className='error-color'> *</span>}</h4>}
        <Input
            autoFocus={autoFocus}
            size='large'
            autoComplete={autoComplete}
            value={value}
            className={`${error_message != null ? 'error-input' : ''} ${inputClassName}`}
            placeholder={placeholder}
            disabled={disabled}
            prefix={leftIcon}
            suffix={suffix}
            // suffix={<StepBackwardOutlined />}
            onChange={(e) => {
                typeof onChange === 'function' && onChange(e.target.value)
            }}
            {...injected_props}

        />
        <span className='t-meta-info error-color'>{error_message}</span>
    </div>
}
const PasswordInput = ({ value, onChange, placeholder, error_message, label, required, disabled, inputClassName = '', leftIcon, onPressEnter, autoComplete, autoFocus, suffix }) => {
    let injected_props = {};

    if (typeof onPressEnter === 'function') {
        injected_props = {
            onKeyDown: (e) => {
                if (e.key == 'Enter') {
                    onPressEnter(e)
                }
            }
        }
    }

    return <div className='rl-form-item'>
        {label && <h4><span className='form-label'>{label}</span>{required && <span className='error-color'> *</span>}</h4>}
        <Input.Password
            autoFocus={autoFocus}
            size='large'
            autoComplete={autoComplete}
            value={value}
            className={`${error_message != null ? 'error-input' : ''} ${inputClassName}`}
            placeholder={placeholder}
            disabled={disabled}
            prefix={leftIcon}
            suffix={suffix}
            // suffix={<StepBackwardOutlined />}
            onChange={(e) => {
                typeof onChange === 'function' && onChange(e.target.value)
            }}
            {...injected_props}

        />
        <span className='t-meta-info error-color'>{error_message}</span>
    </div>
}



const TagInput = ({ value = [], onChange, placeholder, error_message, label, required, disabled, inputClassName = '', leftIcon }) => {
    return <div className={`rl-form-item ${error_message ? 'error' : ''}`}>
        {label && <h4><span className='form-label'>{label}</span>{required && <span className='error-color'> *</span>}</h4>}
        <ReactTagInput
            size='large'
            tags={value}
            placeholder={placeholder}
            readOnly={disabled}
            prefix={leftIcon}
            // suffix={<StepBackwardOutlined />}
            removeOnBackspace={true}
            onChange={(tags) => {
                typeof onChange === 'function' && onChange(tags)
            }}

        />
        <span className='t-meta-info error-color'>{error_message}</span>
    </div>
}

const TextArea = ({ value, onChange, placeholder, error_message, label, required, disabled, rows, style, autoSize = true }) => {
    return <div className='rl-form-item' style={{
        ...style
    }}>
        {label != null &&
            <h4><span className='form-label'>{label}</span>{required && <span className='error-color'> *</span>}</h4>}
        <Input.TextArea
            size='large'
            value={value}
            autoSize={autoSize ? autoSize : {
                minRows: rows
            }}

            className={`${error_message != null ? 'error-input' : ''}`}
            placeholder={placeholder}
            disabled={disabled}
            // rows={rows || 1}
            onChange={(e) => {
                console.log("textarea changes", e.target.value)
                typeof onChange === 'function' && onChange(e.target.value)
            }}

        />
        <span className='t-meta-info error-color'>{error_message}</span>
    </div>
}
const NumberInput = ({ value, onChange, placeholder, error_message, label, required, disabled, inputClassName = '', min, max, style = {} }) => {
    const [focused, toggleFocus] = useState(false);
    return <div className='rl-form-item' style={{
        // width:'100%'
        ...style
    }}>
        {label && <h4><span className='form-label'>{label}</span>{required && <span className='error-color'> *</span>}</h4>}
        <NumberInputShortcutWrapper
            key={label}
            onChange={(value) => {
                typeof onChange === 'function' && onChange(value)
            }}
            focused={focused}
        >
            <InputNumber
                onBlur={() => {
                    toggleFocus(false)
                }}
                onFocus={() => {
                    toggleFocus(true)
                }}
                style={{
                    width: '100%'
                }}
                min={min}
                max={max}
                size='large'
                value={value}
                className={`${error_message != null ? 'error-input' : ''} ${inputClassName}`}
                placeholder={placeholder}
                disabled={disabled}
                onChange={(value) => {
                    typeof onChange === 'function' && onChange(value)
                }}
            />
        </NumberInputShortcutWrapper>
        <span className='t-meta-info error-color'>{error_message}</span>
    </div>
}

class NumberInputShortcutWrapper extends Component {

    constructor(props) {
        super(props)

        this.state = {
            visible: false,
            expression: ""
        }
    }

    onSubmit = () => {

    }

    onKeyDown = () => {
        console.log("key  down", this.props.focused)
        if (this.props.focused) {
            this.setState({
                visible: true,
                expression: ""
            })
        }
    }

    getCalculatedAmount = () => {
        try {
            let value = evaluate(this.state.expression);
            return formatNumber(value);
        } catch (e) {
            return "0"
        }
    }
    render() {
        return <>
            <Modal visible={this.state.visible}
                onCancel={() => {
                    this.setState({
                        visible: false
                    })
                }}
                destroyOnClose
                className='form-modal math-expression-modal'
                centered
                width='50%'
            >
                <TextInput
                    onChange={(_v) => {
                        this.setState({
                            expression: _v
                        })
                    }}
                    placeholder="Math Expression (eg. 2 * 4 )"
                    autoFocus
                    suffix={this.getCalculatedAmount()}
                    onPressEnter={(e) => {
                        let _v = e.target.value || "";

                        console.log("press enter", e.target.value);
                        try {
                            let value = evaluate(_v);

                            console.log("press enter val", evaluate(_v))
                            typeof this.props.onChange === 'function' && this.props.onChange(value);
                            this.setState({
                                visible: false
                            })
                        } catch (e) {
                            console.log("error", e)
                        }

                    }}
                />
            </Modal>
            {this.props.focused ?
                <Hotkeys
                    keyName={"Shift+Space"}
                    onKeyDown={(sk, e) => {
                        e.preventDefault();
                        e.stopPropagation()
                        console.log("key  down", this.props.focused)
                        // if(this.props.focused){
                        this.setState({
                            visible: true
                        })
                        // }
                    }}
                    filter={event => true}
                >
                    {React.cloneElement(this.props.children, { autoFocus: true })}
                </Hotkeys>
                : this.props.children
            }

        </>
    }
}

const TextInputWithDropdown = ({ value, onChange, placeholder, error_message, label, required, disabled, options = [], optionValue, onChangeOption, optionPlaceholder }) => {
    const selectAfter = options.length > 0 ? (
        <Select
            bordered={false}
            value={optionValue}
            onChange={(val) => {
                typeof onChangeOption === 'function' && onChangeOption(val)
            }}
            style={{
                padding: 0
            }}
            placeholder={optionPlaceholder}
            className="select-after">
            {options.map((select_item) => {
                return <Option
                    value={select_item.key}
                    key={select_item.key}
                >{select_item.value}</Option>
            })}
        </Select>
    ) : null;
    return <div className='rl-form-item'>
        <h4><span className='form-label'>{label}</span>{required && <span className='error-color'> *</span>}</h4>
        <Input
            size='large'
            value={value}
            className={`${error_message != null ? 'error-input' : ''} input-with-dropdown`}
            placeholder={placeholder}
            disabled={disabled}
            onChange={(e) => {
                typeof onChange === 'function' && onChange(e.target.value)
            }}
            addonAfter={selectAfter}
        />
        <span className='t-meta-info error-color'>{error_message}</span>
    </div>
}


const NumberInputWithDropdown = ({ value, onChange, placeholder, error_message, label, required, disabled, options = [], optionValue, onChangeOption, optionPlaceholder, inputClassName = '' }) => {
    const selectAfter = options.length > 0 ? (
        <Select
            bordered={false}
            value={optionValue}
            onChange={(val) => {
                typeof onChangeOption === 'function' && onChangeOption(val)
            }}
            style={{
                padding: 0
            }}
            placeholder={optionPlaceholder}
            className="select-after">
            {options.map((select_item) => {
                return <Option
                    value={select_item.key}
                    key={select_item.key}
                >{select_item.value}</Option>
            })}
        </Select>
    ) : null;
    return <div className='rl-form-item'>
        {label && <h4><span className='form-label'>{label}</span>{required && <span className='error-color'> *</span>}</h4>}
        <Input
            size='large'
            type='number'
            value={value}
            className={`${error_message != null ? 'error-input' : ''} input-with-dropdown ${inputClassName}`}
            placeholder={placeholder}
            disabled={disabled}
            onChange={(e) => {
                typeof onChange === 'function' && onChange(Number(e.target.value))
            }}
            addonAfter={selectAfter}
        />
        <span className='t-meta-info error-color'>{error_message}</span>
    </div>
}

const SearchArea = ({ value, placeholder, onChange, onSearch, style }) => {

    return <Input
        className='rl-search-area'
        size='small'
        style={{
            ...style
        }}
        placeholder="Search For Contacts, ..."
        prefix={<SearchOutlined />}
        onFocus={() => {

        }}
        onBlur={() => {

        }}
    />
}



export { TextInput, TextInputWithDropdown, NumberInputWithDropdown, TextArea, NumberInput, SearchArea, TagInput, DateInput, PasswordInput, DescriptionInputText };
