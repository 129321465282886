import React from 'react'
import { Upload } from 'antd';
const { Dragger } = Upload;

function UploadInput({ style, label, required, fileList,onChangeFileList }) {
    return (
        <div className='rl-form-item' style={{
            ...style
        }}>
            {label != null &&
                <h4><span className='form-label'>{label}</span>{required && <span className='error-color'> *</span>}</h4>}

            <div className='rl-upload-input-wrapper'>
            {

            }
            <Dragger previewFile={(file)=>{
            }}  style={{
            }} className='rl-upload-input' >
                <p className="rl-upload-text">+ Upload or Drag Files</p>
                <p className="rl-upload-hint">(JPG, PNG, PDF, XLS, TXT)</p>
            </Dragger>
            </div>

        </div>
    )
}

export default UploadInput
