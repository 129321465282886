import React, { Component } from 'react';
import { LayoutManager } from '../../util/layout_manager';
import { Space } from 'antd';
import { CustomButton, TransparentButton } from './buttons';
import { UndoOutlined } from '@ant-design/icons';
import { ACCOUNT_ITEM_TABLE, BASIC_FORM_SEGMENT, CONTACT_TABLE_SEGMENT, MAIN_FORM_SEGMENT, PAYMENT_ALLOCATION_TABLE_SEGMENT, 
    PRODUCT_ORDER_TABLE_SEGMENT, PRODUCT_VARIATION_SEGMENT,PRODUCT_AND_QUANTITY_TABLE_SEGMENT, ACCOUNT_EXPENSE_TABLE, BOM_ITEM_SEGMENT, PRODUCTION_VOUCHER_ITEM_SEGMENT, 
    LANDED_COST_TABLE_SEGMENT,TRANSFER_PRODUCT_TABLE_SEGMENT } from './form_segments';
import PropTypes from 'prop-types'


/**
 * @constructor
 * @augments {Component<Props, State>}

 */


class MainForm extends Component {

    static propTypes = {
        /** Title of a Form */
        title: PropTypes.string,
        /** Form Field Schema 
         * [
         * {
         *  type: "BASIC",
         * title: "REPORTING TAGS",
         * expandable: false,
         * schema: []
        * },
        * {
         *   type:"FORM_MAIN",
         *   label:"INVOICE NO#",
        *}
         * ]
         */
        leftSchema: PropTypes.array,

        /** Network Response of post or patch */
        networkState: PropTypes.object,

        /** onChange trigger of form  */
        onChange: PropTypes.func,

        /** Action override for reset form */
        onReset: PropTypes.func,

        /** Data of form */
        data: PropTypes.object,

        /** Form ID (Optional)*/
        form_id: PropTypes.string,

        /** Form ID change trigger*/
        onChangeFormId: PropTypes.func,

        /** on save form clicked*/
        onSave: PropTypes.func,


        /** onApprove Clicked*/
        onApprove: PropTypes.func,

        /** onsave and add new clicked*/
        onSaveAndAdd: PropTypes.func,

        /** on approve and add new clicked*/
        onApproveAndAdd: PropTypes.func,

        /** to  get reference of  form 
         * {validateForm(callback on validate),resetForm()}
        */
        onRef: PropTypes.func,

        /** true if the form is for edit */
        forEdit: PropTypes.bool

    }

    constructor(props) {
        super(props)

        this.state = {
            schema: this.props.leftSchema || [],
            submitted: false
        }

        this.formRefs = {};
        this.resetForm = this.resetForm.bind(this);
        this.validateForm = this.validateForm.bind(this);
    }

    resetForm = () => {
        console.log("reseting form")
        this.resetAllSegments()
        if (typeof this.props.onReset === 'function') {
            this.props.onReset()
        } else if (typeof this.props.onChange === 'function') {

            this.props.onChange({})
        }
    }
    validateForm = (callback) => {
        this.validateAllSegments(() => {
            typeof callback === 'function' && callback()
        })
    }

    resetAllSegments = () => {
        Object.keys(this.formRefs).map((_refKey) => {
            try {
                let singleHasError = this.formRefs[_refKey].resetFormSchema();

            }
            catch (e) {
                console.log("single has error failed", e)
            }
        })

    }

    validateAllSegments = (successCallback) => {
        let errors = [];
        Object.keys(this.formRefs).map((_refKey) => {
            try {
                let singleHasError = this.formRefs[_refKey].validateFormInternally();
                errors.push(singleHasError);
                console.log("single has error", singleHasError);
            }
            catch (e) {
                console.log("single has error failed", e)
            }
        })
        console.log("final has error", errors, errors.indexOf(true), errors.indexOf(true) != -1, errors.indexOf(true) != null)

        if (errors.indexOf(true) != -1) {
            // IF any one of them has error
            console.log("any one of them has error")
        } else {
            // all are successfully  valiidated
            console.log("all error validation passed")
            typeof successCallback === 'function' && successCallback()
        }
    }


    _renderFormSegment = (item, _in) => {

        if (this.props.forEdit && item.forEdit == false) {
            return;
        }
        let props_data = this.props.data || {};
        let form_id = this.props.form_id;
        let networkState = this.props.networkState || {};

        let validation_errors = networkState.validation_errors || {};


        switch (item.type) {
            case "FORM_MAIN":
                return <MAIN_FORM_SEGMENT
                    forEdit={this.props.forEdit}
                    value={this.props.form_id}
                    label={item.label}
                    onRef={(ref) => {
                        this.formRefs[_in] = ref
                    }}
                    stringify={item.stringify}
                    validation_errors={item.append_key ? validation_errors[item.append_key] : validation_errors}
                    data={item.append_key ? props_data[item.append_key] || {} : props_data}
                    schema={item.schema}
                    editable={item.editable}
                    onChangeData={(data) => {
                        let new_data = this.props.data;
                        if (item.append_key) {
                            new_data[item.append_key] = data;
                        } else {
                            Object.assign(new_data, data)
                        }
                        typeof this.props.onChange === 'function' && this.props.onChange(new_data)
                        // this.setState({ data: new_data }, () => {
                        //   typeof this.props.onDataChange === 'function' && this.props.onDataChange(new_data)
                        // })
                    }}
                    onChangeValue={(value) => {
                        console.log("changing value,", value, typeof this.props.onChangeFormId)
                        typeof this.props.onChangeFormId === 'function' && this.props.onChangeFormId(value)
                    }}
                />;
            case "BASIC":
                return <BASIC_FORM_SEGMENT
                    forEdit={this.props.forEdit}
                    onRef={(ref) => {
                        this.formRefs[_in] = ref
                    }}
                    validation_errors={item.append_key ? validation_errors[item.append_key] : validation_errors}
                    title={item.title}
                    subtitle={item.subtitle}
                    defaultExpanded={item.defaultExpanded}
                    expandable={item.expandable}
                    schema={item.schema}
                    editable={item.editable}
                    data={item.append_key ? props_data[item.append_key] || {} : props_data}
                    onChangeData={(data) => {
                        let new_data = this.props.data;
                        if (item.append_key) {
                            new_data[item.append_key] = data;
                        } else {
                            Object.assign(new_data, data)
                        }
                        // this.setState({ data: new_data }, () => {
                        typeof this.props.onChange === 'function' && this.props.onChange(new_data)
                        //   typeof this.props.onDataChange === 'function' && this.props.onDataChange(new_data)
                        // })
                    }}
                />
            case "CONTACT_TABLE":
                return <CONTACT_TABLE_SEGMENT
                    title={item.title}
                    defaultExpanded={item.defaultExpanded}
                    expandable={item.expandable}
                    data={props_data[item.append_key || 'contacts'] || []}
                    onChangeData={(data) => {
                        let new_data = this.props.data;
                        new_data[item.append_key || 'contacts'] = data;
                        // this.setState({ data: new_data }, () => {
                        typeof this.props.onChange === 'function' && this.props.onChange(new_data)
                        //   typeof this.props.onDataChange === 'function' && this.props.onDataChange(new_data)
                        // })
                    }}
                />
            case "PRODUCT_VARIATION":
                return <PRODUCT_VARIATION_SEGMENT
                    title={item.title}
                    defaultExpanded={item.defaultExpanded}
                    expandable={item.expandable}
                    data={props_data}
                    validation_errors={item.append_key ? validation_errors[item.append_key] : validation_errors}
                    onChangeData={(data) => {
                        let new_data = this.props.data;
                        new_data[item.append_key || 'products'] = data;
                        // this.setState({ data: new_data }, () => {
                        typeof this.props.onChange === 'function' && this.props.onChange(new_data)
                        //   typeof this.props.onDataChange === 'function' && this.props.onDataChange(new_data)
                        // })
                    }}
                />
            case "ORDER_PRODUCT_TABLE":
                return <PRODUCT_ORDER_TABLE_SEGMENT
                    title={item.title}
                    defaultExpanded={item.defaultExpanded}
                    expandable={item.expandable}
                    main_data={props_data}
                    append_key={item.append_key}
                    data={item.append_key?(props_data[item.append_key]||[]):props_data}
                    validation_errors={item.append_key ? validation_errors[item.append_key] : validation_errors}
                    onChangeData={(data) => {
                        let new_data = this.props.data;
                        new_data[item.append_key || 'items'] = data;
                        // this.setState({ data: new_data }, () => {
                        typeof this.props.onChange === 'function' && this.props.onChange(new_data)
                        //   typeof this.props.onDataChange === 'function' && this.props.onDataChange(new_data)
                        // })
                    }}
                    onChangeMainData={(data)=>{
                        typeof this.props.onChange === 'function' && this.props.onChange(data)
                    }}
                />

                case "PRODUCT_AND_QUANTITY_TABLE":
                    return <PRODUCT_AND_QUANTITY_TABLE_SEGMENT
                        title={item.title}
                        defaultExpanded={item.defaultExpanded}
                        expandable={item.expandable}
                        main_data={props_data}
                        append_key={item.append_key}
                        data={item.append_key?(props_data[item.append_key]||[]):props_data}
                        validation_errors={item.append_key ? validation_errors[item.append_key] : validation_errors}
                        onChangeData={(data) => {
                            let new_data = this.props.data;
                            new_data[item.append_key || 'items'] = data;
                            // this.setState({ data: new_data }, () => {
                            typeof this.props.onChange === 'function' && this.props.onChange(new_data)
                            //   typeof this.props.onDataChange === 'function' && this.props.onDataChange(new_data)
                            // })
                        }}
                        onChangeMainData={(data)=>{
                            typeof this.props.onChange === 'function' && this.props.onChange(data)
                        }}
                    />
                case "TRANSFER_PRODUCT_TABLE":
                    return <TRANSFER_PRODUCT_TABLE_SEGMENT
                        title={item.title}
                        defaultExpanded={item.defaultExpanded}
                        expandable={item.expandable}
                        main_data={props_data}
                        isAdjustment={item.isAdjustment}
                        append_key={item.append_key}
                        data={item.append_key?(props_data[item.append_key]||[]):props_data}
                        validation_errors={item.append_key ? validation_errors[item.append_key] : validation_errors}
                        onChangeData={(data) => {
                            let new_data = this.props.data;
                            new_data[item.append_key || 'items'] = data;
                            // this.setState({ data: new_data }, () => {
                            typeof this.props.onChange === 'function' && this.props.onChange(new_data)
                            //   typeof this.props.onDataChange === 'function' && this.props.onDataChange(new_data)
                            // })
                        }}
                        onChangeMainData={(data)=>{
                            typeof this.props.onChange === 'function' && this.props.onChange(data)
                        }}
                    />
                case "ACCOUNT_ITEM_TABLE":
                return <ACCOUNT_ITEM_TABLE
                    title={item.title}
                    defaultExpanded={item.defaultExpanded}
                    expandable={item.expandable}
                    main_data={props_data}
                    isJournal={item.isJournal}
                    data={item.append_key?(props_data[item.append_key]||[]):props_data}
                    validation_errors={item.append_key ? validation_errors[item.append_key] : validation_errors}
                    onChangeData={(data) => {
                        let new_data = this.props.data;
                        new_data[item.append_key || 'items'] = data;
                        // this.setState({ data: new_data }, () => {
                        typeof this.props.onChange === 'function' && this.props.onChange(new_data)
                        //   typeof this.props.onDataChange === 'function' && this.props.onDataChange(new_data)
                        // })
                    }}
                    onChangeMainData={(data)=>{
                        typeof this.props.onChange === 'function' && this.props.onChange(data)
                    }}
                />
                case "ACCOUNT_EXPENSE_TABLE":
                return <ACCOUNT_EXPENSE_TABLE
                    title={item.title}
                    defaultExpanded={item.defaultExpanded}
                    expandable={item.expandable}
                    main_data={props_data}
                    isJournal={item.isJournal}
                    data={item.append_key?(props_data[item.append_key]||[]):props_data}
                    validation_errors={item.append_key ? validation_errors[item.append_key] : validation_errors}
                    onChangeData={(data) => {
                        let new_data = this.props.data;
                        new_data[item.append_key || 'items'] = data;
                        // this.setState({ data: new_data }, () => {
                        typeof this.props.onChange === 'function' && this.props.onChange(new_data)
                        //   typeof this.props.onDataChange === 'function' && this.props.onDataChange(new_data)
                        // })
                    }}
                    onChangeMainData={(data)=>{
                        typeof this.props.onChange === 'function' && this.props.onChange(data)
                    }}
                />
                case "ALLOCATION_TABLE":
                    return <PAYMENT_ALLOCATION_TABLE_SEGMENT 
                    title={item.title}
                    defaultExpanded={item.defaultExpanded}
                    expandable={item.expandable}
                    main_data={props_data}
                    isJournal={item.isJournal}
                    data={item.append_key?(props_data[item.append_key]||[]):props_data}
                    allocationAPI={item.allocationAPI}
                    validation_errors={item.append_key ? validation_errors[item.append_key] : validation_errors}
                    onChangeData={(data) => {
                        let new_data = this.props.data;
                        console.log("new data",new_data);

                        new_data[item.append_key || 'allocations'] = data;
                        // this.setState({ data: new_data }, () => {
                        typeof this.props.onChange === 'function' && this.props.onChange(new_data)
                        //   typeof this.props.onDataChange === 'function' && this.props.onDataChange(new_data)
                        // })
                    }}
                    onChangeMainData={(data)=>{
                        typeof this.props.onChange === 'function' && this.props.onChange(data)
                    }}
                    />
                    case "BOM_TABLE":
                    return <BOM_ITEM_SEGMENT 
                    title={item.title}
                    defaultExpanded={item.defaultExpanded}
                    expandable={item.expandable}
                    main_data={props_data}
                    isJournal={item.isJournal}
                    data={item.append_key?(props_data[item.append_key]||[]):props_data}
                    allocationAPI={item.allocationAPI}
                    validation_errors={item.append_key ? validation_errors[item.append_key] : validation_errors}
                    onChangeData={(data) => {
                        let new_data = this.props.data;
                        console.log("new data",new_data);

                        new_data[item.append_key || 'allocations'] = data;
                        // this.setState({ data: new_data }, () => {
                        typeof this.props.onChange === 'function' && this.props.onChange(new_data)
                        //   typeof this.props.onDataChange === 'function' && this.props.onDataChange(new_data)
                        // })
                    }}
                    onChangeMainData={(data)=>{
                        typeof this.props.onChange === 'function' && this.props.onChange(data)
                    }}
                    />
                case "PRODUCTION_VOUCHER_TABLE":
                    return <PRODUCTION_VOUCHER_ITEM_SEGMENT 
                    title={item.title}
                    defaultExpanded={item.defaultExpanded}
                    expandable={item.expandable}
                    main_data={props_data}
                    data={item.append_key?(props_data[item.append_key]||[]):props_data}
                    allocationAPI={item.allocationAPI}
                    validation_errors={item.append_key ? validation_errors[item.append_key] : validation_errors}
                    onChangeData={(data) => {
                        let new_data = this.props.data;
                        console.log("new data",new_data);

                        new_data[item.append_key || 'allocations'] = data;
                        // this.setState({ data: new_data }, () => {
                        typeof this.props.onChange === 'function' && this.props.onChange(new_data)
                        //   typeof this.props.onDataChange === 'function' && this.props.onDataChange(new_data)
                        // })
                    }}
                    onChangeMainData={(data)=>{
                        typeof this.props.onChange === 'function' && this.props.onChange(data)
                    }}
                    />
                    case "LANDED_COST_TABLE":
                        return <LANDED_COST_TABLE_SEGMENT 
                        title={item.title}
                        defaultExpanded={item.defaultExpanded}
                        expandable={item.expandable}
                        main_data={props_data}
                        data={item.append_key?(props_data[item.append_key]||[]):props_data}
                        allocationAPI={item.allocationAPI}
                        validation_errors={item.append_key ? validation_errors[item.append_key] : validation_errors}
                        onChangeData={(data) => {
                            let new_data = this.props.data;
                            console.log("new data",new_data);
    
                            new_data[item.append_key || 'allocations'] = data;
                            // this.setState({ data: new_data }, () => {
                            typeof this.props.onChange === 'function' && this.props.onChange(new_data)
                            //   typeof this.props.onDataChange === 'function' && this.props.onDataChange(new_data)
                            // })
                        }}
                        onChangeMainData={(data)=>{
                            typeof this.props.onChange === 'function' && this.props.onChange(data)
                        }}
                        />
            default:
                return <div>SEGMENT NOT DEFINED FOR {item.type}</div>
        }
    }




    render() {
        // PROPERTIES

        /*
        size,title,primaryActions,secondaryActions,rightImage
        */
        let title = this.props.title || "FORM TITLE";
        // let rightImage = this.props.rightImage;
        // let leftSchema=this.props.schema||[];
        let networkState = this.props.networkState || {};

        let validation_errors = networkState.validation_errors || {}

        let props_data = this.props.data || {}
        console.log("props data", props_data)
        // let leftSchema=this.props.leftSchema||[];


        return (
            <div className={`main-form`}>
                <div className='form-left'>
                    {/* <div className='no-wrap-rl-row' style={{
                        justifyContent: 'space-between',
                    }}>
                        <div onClick={() => {
                            // this.validateAllSegments()
                        }} className='page-title'>{title}</div>
                        <Space size={LayoutManager.globalSpacing} className='no-wrap-rl-row'>
                            <TransparentButton
                                hint="Reset Form  Data"
                                onClick={() => {
                                    console.log("reseting form")
                                    this.resetAllSegments()
                                    if (typeof this.props.onReset === 'function') {
                                        this.props.onReset()
                                    } else if (typeof this.props.onChange === 'function') {

                                        this.props.onChange({})
                                    }
                                }} label="RESET"
                                icon={<UndoOutlined />}
                            />
                        </Space>
                    </div> */}
                    <div style={{
                        // paddingTop: 55,
                        ...this.props.leftFormContainerStyle
                    }}>
                        <Space size={0} direction='vertical' style={{
                            width: '100%'
                        }}>
                            {this.props.leftSchema.map((item, _in) => {
                                return this._renderFormSegment(item, _in)
                            })}
                            
                        </Space>
                    </div>
                </div>
                {!this.props.withoutRight&&<div className='form-right'>
                    {/* <div className='no-wrap-rl-row form-right-top' style={{
                        justifyContent: 'flex-end'
                    }}>
                        <Space size={LayoutManager.globalSpacing}>
                            {typeof this.props.onSave === 'function' &&
                                <CustomButton
                                    loading={networkState.fetching}
                                    disabled={networkState.fetching}
                                    onClick={() => {
                                        this.validateAllSegments(() => this.props.onSave())
                                        // this.props.onSave();
                                    }}
                                    filled
                                    type='success'
                                    label="SAVE" />}
                        </Space>
                    </div> */}
                    <div className='form-right-body' style={{
                        // paddingBottom:LayoutManager.formPadding
                    }}>
                        {/* <Space size={LayoutManager.globalSpacing}
                            direction='vertical'
                            style={{
                                width: '100%',

                            }}>
                            <ExpandableCard
                                title={"Attachments"}
                                expanded={false}
                            >
                                <div style={{
                                    height: 900,
                                    backgroundColor: 'red'
                                }}>

                                </div>
                            </ExpandableCard>
                            <ExpandableCard
                                title={"Activity"}
                            >
                                <div style={{
                                    height: 900,
                                    backgroundColor: 'green'
                                }}>

                                </div>
                            </ExpandableCard>
                        </Space> */}
                    </div>
                </div>}
            </div>
        );
    }

    componentDidMount() {
        typeof this.props.onRef === 'function' && this.props.onRef(this)
    }
    componentWillUnmount() {
        typeof this.props.onRef === 'function' && this.props.onRef(null)
    }
}

export default MainForm;



