import React, { useState, useEffect } from 'react'
import { RESTExecutor, withDomains } from "../../lib/domain";
import { Form, Input, Button, Upload, Checkbox, message, DatePicker, Tooltip } from "antd";
import { PhoneFilled, CloseOutlined, CalendarOutlined, UserOutlined } from "@ant-design/icons";
import moment from 'moment';
import ImgCrop from 'antd-img-crop';
import axios from "axios";

import CryptoJS from 'crypto-js';
import { BASE_API_URL } from '../../config';

const key1 = '981239812312312323';
const key2 = '9234809823948394';

const registeredId = loadFromSessionStorage('regId', key1);
let persistedState = loadFromSessionStorage("state", key2);

function loadFromSessionStorage(data, key) {
    try {
        const serializedState = sessionStorage.getItem(data);
        if (serializedState === null) {
            return undefined;
        }
        const decrypted = CryptoJS.AES.decrypt(serializedState, key).toString(CryptoJS.enc.Utf8);
        return JSON.parse(decrypted);
    } catch {
        return undefined;
    }
}


function disabledDate(current) {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
}

const SignupDetailPage = ({
    domains,
    ...props
}) => {

    let register_user = RESTExecutor.post()
        .config({
            label: 'register_user',
        })
        .callbacks(
            (success) => {
                message.success('Profile successfully updated. Please Login.')
                props.history.replace({ pathname: `/` });
                sessionStorage.removeItem('regId');
                sessionStorage.removeItem('state');
            },
            (error) => {
                console.log(error.validation_errors);
                error.error_message && message.error(error.error_message);
                error.validation_errors && message.error("error, please check your credentials");
                props.history.goBack();
            }
        )
        .connect(domains.register)

    let reg_resp = register_user.response();

    useEffect(() => {
        loadFromSessionStorage('regId', key1);
        loadFromSessionStorage('state', key2);
    }, [])

    const onFinish = (formData) => {
        document.querySelector('#skip_text').classList.add('isDisabled')
        let DOB = (formData !== undefined && formData.date_of_birth != undefined) ? { date_of_birth: formData?.date_of_birth.format("DD-MM-YYYY") } : null;
        let data = { ...loadFromSessionStorage('regId', key1), ...formData, ...loadFromSessionStorage('state', key2), ...DOB };
        register_user.execute(data);
    };


    return (
        <div>
            <div className="container">
                <div className="header-menu">
                    < img src='images/logo.svg' className='header-menu-item' />
                </div>
            </div>

            <div className="main-container">
                <div className="container">
                    <div className="form-container">
                        <p className="form-header" style={{ paddingBottom: "5%" }}>Hi {loadFromSessionStorage('state', key2)?.name}, Let’s create your profile</p>
                        < Form
                            name="register"
                            className="login-form"
                            size="large"
                            initialValues={{
                                remember: true
                            }
                            }
                            onFinish={
                                onFinish
                            }
                        >

                            {/* <Form.Item
                                style={{ textAlign: 'center' }}
                                name="profile_picture_id"
                            >
                                <ImgCrop
                                    aspect={1024 / 1024}
                                >

                                    <Upload
                                        className="edit-form-upload"
                                        name="pic"
                                        listType="picture-card"
                                        fileList={fileList}
                                        onChange={onChange}
                                        onPreview={onPreview}
                                        onRemove={() => {
                                            setImgPath('')
                                        }}
                                        maxCount={1}
                                        customRequest={uploadImage}
                                    >
                                        {fileList < 1 && <UserOutlined />}
                                    </Upload>
                                </ImgCrop>
                            </Form.Item> */}
                            <Form.Item
                                name="phone_number"
                            >
                                <Input
                                    type="tel"
                                    style={{ "padding": "20px" }}
                                    prefix={<PhoneFilled rotate='90' style={{ color: "#AFB5BB" }} />}
                                    placeholder="Phone Number"
                                />

                                {/* <Input addonBefore={prefixSelector} style={{ width: '100%' }} /> */}
                            </Form.Item>
                            <Form.Item
                                name="date_of_birth"
                            >
                                <DatePicker className="form-datepicker"
                                    placeholder="Date of Birth (yyyy-mm-dd)"
                                    disabledDate={disabledDate}
                                />
                            </Form.Item>



                            {/* <Form.Item
                                name="address"
                            >
                                <Input.TextArea
                                    style={{ "padding": "20px" }}
                                    placeholder="Address"
                                />
                            </Form.Item> */}

                            <Form.Item>
                                <Button
                                    loading={reg_resp?.fetching}
                                    size="large"
                                    htmlType="submit" className="login-form-button">
                                    Update Profile
                                </Button>
                            </Form.Item>

                            <Form.Item>
                                <a id="skip_text" onClick={() => { onFinish() }} className="form-footer">Skip this step.</a>
                            </Form.Item>


                        </Form >
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withDomains(SignupDetailPage, 'register')