import React, { useState } from "react";
import { Form, Input, Button, Switch, DatePicker } from "antd";
import { LeftOutlined } from "@ant-design/icons";

import moment from "moment";

function disabledDate(current) {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
}




const Second = ({ setForm, formData, extra, setExtra, navigation }) => {

    const { pan_no, registered_address } = formData;
    const { vat_registered, accounting_start_date } = extra;
    const dateFormat = 'DD-MM-YYYY';
    const [Second] = Form.useForm();
    const { previous, next } = navigation;

    const defaultStatus = {
        "pan_no": "validating",
        "accounting_start_date": "validating",
        "registered_address": "validating"
    };

    const helpMessageDefault = {
        "pan_no": "Your business PAN number",
        "accounting_start_date": "The data from which you want to start accounting in Tigg. Once set, this cannot be changed.",
        "registered_address": "The registered address of your business."
    }

    const [validateStatus, setValidateStatus] = useState(defaultStatus);
    const [helpMessage, setHelpMessage] = useState(helpMessageDefault);

    return (
        <Form
            form={Second}
            name="second"
            className="steps-content"

        >
            <Form.Item
                initialValue={pan_no}
                name="pan_no"
                validateStatus={validateStatus.pan_no}
                help={helpMessage.pan_no}

                onFocus={() => {
                    setValidateStatus(prevState => ({
                        ...prevState,
                        "pan_no": "validating"
                    }));
                    setHelpMessage(prevState => ({
                        ...prevState,
                        "pan_no": "Your business PAN number"
                    }))
                }}

                rules={[
                    {
                        len: 9,
                        message: "PAN must be 9 characters long. Please enter a valid PAN"
                    }
                ]}
            >
                <Input
                    type="number"
                    value={pan_no}
                    name="pan_no"
                    onChange={setForm}
                    style={{ "padding": "20px" }}
                    placeholder="PAN number"
                />
            </Form.Item>


            <Form.Item
                initialValue={vat_registered}
                label="Registered with VAT"
                colon={false}
                name="vat_registered"
                valuePropName="checked"
                className="switch-flex"
            >
                <Switch
                    checked={vat_registered}
                    name="vat_registered"
                    onChange={(value) => {
                        setExtra(prevState => ({ ...prevState, "vat_registered": value }))
                    }}
                    className="regSwitch" />
            </Form.Item>


            <Form.Item
                initialValue={accounting_start_date}
                validateStatus={validateStatus.accounting_start_date}
                help={helpMessage.accounting_start_date}
                name="accounting_start_date"
                onFocus={() => {
                    setValidateStatus(prevState => ({
                        ...prevState,
                        "accounting_start_date": "validating"
                    }));
                    setHelpMessage(prevState => ({
                        ...prevState,
                        "accounting_start_date": "The data from which you want to start accounting in Tigg. Once set, this cannot be changed."
                    }))
                }}

                rules={[
                    {
                        required: true,
                        message: "The data from which you want to start accounting in Tigg. Once set, this cannot be changed."
                    }
                ]}
            >
                <DatePicker
                    onChange={(value) => {
                        setExtra(prevState => ({ ...prevState, "accounting_start_date": value }))
                    }}
                    value={accounting_start_date}
                    name="accounting_start_date"
                    className="form-datepicker"
                    format={dateFormat}
                    disabledDate={disabledDate}
                    placeholder="Accounting Start Date"
                />
            </Form.Item>


            <Form.Item
                initialValue={registered_address}
                validateStatus={validateStatus.registered_address}
                name="registered_address"
                help={helpMessage.registered_address}
            >
                <Input.TextArea
                    value={registered_address}
                    name="registered_address"
                    onChange={setForm}
                    style={{ "padding": "20px" }}
                    placeholder="Registered Address"
                />
            </Form.Item>

            <Form.Item>
                <div className="steps-content-buttons">
                    <Button
                        style={{ padding: 0 }}
                        icon={<LeftOutlined />}
                        type="text"
                        onClick={previous}
                    >
                        Back
                    </Button>
                    <Button
                        onClick={() => {
                            Second.validateFields().then(() => {
                                next()
                            }).catch(error => {
                                error.errorFields.map(
                                    (item) => {
                                        setValidateStatus(prevState => ({
                                            ...prevState,
                                            [item.name['0']]: "error"
                                        }));
                                        setHelpMessage(prevState => ({
                                            ...prevState,
                                            [item.name['0']]: item.errors['0']
                                        }))

                                    }
                                )
                            })
                        }}
                        size="large"
                        htmlType="submit"
                        className="steps-form-button"
                    >
                        Next : Company Profile
                    </Button>
                </div>
            </Form.Item>


        </Form >
    );
}

export default Second;
