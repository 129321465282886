import React, { useState } from 'react'
import { RESTExecutor, withDomains } from "../../lib/domain";
import SmallForm from "../../components/forms/small_form";

const VerifyEmailPage = ({
    match,
    domains,
    ...props
}) => {

    let register_user = RESTExecutor.post()
        .config({
            label: 'email_register',

        })
        .forId(match?.params?.id)
        .callbacks(
            (success) => props.history.push('/')
        )
        .connect(domains.register)


    const [formData, setFormData] = useState({

    })


    return (
        <div>
            <SmallForm
                networkState={register_user.response()}
                data={
                    formData
                }

                onChange={(val) => {
                    setFormData({
                        ...formData,
                        ...val
                    })
                }}

                title="Register User"
                schema={USER_REGISTER_SCHEMA}
                onSave={() => {
                    register_user.execute(formData)
                }}
                saveText={'REGISTER'}
            />
        </div>
    )
}


const USER_REGISTER_SCHEMA = [
    { label: "First name", type: 'text', index: 'first_name', required: true },
    { label: "Last name", type: 'text', index: 'last_name', required: true },
    { label: "Password", type: 'password', index: 'password', required: true },

]

export default withDomains(VerifyEmailPage, 'register')