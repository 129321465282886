import jwtDecode from 'jwt-decode';

const DEFAULT_LOGOUT_TIME = 60//in  minute

const DOMAIN_NAME = process.env.NODE_ENV == 'production' ? 'tigg' : 'localhost';
// const SPLITTED_URL=window.location.hostname
var domain_index = window.location.hostname.split('.').indexOf(DOMAIN_NAME);
let subdomain = window.location.hostname.split('.')[domain_index - 1] || 'tigg'

var AUTH_URL = 'https://dev.tigg.app/api/user';

var PRODUCTION_API = 'https://api.tigg.app/api/user';
var DEBUG_API = 'https://dev.tigg.app/api/user';
// var PRODUCTION_API = 'https://api.tigg.app/api/user';
// var DEBUG_API = 'https://api.tigg.app/api/user';
// var DEBUG_API = 'http://192.168.2.32:4000/api/user'
export const BASE_API_URL = localStorage.getItem('BASE_API_URL') || (process.env.REACT_APP_nodeenv == 'production' ? PRODUCTION_API : DEBUG_API);

if (process.env.NODE_ENV == 'production') {
  // AUTH_URL = 'https://dev.tigg.app/api/v1/user'
  AUTH_URL = BASE_API_URL;

}




const APP_CONFIG = {
  dev: false,
  target: 'web',
  network: {

    // base_url: `${window.location.protocol}//${window.location.hostname}/api/v1`,
    // base_url: 'http://localhost:4000/api/v1',
    // base_url: 'https://recon-demo.gokyolabs.com/api/v1',
    // base_url: `https://dev.tigg.app/api/v1/user`,
    base_url: BASE_API_URL,
    // base_url:`https://dev.tigg.app/api`,
    // base_url:"https://2f6072e74b9c.ngrok.io/api/",
    //  socket_url:'ws://localhost:8080/',

    // base_url: `http://192.168.2.84:3000/api/v1`,
    //  auth_url:'',
    // base_url:'https://206.189.93.92/api/v1/',
    ...process.env.NODE_ENV == 'production' ? {


      base_url: BASE_API_URL,
      // base_url: `http://192.168.2.32:3000/api/v1`,
      // base_url: `https://dev.tigg.app/api/v1`
      // base_url: 'http://localhost:4000/api/v1',
    } : {},
    response_formatter: api_response_formatter,
  },
  auth: {
    network: {
      domain_name: 'auth',
      cache_for_seconds: 600,
      url: {
        auth_url: () => `/login`,
        refresh_url: () => `/refresh-token`,
      }
    },
    auth_token_request_formatter: auth_token_request_formatter,
    auth_token_response_formatter: auth_token_response_formatter,
    refresh_token_request_formatter: refresh_token_request_formatter,
    refresh_token_response_formatter: refresh_token_response_formatter,
  }
};

export default APP_CONFIG;

function api_response_formatter(response_data) {
  response_data = JSON.parse(response_data);
  let error = response_data.error || {};

  return {
    result: response_data.data,
    message: response_data.message,
    error_message: error.message,
    validation_errors: error.validation,
    meta: response_data.meta,
  };
}

function auth_token_request_formatter(request_data) {

  return request_data;
}

function auth_token_response_formatter(response_data) {
  let { access_token, refresh_token, first_login = true, expired, inactivity_interval = DEFAULT_LOGOUT_TIME } = response_data.result;
  let decoded = jwtDecode(access_token);
  let data = decoded.identity || {};
  let { permissions } = decoded.user_claims || {};
  return {
    access_token: access_token,
    refresh_token: refresh_token,
    isChanged: !first_login,
    expired: expired,
    logout_time: inactivity_interval,
    meta: {
      permissions: permissions,
      user_data: data
    }
  };
}

function refresh_token_request_formatter(request_data) {
  return request_data;
}

function refresh_token_response_formatter(response_data) {
  let { access_token, refresh_token, first_login = false, expired, inactivity_interval = DEFAULT_LOGOUT_TIME } = response_data.result;
  let decoded = jwtDecode(access_token);
  let data = decoded.identity || {};
  let { permissions } = decoded.user_claims || {};
  return {
    access_token: access_token,
    refresh_token: refresh_token,
    isChanged: !first_login,
    expired: expired,
    logout_time: inactivity_interval,
    meta: {
      permissions: permissions,
      user_data: data
    }
  };
}


export const table_data = {
  normal_perpage: 10,
  large_perpage: 100,
  normal_pageoptions: [10, 20, 30],
  large_pageoptions: [20, 40, 60, 100]
}