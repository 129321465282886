import React, { useState, useEffect } from 'react'
import { Layout, Menu, Breadcrumb, Dropdown, Divider, Tooltip } from 'antd';
import { EditOutlined, UserOutlined, LockOutlined, BellOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { RESTExecutor, withDomains } from "../../lib/domain";
import { CustomButton } from '../../components/forms/buttons';
import { PlusOutlined, SettingOutlined, LogoutOutlined, LeftOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import ProfileEditForm from './editProfile';
import ChangePasswordForm from './changePassoword';
import AccountOverviewPage from './accountOverview';
import NotificationsPage from './notifcations';
import { BASE_API_URL } from '../../config';

let userName = '';
let imgSource = '';
const { Content, Sider } = Layout;

const AccountSettingPage = ({
    domains,
    authenticator,
    ...props
}) => {


    let list_namespace = RESTExecutor.list()
        .config({
            label: 'list_namespace',
        })
        .connect(domains.user)

    let get_user = RESTExecutor.list()
        .config({
            label: 'me'
        }).callbacks(
            (success) => {
                console.log(success);
                imgSource = (success.result.profile_picture_id != "" && success.result.profile_picture_id !== null) ? success.result.profile_picture_id_url : `https://ui-avatars.com/api/?name=${success.result.name.split(" ")[0]}+${success.result.name.split(" ")[1]}&background=1ED760&color=fff&size=36&rounded=true`
                userName = success.result.name == '' ? success.result.email : success.result.name;
            }
        )
        .connect(domains.user)


    let upgrade_credentials = RESTExecutor.post()
        .config({
            label: 'upgrade_credentials',
        })
        .connect(domains.upgradeCredentials)

    useEffect(() => {
        get_user.execute(true)
        list_namespace.execute(true)
    }, [])

    const reloadProps = { get_user }

    let namespace_resp = list_namespace.response();
    let menu = (
        <Menu className={'dropdown-menu'}>
            <Menu.Item key='1' icon={<img className="user-avatar-img" src={imgSource} />} onClick={() => { props.history.push('/') }}>
                <span className="menu-item-text">{userName}</span>
            </Menu.Item>
            <Menu.Divider />

            {<div className="companies-menu-item-header" >Your Companies</div>}

            {
                (namespace_resp.result ?? []).map(
                    (item) =>

                        <Menu.Item key={item.slug}
                            icon={<div className="menu-item-avatar-container">
                                <img className="menu-item-avatar"
                                    src={(item.logo != "" && item.logo !== null) ? item.logo_url : `https://ui-avatars.com/api/?name=${item.name.split(" ")[0]}+${item.name.split(" ")[1]}&background=1ED760&color=fff`}
                                />
                            </div>}
                            title={`${item.name}`}
                            onClick={() => {
                                upgrade_credentials
                                    .onSuccess = ((resp) => {
                                        console.log(resp.result);
                                        let str = "";
                                        Object.entries(resp.result).map(([k, v]) => str += `${k}=${v}&`);
                                        let staging = process.env.REACT_APP_nodeenv === 'production' ? '' : '/staging'
                                        window.open(`https://${item.slug}.tigg.app${staging}/#/validate-login?${str.slice(0, -1)}`)
                                    });
                                upgrade_credentials.execute({
                                    "namespace": `${item.slug}`
                                })
                            }}
                        >
                            <span className="menu-item-text">{item.name}</span>
                        </Menu.Item>
                )
            }
            <Menu.Item key='2' icon={<PlusOutlined />} onClick={() => {
                props.history.push('/new-company');
            }}>
                Add New Company
            </Menu.Item>

            <Menu.Divider />
            <Menu.Item key='3' icon={<SettingOutlined />}>
                Account Settings
            </Menu.Item>

            <Menu.Divider />
            <Menu.Item key='4' icon={<LogoutOutlined />} onClick={() => authenticator.logout()}>
                Logout
            </Menu.Item>

        </Menu>
    );


    const [collapsed, setCollapsed] = useState(false);

    let handleCollapse = () => {
        setCollapsed(!collapsed);
    }

    const [selectedMenu, setSelectedMenu] = useState('2');

    let handleClick = (key) => {
        setSelectedMenu(key.key);
    }


    return (

        <div className={'application-container'} style={{ backgroundColor: "#EEF2F7" }}>

            <div className="main-header-menu">
                <div className="header-container">
                    <img style={{ cursor: 'pointer' }} src='images/logo.svg' className='main-header-menu-item' onClick={() => { window.open('https://tiggapp.com', "_self") }} />
                    <span className='main-header-menu-item'>
                        <span className='add-org-button'>
                            <CustomButton
                                onClick={() => {
                                    props.history.push({ pathname: `/new-company` });
                                }}
                                label="Add New Organisation"
                                style={{ borderRadius: "4px", backgroundColor: "#E8FBEF" }}
                            />
                        </span>
                        <Tooltip className='add-org-icon'
                            title='Add New Organisation'>
                            <PlusCircleOutlined
                                style={{ display: 'none', color: 'var(--primary)', fontSize: '32px' }}
                                onClick={() => {
                                    props.history.push({ pathname: `/new-company` });
                                }}
                            />
                        </Tooltip>
                        <span style={{ width: "20px" }}></span>



                        <Dropdown overlay={menu} trigger={['click']}>
                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                <img className="user-avatar-img" src={imgSource} />
                            </a>
                        </Dropdown>
                    </span>
                </div>
            </div>

            <div className='settings-container'>

                <Layout className='settings-layout'>

                    <Sider
                        theme="light"
                        breakpoint="lg"
                        trigger={null}
                        collapsible collapsed={collapsed} onCollapse={handleCollapse}>
                        <Menu theme="light" defaultSelectedKeys={['2']} mode="inline" onClick={handleClick} style={{ paddingTop: "20px" }}>
                            <Menu.Item className="account-setting-form-header" style={{ marginBottom: "25px" }} key="back" icon={<LeftOutlined />} onClick={() => { props.history.goBack() }}>
                                Account Settings
                            </Menu.Item>
                            <Menu.Item key="1" icon={<UserOutlined />}>
                                Account Overview
                            </Menu.Item>
                            <Menu.Item key="2" icon={<EditOutlined />}>
                                Edit Profile
                            </Menu.Item>
                            <Menu.Item key="3" icon={<LockOutlined />}>
                                Change Password
                            </Menu.Item>
                            <Menu.Item key="4" icon={<BellOutlined />}>
                                Notifications
                            </Menu.Item>
                        </Menu>
                    </Sider>
                    <Layout className="site-layout">
                        <Content
                            style={{ margin: '0px 10px' }}>
                            <>

                                {(() => {
                                    switch (selectedMenu) {
                                        case '1':
                                            return <AccountOverviewPage />
                                        case '2':
                                            return <ProfileEditForm {...reloadProps} />
                                        case '3':
                                            return <ChangePasswordForm />
                                        case '4':
                                            return <NotificationsPage />
                                        default:
                                            break;
                                    }
                                })()}
                            </>
                        </Content>
                    </Layout>
                </Layout>

            </div>
        </div>
    );
}

export default withDomains(AccountSettingPage, 'register', 'user', 'auth', 'upgradeCredentials', 'fileUpload')