import React, { Component } from 'react';
import Text from 'antd/lib/typography/Text';
import FormEditorsContainer, { LeftFormContainerWrapper } from './form-editors-container';
import { CustomButton } from './buttons';
import { Form } from "antd";

class SmallForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      submitted: false
    }
  }

  render() {
    let data = this.props.data || {}
    let networkState = this.props.networkState || {};
    const { submitted } = this.state;
    let validation_errors = {
      ...networkState.validation_errors,
    };
    let error_message = networkState.error_message || ""
    if (!submitted) {
      console.log("not submitted")
      validation_errors = {}
    }
    return (
      <form className='main-form-small'
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {typeof error_message === 'string' && submitted && <center><span className='t-meta-info error-color'>{error_message}</span></center>}

        <div className='main-form-small-header'>
          <div className='t-form-title'>{this.props.title}</div>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end'
          }}>
            {this.props.idKey && <Text editable={{
              onChange: (value) => {
                typeof this.props.onChange === 'function' && this.props.onChange({
                  ...data,
                  [this.props.idKey]: value
                })
              },
            }}

              style={{
                // marginTop: 20
              }}
              className='t-form-id ant-typography-edit-content'>{data[this.props.idKey]}</Text>
            }
            {Array.isArray(validation_errors['code']) && typeof validation_errors['code'][0] === 'string' &&
              <div className='t-meta-info error-color'>{validation_errors['code'][0]}</div>
            }
          </div>
        </div>
        <div className='scrollable-form-body'>
          <LeftFormContainerWrapper
            className="main-form-small-container "
            children={<FormEditorsContainer
              forEdit={this.props.forEdit}
              data={data}
              onRef={(ref) => {
                this.form = ref;
              }}
              validation_errors={validation_errors}
              onChange={(_data) => {
                console.log("data change", _data)
                typeof this.props.onChange === 'function' && this.props.onChange(_data)
                // this.setState({
                //   data
                // })
              }}
              fields={this.props.schema || []}
            />}
          />
        </div>

        <div className='main-form-small-footer'>
          <div className='left'>
            {typeof this.props.leftBottom === 'function' && this.props.leftBottom(this.props.data)}
          </div>
          <div className='right'>
            <CustomButton
              size="Large"
              htmlType={'submit'}
              loading={networkState.fetching}
              filled label={this.props.saveText ?? "SAVE"} onClick={() => {
                try {
                  let has_error = this.form.validateFormInternally();
                  if (!has_error) {
                    console.log("ready for submit in fe");
                    typeof this.props.onSave === 'function' && this.props.onSave()
                    this.setState({
                      submitted: true
                    })
                  } else {
                    console.log("not ready to submit")
                  }
                } catch (e) {
                  console.log("ready for submit in fe with error", e)
                  typeof this.props.onSave === 'function' && this.props.onSave()
                  this.setState({
                    submitted: true
                  })
                }
                // typeof this.props.onSave === 'function' && this.props.onSave()
              }} />
          </div>
        </div>
      </form >
    );
  }
}

export default SmallForm;
