import React, { Component } from 'react'
import {
    Table,
    Avatar,
    Input,
    Menu,
    Popover,
    Divider,
    Space,
    Drawer,
    Spin,
    Empty, Col, Checkbox, Layout, Select
} from 'antd'
import { CustomPagination } from '../custom_pagination'
import { TextInput } from '../forms/inputs'
import { SearchOutlined, FilterOutlined, LayoutOutlined, OrderedListOutlined, MoreOutlined, ArrowDownOutlined } from '@ant-design/icons'
import PopupFilterContainer from '../popup_filter_container'
import { TransparentButton, CustomButtonWithDropdownOnly } from '../forms/buttons'
import { LayoutManager } from '../../util/layout_manager'
import { SortIcon, TiggViewIcon, TableViewIcon, FilterIcon, SearchIcon, MoreIcon, DeleteIcon } from '../../assets/icons'
import { NetworkDelaySearch } from '../../lib/util'
import Modal from 'antd/lib/modal/Modal'
import lodash from 'lodash';
import NetworkPopupFilterContainer from '../network_popup_filter_container';
import PropTypes from 'prop-types'

const { Column, ColumnGroup } = Table;

/**
 * @constructor
 * @augments {Component<Props, State>}

 */


class BaseTable extends Component {
    static propTypes = {
        /** Filter Params Object {} */
        filters: PropTypes.object,
        /**
         * Columns Schema 
         * [{
         * title(any),dataIndex(strinng),filterAvailable(bool),
         * filter_options=[{key,value}],
         * width,render,sortAvailable,filterAPI,ellipsis
         * }]         
         */
        columns: PropTypes.array,

        /** on Filter Change in table to get new data */
        onChangeFilter: PropTypes.func,
        /** on Filter Change in table only */
        onUpdateFilter: PropTypes.func,

        /** Injectable custom tigg view , args: [list of data] */
        tiggRenderer: PropTypes.func,

        /** Handle on row click */
        onRowClick: PropTypes.func,

        /** List of data   */
        data: PropTypes.array,

        /** loading indicator for table */
        loading: PropTypes.bool,

        /** Meta data for pagination {current_page_total,limit,page_number,total,total_pages} */
        meta: PropTypes.object,
        /** Placeholder for search input */
        searchPlaceholder: PropTypes.string,
        /** Array of label,condition(optional function,arg:record) and  onClick for right alligned actions in each row*/
        actions: PropTypes.array,

        /** Array of type,label,onClick */
        selectionActions: PropTypes.array,

        /** Default size of table small or null  */
        defaultSize: PropTypes.string,

        /** Remove tigg view, size toggle and other action */
        removeRightTopAction: PropTypes.bool,

        /** function with arguement (record,index) */
        rowClassName: PropTypes.func

    }
    constructor(props) {
        super(props)

        this.state = {
            size: props.defaultSize || null,
            dataView: "TABLE",
            // TIGG OR TABLE
            popvisibleProps: {

            },
            selected: {
                keys: [],
                data: []
            },
            showSelected: false
        }
        this.getColumnTitle = this.getColumnTitle.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.delay_search = new NetworkDelaySearch();
    }

    getColumns = (_list = [], forDisplayOnly) => {
        let _cols = [];
        let old_filters = this.props.filters || {};
        _list.map((item, _col_index) => {
            if (item.children) {
                _cols.push(
                    <ColumnGroup title={item.title}>
                        {this.getColumns(item.children || [])}
                    </ColumnGroup>
                )
            } else {
                if (
                    (forDisplayOnly && item.forDisplay != true)
                    && (forDisplayOnly && item.dataIndex != 'name')
                    && (forDisplayOnly && item.dataIndex != 'code')
                ) {

                } else {
                    _cols.push(<Column
                        {...item}
                        className={`${item.className || ""} ${(Array.isArray(old_filters[item.dataIndex]) && old_filters[item.dataIndex].length > 0) ? 'sorted' : ''}`}
                        title={forDisplayOnly ? item.title : this.getColumnTitle(item.title, item, `${_col_index}-${item.dataIndex}`)}
                    />)
                }
            }
        })
        if (forDisplayOnly) {
            _cols.push(<Column
                width={90}
                key='removeAction'
                render={(val, record) => {
                    return <TransparentButton hint="Remove Selection" label="Remove"
                    type='dager'
                        className='danger-text' onClick={() => {
                            let total_keys = this.state.selected.keys || [];
                            let total_datas = this.state.selected.data || [];

                            let final_keys = lodash.differenceBy(total_keys, [record.id]);
                            let final_datas = lodash.differenceBy(total_datas, [record]);
                            this.setState({
                                selected: {
                                    keys: final_keys,
                                    data: final_datas
                                },
                                showSelected: final_keys.length != 0
                            })
                        }} />
                }}
            />)
        }
        if (Array.isArray(this.props.actions) && this.props.actions.length > 0 && forDisplayOnly != true) {
            let lastColumn = {
                width: 40,
                align: 'center',
                dataIndex: 'action',
                render: (val, record, index) => {
                    const _menu = []
                    this.props.actions.map((_act_item, _ind) => {
                        typeof _act_item.condition === 'function' && console.log("comparison", _act_item.condition(record))
                        if (typeof _act_item.condition === 'function' && _act_item.condition(record) == false) {
                        } else {
                            _menu.push(<Menu.Item className='custom-dropdown-btn-menu-item' key={_ind} onClick={(e) => {
                                // console.log("menu clicked",e.domEvent.preventDefault())
                                e.domEvent.preventDefault();
                                e.domEvent.stopPropagation();
                                typeof _act_item.onClick === 'function' && _act_item.onClick(record, index)
                            }} >
                                {_act_item.label}
                            </Menu.Item>)
                        }
                    })
                    console.log("menu item ", _menu)
                    if (_menu.length == 0) {
                        return;
                    }
                    return <Popover
                        overlayClassName='noPaddingPopover'
                        trigger='click'

                        content={<Menu className='custom-dropdown-btn-menu'>
                            {_menu}
                        </Menu>}>
                        <MoreIcon onClick={(e) => {
                            e.stopPropagation()
                        }} />
                    </Popover>
                }
            };
            _cols.push(<Column {...lastColumn} />)

        }
        return _cols
    }
    getColumnTitle = (title, item, _index) => {
        let old_filters = this.props.filters || {};
        let popvisibleProps = this.state.popvisibleProps || {}
        let order = old_filters.order || [];
        let order_key = order[0] || "349839434734837483";
        return <div title={typeof title === 'string' ? title : null}
            className={`col-title-container`}>
            <div className={`col-title 
                ${item.sortAvailable == true ? 'sort' : ''}
                ${item.sortAvailable == true && order_key == item.dataIndex ? 'desc sorted' : ''}
                ${item.sortAvailable == true && order_key == `-${item.dataIndex}` ? 'asc sorted' : ''}
                `}
                onClick={() => {
                    console.log("order", item.dataIndex, order_key)
                    if (item.sortAvailable == true && typeof this.props.onChangeFilter === 'function') {
                        if (order_key == item.dataIndex) {
                            console.log("same order positive")
                            this.props.onChangeFilter({
                                ...old_filters,
                                order: [`-${item.dataIndex}`]
                            })
                        } else if (order_key == `-${item.dataIndex}`) {
                            console.log("same order negative")
                            delete old_filters['order']
                            this.props.onChangeFilter({
                                ...old_filters,
                            })
                        }
                        else {
                            console.log("no any order")
                            this.props.onChangeFilter({
                                ...old_filters,
                                order: [`${item.dataIndex}`]
                            })
                        }
                    }
                }}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}>
                {item.sortAvailable == true &&
                    <SortIcon className='sort-icon' />
                }
                <div className='text-title'>{title}</div>

            </div>
            {item.filterAvailable &&
                <Popover
                    placement='bottomLeft'
                    overlayClassName='noPaddingPopover'
                    visible={popvisibleProps[_index]}
                    onVisibleChange={(visible) => {
                        console.log("visible change", visible)
                        this.setState({
                            popvisibleProps: {
                                ...popvisibleProps,
                                [_index]: visible
                            }
                        })
                    }}
                    ref={(ref) => {
                        this.popover = ref;

                        // this[`${item.dataIndex}-popover`]=ref
                    }}
                    content={Array.isArray(item.filter_options) ? < PopupFilterContainer
                        options={item.filter_options || []}
                        values={old_filters[item.dataIndex]}
                        onOK={(filtered_keys) => {
                            this.props.onChangeFilter({
                                ...old_filters,
                                [item.dataIndex]: filtered_keys
                            });
                            this.setState({
                                popvisibleProps: {
                                    ...popvisibleProps,
                                    [_index]: false
                                }
                            })

                            // this.popover.
                            // console.log("filtered keys", filtered_keys)

                        }}
                        onCancel={() => {
                            this.setState({
                                popvisibleProps: {
                                    ...popvisibleProps,
                                    [_index]: false
                                }
                            })
                        }}
                    /> : item.filterAPI ?
                            <NetworkPopupFilterContainer
                                apiURL={item.filterAPI}
                                pastURL={item.filterAPI}

                                // options={item.filter_options || []}
                                value={old_filters[item.dataIndex]}
                                onOK={(filtered_keys) => {
                                    this.props.onChangeFilter({
                                        ...old_filters,
                                        [item.dataIndex]: filtered_keys
                                    });
                                    this.setState({
                                        popvisibleProps: {
                                            ...popvisibleProps,
                                            [_index]: false
                                        }
                                    })

                                    // this.popover.
                                    // console.log("filtered keys", filtered_keys)

                                }}
                                onCancel={() => {
                                    this.setState({
                                        popvisibleProps: {
                                            ...popvisibleProps,
                                            [_index]: false
                                        }
                                    })
                                }}
                            />
                            : <div>Neither  filter_options=[] nor filterAPI provided</div>}
                    trigger='click'>
                    <FilterIcon title="Filter Data" onClick={(e) => {
                        e.stopPropagation()
                    }} className='filter-icon' />
                </Popover>
            }
        </div>
    }

    _selectedActionItemRender = (item) => {

        return <div onClick={() => {
            typeof item.onClick === 'function' && item.onClick(this.state.selected.keys, this.state.selected.data, () => {
                this.setState({
                    selected: {
                        keys: [],
                        data: []
                    }
                })
            })
        }} className={`selected-actions-item ${item.type || "primary"}`}>{item.label}</div>
    }

    render() {

        let tableSize = this.state.size || ''; //small or empty string


        let columns = this.props.columns || [];



        let meta = this.props.meta || {}

        let data = this.props.data || [];
        let allKeys = data.map(_d => _d.id);
        console.log("ALL KEYS", allKeys)
        let old_filters = this.props.filters || {};
        let selectionActions = this.props.selectionActions || [
            {
                type: 'primary',
                onClick: (keys, datas, callback) => {
                    console.log("clicked", keys, datas)
                    typeof callback === 'function' && callback()
                },
                label: "Approve Selected"
            },
            {
                type: 'success',
                onClick: (keys, datas, callback) => {
                    console.log("clicked", keys, datas)
                    typeof callback === 'function' && callback()
                },
                label: "Clear Selection"
            },
            {
                type: 'danger',
                onClick: (keys, datas, callback) => {
                    console.log("clicked", keys, datas)
                    typeof callback === 'function' && callback()
                },
                label: "Delete Selected"
            }
        ];

        const { selected } = this.state;

        return (
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    paddingLeft: LayoutManager.globalPadding,
                    paddingRight: LayoutManager.globalPadding
                }}>
                {selected.keys.length > 0 ?
                    <div className='table-selection-actions-row'>
                        <div onClick={() => {
                            this.setState({
                                showSelected: true
                            })
                        }} className='selected-title'>{selected.keys.length} Items Selected</div>
                        <div className='selection-actions-container'>
                            {selectionActions.map((it) => {
                                return this._selectedActionItemRender(it)
                            })}
                        </div>
                    </div>
                    : null
                }
                <Drawer
                    closeIcon={null}
                    visible={this.state.showSelected}
                    onClose={() => {
                        this.setState({
                            showSelected: false
                        })
                    }}
                    className='selected-item-table-drawer'
                    placement='right'
                    width={'80%'}
                    destroyOnClose  >
                    <div className='selected-drawer-action-container'>
                        <div onClick={() => {
                            this.setState({
                                showSelected: true
                            })
                        }} className='selected-title'>{selected.keys.length} Items Selected</div>
                        <div className='selection-actions-container'>
                            {selectionActions.map((it) => {
                                return this._selectedActionItemRender(it)
                            })}
                        </div>
                    </div>
                    <div className='selected-drawer-table-container'>
                        <Table
                            // rowSelection={selectionActions.length > 0 ? {
                            //     selectedRowKeys: selected.keys,
                            //     onChange: (_keys, _data) => {
                            //         this.setState({
                            //             selected: {
                            //                 keys: _keys,
                            //                 data: _data
                            //             }
                            //         })
                            //     },
                            // } : null}
                            rowKey={(record, index) => `${record.id}`}
                            // rowClassName={this.props.rowClassName}
                            // rowClassName={(record, index) => {
                            //     return 'hand-cursor'
                            // }}
                            // onRow={(record, rowIndex) => {
                            //     return {
                            //         onClick: event => {
                            //             typeof this.props.onRowClick === 'function' && this
                            //                 .props
                            //                 .onRowClick(rowIndex, record)
                            //         }
                            //     }
                            // }}
                            className={`main-table base-table primary-hover-row main-table-${tableSize}`}
                            dataSource={selected.data}
                            pagination={false}>
                            {this.getColumns(columns, true)}
                        </Table>
                    </div>
                </Drawer>

                <div
                    className='no-wrap-rl-row  table-top-content '
                    style={{
                        backgroundColor: '#fff'
                    }}>

                    <div style={{
                        flex: 1
                    }}>
                        <TextInput
                            value={old_filters.search_phrase}
                            onChange={(val) => {
                                typeof this.props.onUpdateFilter === 'function' &&
                                    this.props.onUpdateFilter({
                                        ...old_filters,
                                        search_phrase: val == "" ? undefined : val
                                    }, (_d) => {
                                        this.delay_search.onSearch(() => {
                                            typeof this.props.onChangeFilter === 'function' &&
                                                this.props.onChangeFilter(_d)
                                        })
                                    })
                            }}
                            inputClassName='no-border-input'
                            placeholder={this.props.searchPlaceholder || "Search For..."}
                            leftIcon={< SearchIcon className='font-sm gray50' style={{
                                marginRight: 15
                            }} />} />
                    </div>
                    <Divider
                        style={{
                            height: '100%',
                            marginLeft: 25,
                            marginRight: 25
                        }}
                        type='vertical' />
                    <CustomPagination meta={meta} onChange={(page, size) => {
                        console.log("on page chnage", page, size)
                        typeof this.props.onChangeFilter === 'function' &&
                            this.props.onChangeFilter({
                                ...old_filters,
                                page: page,
                                limit: size
                            })
                    }} />
                    {this.props.removeRightTopAction ? <></> : <>
                        <Divider
                            style={{
                                height: '100%',
                                marginLeft: 25,
                                marginRight: 25
                            }}
                            type='vertical' />
                        <div className='no-wrap-rl-row'>
                            <Space size={50}>
                                {/* <TransparentButton onClick={() => {
                                this.setState({
                                    dataView: 'TIGG'
                                })
                            }}
                                icon={< TiggViewIcon className={this.state.dataView == "TIGG" ? 'success-icon' : null} />} /> */}
                                <TransparentButton type={tableSize == 'small' ? 'primary' : null}
                                    onClick={() => {
                                        this.setState({
                                            size: tableSize == 'small' ? null : 'small',
                                            dataView: 'TABLE'
                                        })
                                    }}
                                    icon={< TableViewIcon />} />
                                <Popover
                                    overlayClassName='noPaddingPopover'
                                    trigger='click'
                                    content={< Menu className='custom-dropdown-btn-menu' onClick={
                                        (e) => {
                                            console.log("clicked menu", e)
                                        }
                                    } >
                                        <Menu.Item className='custom-dropdown-btn-menu-item' key="1">Export to PDF</Menu.Item>
                                        <Menu.Item className='custom-dropdown-btn-menu-item' key="2">Export to CSV</Menu.Item>
                                        <Menu.Item className='custom-dropdown-btn-menu-item' key="3">Export Other..</Menu.Item >
                                    </Menu>}>
                                    <TransparentButton icon={< MoreOutlined />} label="OPTIONS" />
                                </Popover>

                            </Space>
                        </div>
                    </>
                    }
                </div>
                {(this.state.dataView == 'TIGG' && typeof this.props.tiggRenderer === 'function') ?
                    <Spin spinning={this.props.loading}><div className='tigg-container'>
                        {this.props.loading != true && data.length == 0 && <Empty />}
                        {this.props.tiggRenderer(data)}
                    </div></Spin>
                    :
                    <Table
                        loading={this.props.loading}
                        rowSelection={selectionActions.length > 0 ? {
                            selectedRowKeys: selected.keys,
                            onChange: (_keys, _data) => {
                                console.log("selected keys and data", _keys, _data);
                                let to_remove_keys = lodash.differenceBy(allKeys, _keys);
                                let to_remove_datas = lodash.differenceBy(data, _data);

                                let total_keys = lodash.uniq(Array().concat(this.state.selected.keys, _keys));
                                let total_datas = lodash.uniq(Array().concat(this.state.selected.data, _data), 'id');
                                let final_keys = lodash.differenceBy(total_keys, to_remove_keys);
                                let final_datas = lodash.differenceBy(total_datas, to_remove_datas);

                                console.log("to remove key", { to_remove_keys, _keys, total_keys, final_keys, allKeys })
                                this.setState({
                                    selected: {
                                        keys: final_keys,
                                        data: final_datas
                                    }
                                })
                            },
                        } : null}
                        rowKey={(record, index) => `${record.id}`}
                        rowClassName={(record, index) => {
                            return typeof this.props.onRowClick === 'function' ? `hand-cursor` :
                                typeof this.props.rowClassName === 'function' ? this.props.rowClassName(record, index) : ''
                        }}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => {
                                    typeof this.props.onRowClick === 'function' && this
                                        .props
                                        .onRowClick(record, rowIndex)
                                }
                            }
                        }}
                        className={`main-table base-table primary-hover-row main-table-${tableSize}`}
                        dataSource={data}
                        pagination={false}>
                        {this.getColumns(columns)}

                    </Table>
                }
                <div className='no-wrap-rl-row' style={{
                    justifyContent: 'flex-end',
                    paddingTop: LayoutManager.globalPadding,
                    paddingBottom: LayoutManager.globalPadding,
                }}>
                    <div className='no-wrap-rl-row' style={{
                         marginRight:LayoutManager.globalMargin/2
                    }}>
                        <div className='font-sm gray80' style={{
                            marginRight:LayoutManager.globalMargin/2
                        }}>Rows Count</div>
                        <Select value={old_filters.limit||100} onChange={(v)=>{
                            this.props.onChangeFilter({
                                ...old_filters,
                                page: 1,
                                limit: v
                            })
                        }}>
                            {[10,25,50,75,100].map((lim)=>{
                                return <Select.Option key={lim} value={lim}>{lim}</Select.Option>
                            })}

                        </Select>

                    </div>
                    <CustomPagination meta={meta} onChange={(page, size) => {
                        console.log("on page chnage", page, size)
                        typeof this.props.onChangeFilter === 'function' &&
                            this.props.onChangeFilter({
                                ...old_filters,
                                page: page,
                                limit: size
                            })
                    }} />

                </div>
            </div>
        )
    }
}

export default BaseTable
