import React, { useEffect, useState } from "react";
import { Menu, Button, message, Modal } from "antd";
import { RESTExecutor, withDomains } from "../../lib/domain";
import CompanyDetailCard from "../../components/company_detail_card";
import { ExclamationCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { BASE_API_URL } from "../../config";

const { confirm } = Modal;

const Final = ({ domains, ...props }) => {


    const requestsMenu = (id) => {
        return (
            <Menu>
                <Menu.Item icon={<CloseOutlined />} onClick={() => {

                    function deleteCompany() {
                        delete_companies.forId(id).execute(true);
                    }
                    showPromiseConfirm("Do you really want to delete your request?", deleteCompany);

                }}>
                    Delete Request
                </Menu.Item>
            </Menu>
        );
    };


    let delete_companies = RESTExecutor.delete()
        .config({
            label: 'namespace_requests',
        })
        .callbacks(
            (success) => props.props.history.push('/'),
            (error) => {
                error.error_message && message.error(error.error_message);
            }
        )
        .connect(domains.register)


    let get_companies = RESTExecutor.list()
        .config({
            label: 'namespace_requests',
        })
        .callbacks(
            (success) => {
            },
            (error) => {
                console.log(error);
                error.error_message && message.error("2", error.error_message);
            }
        )
        .forId(props.regId)
        .connect(domains.register)


    useEffect(() => {
        get_companies.execute();
    }, [
        props.regId
    ]);

    function showPromiseConfirm(title, ok) {
        confirm({
            content: "This action cannot be reversed",
            title: title,
            icon: <ExclamationCircleOutlined />,
            onOk() {
                ok()
            },
            onCancel() { },
        });
    }

    let companies_resp = get_companies.response().result;
    let delete_resp = delete_companies.response();

    return (
        <div className="steps-final-container">
            <div className="steps-final-card">

                <CompanyDetailCard
                    avatar={(companies_resp?.data.logo != null && companies_resp?.data.name != undefined) ? companies_resp?.data.logo_url : `https://ui-avatars.com/api/?name=${companies_resp?.data.name.split(" ")[0]}+${companies_resp?.data.name.split(" ")[1]}&background=1ED760&color=fff`}
                    loading={delete_resp?.fetching}
                    menu={requestsMenu(props.regId)}
                    key={props.regId}
                    slug={companies_resp?.data.slug}
                    title={companies_resp?.data.name}
                    subtitle={`${companies_resp?.data.display_name} | ${companies_resp?.data.slug}.tigg.app`}
                    actionName="Delete Request"
                    detailInfo={`Status ${companies_resp?.data.status}`}
                    onClick={() => {

                        function deleteCompany() {
                            delete_companies.forId(companies_resp?.data.id).execute(true);
                        }
                        showPromiseConfirm("Do you really want to delete your request?", deleteCompany);

                    }}
                />
            </div>

            <div className="steps-help-text">
                We will review your submission and contact you through email or phone within 7 business days<br />
                For other inquiries, contact us at <a href="mailto:hello@tiggapp.com?subject=Inquiries">hello@tiggapp.com</a>
            </div>
            <div className="steps-content-buttons">
                <Button
                    onClick={() => {
                        props.props.history.push('/')
                    }}
                    size="large"
                    htmlType="submit" className="steps-form-button">
                    Go to your Profile
                </Button>
            </div>
        </div>
    );
}

export default withDomains(Final, 'register')