import { BASE_API_URL } from "../config";

const CACHE_SECONDS = 0;

const basicDomains = {};

// var BASE_URL='http://localhost:4000/api/v1';
// var BASE_URL='http://192.168.2.84:3000/api/v1';
// var BASE_URL = 'https://dev.tigg.app/api/v1/user';
var BASE_URL = BASE_API_URL;

if (process.env.NODE_ENV == 'production') {
    // BASE_URL='https://dev.tigg.app/api/v1' q
    // BASE_URL='http://localhost:4000/api/v1';
}
const networkDomains = {
    "user": {
        url: {
            me: () => `${BASE_URL}/me`,
            edit: () => `${BASE_URL}/edit-me-user`,
            list_namespace: () => `${BASE_URL}/namespaces`,
            send_invite: ({ identifier }) => `${BASE_URL}/${identifier}/namespace-invite`,
            namespace_invitations: () => `${BASE_URL}/namespace-invitations`,
            respond_to_invitation: () => `${BASE_URL}/respond-to-invitation`,
            attachments: () => `${BASE_URL}/attachments`,
            leave_company: () => `${BASE_URL}/leave-company`
        }
    },

    "register": {
        url: {
            register: () => `${BASE_URL}/user-registration-qualify`,
            verify_code: () => `${BASE_URL}/user-registration-verify`,
            register_user: () => `${BASE_URL}/user-registration`,
            namespace_register: () => `${BASE_URL}/namespace-requests`,
            namespace_requests: ({ identifier }) => `${BASE_URL}/namespace-requests/${identifier}`
        }
    },

    "password": {
        url: {
            send_email: () => `${BASE_URL}/get-forget-password-link`,
            reset_password: ({ identifier }) => `${BASE_URL}/forget-password${identifier}`,
            change_password: () => `${BASE_URL}/change-password`,
        }
    },
    "upgradeCredentials": {
        url: {
            upgrade_credentials: () => `${BASE_URL}/upgrade-credentials`,
        }
    },

    "fileUpload": {
        url: {
            upload: () => `/upload-file`,
            download: () => `/download-file`,
        }
    },

    "dynamic": {
        // urls used for dynamic selection
        url: {}
    },

};

export { basicDomains, networkDomains };
